import React from 'react'
import styled from 'styled-components'

export default function OlympusTooltipContent({ text }) {
  return (
    <div>
      <TooltipText>{text}</TooltipText>
    </div>
  )
}

const TooltipText = styled.div`
  color: #dadce0;
  font-size: 16px;
  line-height: 19px;
  max-width: 270px;
  ${'@media screen and (max-width: 1540px)'} {
    font-size: 12px;
    line-height: 13px;
    max-width: 205px;
  }
  ${'@media screen and (max-width: 1100px)'} {
    font-size: 8px;
    line-height: 9px;
    max-width: 140px;
  }
  ${'@media screen and (max-width: 620px), screen and (max-height: 810px)'} {
    font-size: 12px;
    line-height: 13px;
    max-width: 205px;
  }
  ${'@media screen and (max-width: 400px), screen and (max-height: 540px)'} {
    font-size: 8px;
    line-height: 9px;
    max-width: 140px;
  }
`
