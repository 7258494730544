import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import bgTop from '../../img/header/bg-top.svg'
import bgBot from '../../img/header/bg-bot.svg'
import SocialBg from '../../img/header/border.svg'
import SocialBgSm from '../../img/header/border-sm.svg'
// import deckImg from "../../img/docs/deck-img.svg";
// import paperImg from "../../img/docs/paper-img.svg";
// import occamXImg from "../../img/docs/occamX-img.svg";
import { Link } from 'react-router-dom'
import Socials from '../Socials/Socials'
import {
  APPLY_IDO_LINK,
  FORUM_LINK,
  CAREERS_LINK,
  OCCAMX_LINK,
  OCCAMX_DASHBOARD_LINK,
  KYC_LINK,
} from '../../configs/app.config'
import { ReactComponent as Arrow } from 'img/header/arrow.svg'
// import socialsBg from '../../img/header/menuSocialsBg.svg'
// import socialsBgMob from '../../img/header/menuSocialsBgMob.svg'
// import useWindowSize from "../../helpers/utils/useWindowSize";
// import { APPLY_IDO_LINK, ADA_STAKE_POOL_LINK, CAREERS_LINK } from '../../configs/app.config'

interface menuProps {
  active: boolean
  onClose: () => void
}

const HeaderMenu: React.FunctionComponent<menuProps> = (props) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <>
      <HeaderBackground onClick={props.onClose} isActive={props.active} />
      <HeaderMenuRoot isActive={props.active}>
        <MenuContent>
          <Links>
            <Link to={{ pathname: OCCAMX_LINK }} target="_blank">
              OccamX
            </Link>
            <Link to={{ pathname: OCCAMX_DASHBOARD_LINK }} target="_blank">
              OCX DASHBOARD
            </Link>
            {/* <Link to={'/ecosystem'}>ECOSYSTEM</Link> */}
            {/* <Link to={"/team"}>TEAM</Link> */}
            <Link to={{ pathname: APPLY_IDO_LINK }} target="_blank">
              APPLY FOR IDO
            </Link>
            <Link to={{ pathname: CAREERS_LINK }} target="_blank">
              CAREERS
            </Link>
            <Link to={{ pathname: KYC_LINK }} target="_blank">
              IDO KYC
            </Link>
            {/*<Link to={"/swap"}>ETH {"<>"} ADA BRIDGE</Link>*/}
            <Link to={'/incubator'}>Incubator</Link>
            <Link to={'/vcdao'}>VC DAO</Link>
            <Link to={{ pathname: FORUM_LINK }} target="_blank">
              Forum
            </Link>
          </Links>
          <BottomBlock>
            {/*<Docs>*/}
            {/*  <DocWrap href={'/docs/occam_deck.pdf'}>*/}
            {/*    <DocImg src={deckImg} />*/}
            {/*    <DocName>Deck</DocName>*/}
            {/*  </DocWrap>*/}
            {/*  <DocWrap href={'/docs/OccamX_Whitepaper.pdf'}>*/}
            {/*    <DocImg src={paperImg}/>*/}
            {/*    <DocName>White Paper</DocName>*/}
            {/*  </DocWrap>*/}
            {/*  <DocWrap href={'/docs/occamrazer_wp_v1.pdf'}>*/}
            {/*    <DocImg src={occamXImg} />*/}
            {/*    <DocName>OccamX</DocName>*/}
            {/*  </DocWrap>*/}
            {/*</Docs>*/}
            <SocialsWrap>
              <SocialsCont>
                <Socials wrap={true} />
              </SocialsCont>
              <SocialText>Be a part of our community</SocialText>
            </SocialsWrap>
          </BottomBlock>
          <Bg>
            <BgRow>
              <BgTop />
            </BgRow>
            <BgRow>
              <BgMid />
            </BgRow>
            <BgRow>
              <BgBot />
            </BgRow>
          </Bg>
        </MenuContent>
      </HeaderMenuRoot>
    </>
  )
}

export default HeaderMenu

const HeaderBackground = styled.div<{ isActive: boolean }>`
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  transition: 0.5s ease-in-out;
  opacity: ${({ isActive }) => (isActive ? '1' : '0')};
  z-index: ${({ isActive }) => (isActive ? '6' : '0')};
  pointer-events: ${({ isActive }) => (isActive ? 'auto' : 'none')};
  backdrop-filter: blur(5px);
`

const HeaderMenuRoot = styled.div<{ isActive: boolean }>`
  position: fixed;
  height: 100%;
  width: 734px;
  top: 0;
  right: ${({ isActive }) => (isActive ? '0' : '-100%')};
  transition: 0.5s ease-in-out;
  overflow: hidden;
  z-index: 10;
  @media screen and (max-width: 1440px) {
    right: ${({ isActive }) => (isActive ? '0' : '-734px')};
  }

  ${({ theme }) => theme.adaptive.headerMenu.sm} {
    right: unset;
    left: ${({ isActive }) => (isActive ? '0' : '100%')};
    width: 708px;
  }

  @media screen and (min-width: 2000px) {
    width: 1000px;
  }
`

const MenuContent = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  padding: 110px 183px 30px 100px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media screen and (max-width: 680px) {
    padding: 110px 143px 30px 140px;
  }

  ${({ theme }) => theme.adaptive.headerMenu.sm} {
    padding: 80px 183px 78px 40px;
  }

  ${({ theme }) => theme.adaptive.headerMenu.smm} {
    padding: 80px 183px 30px 20px;
  }

  @media screen and (max-height: 500px) and (min-width: 621px) {
    padding: 90px 143px 10px 140px;
  }
  @media screen and (min-width: 2000px) {
    padding: 55px 183px 30px 128px;
  }
`

const Bg = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  z-index: -1;
`

const BgTop = styled.div`
  width: 100%;
  height: 154px;
  background: url('${bgTop}') center no-repeat;

  ${({ theme }) => theme.adaptive.headerMenu.sm} {
    display: none;
  }

  @media screen and (min-width: 2000px) {
    height: 200px;
    background-size: cover;
  }
`

const BgRow = styled.div`
  display: flex;
  justify-content: flex-end;

  &:nth-child(2) {
    flex: 1;
  }
`

const BgBot = styled.div`
  width: 708px;
  height: 306px;
  background: url('${bgBot}') center no-repeat;

  ${({ theme }) => theme.adaptive.headerMenu.sm} {
    margin-bottom: 55px;
  }

  ${({ theme }) => theme.adaptive.headerMenu.smm} {
    display: none;
  }

  @media screen and (min-width: 2000px) {
    width: 966px;
    height: 350px;
    background-size: cover;
  }
`

const BgMid = styled.div`
  width: 708px;
  height: 100%;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.65), rgba(0, 0, 0, 0.65)),
    linear-gradient(90deg, #505d6d 0%, #31334a 47.4%, #151821 100%);
  opacity: 0.95;
  @media screen and (min-width: 2000px) {
    width: 964px;
  }
`

const Links = styled.div`
  ${({ theme }) => theme.adaptive.headerMenu.slg} {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    max-height: 245px;
  }

  ${({ theme }) => theme.adaptive.headerMenu.sm} {
    max-height: unset;
    flex-wrap: nowrap;
  }

  a {
    position: relative;
    display: block;
    font-weight: 300;
    font-size: 24px;
    line-height: 29px;
    text-transform: uppercase;
    color: #939ba9;
    margin-bottom: 20px;
    transition: 0.4s;

    ${({ theme }) => theme.adaptive.headerMenu.lg} {
      margin-bottom: 12px;
    }

    ${({ theme }) => theme.adaptive.headerMenu.slg} {
      font-size: 20px;
      line-height: 24px;
      width: 220px;
      margin-bottom: 20px;

      &:nth-child(6n) {
        margin-bottom: 0;
      }
    }

    ${({ theme }) => theme.adaptive.headerMenu.md} {
      margin-bottom: 12px;

      &:nth-child(6n) {
        margin-bottom: 12px;
      }
    }

    ${({ theme }) => theme.adaptive.headerMenu.smm} {
      width: 180px;
      font-size: 18px;
      line-height: 22px;
      margin-bottom: 8px;

      &:nth-child(6n) {
        margin-bottom: 8px;
      }
    }

    @media screen and (max-height: 470px) {
      margin-bottom: 6px;
      &:nth-child(6n) {
        margin-bottom: 6px;
      }
    }

    &:last-child {
      margin: 0;
    }

    &:hover {
      color: #ffffff;
    }
  }
`

const DropDownText = styled.div`
  position: relative;
  display: block;
  font-weight: 300;
  font-size: 26px;
  line-height: 31px;
  text-transform: uppercase;
  transition: 0.4s;

  ${({ theme }) => theme.adaptive.headerMenu.slg} {
    font-size: 24px;
    line-height: 29px;
  }

  ${({ theme }) => theme.adaptive.headerMenu.md} {
    font-size: 18px;
    line-height: 22px;
  }

  ${({ theme }) => theme.adaptive.headerMenu.sm} {
    font-size: 12px;
    line-height: 14px;
  }

  ${({ theme }) => theme.adaptive.headerMenu.smm} {
    font-size: 18px;
    line-height: 22px;
  }
`

const DropDownIcon = styled.div`
  width: 10px;
  height: 4px;
  transition: 0.4s;
  margin-left: 10px;

  ${({ theme }) => theme.adaptive.headerMenu.sm} {
    width: 7px;
    height: 3px;
  }

  ${({ theme }) => theme.adaptive.headerMenu.smm} {
    width: 10px;
    height: 4px;
  }

  svg {
    display: block;
    width: 100%;
    height: 100%;
    transition: 0.4s;
  }
`

const DropDownLinks = styled.div<{ isOpen: boolean }>`
  display: flex;
  flex-wrap: wrap;
  width: 305px;
  opacity: ${({ isOpen }) => (isOpen ? '1' : '0')};
  transition: 0.4s;
  margin-top: 16px;
  pointer-events: ${({ isOpen }) => (isOpen ? 'auto' : 'none')};

  ${({ theme }) => theme.adaptive.headerMenu.slg} {
    width: 275px;
    margin-top: 16px;
  }

  ${({ theme }) => theme.adaptive.headerMenu.md} {
    margin-top: 10px;
  }

  ${({ theme }) => theme.adaptive.headerMenu.sm} {
    width: 220px;
    margin-top: 7px;
  }

  ${({ theme }) => theme.adaptive.headerMenu.smm} {
    width: 275px;
    margin-top: 10px;
  }

  a {
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    color: #5e5e5e;
    opacity: 0.7;
    margin-right: 18px;
    margin-bottom: 4px;

    ${({ theme }) => theme.adaptive.headerMenu.slg} {
      font-size: 14px;
      margin-bottom: 0;
    }

    ${({ theme }) => theme.adaptive.headerMenu.md} {
      font-size: 14px;
    }

    ${({ theme }) => theme.adaptive.headerMenu.sm} {
      font-size: 10px;
      line-height: 16px;
    }

    ${({ theme }) => theme.adaptive.headerMenu.smm} {
      font-size: 14px;
    }
  }
`

const DropDown = styled.div<{ isOpen: boolean }>`
  display: flex;
  align-items: center;
  cursor: pointer;

  ${DropDownText} {
    color: #939ba9;
  }

  ${DropDownIcon} {
    svg {
      stroke: #939ba9;
    }
  }

  ${({ isOpen }) => {
    if (isOpen)
      return css`
        ${DropDownText} {
          color: #939ba9;
        }

        ${DropDownIcon} {
          transform: rotate(180deg);

          svg {
            stroke: #939ba9;
          }
        }
      `
  }}
  &:hover {
    ${DropDownIcon} {
      svg {
        stroke: #ffffff;
      }
    }

    ${DropDownText} {
      color: #ffffff;
    }
  }
`

const BottomBlock = styled.div``

const Docs = styled.div`
  display: flex;

  ${({ theme }) => theme.adaptive.headerMenu.smm} {
    display: block;
    position: absolute;
    top: 78px;
    left: 210px;
  }
`

const DocWrap = styled.a`
  display: block;
  width: 90px;
  margin-right: 15px;

  &:last-child {
    margin: 0;
  }

  ${({ theme }) => theme.adaptive.headerMenu.smm} {
    margin-bottom: 15px;
    margin-right: 0;
  }
`

const DocName = styled.div`
  width: 100%;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  text-transform: capitalize;
  color: #c7ccd1;
  margin-top: 10px;
`

const DocImg = styled.img`
  display: block;
  margin: 0 auto;
  width: 56px;
  height: 38px;
`

const SocialText = styled.div`
  width: 187px;
  margin-left: 60px;
  font-weight: 200;
  font-size: 32px;
  line-height: 120%;
  letter-spacing: -0.02em;
  color: #939ba9;
  opacity: 0.7;

  ${({ theme }) => theme.adaptive.headerMenu.sm} {
    font-size: 24px;
    width: 100%;
    margin: 22px 0 0 0;
    text-align: right;
  }
`

const SocialsWrap = styled.div`
  margin-top: 30px;
  display: flex;
  padding: 30px 40px;
  width: 451px;
  height: 139px;
  background: url('${SocialBg}') center no-repeat;

  ${({ theme }) => theme.adaptive.headerMenu.sm} {
    flex-wrap: wrap;
    padding: 23px 20px;
    margin-left: 30px;
    width: 311px;
    height: 119px;
    background: url('${SocialBgSm}') center no-repeat;
    background-size: cover;
  }

  ${({ theme }) => theme.adaptive.headerMenu.smm} {
    margin-left: 0;
    margin-top: 20px;
  }

  @media screen and (max-height: 450px) {
    display: none;
  }
`

const SocialsCont = styled.div`
  max-width: 125px;

  ${({ theme }) => theme.adaptive.headerMenu.sm} {
    width: 100%;
    max-width: unset;
  }

  a {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: 27px;
    height: 26px;
    margin: 23px 21px 0 0;

    ${({ theme }) => theme.adaptive.headerMenu.sm} {
      margin: 0 21px 0 0;
    }

    &:nth-child(1),
    &:nth-child(2),
    &:nth-child(3) {
      margin-top: 0;
    }

    &:nth-child(3n) {
      margin-right: 0;

      ${({ theme }) => theme.adaptive.headerMenu.sm} {
        margin: 0 21px 0 0;
      }
    }

    &:last-child {
      ${({ theme }) => theme.adaptive.headerMenu.sm} {
        margin: 0;
      }
    }

    svg {
      max-width: 27px;
      max-height: 26px;
    }
  }
`
