export const END_TIMER_DATE = new Date(2021, 4, 14, 18, 30, 0)
export const APPLY_IDO_LINK = 'https://forms.gle/tztXqpjcSWmS9TjE6'
export const FAQ_LINK = 'https://hackmd.io/@OccamDAO/OccamDAO'
export const RAZER_APP_LINK = 'https://razer.occam.fi/'
export const OCCAMX_APP_LINK = 'https://app.occam-x.fi/'
export const RAZER_STAKE_LINK =
  'https://razer.occam.fi/personal/occ-panel/staking'
export const CAREERS_LINK = 'https://angel.co/company/occam-fi/jobs'
export const ADA_STAKE_POOL_LINK =
  'https://adapools.org/pool/34f513d0f993d188557696d598f54944879c24cb60811aea840fce47'
export const ADA_STAKE_POOL_BASE_LINK = 'https://adapools.org/pool/'
export const FORUM_LINK = 'https://forum.occam.fi/'
export const OCCAMX_LINK = 'https://occam-x.fi'
export const INCUBATOR_LINK = '/incubator'
export const GOVERNANCE_LINK = 'https://hackmd.io/@OccamDAO/Governance'
export const OCCAMX_DASHBOARD_LINK = 'https://occam-x.fi/ocx_board'
export const INCUBATOR_DECK = '/incubator_deck.pdf'
export const GET_IN_TOUCH =
  'https://docs.google.com/forms/d/e/1FAIpQLSdyDA6YuptoppJXWSskMmKDbHPhIru3C_syOgY9t3X0ldP0JA/viewform'
export const MAIL_LINK = 'mailto:ramiro@occam.fi'
export const SUPPORT_LINK = 'https://support.occam.fi/support/tickets/new'
export const KYC_LINK = 'https://in.sumsub.com/idensic/l/#/uni_aJXf63Bea9tu1rKS'
export const KYC_US_LINK =
  'https://in.sumsub.com/idensic/l/#/uni_2R6xN6qYWe8CTn6i'
export const TWITTER_LINK = 'https://twitter.com/OccamFi'
export const REDDIT_LINK = 'https://www.reddit.com/user/OccamFi'
export const TELEGRAM_LINK = 'https://t.me/occamfi_com'
export const MEDIUM_LINK = 'https://medium.com/occam-finance'
export const DISCORD_LINK = 'http://discord.gg/occamfi'
export const ISPO_LINK = 'https://ispo.occam.fi/'
