import React from 'react'
import styled from 'styled-components'
import Apps from './Apps'
import { TitleCss, Container } from 'theme/theme'
import useWindowSize from 'helpers/utils/useWindowSize'
import Button from './Button'

const Incubator: React.FunctionComponent = () => {
  const { width } = useWindowSize()

  return (
    <Root>
      <Container>
        <Row>
          <Title>Interchain incubator DAO</Title>
          {width <= 790 && <SubTitle>& DeFi powerhouse</SubTitle>}
          <ButtonWrap>
            <Button />
          </ButtonWrap>
        </Row>
        {width > 790 && <SubTitle>& DeFi powerhouse</SubTitle>}
      </Container>
      <AppsWrapper>
        <Apps />
      </AppsWrapper>
    </Root>
  )
}

export default Incubator

const Root = styled.div`
  position: relative;
  width: 100%;
  padding-bottom: 294px;
  z-index: 2;
  ${({ theme }) => theme.adaptive.newHomePage.lg} {
    padding-bottom: 277px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.slg} {
    padding-bottom: 239px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.md} {
    padding-bottom: 177px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.sm} {
    padding-bottom: 231px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.smm} {
    padding-bottom: 194px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.xs} {
    padding-bottom: 151px;
  }
`

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${({ theme }) => theme.adaptive.newHomePage.sm} {
    display: block;
  }
`

const Title = styled.div`
  ${TitleCss};
  letter-spacing: -0.02em;
`

const SubTitle = styled.div`
  font-family: 'NeueHaasDisplay';
  font-weight: 300;
  font-size: 40px;
  line-height: 48px;
  color: #27303b;
  opacity: 0.3;
  margin-top: 9px;
  ${({ theme }) => theme.adaptive.newHomePage.lg} {
    font-size: 34px;
    line-height: 41px;
    margin-top: 5px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.slg} {
    font-size: 30px;
    line-height: 36px;
    margin-top: 6px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.md} {
    font-size: 22px;
    line-height: 26px;
    margin-top: 5px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.sm} {
    font-size: 32px;
    line-height: 38px;
    margin-top: 18px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.xs} {
    font-size: 24px;
    line-height: 29px;
    margin-top: 14px;
  }
`

const AppsWrapper = styled.div`
  width: 1235px;
  margin: 0 auto;
  ${({ theme }) => theme.adaptive.newHomePage.lg} {
    width: 1058px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.slg} {
    width: 910px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.md} {
    width: 676px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.sm} {
    width: 520px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.smm} {
    width: 350px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.xs} {
    width: 260px;
  }
`

const ButtonWrap = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  z-index: 3;
  ${({ theme }) => theme.adaptive.newHomePage.sm} {
    top: 223px;
    right: unset;
    left: 0;
  }
  ${({ theme }) => theme.adaptive.newHomePage.xs} {
    top: 166px;
  }
`
