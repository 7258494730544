import React from 'react'
import styled from 'styled-components'
import socialsStore from '../../store/socials'

export default function Socials({
  x = 'start',
  wrap = false,
  isVertical = false,
  fill = '#80858A',
}) {
  return (
    <SocialsList x={x} isVertical={isVertical} wrap={wrap}>
      {socialsStore.map((social, idx) => {
        return (
          <SocialLink key={idx} href={social.link} target="_blank">
            {social.getIcon(fill)}
          </SocialLink>
        )
      })}
    </SocialsList>
  )
}

const SocialsList = styled.div<{
  isVertical: boolean
  x: string
  wrap: boolean
}>`
  display: flex;
  align-items: center;
  justify-content: flex-${({ x }) => x};
  flex-direction: ${({ isVertical }) => (isVertical ? 'column' : 'row')};
  flex-wrap: ${({ wrap }) => (wrap ? 'wrap' : '')};
`

const SocialLink = styled.a`
  display: inline-block;
  margin: 0 0 0 15px;
  @media screen and (max-width: 1000px) {
    margin-left: 10px;
  }
  @media screen and (max-width: 800px) {
    margin-left: 8px;
  }
  @media screen and (max-height: 600px) {
    margin-left: 8px;
  }
  svg {
    max-width: 22px;
    height: auto;
    @media screen and (max-width: 480px) {
      max-width: 28px;
    }
    @media screen and (max-height: 600px) {
      max-width: 22px;
    }
  }
  &:first-child {
    margin-left: 0;
  }
`
