import React from 'react'
import styled from 'styled-components'
import { TitleCss, Container } from 'theme/theme'
import { ReactComponent as ExploreIcon } from 'img/rebranding/dao/explore-icon.svg'
import { ReactComponent as ExploreSmall } from 'img/rebranding/dao/small-explore.svg'
import exploreBgImg from 'img/rebranding/dao/explore-bg.svg'
import daoBgImg from 'img/rebranding/dao/dao-bg.jpg'
import daoBgImg2x from 'img/rebranding/dao/dao-bg-2x.jpg'
import daoBgImgWebp from 'img/rebranding/dao/dao-bg@1x.webp'
import daoBgImgWebp2x from 'img/rebranding/dao/dao-bg@2x.webp'
import cloudsImg from 'img/rebranding/dao/clouds.png'
import cloudsImgWebp from 'img/rebranding/dao/clouds@1x.webp'
import cloudsImgSm from 'img/rebranding/dao/clouds-sm.png'
import cloudsImgSmWebp from 'img/rebranding/dao/clouds-sm@1x.webp'
import { GOVERNANCE_LINK } from 'configs/app.config'

const Dao: React.FunctionComponent = () => {
  return (
    <Root>
      <DaoBg>
        <source
          type="image/webp"
          srcSet={`${daoBgImgWebp}, ${daoBgImgWebp2x}`}
        />
        <source type="image/png" srcSet={`${daoBgImg}, ${daoBgImg2x}`} />
        <img src={daoBgImg} alt=" content" loading="lazy" />
      </DaoBg>
      <Container>
        <TextBlock>
          <Clouds />
          <Title>
            Occam DAO
            <SmallExplore href={GOVERNANCE_LINK} target={'_blank'}>
              <ExploreSmall />
              Explore
            </SmallExplore>
          </Title>
          <SubTitle>
            OccamDAO is a decentralized community middleware between the
            Foundations of leading L1/L2s and their project pipelines.
          </SubTitle>
          <Partners>Proudly Interchain</Partners>
          <ExploreWrapper>
            <ExploreBg />
            <ExploreBtn href={GOVERNANCE_LINK} target={'_blank'}>
              <ExploreIcon />
              <ExploreText>DAO</ExploreText>
              {/*<ExploreName>Coming Soon</ExploreName>*/}
            </ExploreBtn>
          </ExploreWrapper>
        </TextBlock>
      </Container>
    </Root>
  )
}

export default Dao

const Root = styled.div`
  position: relative;
  width: 100%;
  padding-bottom: 434px;
  ${({ theme }) => theme.adaptive.newHomePage.lg} {
    padding-bottom: 375px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.slg} {
    padding-bottom: 319px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.md} {
    padding-bottom: 234px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.sm} {
    padding-bottom: 353px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.smm} {
    padding-bottom: 367px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.xs} {
    padding-bottom: 295px;
  }
`

const TextBlock = styled.div`
  position: relative;
`

const Title = styled.div`
  position: relative;
  ${TitleCss};
  width: 364px;
  letter-spacing: -0.03em;
  margin-bottom: 30px;
  z-index: 2;
  ${({ theme }) => theme.adaptive.newHomePage.lg} {
    width: 312px;
    margin-bottom: 26px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.slg} {
    width: 269px;
    margin-bottom: 22px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.md} {
    width: 202px;
    margin-bottom: 16px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.sm} {
    width: 297px;
    margin-bottom: 18px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.smm} {
    margin-bottom: 16px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.xs} {
    width: 232px;
    margin-bottom: 12px;
  }
`

const SubTitle = styled.div`
  position: relative;
  font-family: 'NeueHaasDisplay';
  font-weight: 200;
  font-size: 40px;
  line-height: 125%;
  color: #27303b;
  width: 541px;
  margin-bottom: 32px;
  z-index: 2;
  ${({ theme }) => theme.adaptive.newHomePage.lg} {
    font-size: 34px;
    width: 464px;
    margin-bottom: 27px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.slg} {
    font-size: 30px;
    width: 399px;
    margin-bottom: 19px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.md} {
    font-size: 22px;
    width: 296px;
    margin-bottom: 15px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.sm} {
    font-size: 32px;
    width: 436px;
    margin-bottom: 28px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.smm} {
    font-size: 32px;
    width: 100%;
    margin-bottom: 23px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.xs} {
    font-size: 24px;
    margin-bottom: 16px;
  }
`

const Partners = styled.div`
  position: relative;
  font-family: 'NeueHaasDisplay';
  font-weight: 300;
  font-size: 24px;
  line-height: 120%;
  letter-spacing: -0.01em;
  color: #27303b;
  opacity: 0.8;
  width: 403px;
  z-index: 2;
  ${({ theme }) => theme.adaptive.newHomePage.lg} {
    font-size: 20px;
    width: 345px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.slg} {
    font-size: 18px;
    width: 297px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.md} {
    font-size: 14px;
    width: 221px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.sm} {
    font-size: 19px;
    width: 304px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.smm} {
    font-size: 19px;
    width: 215px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.xs} {
    font-size: 15px;
    width: 169px;
  }
  span {
    font-weight: 500;
  }
`

const ExploreBtn = styled.a`
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(
      79.29% 79.29% at 65.46% 20.88%,
      rgba(255, 255, 255, 0.77) 27.44%,
      rgba(255, 255, 255, 0) 100%
    ),
    #f4f4f4;
  box-shadow: 0px -1px 0px #ffffff, 1px 2px 0px #dfdfdf,
    0px 12px 22px rgba(18, 46, 71, 0.12);
  border-radius: 50%;
  cursor: pointer;
  transition: 0.4s;
  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: block;
    width: 128px;
    height: 138px;
    ${({ theme }) => theme.adaptive.newHomePage.lg} {
      width: 110px;
      height: 118px;
    }
    ${({ theme }) => theme.adaptive.newHomePage.slg} {
      width: 95px;
      height: 102px;
    }
    ${({ theme }) => theme.adaptive.newHomePage.md} {
      width: 70px;
      height: 76px;
    }
    ${({ theme }) => theme.adaptive.newHomePage.sm} {
      width: 86px;
      height: 93px;
    }
    ${({ theme }) => theme.adaptive.newHomePage.xs} {
      width: 67px;
      height: 73px;
    }
  }
`

const ExploreText = styled.div`
  position: relative;
  font-family: 'RPBrawl';
  font-weight: 400;
  font-size: 41px;
  line-height: 49px;
  text-align: center;
  background: linear-gradient(180deg, #505d6d 0%, #3a4350 100%), #ffffff;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-transform: uppercase;
  margin-top: 78px;
  ${({ theme }) => theme.adaptive.newHomePage.lg} {
    font-size: 35px;
    line-height: 42px;
    margin-top: 67px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.slg} {
    font-size: 30px;
    line-height: 36px;
    margin-top: 57px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.md} {
    font-size: 22px;
    line-height: 26px;
    margin-top: 42px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.sm} {
    font-size: 27px;
    line-height: 33px;
    margin-top: 52px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.xs} {
    font-size: 21px;
    line-height: 26px;
    margin-top: 38px;
  }
`

const ExploreName = styled.div`
  font-family: 'NeueHaasDisplay';
  font-weight: 300;
  font-size: 18px;
  line-height: 145%;
  text-align: center;
  letter-spacing: 0.01em;
  color: #27303b;
  opacity: 0.5;
  margin-top: 7px;
  transition: 0.4s;
  ${({ theme }) => theme.adaptive.newHomePage.lg} {
    font-size: 20px;
    margin-top: 6px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.slg} {
    font-size: 18px;
    margin-top: 3px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.md} {
    font-size: 14px;
    margin-top: 4px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.sm} {
    font-size: 16px;
    margin-top: 5px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.xs} {
    font-size: 15px;
    margin-top: 2px;
  }
`

const ExploreWrapper = styled.div`
  position: absolute;
  display: block;
  left: 806px;
  top: 196px;
  width: 203px;
  height: 203px;
  ${({ theme }) => theme.adaptive.newHomePage.lg} {
    left: 691px;
    top: 168px;
    width: 174px;
    height: 174px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.slg} {
    left: 595px;
    top: 145px;
    width: 150px;
    height: 150px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.md} {
    left: 441px;
    top: 107px;
    width: 111px;
    height: 111px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.sm} {
    left: 360px;
    top: 264px;
    width: 137px;
    height: 137px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.smm} {
    left: 213px;
    top: 467px;
    width: 137px;
    height: 137px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.xs} {
    left: 159px;
    top: 365px;
    width: 107px;
    height: 107px;
  }
  // &:hover {
  //   ${ExploreBtn} {
  //     box-shadow: 0px -1.04195px 0px #ffffff, 1.04195px 2.08389px 0px #dfdfdf,
  //       0px 12.5034px 22.9228px rgba(18, 46, 71, 0.2);
  //   }
  //   ${ExploreName} {
  //     color: #56a0f5;
  //   }
  // }
`

const ExploreBg = styled.div`
  position: absolute;
  top: -997px;
  left: -1200px;
  width: 2592px;
  height: 2628px;
  background: url('${exploreBgImg}') center no-repeat;
  background-size: cover;
  pointer-events: none;
  ${({ theme }) => theme.adaptive.newHomePage.lg} {
    top: -855px;
    left: -1028px;
    width: 2222px;
    height: 2253px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.slg} {
    top: -736px;
    left: -886px;
    width: 1913px;
    height: 1940px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.md} {
    top: -546px;
    left: -657px;
    width: 1420px;
    height: 1439px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.sm} {
    top: -673px;
    left: -809px;
    width: 1749px;
    height: 1773px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.xs} {
    top: -525px;
    left: -632px;
    width: 1365px;
    height: 1384px;
  }
`

const DaoBg = styled.picture`
  position: absolute;
  left: 0;
  top: 30%;
  transform: translateY(-50%);
  width: 100%;
  img {
    width: 100%;
  }
  ${({ theme }) => theme.adaptive.newHomePage.sm} {
    left: -20%;
    top: -15%;
    height: 1000px;
    transform: translateX(-50%);
    img {
      width: 1574px;
      height: 100%;
    }
  }
  ${({ theme }) => theme.adaptive.newHomePage.smm} {
    left: -48%;
    top: -2%;
  }
  ${({ theme }) => theme.adaptive.newHomePage.xs} {
    left: -63%;
    top: -19%;
  }
`

const Clouds = styled.div`
  position: absolute;
  top: 309px;
  left: -13px;
  width: 1361px;
  height: 625px;
  background-image: url('${cloudsImg}');
  background-image: image-set(
    url('${cloudsImgWebp}') 1x,
    url('${cloudsImg}') 1x
  );
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  ${({ theme }) => theme.adaptive.newHomePage.lg} {
    top: 265px;
    left: -11px;
    width: 1167px;
    height: 536px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.slg} {
    top: 228px;
    left: -10px;
    width: 1005px;
    height: 461px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.md} {
    top: 169px;
    left: -7px;
    width: 745px;
    height: 342px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.sm} {
    top: -169px;
    left: -281px;
    width: 1422px;
    height: 653px;
    background-image: url('${cloudsImgSm}');
    background-image: image-set(
      url('${cloudsImgSmWebp}') 1x,
      url('${cloudsImgSm}') 1x
    );
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }
  ${({ theme }) => theme.adaptive.newHomePage.smm} {
    top: -53px;
    left: -361px;
    width: 1422px;
    height: 653px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.xs} {
    top: -42px;
    left: -289px;
    width: 1110px;
    height: 510px;
  }
`

const SmallExplore = styled.a`
  position: absolute;
  display: block;
  right: -126px;
  top: -33px;
  width: 111px;
  height: 63px;
  padding: 16px 24px;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: #6288b4;
  z-index: 2;
  letter-spacing: normal;
  transition: 0.4s;
  cursor: pointer;
  ${({ theme }) => theme.adaptive.newHomePage.lg} {
    width: 95px;
    height: 54px;
    right: -108px;
    top: -27px;
    padding: 14px 20px;
    font-size: 15px;
    line-height: 18px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.slg} {
    width: 82px;
    height: 47px;
    right: -93px;
    top: -23px;
    padding: 11px 17px;
    font-size: 15px;
    line-height: 18px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.md} {
    width: 61px;
    height: 35px;
    right: -67px;
    top: -17px;
    padding: 7px 11px;
    font-size: 12px;
    line-height: 14px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.sm} {
    width: 79px;
    height: 45px;
    right: -50px;
    top: -48px;
    padding: 11px 16px;
    font-size: 14px;
    line-height: 17px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.xs} {
    width: 62px;
    height: 35px;
    right: -28px;
    top: -38px;
    padding: 8px 13px;
    font-size: 11px;
    line-height: 13px;
  }
  svg {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    filter: drop-shadow(0px 22px 33px rgba(13, 52, 89, 0.08))
      drop-shadow(0px 5px 10px rgba(13, 52, 89, 0.04));
    z-index: -1;
    transition: 0.4s;
    transform: translate3d(0, 0, 0);
  }
  //&:hover {
  //  color: #56a0f5;
  //  svg {
  //    filter: drop-shadow(0px 22px 33px rgba(13, 52, 89, 0.08))
  //      drop-shadow(0px 5px 10px rgba(13, 52, 89, 0.04));
  //  }
  //}
`
