import React from 'react'
import styled from 'styled-components'
import PageWrapper from 'components/PageWrapper/PageWrapper'
import PageContent from 'components/Incubator/index'

export default function Incubator() {
  return (
    <Root>
      <PageWrapper
        footerEnabled={false}
        fixedHeader={true}
        headerBtn={false}
        bgImage={false}
        indexPage={false}
        olympusPage={true}
        isFullHeight={false}
        headerWhite={true}
        headerBurgerColor={'#fff'}
      >
        <PageContent />
      </PageWrapper>
    </Root>
  )
}

const Root = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
`
