import React from 'react'
import styled, { css } from 'styled-components'
import bg from '../../img/zeroTier/resume/bg.png'
import bgSmd from '../../img/zeroTier/resume/bg-smd.png'
import bgSm from '../../img/zeroTier/resume/bg-sm.png'
import bgSmm from '../../img/zeroTier/resume/bg-ssm.png'
import helm from '../../img/zeroTier/resume/helm.jpg'
import smallApy from '../../img/zeroTier/resume/small-apy.svg'

export default function Resume() {
  return (
    <Root>
      <Content>
        <Img src={helm} alt={''} />
        <Info>
          <TextContent>
            <BigText>
              Having over <span>33,000</span> to <span>130,000</span> OCC staked
              enables one to become a member of Occam DAO board.
            </BigText>
            <SmallText>
              Doing so will also enable access to our staking rewards. Likewise,
              stakers retain access to OccamRazer IDO allocations.
            </SmallText>
          </TextContent>
          {/* <AverageText>Current average APYs:</AverageText>
          <ApysList>
            <ApyWrap>
              <ApySmallText>Staking</ApySmallText>
              <SmallApy>108 %</SmallApy>
            </ApyWrap>
            <ApyWrap>
              <ApySmallText>IDO</ApySmallText>
              <SmallApy>348 %</SmallApy>
            </ApyWrap>
            <ApyWrap>
              <ApySmallText>Seed rounds</ApySmallText>
              <SmallApy>420 %</SmallApy>
            </ApyWrap>
            <ApyWrap>
              <ApySmallText>Private rounds</ApySmallText>
              <SmallApy>385 %</SmallApy>
            </ApyWrap>
            <ApyWrap>
              <ApyBigText>Total APYs</ApyBigText>
              <BigApy>1251 %</BigApy>
            </ApyWrap>
          </ApysList> */}
        </Info>
      </Content>
    </Root>
  )
}

const Root = styled.div`
  position: relative;
  padding: 167px 0 81px 0;
  ${({ theme }) => theme.adaptive.zeroTier.xl} {
    padding: 111px 0 70px 0;
  }
  ${({ theme }) => theme.adaptive.zeroTier.lg} {
    padding: 95px 0 60px 0;
  }
  ${({ theme }) => theme.adaptive.zeroTier.slg} {
    padding: 82px 0 52px 0;
  }
  ${({ theme }) => theme.adaptive.zeroTier.md} {
    padding: 61px 0 38px 0;
  }
  ${({ theme }) => theme.adaptive.zeroTier.smd} {
    padding: 49px 0 154px 0;
  }
  ${({ theme }) => theme.adaptive.zeroTier.sm} {
    padding: 53px 0 273px 0;
  }
  ${({ theme }) => theme.adaptive.zeroTier.ssm} {
    padding: 49px 0 212px 0;
  }
  &:before {
    content: '';
    position: absolute;
    top: -108px;
    left: 50%;
    transform: translateX(-50%);
    width: 2122px;
    height: 970px;
    background: url('${bg}') center no-repeat;
    ${({ theme }) => theme.adaptive.zeroTier.xl} {
      background-size: cover;
      top: -130px;
      width: 1856px;
      height: 848px;
    }
    ${({ theme }) => theme.adaptive.zeroTier.lg} {
      top: -110px;
      width: 1591px;
      height: 727px;
    }
    ${({ theme }) => theme.adaptive.zeroTier.slg} {
      top: -90px;
      width: 1370px;
      height: 626px;
    }
    ${({ theme }) => theme.adaptive.zeroTier.md} {
      top: -66px;
      width: 1017px;
      height: 465px;
    }
    ${({ theme }) => theme.adaptive.zeroTier.smd} {
      background: url('${bgSmd}') center no-repeat;
      top: -45px;
      width: 100%;
      height: 524px;
    }
    ${({ theme }) => theme.adaptive.zeroTier.sm} {
      background: url('${bgSm}') center no-repeat;
      top: -14px;
      height: 728px;
    }
    ${({ theme }) => theme.adaptive.zeroTier.ssm} {
      background: url('${bgSmm}') center no-repeat;
      top: -2px;
      height: 569px;
    }
  }
`

const Content = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 1600px;
  margin: 0 auto;
  ${({ theme }) => theme.adaptive.zeroTier.xl} {
    width: 1400px;
    margin: 0 auto;
  }
  ${({ theme }) => theme.adaptive.zeroTier.lg} {
    width: 1200px;
    margin: 0 auto;
  }
  ${({ theme }) => theme.adaptive.zeroTier.slg} {
    width: 1032px;
    margin: 0 auto;
  }
  ${({ theme }) => theme.adaptive.zeroTier.md} {
    width: 765px;
    margin: 0 auto;
  }
  ${({ theme }) => theme.adaptive.zeroTier.smd} {
    width: 500px;
    margin: 0 auto;
  }
  ${({ theme }) => theme.adaptive.zeroTier.sm} {
    display: block;
    width: 332px;
    margin: 0 auto;
  }
  ${({ theme }) => theme.adaptive.zeroTier.ssm} {
    width: 258px;
  }
`

const Img = styled.img`
  position: relative;
  display: block;
  margin-left: -108px;
  margin-right: 33px;
  width: 632px;
  height: 420px;
  ${({ theme }) => theme.adaptive.zeroTier.xl} {
    margin-left: -95px;
    margin-right: 29px;
    width: 553px;
    height: 368px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.lg} {
    margin-left: -81px;
    margin-right: 25px;
    width: 474px;
    height: 315px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.slg} {
    margin-left: -70px;
    margin-right: 21px;
    width: 408px;
    height: 271px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.md} {
    margin-left: -52px;
    margin-right: 16px;
    width: 303px;
    height: 201px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.smd} {
    margin-left: -30px;
    margin-right: 7px;
    width: 198px;
    height: 131px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.sm} {
    margin: 0 auto 31px;
    width: 191px;
    height: 127px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.ssm} {
    margin: 0 auto 24px;
    width: 149px;
    height: 99px;
  }
`

const Info = styled.div``

const TextContent = styled.div`
  display: flex;
  ${({ theme }) => theme.adaptive.zeroTier.smd} {
    display: block;
  }
`

const BigText = styled.div`
  width: 509px;
  font-weight: 200;
  font-size: 36px;
  line-height: 130%;
  color: #465062;
  margin-right: 53px;
  ${({ theme }) => theme.adaptive.zeroTier.xl} {
    width: 445px;
    font-size: 32px;
    margin-right: 46px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.lg} {
    width: 382px;
    font-size: 27px;
    margin-right: 40px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.slg} {
    width: 328px;
    font-size: 24px;
    margin-right: 32px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.md} {
    width: 244px;
    font-size: 18px;
    margin-right: 25px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.smd} {
    width: 306px;
    font-size: 24px;
    margin-right: 0;
    margin-bottom: 10px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.sm} {
    width: 100%;
    margin-bottom: 14px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.ssm} {
    font-size: 18px;
    margin-bottom: 11px;
  }
  span {
    font-weight: 600;
  }
`

const SmallText = styled.div`
  width: 419px;
  font-weight: 300;
  font-size: 25px;
  line-height: 140%;
  color: #465062;
  opacity: 0.8;
  ${({ theme }) => theme.adaptive.zeroTier.xl} {
    width: 367px;
    font-size: 25px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.lg} {
    width: 314px;
    font-size: 19px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.slg} {
    width: 271px;
    font-size: 16px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.md} {
    width: 201px;
    font-size: 12px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.smd} {
    width: 306px;
    font-size: 16px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.sm} {
    width: 100%;
  }
  ${({ theme }) => theme.adaptive.zeroTier.ssm} {
    font-size: 12px;
  }
`

const AverageText = styled.div`
  font-weight: 300;
  font-size: 25px;
  line-height: 140%;
  color: #465062;
  opacity: 0.8;
  margin-top: 38px;
  ${({ theme }) => theme.adaptive.zeroTier.xl} {
    font-size: 22px;
    margin-top: 27px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.lg} {
    font-size: 19px;
    margin-top: 23px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.slg} {
    font-size: 16px;
    margin-top: 20px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.md} {
    font-size: 12px;
    margin-top: 18px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.smd} {
    font-size: 16px;
    margin-top: 6px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.sm} {
    margin-top: 17px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.ssm} {
    font-size: 12px;
    margin-top: 13px;
  }
`

const ApysList = styled.div`
  display: flex;
  margin-top: -20px;
  ${({ theme }) => theme.adaptive.zeroTier.xl} {
    margin-top: -15px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.lg} {
    margin-top: -10px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.slg} {
    margin-top: -8px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.md} {
    margin-top: -6px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.smd} {
    position: absolute;
    width: 100%;
    left: 50%;
    transform: translateX(-50%);
    top: 219px;
    justify-content: space-between;
    margin: 0 10px 0 0;
  }
  ${({ theme }) => theme.adaptive.zeroTier.sm} {
    position: absolute;
    flex-wrap: wrap;
    width: 100%;
    top: 401px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.ssm} {
    position: absolute;
    top: 304px;
    margin: 0 10px 0 0;
  }
`

const ApyWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  ${({ theme }) => theme.adaptive.zeroTier.smd} {
    margin: 0 -15px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.sm} {
    margin: 0 -5px;
  }
  &:nth-child(4),
  &:nth-child(5) {
    ${({ theme }) => theme.adaptive.zeroTier.smd} {
      margin-top: 36px;
    }
    ${({ theme }) => theme.adaptive.zeroTier.ssm} {
      margin-top: 27px;
    }
  }
`

const ApySmallText = styled.div`
  font-weight: 300;
  font-size: 16px;
  line-height: 140%;
  color: #465062;
  margin-bottom: 12px;
  margin-left: 48px;
  ${({ theme }) => theme.adaptive.zeroTier.xl} {
    font-size: 14px;
    margin-bottom: 8px;
    margin-left: 44px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.lg} {
    font-size: 12px;
    margin-bottom: 10px;
    margin-left: 38px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.slg} {
    font-size: 12px;
    margin-bottom: 9px;
    margin-left: 34px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.md} {
    font-size: 9px;
    margin-bottom: 6px;
    margin-left: 24px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.smd} {
    font-size: 12px;
    margin-bottom: 9px;
    margin-left: 34px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.sm} {
    margin-bottom: 7px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.ssm} {
    font-size: 9px;
    margin-bottom: 6px;
    margin-left: 24px;
  }
`

const ApyCss = css`
  background: url('${smallApy}') center no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
`

const SmallApy = styled.div`
  width: 180px;
  height: 73px;
  font-weight: 600;
  font-size: 26px;
  line-height: 130%;
  display: flex;
  align-items: center;
  text-align: center;
  color: #ffffff;
  ${ApyCss};
  ${({ theme }) => theme.adaptive.zeroTier.xl} {
    width: 157px;
    height: 63px;
    font-size: 23px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.lg} {
    width: 135px;
    height: 54px;
    font-size: 20px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.slg} {
    width: 116px;
    height: 47px;
    font-size: 18px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.md} {
    width: 86px;
    height: 35px;
    font-size: 12px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.smd} {
    width: 116px;
    height: 47px;
    font-size: 18px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.sm} {
    width: 111px;
    height: 45px;
    font-size: 16px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.ssm} {
    width: 86px;
    height: 35px;
    font-size: 12px;
  }
`

const BigApy = styled.div`
  width: 298px;
  height: 120px;
  font-weight: 600;
  font-size: 42px;
  line-height: 130%;
  display: flex;
  align-items: center;
  text-align: center;
  color: #ffffff;
  ${ApyCss};
  ${({ theme }) => theme.adaptive.zeroTier.xl} {
    width: 261px;
    height: 105px;
    font-size: 37px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.lg} {
    width: 224px;
    height: 90px;
    font-size: 32px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.slg} {
    width: 193px;
    height: 78px;
    font-size: 28px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.md} {
    width: 143px;
    height: 58px;
    font-size: 20px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.smd} {
    width: 193px;
    height: 78px;
    font-size: 28px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.sm} {
    width: 183px;
    height: 74px;
    font-size: 26px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.ssm} {
    width: 143px;
    height: 58px;
    font-size: 20px;
  }
`

const ApyBigText = styled.div`
  font-weight: 300;
  font-size: 18px;
  line-height: 140%;
  color: #465062;
  margin-bottom: 18px;
  margin-left: 80px;
  ${({ theme }) => theme.adaptive.zeroTier.xl} {
    font-size: 14px;
    margin-bottom: 16px;
    margin-left: 70px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.lg} {
    font-size: 12px;
    margin-bottom: 13px;
    margin-left: 61px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.slg} {
    font-size: 12px;
    margin-bottom: 13px;
    margin-left: 53px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.md} {
    font-size: 9px;
    margin-bottom: 9px;
    margin-left: 38px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.smd} {
    font-size: 12px;
    margin-bottom: 13px;
    margin-left: 53px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.sm} {
    margin-bottom: 7px;
    margin-left: 53px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.ssm} {
    margin-bottom: 6px;
    margin-left: 39px;
    font-size: 9px;
  }
`
