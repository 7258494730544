import React, { useEffect, useState } from 'react'
import HeaderBurger from './HeaderBurger'
import HeaderButton from './HeaderButton'
import HeaderLogo from './HeaderLogo'
import HeaderMenu from './HeaderMenu'
import styled, { css } from 'styled-components'
import buttonBg from '../../img/header/buttonBg.svg'
import useWindowSize from '../../helpers/utils/useWindowSize'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import { olympusHeaderStyles } from '../Olympus/Olympus'
import HeaderMenuWhite from './HeaderMenuWhite'

interface headerProps {
  headerTitle?: string
  headerTitleColor?: string
  burgerColor?: string
  xPadding?: number
  indexPage?: boolean
  olympusPage?: boolean
  mainPage?: boolean
  logoWhite?: boolean
}

const Header: React.FunctionComponent<headerProps> = (props) => {
  const {
    headerTitle,
    headerTitleColor,
    burgerColor = undefined,
    xPadding = 28,
    indexPage = false,
    mainPage = false,
    olympusPage = false,
    logoWhite = false,
  } = props
  const { width } = useWindowSize()
  const [active, setActive] = useState(false)
  const [animationStart, setAnimationStart] = useState(false)
  const [isAnimStarted, setIsAnimStarted] = useState(false)
  const body = document.body

  useEffect(() => {
    return () => {
      enableBodyScroll(body)
    }
  }, [])

  const onBurgerClick = () => {
    setActive(!active)
    if (!active) {
      // window.scrollTo({ top: 0, behavior: 'smooth' })
      disableBodyScroll(body)
    } else {
      enableBodyScroll(body)
    }
    if (!isAnimStarted) {
      return animationStart ? setAnimationStart(false) : setAnimationStart(true)
    }
  }

  return (
    <HeaderRoot isIndex={indexPage}>
      <HeaderRow
        xPadding={xPadding}
        isOlympus={olympusPage}
        isIndex={indexPage}
      >
        <HeaderLeftSide>
          <HeaderLogo
            logoWhite={logoWhite}
            light={width < 780 && active && !mainPage}
            index={indexPage}
          />
          {headerTitle && (
            <HeaderTitle color={headerTitleColor}>{headerTitle}</HeaderTitle>
          )}
        </HeaderLeftSide>
        {indexPage && <OccamLogo>Occam.fi</OccamLogo>}
        <HeaderRightSide isIndex={indexPage}>
          {/*<HeaderButton icon={true} isActive={active} isShow={headerBtn} />*/}
          <HeaderBurgerWrapper>
            <HeaderBurger
              animationStart={animationStart}
              color={burgerColor ? burgerColor : 'rgb(24, 27, 37)'}
              activeColor={mainPage ? '#252525' : '#fff'}
              active={active}
              onBurgerClick={onBurgerClick}
              indexPage={indexPage}
            />
          </HeaderBurgerWrapper>
        </HeaderRightSide>
      </HeaderRow>
      {mainPage ? (
        <HeaderMenuWhite active={active} onClose={() => onBurgerClick()} />
      ) : (
        <HeaderMenu active={active} onClose={() => onBurgerClick()} />
      )}
    </HeaderRoot>
  )
}

export default Header

const HeaderRoot = styled.header<{
  isIndex: boolean
}>`
  overflow: ${({ isIndex }) => (isIndex ? 'hidden' : '')};
`

const HeaderRow = styled.div<{
  xPadding: number
  isOlympus: boolean
  isIndex: boolean
}>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 23px ${({ xPadding }) => xPadding}px;
  position: relative;
  ${({ theme }) => theme.adaptive.sm} {
    padding: 10px 16px;
  }
  ${({ isOlympus }) => isOlympus && olympusHeaderStyles}
  ${({ isIndex }) =>
    isIndex &&
    css`
      width: 1766px;
      padding: 29px 30px 27px;
      margin: 0 auto;
      ${({ theme }) => theme.adaptive.newDesign.xl} {
        width: 1545px;
        padding: 23px 25px 21px;
      }

      ${({ theme }) => theme.adaptive.newDesign.lg} {
        width: 1324px;
        padding: 22px 23px 18px;
      }

      ${({ theme }) => theme.adaptive.newDesign.slg} {
        width: 1142px;
        padding: 19px 21px 17px;
      }
      ${({ theme }) => theme.adaptive.newDesign.md} {
        width: 846px;
        padding: 14px 15px 12px;
      }
      ${({ theme }) => theme.adaptive.newDesign.sm} {
        width: 520px;
        padding: 16px 0px 15px;
      }
      ${({ theme }) => theme.adaptive.newDesign.smm} {
        width: 335px;
        padding: 19px 0px 18px;
      }
      ${({ theme }) => theme.adaptive.newDesign.xs} {
        width: 280px;
      }
    `}
`

const HeaderRightSide = styled.div<{
  isIndex: boolean
}>`
  position: relative;
  z-index: 12;
  display: flex;
  align-items: center;
  border-radius: 0 8px 8px 0;
  padding: ${({ isIndex }) => (isIndex ? 0 : '18px 24px')};
  ${({ theme }) => theme.adaptive.sm} {
    background-position: right center;
    padding: ${({ isIndex }) => (isIndex ? 0 : '18px 14px')};
  }
`

const HeaderLeftSide = styled.div`
  display: flex;
  align-items: center;
  z-index: 12;
`

const HeaderTitle = styled.h1<{ color?: string }>`
  font-family: 'RPBrawl';
  margin-left: 40px;
  font-size: 48px;
  text-transform: uppercase;
  ${({ color }) =>
    color
      ? css`
          color: ${color};
        `
      : css`
          background: linear-gradient(#505d6d, #31334a, #151821);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        `}
  ${({ theme }) => theme.adaptive.lg} {
    font-size: 32px;
    margin-left: 20px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    display: none;
  }
`

const HeaderBurgerWrapper = styled.div`
  // margin-left: 25px;
  // ${({ theme }) => theme.adaptive.sm} {
  //   margin-left: 12px;
  // }
`

const OccamLogo = styled.div`
  font-family: 'RPBrawl';
  font-size: 24px;
  line-height: 20px;
  text-transform: uppercase;
  background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.2) 0%,
      rgba(255, 255, 255, 0) 100%
    ),
    linear-gradient(180deg, #34404f 0%, #202131 47.4%, #090b0f 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  z-index: 12;
  ${({ theme }) => theme.adaptive.newDesign.lg} {
    font-size: 20px;
    line-height: 16px;
  }

  ${({ theme }) => theme.adaptive.newDesign.slg} {
    font-size: 17px;
    line-height: 13px;
  }
  ${({ theme }) => theme.adaptive.newDesign.md} {
    font-size: 15px;
    line-height: 12px;
  }
  ${({ theme }) => theme.adaptive.newDesign.sm} {
    font-size: 18px;
    line-height: 15px;
  }
`
