import React from 'react'
import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'
import Logo from '../Icons/Logo'

interface LogoProps {
  light?: boolean
  logoWhite?: boolean
  index?: boolean
}

interface WrapperProps {
  index?: boolean
}

const HeaderLogo: React.FunctionComponent<LogoProps> = (props) => {
  return (
    <LogoWrapper index={props.index}>
      <Link to="/">
        <Logo headerWhite={props.logoWhite} light={props.light} />
      </Link>
    </LogoWrapper>
  )
}

export default HeaderLogo

const LogoWrapper = styled.div<WrapperProps>`
  width: 77px;

  ${({ theme }) => theme.adaptive.index.lg} {
    width: 50px;
  }
  ${({ theme }) => theme.adaptive.index.md} {
    width: 44px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    position: relative;
    z-index: 5;
  }
  ${({ index }) =>
    index &&
    css`
      width: 59px;

      ${({ theme }) => theme.adaptive.newDesign.lg} {
        width: 48px;
      }

      ${({ theme }) => theme.adaptive.newDesign.slg} {
        width: 40px;
      }
      ${({ theme }) => theme.adaptive.newDesign.md} {
        width: 29px;
      }
      ${({ theme }) => theme.adaptive.newDesign.sm} {
        width: 30px;
      }
    `}
  a {
    display: block;
  }
  svg {
    width: 100%;
    height: auto;
    transition: 0.4s ease;
    ${({ theme }) => theme.adaptive.sm} {
      width: 47px;
      height: auto;
    }
    ${({ index }) =>
      index &&
      css`
        ${({ theme }) => theme.adaptive.newDesign.md} {
          width: 29px;
        }
        ${({ theme }) => theme.adaptive.newDesign.sm} {
          width: 30px;
        }
      `}
  }
`
