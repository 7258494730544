import React from 'react'
import styled from 'styled-components'
import bg from 'img/incubator/about/bg.svg'
import aboutImg from 'img/incubator/about/about-img.png'

const About: React.FunctionComponent = () => {
  return (
    <Root>
      <Bg />
      <Text>
        <span>We onboard the project at various stages of development</span> and
        deploy our massive network carrying it all the way from concept
        formulation to successful funding round led by reputable VCs to IDO
        launch and beyond.
        <Img />
      </Text>
    </Root>
  )
}

export default About

const Root = styled.div`
  position: relative;
  width: 100%;
  margin-top: 185px;
  padding-bottom: 197px;
  ${({ theme }) => theme.adaptive.zeroTier.xl} {
    margin-top: 192px;
    padding-bottom: 197px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.lg} {
    margin-top: 139px;
    padding-bottom: 149px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.slg} {
    padding-bottom: 165px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.md} {
    margin-top: 134px;
    padding-bottom: 30px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.smd} {
    margin-top: 65px;
    padding-bottom: 17px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.sm} {
    padding-bottom: 21px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.ssm} {
    padding-bottom: 28px;
  }
  &:before {
    content: '';
    position: absolute;
    width: 100vw;
    height: 1000px;
    top: 200px;
    left: 50%;
    transform: translateX(-50%);
    background: #fff;
    ${({ theme }) => theme.adaptive.zeroTier.smd} {
      content: unset;
    }
  }
`

const Bg = styled.div`
  position: absolute;
  top: -199px;
  left: -495px;
  width: 2594px;
  height: 538px;
  background: url('${bg}') center no-repeat;
  background-size: cover;
  ${({ theme }) => theme.adaptive.zeroTier.xl} {
    top: -202px;
    left: -486px;
    width: 2376px;
    height: 510px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.lg} {
    top: -153px;
    left: -420px;
    width: 2071px;
    height: 444px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.slg} {
    top: -139px;
    left: -338px;
    width: 1730px;
    height: 371px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.md} {
    top: -137px;
    left: -464px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.smd} {
    top: -68px;
    left: -515px;
    width: 1538px;
    height: 329px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.sm} {
    top: -61px;
    left: -610px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.ssm} {
    top: -71px;
    left: -633px;
  }
`

const Text = styled.div`
  position: relative;
  margin-left: 59px;
  width: 864px;
  font-weight: 200;
  font-size: 36px;
  line-height: 130%;
  color: #465062;
  ${({ theme }) => theme.adaptive.zeroTier.xl} {
    margin-left: 57px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.lg} {
    width: 678px;
    margin-left: 43px;
    font-size: 28px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.slg} {
    width: 646px;
    font-size: 26px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.smd} {
    width: 503px;
    font-size: 20px;
    margin-left: 17px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.sm} {
    width: 317px;
    font-size: 18px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.ssm} {
    width: 262px;
    font-size: 16px;
  }
  span {
    font-weight: 400;
    color: #62a85d;
  }
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: -57px;
    width: 2px;
    height: 172px;
    background: #7cb36c;
    ${({ theme }) => theme.adaptive.zeroTier.lg} {
      left: -43px;
      width: 2px;
      height: 129px;
    }
    ${({ theme }) => theme.adaptive.zeroTier.smd} {
      left: -17px;
      width: 1px;
      height: 110px;
    }
    ${({ theme }) => theme.adaptive.zeroTier.ssm} {
      height: 120px;
    }
  }
`

const Img = styled.div`
  position: absolute;
  right: -612px;
  top: -114px;
  width: 483px;
  height: 478px;
  background: url('${aboutImg}') center no-repeat;
  background-size: cover;
  ${({ theme }) => theme.adaptive.zeroTier.xl} {
    right: -525px;
    top: -123px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.lg} {
    width: 385px;
    height: 381px;
    right: -415px;
    top: -95px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.slg} {
    right: -411px;
    top: -84px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.md} {
    position: relative;
    right: unset;
    top: unset;
    margin: 52px auto 0;
  }
  ${({ theme }) => theme.adaptive.zeroTier.smd} {
    width: 228px;
    height: 219px;
    margin: 20px auto 0;
  }
  ${({ theme }) => theme.adaptive.zeroTier.sm} {
    margin: 37px auto 0;
  }
  ${({ theme }) => theme.adaptive.zeroTier.ssm} {
    margin: 27px auto 0;
  }
`
