import React from 'react'

export default function SecondClipPath() {
  return (
    <svg width={0} height={0}>
      <clipPath id={'second'}>
        <path d="M220.043 0.000212973L190.768 0.000197722L14.9864 0.159708C6.70746 0.16722 -3.08996e-07 6.88074 -6.80792e-07 15.1597L-3.54778e-06 79.0002C-3.91981e-06 87.2845 6.71569 94.0002 15 94.0002L706.831 94.0002C710.769 94.0002 714.55 92.451 717.356 89.6871L768.526 39.2898C771.388 36.4705 773 32.6206 773 28.6028L773 15.0002C773 6.71594 766.284 0.000213337 758 0.000212985L220.043 0.000212973Z" />
      </clipPath>
      <clipPath id={'secondXl'}>
        <path d="M192.431 6.04167e-05L166.83 4.71097e-05L13.9873 0.138396C6.26025 0.14539 -2.87924e-07 6.41137 -6.34934e-07 14.1384L-3.05378e-06 68C-3.40102e-06 75.732 6.268 82 14 82L617.783 82C621.453 82 624.976 80.5588 627.594 77.9867L671.812 34.5447C674.491 31.9124 676 28.314 676 24.558L676 14.0001C676 6.26807 669.732 6.07685e-05 662 6.04395e-05L192.431 6.04167e-05Z" />
      </clipPath>
      <clipPath id={'secondLg'}>
        <path d="M165.103 -5.95207e-07L143.138 -1.19584e-05L11.9892 0.118101C5.36597 0.124066 -2.47074e-07 5.49489 -5.4488e-07 12.1181L-2.60792e-06 58C-2.90591e-06 64.6274 5.37256 70 12 70L530.073 70C533.208 70 536.219 68.7729 538.461 66.5812L576.388 29.5071C578.698 27.2493 580 24.1557 580 20.9258L580 12C580 5.37258 574.627 -2.28301e-07 568 -5.09923e-07L165.103 -5.95207e-07Z" />
      </clipPath>
      <clipPath id={'secondSlg'}>
        <path d="M149.734 0.984252L129.813 0.984242L9.99304 1.09133C4.47369 1.09627 0.00195292 5.57198 0.00195267 11.0913L0.00195074 53.9842C0.00195049 59.5071 4.47912 63.9842 10.002 63.9842L481.105 63.9842C483.705 63.9842 486.202 62.9719 488.068 61.1619L522.965 27.3094C524.906 25.426 526.002 22.8366 526.002 20.1317L526.002 10.9842C526.002 5.4614 521.525 0.984253 516.002 0.984253L149.734 0.984252Z" />
      </clipPath>
    </svg>
  )
}
