import React from 'react'

export default function FirstClipPath() {
  return (
    <svg width={0} height={0}>
      <g>
        <clipPath id={'first'}>
          <path d="M215.556 28.6795L195.261 5.19777C192.408 1.89756 188.26 0.00249817 183.898 0.00646189L14.9864 0.159949C6.70746 0.167472 -3.09006e-07 6.88099 -6.80802e-07 15.1599L-5.07467e-06 113C-5.44671e-06 121.284 6.71569 128 15 128L758 128C766.284 128 773 121.284 773 113L773 68.8016L773 48.8708C773 40.5865 766.284 33.8708 758 33.8708L226.905 33.8708C222.547 33.8708 218.405 31.9761 215.556 28.6795Z" />
        </clipPath>
      </g>
      <g>
        <clipPath id={'firstXl'}>
          <path d="M188.243 24.7886L171.023 4.854C168.36 1.77206 164.488 0.00216833 160.415 0.00587107L13.9872 0.139001C6.26025 0.146027 -2.87951e-07 6.41197 -6.3496e-07 14.139L-4.40104e-06 98C-4.74827e-06 105.732 6.268 112 14 112L662 112C669.732 112 676 105.732 676 98L676 60.2012L676 43.6368C676 35.9048 669.732 29.6368 662 29.6368L198.837 29.6368C194.769 29.6368 190.902 27.8672 188.243 24.7886Z" />
        </clipPath>
      </g>
      <g>
        <clipPath id={'firstLg'}>
          <path d="M161.514 21.2518L146.732 4.15608C144.45 1.51708 141.132 0.00180309 137.644 0.00497189L11.9891 0.119102C5.36591 0.125118 -2.47122e-07 5.49595 -5.44926e-07 12.1191L-3.77699e-06 84C-4.07498e-06 90.6274 5.37255 96 12 96L568 96C574.627 96 580 90.6274 580 84L580 51.601L580 37.4029C580 30.7755 574.627 25.4029 568 25.4029L170.591 25.4029C167.106 25.4029 163.793 23.8879 161.514 21.2518Z" />
        </clipPath>
      </g>
      <g>
        <clipPath id={'firstSlg'}>
          <path d="M146.743 20.549L132.808 4.44498C130.906 2.24742 128.143 0.985752 125.237 0.98839L9.99292 1.09299C4.47357 1.098 0.00195292 5.57369 0.00195267 11.093L0.00194966 77.9842C0.00194941 83.5071 4.47912 87.9842 10.0019 87.9842L516.002 87.9842C521.525 87.9842 526.002 83.5071 526.002 77.9842L526.002 47.7477L526.002 34.0056C526.002 28.4828 521.525 24.0056 516.002 24.0056L154.305 24.0056C151.402 24.0056 148.642 22.7442 146.743 20.549Z" />
        </clipPath>
      </g>
    </svg>
  )
}
