import React from 'react'
import PageContent from '../components/Careers/Careers'
import PageWrapper from '../components/PageWrapper/PageWrapper'
import MetaSEO from '../components/MetaSEO/MetaSEO'

export default function Careers() {
  const seoTitle = 'OccamFi | Careers'
  const seoDesc = ''

  return (
    <>
      <MetaSEO title={seoTitle} desc={seoDesc} />
      <PageWrapper headerTitle="Careers">
        <PageContent />
      </PageWrapper>
    </>
  )
}
