import React from 'react'
import styled from 'styled-components'
import logoImg from 'img/rebranding/Logo.svg'
import { ReactComponent as IconMenu } from 'img/rebranding/menu-icon.svg'
import { ReactComponent as Telegram } from 'img/rebranding/footer/socials/telegram.svg'
import { ReactComponent as Discord } from 'img/rebranding/footer/socials/discord.svg'
import { ReactComponent as Medium } from 'img/rebranding/footer/socials/medium.svg'
import { ReactComponent as Twitter } from 'img/rebranding/footer/socials/twitter.svg'
import { ReactComponent as Reddit } from 'img/rebranding/footer/socials/reddit.svg'
import { Container } from 'theme/theme'
import bgImg from 'img/rebranding/bg.png'
import bgImg2x from 'img/rebranding/bg-2x.png'
import bgWebpImg from 'img/rebranding/bg@1x.webp'
import bgWebpImg2x from 'img/rebranding/bg@2x.webp'
import { setIsMenuOpen } from 'store/menu'
import {
  DISCORD_LINK,
  MEDIUM_LINK,
  REDDIT_LINK,
  TELEGRAM_LINK,
  TWITTER_LINK,
} from 'configs/app.config'

const Header: React.FunctionComponent = () => {
  return (
    <Root>
      <Bg>
        <source type="image/webp" srcSet={`${bgWebpImg}, ${bgWebpImg2x}`} />
        <source type="image/png" srcSet={`${bgImg}, ${bgImg2x}`} />
        <img src={bgImg} alt=" content" loading="lazy" />
      </Bg>
      <Container>
        <Row>
          <Logo />
          <Right>
            <Socials>
              <SocialItem href={MEDIUM_LINK} target={'_blank'}>
                <Medium />
              </SocialItem>
              <SocialItem href={TELEGRAM_LINK} target={'_blank'}>
                <Telegram />
              </SocialItem>
              <SocialItem href={REDDIT_LINK} target={'_blank'}>
                <Reddit />
              </SocialItem>
              <SocialItem href={DISCORD_LINK} target={'_blank'}>
                <Discord />
              </SocialItem>
              <SocialItem href={TWITTER_LINK} target={'_blank'}>
                <Twitter />
              </SocialItem>
            </Socials>
            <MenuBurger
              onClick={() => {
                setIsMenuOpen(true)
              }}
            >
              <IconMenu />
            </MenuBurger>
          </Right>
        </Row>
      </Container>
    </Root>
  )
}

export default Header

const Root = styled.div`
  position: relative;
  width: 100%;
  padding-top: 68px;
  padding-bottom: 80px;
  z-index: 2;
  ${({ theme }) => theme.adaptive.newHomePage.lg} {
    padding-top: 57px;
    padding-bottom: 69px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.slg} {
    padding-top: 49px;
    padding-bottom: 59px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.md} {
    padding-top: 36px;
    padding-bottom: 44px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.sm} {
    padding-top: 45px;
    padding-bottom: 48px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.xs} {
    font-size: 34px;
    line-height: 38px;
  }
`

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`

const Logo = styled.a`
  display: block;
  width: 179px;
  height: 24px;
  background: url('${logoImg}') center no-repeat;
  background-size: contain;
  cursor: pointer;
  ${({ theme }) => theme.adaptive.newHomePage.lg} {
    width: 153px;
    height: 21px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.slg} {
    width: 132px;
    height: 18px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.md} {
    width: 98px;
    height: 13px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.sm} {
    width: 179px;
    height: 24px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.xs} {
    width: 140px;
    height: 19px;
  }
`

const Right = styled.div`
  display: flex;
  align-items: center;
`

const Socials = styled.div`
  display: flex;
  align-items: center;
  margin-right: 104px;
  ${({ theme }) => theme.adaptive.newHomePage.lg} {
    margin-right: 89px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.slg} {
    margin-right: 77px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.md} {
    margin-right: 57px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.sm} {
    display: none;
  }
`

const SocialItem = styled.a`
  display: flex;
  margin-left: 48px;
  align-items: center;
  cursor: pointer;
  ${({ theme }) => theme.adaptive.newHomePage.lg} {
    margin-left: 41px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.slg} {
    margin-left: 35px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.md} {
    margin-left: 26px;
  }
  svg {
    display: block;
    max-width: 18px;
    max-height: 15px;
    fill: #939baa;
    ${({ theme }) => theme.adaptive.newHomePage.lg} {
      max-width: 14px;
      max-height: 13px;
    }
    ${({ theme }) => theme.adaptive.newHomePage.slg} {
      max-width: 13px;
      max-height: 11px;
    }
    ${({ theme }) => theme.adaptive.newHomePage.md} {
      max-width: 19px;
      max-height: 8px;
    }
  }
`

const MenuBurger = styled.div`
  width: 65px;
  height: 26px;
  cursor: pointer;
  ${({ theme }) => theme.adaptive.newHomePage.lg} {
    width: 56px;
    height: 22px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.slg} {
    width: 48px;
    height: 19px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.md} {
    width: 35px;
    height: 14px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.sm} {
    width: 65px;
    height: 26px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.xs} {
    width: 51px;
    height: 19px;
  }
  svg {
    display: block;
    fill: #505d6d;
    transition: 0.4s;
  }
  &:hover {
    svg {
      fill: #56a0f5;
    }
  }
`

const Bg = styled.picture`
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  ${({ theme }) => theme.adaptive.newHomePage.sm} {
    width: 1920px;
    height: 1163px;
    top: -100px;
    left: -383px;
  }
  img {
    width: 100%;
  }
`
