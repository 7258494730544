import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { noop } from 'lodash'
import olympusRequest from '../../api/olympusRequest'
import Modal from './Modal'
import modalFigure from '../../img/olympus/modal-figure.svg'
import modalBackground from '../../img/olympus/modal-bg.png'
import tooltipIcon from '../../img/olympus/tooltip-icon.svg'
import { ReactComponent as CloseIcon } from '../../img/olympus/modal-close.svg'
import useWindowSize from '../../helpers/utils/useWindowSize'
import ReactDOMServer from 'react-dom/server'
import OlympusTooltipContent from '../Olympus/OlympusTooltipContent'
import OlympusTooltip from '../Olympus/OlympusTooltip'

function ModalOlympus({ open = false, onClose = noop }) {
  const [email, setEmail] = useState('')
  const [address, setAddress] = useState('')
  const [signal, setSignal] = useState('')
  const [btnDisabled, setBtnDisabled] = useState(false)
  const [isSuccess, setIsSuccess] = useState(true)
  const { width } = useWindowSize()

  useEffect(() => {
    if (!open) return setIsSuccess(false)
  }, [open])

  const clearForm = () => {
    setEmail('')
    setAddress('')
    setSignal('')
  }

  const onFormSubmit = () => {
    setBtnDisabled(true)
    olympusRequest({
      email,
      address,
      signal,
    })
      .then(() => {
        clearForm()
        setIsSuccess(true)
      })
      .catch((error) => {
        error.response.data.message
          ? alert(error.response.data.message)
          : clearForm()
      })
      .finally(() => {
        setBtnDisabled(false)
      })
  }

  return (
    <Modal
      open={open}
      onClose={onClose}
      noOutside={true}
      isFullSize={true}
      center={width > 600}
    >
      <Background />
      {/* <ContentWrapper> */}
      <Content active={!isSuccess}>
        <CloseWrapper onClick={onClose}>
          <CloseIcon />
        </CloseWrapper>
        <TextWrapper>
          <Title>Confirmation of Tier Zero Status</Title>
          <Desc>
            If you are staking <b>{'>'}33,000</b> please take a moment to
            provide us with the following info. We will get in touch with you
            shortly.{' '}
          </Desc>
        </TextWrapper>
        <Fields>
          <FieldWrapper>
            <NameWrap>
              <FieldName>Your email</FieldName>
            </NameWrap>
            <Field
              value={email}
              placeholder="example@gmail.com"
              onChange={(e) => setEmail(e.target.value)}
            />
          </FieldWrapper>
          <FieldWrapper>
            <NameWrap>
              <FieldName>Your Ethereum address</FieldName>
            </NameWrap>
            <Field
              value={address}
              onChange={(e) => setAddress(e.target.value)}
            />
          </FieldWrapper>
          <FieldWrapper>
            <NameWrap>
              <FieldName>Your Telegram handle</FieldName>
              <TooltipIcon
                data-for="olympus"
                data-tip={ReactDOMServer.renderToStaticMarkup(
                  <OlympusTooltipContent
                    text={
                      "For security reasons you must have a Telegram account to setup Tier Zero. Please provide your username, and we will contact you from '@Occam_private_desk'."
                    }
                  />
                )}
              />
              <OlympusTooltip />
            </NameWrap>
            <Field value={signal} onChange={(e) => setSignal(e.target.value)} />
          </FieldWrapper>
        </Fields>
        <Button onClick={onFormSubmit} disabled={btnDisabled}>
          Enter Tier Zero
        </Button>
      </Content>
      <Success active={isSuccess}>
        <CloseWrapper onClick={onClose}>
          <CloseIcon />
        </CloseWrapper>
        <SuccessTextWrapper>
          <SuccessTitle>Tier Confirmation</SuccessTitle>
        </SuccessTextWrapper>
        <SuccessText>
          Reaching one of these tiers is by all means extraordinary,
          congratulations!
        </SuccessText>
        <SuccessText>
          Upon confirmation we will contact you. You will learn about OccamFi’s
          pre-IDO funding rounds participation and much more.
        </SuccessText>
        <SuccessText alert={true}>
          Please be vigilant for scams and impostors. Never share your private
          information or seed phrase with anyone, we will not ask for this
          information.
        </SuccessText>
      </Success>
      {/* </ContentWrapper> */}
    </Modal>
  )
}

const Background = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: url(${modalBackground}) no-repeat bottom -130px left #393a3e;
  ${({ theme }) => theme.adaptive.olympusModal.sm} {
    background: url(${modalBackground}) no-repeat bottom -190px left -180px
      #393a3e;
  }
  ${({ theme }) => theme.adaptive.olympusModal.ssm} {
    background: url(${modalBackground}) no-repeat bottom -190px left -220px
      #393a3e;
  }
`

// const ContentWrapper = styled.div`
//   width: 100%;
//   height: 100%;
// `

const Content = styled.div`
  position: relative;
  display: ${({ active }) => (active ? 'block' : 'none')};
  width: 590px;
  height: 646px;
  background: url(${modalFigure}) no-repeat center;
  background-size: contain;
  padding: 54px 70px 0;
  margin: 0 auto;
  ${({ theme }) => theme.adaptive.olympusModal.lg} {
    width: 381px;
    height: 417px;
    padding: 35px 45px 0;
  }
  ${({ theme }) => theme.adaptive.olympusModal.md} {
    width: 283px;
    height: 310px;
    padding: 26px 34px 0;
  }
  ${({ theme }) => theme.adaptive.olympusModal.sm} {
    width: 381px;
    height: 417px;
    padding: 35px 45px 0;
  }
  ${({ theme }) => theme.adaptive.olympusModal.ssm} {
    width: 283px;
    height: 310px;
    padding: 26px 34px 0;
  }
`

const CloseWrapper = styled.button`
  position: absolute;
  right: 25px;
  top: 25px;
  ${({ theme }) => theme.adaptive.olympusModal.lg} {
    right: 16px;
    top: 16px;
  }
  ${({ theme }) => theme.adaptive.olympusModal.md} {
    right: 12px;
    top: 12px;
  }
  ${({ theme }) => theme.adaptive.olympusModal.sm} {
    right: 16px;
    top: 16px;
  }
  ${({ theme }) => theme.adaptive.olympusModal.ssm} {
    right: 12px;
    top: 12px;
  }
  svg {
    ${({ theme }) => theme.adaptive.olympusModal.lg} {
      width: 14px;
      height: 14px;
    }
    ${({ theme }) => theme.adaptive.olympusModal.md} {
      width: 11px;
      height: 11px;
    }
    ${({ theme }) => theme.adaptive.olympusModal.sm} {
      width: 14px;
      height: 14px;
    }
    ${({ theme }) => theme.adaptive.olympusModal.ssm} {
      width: 11px;
      height: 11px;
    }
  }
`

const TextWrapper = styled.div`
  padding-bottom: 20px;
  margin-bottom: 32px;
  border-bottom: 1px solid #5e646b;
  ${({ theme }) => theme.adaptive.olympusModal.lg} {
    padding-bottom: 13px;
    margin-bottom: 21px;
  }
  ${({ theme }) => theme.adaptive.olympusModal.md} {
    padding-bottom: 10px;
    margin-bottom: 15px;
  }
  ${({ theme }) => theme.adaptive.olympusModal.sm} {
    padding-bottom: 13px;
    margin-bottom: 21px;
  }
  ${({ theme }) => theme.adaptive.olympusModal.ssm} {
    padding-bottom: 10px;
    margin-bottom: 15px;
  }
`

const Title = styled.div`
  font-weight: 300;
  font-size: 32px;
  margin-bottom: 4px;
  line-height: 130%;
  color: #fff;
  ${({ theme }) => theme.adaptive.olympusModal.lg} {
    font-size: 21px;
    margin-bottom: 3px;
  }
  ${({ theme }) => theme.adaptive.olympusModal.md} {
    font-size: 16px;
    margin-bottom: 2px;
  }
  ${({ theme }) => theme.adaptive.olympusModal.sm} {
    font-size: 21px;
    margin-bottom: 3px;
  }
  ${({ theme }) => theme.adaptive.olympusModal.ssm} {
    font-size: 16px;
    margin-bottom: 2px;
  }
`

const Desc = styled.p`
  font-size: 18px;
  line-height: 19px;
  color: #939ba9;
  margin: 0;
  ${({ theme }) => theme.adaptive.olympusModal.lg} {
    font-size: 11px;
    line-height: 14px;
  }
  ${({ theme }) => theme.adaptive.olympusModal.md} {
    font-size: 9px;
    line-height: 10px;
  }
  ${({ theme }) => theme.adaptive.olympusModal.sm} {
    font-size: 11px;
    line-height: 14px;
  }
  ${({ theme }) => theme.adaptive.olympusModal.ssm} {
    font-size: 9px;
    line-height: 10px;
  }
`

const Fields = styled.div`
  margin-bottom: 40px;
  ${({ theme }) => theme.adaptive.olympusModal.lg} {
    margin-bottom: 21px;
  }
  ${({ theme }) => theme.adaptive.olympusModal.md} {
    margin-bottom: 19px;
  }
  ${({ theme }) => theme.adaptive.olympusModal.sm} {
    margin-bottom: 21px;
  }
  ${({ theme }) => theme.adaptive.olympusModal.ssm} {
    margin-bottom: 19px;
  }
`

const FieldWrapper = styled.div`
  margin-bottom: 20px;
  ${({ theme }) => theme.adaptive.olympusModal.lg} {
    margin-bottom: 13px;
  }
  ${({ theme }) => theme.adaptive.olympusModal.md} {
    margin-bottom: 10px;
  }
  ${({ theme }) => theme.adaptive.olympusModal.sm} {
    margin-bottom: 13px;
  }
  ${({ theme }) => theme.adaptive.olympusModal.ssm} {
    margin-bottom: 10px;
  }
  &:last-child {
    margin-bottom: 0;
  }
`

const NameWrap = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  ${({ theme }) => theme.adaptive.olympusModal.lg} {
    margin-bottom: 3px;
  }
  ${({ theme }) => theme.adaptive.olympusModal.md} {
    margin-bottom: 2px;
  }
  ${({ theme }) => theme.adaptive.olympusModal.sm} {
    margin-bottom: 3px;
  }
  ${({ theme }) => theme.adaptive.olympusModal.ssm} {
    margin-bottom: 2px;
  }
`

const TooltipIcon = styled.div`
  width: 16px;
  height: 16px;
  background: url('${tooltipIcon}') center no-repeat;
  background-size: cover;
  ${({ theme }) => theme.adaptive.olympusModal.lg} {
    width: 10px;
    height: 10px;
  }
  ${({ theme }) => theme.adaptive.olympusModal.md} {
    width: 8px;
    height: 8px;
  }
  ${({ theme }) => theme.adaptive.olympusModal.sm} {
    width: 10px;
    height: 10px;
  }
  ${({ theme }) => theme.adaptive.olympusModal.ssm} {
    width: 8px;
    height: 8px;
  }
`

const FieldName = styled.div`
  font-weight: 300;
  font-size: 16px;
  line-height: 140%;
  color: #ffffff;
  margin-right: 5px;
  ${({ theme }) => theme.adaptive.olympusModal.lg} {
    font-size: 12px;
    margin-right: 3px;
  }
  ${({ theme }) => theme.adaptive.olympusModal.md} {
    font-size: 8px;
    margin-right: 2px;
  }
  ${({ theme }) => theme.adaptive.olympusModal.sm} {
    font-size: 12px;
    margin-right: 3px;
  }
  ${({ theme }) => theme.adaptive.olympusModal.ssm} {
    font-size: 8px;
    margin-right: 2px;
  }
`

const Field = styled.input`
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  width: 100%;
  height: 50px;
  background: rgba(235, 235, 235, 0.03);
  border: 1px solid rgba(235, 235, 235, 0.25);
  color: #dbdcdc;
  font-size: 21px;
  border-radius: 5px;
  padding: 0 20px;
  ${({ theme }) => theme.adaptive.olympusModal.lg} {
    height: 35px;
    font-size: 14px;
    padding: 0 13px;
    border-radius: 4px;
  }
  ${({ theme }) => theme.adaptive.olympusModal.md} {
    height: 24px;
    font-size: 10px;
    padding: 0 10px;
    border-radius: 2px;
  }
  ${({ theme }) => theme.adaptive.olympusModal.sm} {
    height: 35px;
    font-size: 14px;
    padding: 0 13px;
    border-radius: 4px;
  }
  ${({ theme }) => theme.adaptive.olympusModal.ssm} {
    height: 24px;
    font-size: 10px;
    padding: 0 10px;
    border-radius: 2px;
  }
`

const Button = styled.button`
  width: 100%;
  font-size: 16px;
  line-height: 120%;
  background: #ebebeb;
  border: 1px solid #ffffff;
  border-radius: 5px;
  padding: 16px 0;
  ${({ theme }) => theme.adaptive.olympusModal.lg} {
    font-size: 12px;
    padding: 10px 0;
  }
  ${({ theme }) => theme.adaptive.olympusModal.md} {
    font-size: 8px;
    padding: 7px 0;
  }
  ${({ theme }) => theme.adaptive.olympusModal.sm} {
    font-size: 12px;
    padding: 10px 0;
  }
  ${({ theme }) => theme.adaptive.olympusModal.ssm} {
    font-size: 8px;
    padding: 7px 0;
  }
`

const Success = styled.div`
  display: ${({ active }) => (active ? 'block' : 'none')};
  position: relative;
  width: 590px;
  height: 646px;
  background: url(${modalFigure}) no-repeat center;
  background-size: contain;
  padding: 54px 70px 0;
  margin: 0 auto;
  ${({ theme }) => theme.adaptive.olympusModal.lg} {
    width: 381px;
    height: 417px;
    padding: 35px 45px 0;
  }
  ${({ theme }) => theme.adaptive.olympusModal.md} {
    width: 283px;
    height: 310px;
    padding: 26px 34px 0;
  }
  ${({ theme }) => theme.adaptive.olympusModal.sm} {
    width: 381px;
    height: 417px;
    padding: 35px 45px 0;
  }
  ${({ theme }) => theme.adaptive.olympusModal.ssm} {
    width: 283px;
    height: 310px;
    padding: 26px 34px 0;
  }
`

const SuccessTitle = styled(Title)`
  margin: 0;
`

const SuccessTextWrapper = styled(TextWrapper)`
  margin-bottom: 57px;
  ${({ theme }) => theme.adaptive.olympusModal.lg} {
    margin-bottom: 28px;
  }
  ${({ theme }) => theme.adaptive.olympusModal.md} {
    margin-bottom: 20px;
  }
  ${({ theme }) => theme.adaptive.olympusModal.sm} {
    margin-bottom: 28px;
  }
  ${({ theme }) => theme.adaptive.olympusModal.ssm} {
    margin-bottom: 20px;
  }
`

const SuccessText = styled.div`
  font-size: 25px;
  line-height: 140%;
  color: ${({ alert }) => (alert ? '#FF4A4A' : '#DADCE0')};
  margin: 0 0 20px 0;
  opacity: ${({ opacityVal }) => (opacityVal ? opacityVal : '1')};
  ${({ theme }) => theme.adaptive.olympusModal.lg} {
    font-size: 17px;
    margin: 0 0 13px 0;
  }
  ${({ theme }) => theme.adaptive.olympusModal.md} {
    font-size: 12px;
    margin: 0 0 10px 0;
  }
  ${({ theme }) => theme.adaptive.olympusModal.sm} {
    font-size: 17px;
    margin: 0 0 13px 0;
  }
  ${({ theme }) => theme.adaptive.olympusModal.ssm} {
    font-size: 12px;
    margin: 0 0 10px 0;
  }
  &:last-child {
    margin-bottom: 0;
  }
`

export default ModalOlympus
