import React from 'react'
import ModalSoon from './ModalSoon'

export default function ModalSuccess({ open, onClose }) {
  return (
    <ModalSoon
      open={open}
      name=" Thank you for reaching out. <br /> We will get back to you shortly."
      onClose={onClose}
    />
  )
}
