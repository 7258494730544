import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import bgImg from '../../img/zeroTier/bg.jpg'
import exploreBg from '../../img/zeroTier/explore-btn.svg'
import contactBg from '../../img/zeroTier/contact-btn.svg'
import exploreIcon from '../../img/zeroTier/explore-icon.svg'
import footerDarkBg from '../../img/index/footer/footer-bg-dark.svg'
import About from './About'
import Board from './Board'
import Tiers from './Tiers'
import Resume from './Resume'
import GiveUs from './GiveUs'
import IndexFooter from '../Index/IndexFooter'
import ModalOlympus from '../Modal/ModalOlympus'

export default function ZeroTier() {
  const [modalOpen, setModalOpen] = useState(false)

  return (
    <Root>
      <ModalOlympus open={modalOpen} onClose={() => setModalOpen(false)} />
      <Container>
        <TitleWrap>
          <Title>Occam.fi</Title>
          <SubTitleWrap>
            <SubTitle>Tokenized incubator and a VC DAO</SubTitle>
            <ButtonsWrap>
              <ExploreBtn href={'#tiers'}>Explore</ExploreBtn>
              <ContactBtn onClick={() => setModalOpen(true)}>
                Contact Us
              </ContactBtn>
            </ButtonsWrap>
          </SubTitleWrap>
        </TitleWrap>
        <About />
        <Board />
        <Tiers />
        <Resume />
        <GiveUs modalOpen={() => setModalOpen(true)} />
      </Container>
      <IndexFooter
        bgImage={false}
        bgColor={false}
        backGround={footerDarkBg}
        color={'#FFFFFF'}
      />
      <BottomBg />
    </Root>
  )
}

const Root = styled.div`
  position: relative;
  width: 100%;
  &:before {
    content: '';
    position: absolute;
    top: -122px;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    height: 1168px;
    background: url('${bgImg}') center no-repeat;
    background-size: cover;
    ${({ theme }) => theme.adaptive.zeroTier.xl} {
      height: 1050px;
      top: -118px;
    }
    ${({ theme }) => theme.adaptive.zeroTier.lg} {
      height: 920px;
      top: -107px;
    }
    ${({ theme }) => theme.adaptive.zeroTier.slg} {
      height: 795px;
      top: -94px;
    }
    ${({ theme }) => theme.adaptive.zeroTier.md} {
      height: 590px;
      top: -91px;
    }
    ${({ theme }) => theme.adaptive.zeroTier.smd} {
      height: 390px;
      top: -91px;
    }
    ${({ theme }) => theme.adaptive.zeroTier.sm} {
      height: 363px;
      top: -78px;
    }
    ${({ theme }) => theme.adaptive.zeroTier.ssm} {
      height: 295px;
    }
  }
`

const Container = styled.div`
  padding: 242px 0 0 0;
  ${({ theme }) => theme.adaptive.zeroTier.xl} {
    padding: 210px 0 0 0;
  }
  ${({ theme }) => theme.adaptive.zeroTier.lg} {
    padding: 180px 0 0 0;
  }
  ${({ theme }) => theme.adaptive.zeroTier.slg} {
    padding: 155px 0 0 0;
  }
  ${({ theme }) => theme.adaptive.zeroTier.md} {
    padding: 107px 0 0 0;
  }
  ${({ theme }) => theme.adaptive.zeroTier.smd} {
    padding: 31px 0 0 0;
  }
  ${({ theme }) => theme.adaptive.zeroTier.sm} {
    padding: 40px 0 0 0;
  }
  ${({ theme }) => theme.adaptive.zeroTier.ssm} {
    padding: 25px 0 0 0;
  }
`

const TitleWrap = styled.div`
  position: relative;
  width: 933px;
  margin: 0 auto;
  ${({ theme }) => theme.adaptive.zeroTier.xl} {
    width: 816px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.lg} {
    width: 700px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.slg} {
    width: 603px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.md} {
    width: 447px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.smd} {
    width: 300px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.sm} {
    width: 293px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.ssm} {
    width: 230px;
  }
`

const Title = styled.div`
  font-family: 'RPBrawl';
  position: relative;
  font-size: 142px;
  line-height: 116px;
  text-align: center;
  background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.3) 0%,
      rgba(9, 11, 15, 0.3) 100%
    ),
    linear-gradient(0deg, #ffffff, #ffffff),
    linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.2) 0%,
      rgba(255, 255, 255, 0) 100%
    );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-transform: uppercase;
  margin-bottom: 33px;
  ${({ theme }) => theme.adaptive.zeroTier.xl} {
    font-size: 124px;
    line-height: 100px;
    margin-bottom: 29px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.lg} {
    font-size: 106px;
    line-height: 87px;
    margin-bottom: 25px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.slg} {
    font-size: 91px;
    line-height: 75px;
    margin-bottom: 23px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.md} {
    font-size: 68px;
    line-height: 54px;
    margin-bottom: 16px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.smd} {
    font-size: 45px;
    line-height: 36px;
    margin-bottom: 8px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.sm} {
    width: 205px;
    margin: 0 auto;
    font-size: 31px;
    line-height: 25px;
    margin-bottom: 16px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.ssm} {
    width: 160px;
    margin: 0 auto;
    font-size: 24px;
    line-height: 19px;
    margin-bottom: 12px;
  }
`

const SubTitleWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${({ theme }) => theme.adaptive.zeroTier.smd} {
    display: block;
  }
`

const SubTitle = styled.div`
  font-weight: 300;
  font-size: 34px;
  line-height: 36px;
  text-transform: capitalize;
  color: #ffffff;
  ${({ theme }) => theme.adaptive.zeroTier.xl} {
    font-size: 28px;
    line-height: 37px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.lg} {
    font-size: 24px;
    line-height: 31px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.slg} {
    font-size: 21px;
    line-height: 28px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.md} {
    font-size: 16px;
    line-height: 20px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.smd} {
    font-size: 18px;
    line-height: 28px;
    margin-bottom: 10px;
    text-align: center;
  }
  ${({ theme }) => theme.adaptive.zeroTier.sm} {
    font-size: 18px;
    line-height: 26px;
    margin-bottom: 16px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.ssm} {
    width: 100%;
    margin: 0 auto;
    font-size: 15px;
    line-height: 20px;
    margin-bottom: 15px;
  }
`

const ButtonsWrap = styled.div`
  display: flex;
  ${({ theme }) => theme.adaptive.zeroTier.smd} {
    width: 100%;
    justify-content: space-between;
  }
  ${({ theme }) => theme.adaptive.zeroTier.ssm} {
    width: 200px;
    margin: 0 auto;
    justify-content: space-between;
  }
`

const BtnsCss = css`
  position: relative;
  display: block;
  width: 217px;
  height: 38px;
  padding: 7px 62px 8px;
  font-weight: 500;
  font-size: 19px;
  line-height: 23px;
  text-align: left;
  text-transform: capitalize;
  ${({ theme }) => theme.adaptive.zeroTier.xl} {
    width: 196px;
    height: 33px;
    padding: 7px 59px 7px;
    font-size: 16px;
    line-height: 19px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.lg} {
    width: 168px;
    height: 29px;
    padding: 6px 43px 6px;
    font-size: 15px;
    line-height: 18px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.slg} {
    width: 145px;
    height: 25px;
    padding: 5px 43px 5px;
    font-size: 12px;
    line-height: 14px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.md} {
    width: 107px;
    height: 18px;
    padding: 3px 29px 3px;
    font-size: 10px;
    line-height: 12px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.smd} {
    width: 152px;
    height: 26px;
    padding: 7px 46px 4px;
    font-size: 12px;
    line-height: 14px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.sm} {
    width: 138px;
    height: 23px;
    padding: 4px 37px 5px;
    font-size: 13px;
    line-height: 15px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.ssm} {
    width: 107px;
    height: 18px;
    padding: 3px 28px 4px;
    font-size: 10px;
    line-height: 12px;
  }
`

const ExploreBtn = styled.a`
  ${BtnsCss};
  background: url('${exploreBg}') center no-repeat;
  background-size: cover;
  color: #50515f;
  &:before {
    content: '';
    position: absolute;
    top: 11px;
    right: 62px;
    width: 23px;
    height: 22px;
    background: url('${exploreIcon}') center no-repeat;
    background-size: cover;
    ${({ theme }) => theme.adaptive.zeroTier.xl} {
      top: 9px;
      right: 55px;
      width: 20px;
      height: 20px;
    }
    ${({ theme }) => theme.adaptive.zeroTier.lg} {
      top: 8px;
      right: 47px;
      width: 18px;
      height: 17px;
    }
    ${({ theme }) => theme.adaptive.zeroTier.slg} {
      top: 7px;
      right: 40px;
      width: 15px;
      height: 14px;
    }
    ${({ theme }) => theme.adaptive.zeroTier.md} {
      top: 5px;
      right: 30px;
      width: 11px;
      height: 11px;
    }
    ${({ theme }) => theme.adaptive.zeroTier.smd} {
      top: 8px;
      right: 42px;
      width: 16px;
      height: 16px;
    }
    ${({ theme }) => theme.adaptive.zeroTier.sm} {
      top: 7px;
      right: 38px;
      width: 14px;
      height: 14px;
    }
    ${({ theme }) => theme.adaptive.zeroTier.ssm} {
      top: 5px;
      right: 30px;
      width: 11px;
      height: 11px;
    }
  }
`

const ContactBtn = styled.div`
  ${BtnsCss};
  background: url('${contactBg}') center no-repeat;
  background-size: cover;
  text-align: center;
  color: #ffffff;
  margin-left: -10px;
  cursor: pointer;
`

const BottomBg = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1000px;
  background: #16181e;
  z-index: -1;
  ${({ theme }) => theme.adaptive.zeroTier.xl} {
    height: 700px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.lg} {
    height: 600px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.slg} {
    height: 400px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.md} {
    height: 400px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.smd} {
    height: 730px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.sm} {
    height: 1000px;
  }
`
