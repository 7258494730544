import React from 'react'
import styled from 'styled-components'
import Header from 'components/Rebranding/Header'
import Incubator from 'components/Rebranding/Incubator'
import Dao from 'components/Rebranding/Dao'
import Ispo from 'components/Rebranding/Ispo'
import Governance from 'components/Rebranding/Governance'
import News from 'components/Rebranding/News'
import Partners from 'components/Rebranding/Partners'
import Footer from 'components/Rebranding/Footer'
import Menu from '../Header/menu'

const HomePage: React.FunctionComponent = () => {
  return (
    <Root>
      <Menu />
      <Header />
      <Incubator />
      <Dao />
      <Ispo />
      <Governance />
      <News />
      <Partners />
      <Footer />
    </Root>
  )
}

export default HomePage

const Root = styled.div`
  position: relative;
  width: 100%;
  background: #ededed;
  overflow: hidden;
`
