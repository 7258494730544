import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import bgTop from 'img/header/bg-top.svg'
import bgBot from 'img/header/bg-bot.svg'
import SocialBg from 'img/header/border.svg'
import { Link } from 'react-router-dom'
import Socials from '../Socials/Socials'
import {
  APPLY_IDO_LINK,
  FORUM_LINK,
  CAREERS_LINK,
  OCCAMX_LINK,
  OCCAMX_DASHBOARD_LINK,
} from 'configs/app.config'
import { ReactComponent as Arrow } from 'img/header/arrow.svg'
import exchangesList from 'store/exchanges'

interface menuProps {
  active: boolean
  onClose: () => void
}

const HeaderMenuWhite: React.FunctionComponent<menuProps> = (props) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <>
      <HeaderBackground onClick={props.onClose} isActive={props.active} />
      <HeaderMenuRoot isActive={props.active}>
        <MenuContent>
          <Links>
            <Link to={{ pathname: OCCAMX_LINK }} target="_blank">
              OccamX
            </Link>
            <Link to={{ pathname: OCCAMX_DASHBOARD_LINK }} target="_blank">
              OCX DASHBOARD
            </Link>
            {/* <Link to={'/ecosystem'}>ECOSYSTEM</Link> */}
            {/* <Link to={"/team"}>TEAM</Link> */}
            <Link to={{ pathname: APPLY_IDO_LINK }} target="_blank">
              APPLY FOR IDO
            </Link>
            <Link to={{ pathname: CAREERS_LINK }} target="_blank">
              CAREERS
            </Link>
            <Link to={'/ido-kyc'}>IDO KYC</Link>
            <Link to={'/usinst-ido-kyc'}>ACCREDITED KYC</Link>
            {/*<Link to={"/swap"}>ETH {"<>"} ADA BRIDGE</Link>*/}
            <Link to={'/vcdao'}>VC DAO</Link>
            <Link to={{ pathname: FORUM_LINK }} target="_blank">
              Forum
            </Link>
            <DropDown onClick={() => setIsOpen(!isOpen)} isOpen={isOpen}>
              <DropDownText>Buy OCC</DropDownText>
              <DropDownIcon>
                <Arrow />
              </DropDownIcon>
            </DropDown>
            <DropDownLinks isOpen={isOpen}>
              {exchangesList.map((exchange, idx) => {
                return (
                  <Link
                    to={{
                      pathname: exchange.link,
                    }}
                    target="_blank"
                  >
                    {exchange.title}
                  </Link>
                )
              })}
            </DropDownLinks>
          </Links>
          <BottomBlock>
            {/*<Docs>*/}
            {/*  <DocWrap href={'/docs/occam_deck.pdf'}>*/}
            {/*    <DocImg src={deckImg} />*/}
            {/*    <DocName>Deck</DocName>*/}
            {/*  </DocWrap>*/}
            {/*  <DocWrap href={'/docs/OccamX_Whitepaper.pdf'}>*/}
            {/*    <DocImg src={paperImg}/>*/}
            {/*    <DocName>White Paper</DocName>*/}
            {/*  </DocWrap>*/}
            {/*  <DocWrap href={'/docs/occamrazer_wp_v1.pdf'}>*/}
            {/*    <DocImg src={occamXImg} />*/}
            {/*    <DocName>OccamX</DocName>*/}
            {/*  </DocWrap>*/}
            {/*</Docs>*/}
            <SocialsWrap>
              <SocialsCont>
                <Socials wrap={true} />
              </SocialsCont>
              <SocialText>Be a part of our community</SocialText>
            </SocialsWrap>
          </BottomBlock>
          <Bg />
        </MenuContent>
      </HeaderMenuRoot>
    </>
  )
}

export default HeaderMenuWhite

const HeaderBackground = styled.div<{ isActive: boolean }>`
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  transition: 0.5s ease-in-out;
  opacity: ${({ isActive }) => (isActive ? '1' : '0')};
  z-index: ${({ isActive }) => (isActive ? '6' : '0')};
  pointer-events: ${({ isActive }) => (isActive ? 'auto' : 'none')};
  backdrop-filter: blur(5px);
`

const HeaderMenuRoot = styled.div<{ isActive: boolean }>`
  position: fixed;
  height: 100%;
  top: 0;
  width: 734px;
  right: ${({ isActive }) => (isActive ? '0' : '-100%')};
  transition: 0.5s ease-in-out;
  overflow: hidden;
  z-index: 10;
  @media screen and (max-width: 734px) {
    width: 100%;
  }
`

const MenuContent = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  padding: 109px 0 109px 108px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  ${({ theme }) => theme.adaptive.headerMenuWhite.xl} {
    padding: 109px 0 90px 91px;
  }
  ${({ theme }) => theme.adaptive.headerMenuWhite.lg} {
    padding: 80px 0 60px 81px;
  }
  ${({ theme }) => theme.adaptive.headerMenuWhite.slg} {
    padding: 66px 0 61px 67px;
  }
  ${({ theme }) => theme.adaptive.headerMenuWhite.md} {
    padding: 65px 0 40px 52px;
  }
  ${({ theme }) => theme.adaptive.headerMenuWhite.sm} {
    padding: 65px 0 13px 34px;
  }
  ${({ theme }) => theme.adaptive.headerMenuWhite.smm} {
    padding: 72px 0 34px 50px;
  }
`

const Bg = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  z-index: -1;
  background: rgba(255, 255, 255, 0.92);
  backdrop-filter: blur(11px);
  border-radius: 0 0 0 20px;
`

const BgTop = styled.div`
  width: 100%;
  height: 154px;
  background: url('${bgTop}') center no-repeat;
  ${({ theme }) => theme.adaptive.headerMenuWhite.sm} {
    display: none;
  }
  @media screen and (min-width: 2000px) {
    height: 200px;
    background-size: cover;
  }
`

const BgRow = styled.div<{ isActive: boolean }>`
  display: ${({ isActive }) => (isActive ? 'flex' : 'none')};
  justify-content: flex-end;
  &:nth-child(2) {
    flex: 1;
  }
`

const BgBot = styled.div`
  width: 708px;
  height: 306px;
  background: url('${bgBot}') center no-repeat;
  ${({ theme }) => theme.adaptive.headerMenuWhite.sm} {
    margin-bottom: 55px;
  }
  ${({ theme }) => theme.adaptive.headerMenuWhite.smm} {
    display: none;
  }
  @media screen and (min-width: 2000px) {
    width: 966px;
    height: 350px;
    background-size: cover;
  }
`

const BgMid = styled.div`
  width: 708px;
  height: 100%;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.65), rgba(0, 0, 0, 0.65)),
    linear-gradient(90deg, #505d6d 0%, #31334a 47.4%, #151821 100%);
  opacity: 0.95;
  @media screen and (min-width: 2000px) {
    width: 964px;
  }
`

const Links = styled.div`
  a {
    position: relative;
    display: block;
    font-weight: 300;
    font-size: 26px;
    line-height: 31px;
    text-transform: uppercase;
    color: #252525;
    margin-bottom: 22px;
    transition: 0.4s;
    ${({ theme }) => theme.adaptive.headerMenuWhite.slg} {
      font-size: 24px;
      line-height: 29px;
      margin-bottom: 18px;
    }
    ${({ theme }) => theme.adaptive.headerMenuWhite.md} {
      font-size: 18px;
      line-height: 22px;
      margin-bottom: 10px;
    }
    ${({ theme }) => theme.adaptive.headerMenuWhite.sm} {
      font-size: 12px;
      line-height: 14px;
      margin-bottom: 7px;
    }
    ${({ theme }) => theme.adaptive.headerMenuWhite.smm} {
      font-size: 18px;
      line-height: 22px;
      margin-bottom: 18px;
    }
    &:last-child {
      margin: 0;
    }
    &:hover {
      color: #939ba9;
    }
  }
`

const DropDownText = styled.div`
  position: relative;
  display: block;
  font-weight: 300;
  font-size: 26px;
  line-height: 100%;
  text-transform: uppercase;
  transition: 0.4s;
  ${({ theme }) => theme.adaptive.headerMenuWhite.slg} {
    font-size: 24px;
  }
  ${({ theme }) => theme.adaptive.headerMenuWhite.md} {
    font-size: 18px;
  }
  ${({ theme }) => theme.adaptive.headerMenuWhite.sm} {
    font-size: 12px;
  }
  ${({ theme }) => theme.adaptive.headerMenuWhite.smm} {
    font-size: 18px;
  }
`

const DropDownIcon = styled.div`
  width: 10px;
  height: 4px;
  transition: 0.4s;
  margin-left: 10px;
  ${({ theme }) => theme.adaptive.headerMenuWhite.sm} {
    width: 7px;
    height: 3px;
  }
  ${({ theme }) => theme.adaptive.headerMenuWhite.smm} {
    width: 10px;
    height: 4px;
  }
  svg {
    display: block;
    width: 100%;
    height: 100%;
    transition: 0.4s;
  }
`

const DropDownLinks = styled.div<{ isOpen: boolean }>`
  display: flex;
  flex-wrap: wrap;
  width: 305px;
  opacity: ${({ isOpen }) => (isOpen ? '1' : '0')};
  transition: 0.4s;
  margin-top: 16px;
  pointer-events: ${({ isOpen }) => (isOpen ? 'auto' : 'none')};
  ${({ theme }) => theme.adaptive.headerMenuWhite.slg} {
    width: 275px;
    margin-top: 16px;
  }
  ${({ theme }) => theme.adaptive.headerMenuWhite.md} {
    margin-top: 10px;
  }
  ${({ theme }) => theme.adaptive.headerMenuWhite.sm} {
    width: 220px;
    margin-top: 7px;
  }
  ${({ theme }) => theme.adaptive.headerMenuWhite.smm} {
    width: 275px;
    margin-top: 10px;
  }
  a {
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    color: #5e5e5e;
    opacity: 0.7;
    margin-right: 18px;
    margin-bottom: 4px;
    ${({ theme }) => theme.adaptive.headerMenuWhite.slg} {
      font-size: 14px;
      margin-bottom: 0;
    }
    ${({ theme }) => theme.adaptive.headerMenuWhite.md} {
      font-size: 14px;
    }
    ${({ theme }) => theme.adaptive.headerMenuWhite.sm} {
      font-size: 10px;
      line-height: 16px;
    }
    ${({ theme }) => theme.adaptive.headerMenuWhite.smm} {
      font-size: 14px;
    }
  }
`

const DropDown = styled.div<{ isOpen: boolean }>`
  display: flex;
  align-items: center;
  cursor: pointer;
  ${DropDownText} {
    color: #252525;
  }
  ${DropDownIcon} {
    svg {
      stroke: #252525;
    }
  }
  ${({ isOpen }) => {
    if (isOpen)
      return css`
        ${DropDownText} {
          color: #939ba9;
        }
        ${DropDownIcon} {
          transform: rotate(180deg);
          svg {
            stroke: #939ba9;
          }
        }
      `
  }}
  &:hover {
    ${DropDownIcon} {
      svg {
        stroke: #939ba9;
      }
    }
    ${DropDownText} {
      color: #939ba9;
    }
  }
`

const BottomBlock = styled.div``

const Docs = styled.div`
  display: flex;
  ${({ theme }) => theme.adaptive.headerMenuWhite.smm} {
    display: block;
    position: absolute;
    top: 78px;
    left: 210px;
  }
`

const DocWrap = styled.a`
  display: block;
  width: 90px;
  margin-right: 15px;
  &:last-child {
    margin: 0;
  }
  ${({ theme }) => theme.adaptive.headerMenuWhite.smm} {
    margin-bottom: 15px;
    margin-right: 0;
  }
`

const DocName = styled.div`
  width: 100%;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  text-transform: capitalize;
  color: #c7ccd1;
  margin-top: 10px;
`

const DocImg = styled.img`
  display: block;
  margin: 0 auto;
  width: 56px;
  height: 38px;
`

const SocialText = styled.div`
  width: 187px;
  margin-left: 60px;
  font-weight: 200;
  font-size: 32px;
  line-height: 120%;
  letter-spacing: -0.02em;
  color: #939ba9;
  opacity: 0.7;
  ${({ theme }) => theme.adaptive.headerMenuWhite.sm} {
    font-size: 24px;
    width: 100%;
    margin: 22px 0 0 0;
    text-align: right;
  }
`

const SocialsWrap = styled.div`
  margin-top: 30px;
  display: flex;
  padding: 30px 0;
  width: 451px;
  height: 139px;
  background: url('${SocialBg}') center no-repeat;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  ${SocialText} {
    ${({ theme }) => theme.adaptive.headerMenuWhite.sm} {
      text-align: left;
    }
  }
  ${({ theme }) => theme.adaptive.headerMenuWhite.sm} {
    flex-wrap: wrap;
    margin-left: 30px;
    width: 311px;
    height: 119px;
    padding: 23px 0;
  }
  ${({ theme }) => theme.adaptive.headerMenuWhite.smm} {
    margin-left: 0;
    margin-top: 20px;
  }
`

const SocialsCont = styled.div`
  max-width: 125px;
  ${({ theme }) => theme.adaptive.headerMenuWhite.sm} {
    width: 100%;
    max-width: unset;
  }
  a {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: 27px;
    height: 26px;
    margin: 23px 21px 0 0;
    ${({ theme }) => theme.adaptive.headerMenuWhite.sm} {
      margin: 0 21px 0 0;
    }
    &:nth-child(1),
    &:nth-child(2),
    &:nth-child(3) {
      margin-top: 0;
    }
    &:nth-child(3n) {
      margin-right: 0;
      ${({ theme }) => theme.adaptive.headerMenuWhite.sm} {
        margin: 0 21px 0 0;
      }
    }
    &:last-child {
      ${({ theme }) => theme.adaptive.headerMenuWhite.sm} {
        margin: 0;
      }
    }
    svg {
      max-width: 27px;
      max-height: 26px;
    }
  }
`
