import { ReactComponent as Swissborg } from 'img/rebranding/button/exchanges/swissborg.svg'
import { ReactComponent as Gate } from 'img/rebranding/button/exchanges/gate.svg'
import { ReactComponent as Mxcio } from 'img/rebranding/button/exchanges/mexc.svg'
import { ReactComponent as Bitrue } from 'img/rebranding/button/exchanges/bitrue.svg'
//import { ReactComponent as Mexo } from 'img/rebranding/button/exchanges/mexo.svg'
import { ReactComponent as Uniswap } from 'img/rebranding/button/exchanges/uniswap.svg'
//import { ReactComponent as Bitmart } from 'img/rebranding/button/exchanges/bitmart.svg'
//import { ReactComponent as Sushi } from 'img/rebranding/button/exchanges/sushi.svg'
import { ReactComponent as HitBtc } from 'img/rebranding/button/exchanges/hitbtc.svg'
import { ReactComponent as Pancake } from 'img/rebranding/button/exchanges/pancake.svg'

export default [
  {
    title: 'Uniswap',
    link: 'https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x2f109021afe75b949429fe30523ee7c0d5b27207',
    getIcon: () => <Uniswap />,
  },
  // {
  //   title: 'Sushi',
  //   link: 'https://app.sushi.com/swap?inputCurrency=0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48&outputCurrency=0x2f109021afe75b949429fe30523ee7c0d5b27207',
  //   getIcon: () => <Sushi />,
  // },
  {
    title: 'Gate.io',
    link: 'https://www.gate.io/trade/OCC_USDT?ref=2886755',
    getIcon: () => <Gate />,
  },
  // {
  //   title: 'Bitmart',
  //   link: 'https://www.bitmart.com/register/en?r=OCC',
  //   getIcon: () => <Bitmart />,
  // },
  {
    title: 'Bitrue',
    link: 'https://www.bitrue.com/trade/occ_usdt',
    getIcon: () => <Bitrue />,
  },
  {
    title: 'PancakeSwap',
    link: 'https://pancakeswap.finance/swap?outputCurrency=0x2a4dffa1fa0f86ce7f0982f88aecc199fb3476bc&chainId=56',
    getIcon: () => <Pancake />,
  },
  // {
  //   title: 'Mexo',
  //   link: 'https://www.mexo.io/register/IJye2Q',
  //   getIcon: () => <Mexo />,
  // },
  {
    title: 'Mexc',
    link: 'https://www.mexc.com/auth/signup?inviteCode=1Cid1',
    getIcon: () => <Mxcio />,
  },
  {
    title: 'Swissborg',
    link: 'https://join.swissborg.com/en/r/markNO3R',
    getIcon: () => <Swissborg />,
  },
  {
    title: 'HitBTC',
    link: 'https://hitbtc.com/occ-to-usdt',
    getIcon: () => <HitBtc />,
  },
]
