import { ReactComponent as IncubatorIcon } from 'img/rebranding/apps/incubator-icon.svg'
import { ReactComponent as IspoIcon } from 'img/rebranding/apps/ispo-icon.svg'
import razerBg from 'img/rebranding/apps/backgrounds/razer-bg.jpg'
import razerBg2x from 'img/rebranding/apps/backgrounds/razer-bg-2x.jpg'
import razerBgWebp from 'img/rebranding/apps/backgrounds/razer-bg@1x.webp'
import razerBgWebp2x from 'img/rebranding/apps/backgrounds/razer-bg@2x.webp'
import occamXBg from 'img/rebranding/apps/backgrounds/occamx-bg.jpg'
import occamXBg2x from 'img/rebranding/apps/backgrounds/occamx-bg-2x.jpg'
import occamXBgWebp from 'img/rebranding/apps/backgrounds/occamx-bg@1x.webp'
import occamXBgWebp2x from 'img/rebranding/apps/backgrounds/occamx-bg@2x.webp'
import ispoBg from 'img/rebranding/apps/backgrounds/ispo-bg.jpg'
import ispoBg2x from 'img/rebranding/apps/backgrounds/ispo-bg-2x.jpg'
import ispoBgWebp from 'img/rebranding/apps/backgrounds/ispo-bg@1x.webp'
import ispoBgWebp2x from 'img/rebranding/apps/backgrounds/ispo-bg@2x.webp'
import incubatorBg from 'img/rebranding/apps/backgrounds/incubator-bg.jpg'
import incubatorBg2x from 'img/rebranding/apps/backgrounds/incubator-bg-2x.jpg'
import incubatorBgWebp from 'img/rebranding/apps/backgrounds/incubator-bg@1x.webp'
import incubatorBgWebp2x from 'img/rebranding/apps/backgrounds/incubator-bg@2x.webp'
import { ReactComponent as IncubatorClip } from 'img/rebranding/apps/backgrounds/clips/incubator-clip.svg'
import { ReactComponent as RazerClip } from 'img/rebranding/apps/backgrounds/clips/razer-clip.svg'
import { ReactComponent as RazerSmClip } from 'img/rebranding/apps/backgrounds/clips/razer-sm-clip.svg'
import { ReactComponent as GovernanceClip } from 'img/rebranding/apps/backgrounds/clips/governance-clip.svg'
import { ReactComponent as OccamXClip } from 'img/rebranding/apps/backgrounds/clips/occamx-clip.svg'
import { ReactComponent as OccamXClipSm } from 'img/rebranding/apps/backgrounds/clips/occamx-sm-clip.svg'
import { ReactComponent as GovernanceClipSm } from 'img/rebranding/apps/backgrounds/clips/governance-sm-clip.svg'
import { ReactComponent as IncubatorClipSm } from 'img/rebranding/apps/backgrounds/clips/incubator-sm-clip.svg'
import { ISPO_LINK } from '../configs/app.config'

const appsList = [
  {
    icon: 'R',
    title: 'Razer Launchpad',
    link: 'https://razer.occam.fi/',
    background: razerBg,
    background2x: razerBg2x,
    backgroundWebp: razerBgWebp,
    backgroundWebp2x: razerBgWebp2x,
    getClip: () => <RazerClip />,
    getClipSm: () => <RazerSmClip />,
    btnText: 'Go to App',
  },
  {
    icon: 'X',
    title: 'OccamX DEX',
    link: 'https://app.occamx.fi/ ',
    background: occamXBg,
    background2x: occamXBg2x,
    backgroundWebp: occamXBgWebp,
    backgroundWebp2x: occamXBgWebp2x,
    getClip: () => <OccamXClip />,
    getClipSm: () => <OccamXClipSm />,
    btnText: 'Go to App',
  },
  {
    title: 'ISPO',
    link: ISPO_LINK,
    background: ispoBg,
    background2x: ispoBg2x,
    backgroundWebp: ispoBgWebp,
    backgroundWebp2x: ispoBgWebp2x,
    getIcon: () => <IspoIcon />,
    getClip: () => <GovernanceClip />,
    getClipSm: () => <GovernanceClipSm />,
    btnText: 'Explore',
  },
  {
    title: 'Incubator',
    link: '/incubator',
    background: incubatorBg,
    background2x: incubatorBg2x,
    backgroundWebp: incubatorBgWebp,
    backgroundWebp2x: incubatorBgWebp2x,
    getIcon: () => <IncubatorIcon />,
    getClip: () => <IncubatorClip />,
    getClipSm: () => <IncubatorClipSm />,
    btnText: 'Explore',
  },
]

export default appsList
