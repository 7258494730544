import { createGlobalStyle } from 'styled-components'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

const SliderStyle = createGlobalStyle`

  .slick-slider {
    & div {
      outline: none;
    }
  }

  .project-slider {
    z-index: 2;
  }

  .news-slider .slick-list {
    padding-left: 0 !important;
  }
  .news-slider .slick-slide {
    transition: 0.4s;
    display: block !important;
    width: 282px;
    @media screen and (max-width: 420px) {
      width: 260px;
    }
  }
  .news-slider .slick-slide:not(.slick-active) {
    transform: scale(0.8);
  }
  .news-slider .slick-slide.slick-active {
    transform: scale(1);
  }
  .slider-tabs .slick-slide {
    margin-right: 20px;
  }

  .project-slider .slick-list{
    overflow: unset;
  }

  .project-slider .slick-slide {
    transition: 1s;
  }
  .project-slider .slick-slide:not(.slick-active) {
    opacity: 0.1;
  }

  @keyframes fade_out {
    from {
      opacity: 1;
    }
    to {
      opacity: 0.1;
    }
  }

  .project-slider .prev-slide-anim {
    animation-duration: 500ms;
    animation-name: fade_out;
  }

  .ticker {
    z-index: 3;
  }
`
export default SliderStyle
