import React from 'react'
import styled from 'styled-components'
import bgImg from 'img/incubator/bg.jpg'
import logoImg from 'img/incubator/logo.svg'
import { ReactComponent as BtnImg } from 'img/incubator/touch-btn.svg'
import btnIcon from 'img/incubator/btn-icon.svg'
import About from './about'
import Board from './board'
import Partners from './partners'
import Deck from './deck'
import footerDarkBg from 'img/index/footer/footer-bg-dark.svg'
import footerBgMobile from 'img/index/footer/footer-bg-dark-smm.svg'
import IndexFooter from '../Index/IndexFooter'
import { GET_IN_TOUCH } from 'configs/app.config'

const Incubator: React.FunctionComponent = () => {
  return (
    <Root>
      <Wrapper>
        <Main>
          <div>
            <Title>
              We are seeding innovation building robust Interchain DeFi layer
            </Title>
            <Logo />
          </div>
          <Button>
            <BtnImg />
            <BtnText href={GET_IN_TOUCH} target={'_blank'}>
              Get in touch
            </BtnText>
            <BtnIcon />
          </Button>
        </Main>
      </Wrapper>
      <Container>
        <About />
        <Board />
        <Partners />
        <Deck />
      </Container>
      <IndexFooter
        bgImage={false}
        bgColor={false}
        backGround={footerDarkBg}
        mobileBackGround={footerBgMobile}
        color={'#FFFFFF'}
      />
    </Root>
  )
}

export default Incubator

const Root = styled.div`
  position: relative;
  width: 100%;
  &:before {
    content: '';
    position: absolute;
    top: -122px;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    height: 1253px;
    background: url('${bgImg}') center no-repeat;
    background-size: cover;
    //@media screen and (max-height: 1060px) {
    //  top: -222px;
    //}
    ${({ theme }) => theme.adaptive.zeroTier.xl} {
      height: 1069px;
      top: -118px;
    }
    ${({ theme }) => theme.adaptive.zeroTier.lg} {
      height: 895px;
      top: -111px;
    }
    ${({ theme }) => theme.adaptive.zeroTier.slg} {
      height: 875px;
      top: -94px;
    }
    ${({ theme }) => theme.adaptive.zeroTier.md} {
      height: 863px;
      top: -91px;
    }
    ${({ theme }) => theme.adaptive.zeroTier.smd} {
      height: 668px;
      top: -91px;
    }
    ${({ theme }) => theme.adaptive.zeroTier.sm} {
      top: -78px;
      height: 642px;
    }
    ${({ theme }) => theme.adaptive.zeroTier.ssm} {
      height: 648px;
    }
  }
`

const Wrapper = styled.div`
  padding: 140px 0 0 0;
  ${({ theme }) => theme.adaptive.zeroTier.xl} {
    padding: 116px 0 0 0;
  }
  ${({ theme }) => theme.adaptive.zeroTier.lg} {
    padding: 85px 0 0 0;
  }
  ${({ theme }) => theme.adaptive.zeroTier.smd} {
    padding: 75px 0 0 0;
  }
`

const Main = styled.div`
  position: relative;
  width: 933px;
  height: calc(100vh - 300px);
  max-height: 1000px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0 auto;
  ${({ theme }) => theme.adaptive.zeroTier.xl} {
    width: 816px;
    height: calc(100vh - 270px);
    max-height: 887px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.lg} {
    width: 641px;
    height: calc(100vh - 230px);
    max-height: 720px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.slg} {
    width: 641px;
    max-height: 641px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.smd} {
    width: 465px;
    height: auto;
  }
  ${({ theme }) => theme.adaptive.zeroTier.sm} {
    width: 370px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.ssm} {
    width: 280px;
  }
`

const Title = styled.div`
  font-weight: 300;
  font-size: 40px;
  line-height: 110%;
  text-align: center;
  color: #ffffff;
  width: 743px;
  margin: 0 auto;
  ${({ theme }) => theme.adaptive.zeroTier.lg} {
    font-size: 38px;
    line-height: 118%;
    width: 558px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.smd} {
    font-size: 30px;
    line-height: 124%;
    width: 100%;
  }
  ${({ theme }) => theme.adaptive.zeroTier.sm} {
    font-size: 24px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.ssm} {
    font-size: 20px;
  }
`

const Logo = styled.div`
  width: 100%;
  height: 119px;
  background: url('${logoImg}') center no-repeat;
  background-size: cover;
  ${({ theme }) => theme.adaptive.zeroTier.xl} {
    height: 104px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.lg} {
    height: 81px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.smd} {
    width: 310px;
    height: 40px;
    margin: 5px auto 0;
  }
  ${({ theme }) => theme.adaptive.zeroTier.sm} {
    width: 251px;
    height: 32px;
    margin: 4px auto 0;
  }
`

const Button = styled.button`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 398px;
  height: 62px;
  margin: 0 auto;
  border: 0;
  ${({ theme }) => theme.adaptive.zeroTier.lg} {
    width: 299px;
    height: 47px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.smd} {
    width: 258px;
    height: 40px;
    margin: 280px auto 0;
  }
  ${({ theme }) => theme.adaptive.zeroTier.sm} {
    margin: 303px auto 0;
  }
  svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    filter: drop-shadow(0px 2.24631px 4.49262px rgba(0, 0, 0, 0.03));
  }
`

const BtnText = styled.a`
  position: relative;
  display: block;
  font-weight: 600;
  font-size: 21px;
  line-height: 36px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #50515f;
  ${({ theme }) => theme.adaptive.zeroTier.lg} {
    font-size: 18px;
    line-height: 27px;
    letter-spacing: 0.7px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.smd} {
    font-size: 13px;
    line-height: 24px;
    letter-spacing: 1px;
  }
`

const BtnIcon = styled.div`
  position: relative;
  width: 23px;
  height: 22px;
  background: url('${btnIcon}') center no-repeat;
  background-size: cover;
  margin-left: 8px;
  ${({ theme }) => theme.adaptive.zeroTier.lg} {
    width: 17px;
    height: 16px;
    margin-left: 7px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.smd} {
    margin-left: 6px;
  }
`

const Container = styled.div`
  width: 1600px;
  margin: 0 auto;
  ${({ theme }) => theme.adaptive.zeroTier.xl} {
    width: 1400px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.lg} {
    width: 1200px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.slg} {
    width: 1088px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.md} {
    width: 816px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.smd} {
    width: 520px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.sm} {
    width: 335px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.ssm} {
    width: 280px;
  }
`
