import React from 'react'
import styled, { css } from 'styled-components'
import logoImg from 'img/rebranding/footer/logo.svg'
import { ReactComponent as Telegram } from 'img/rebranding/footer/socials/telegram.svg'
import { ReactComponent as Discord } from 'img/rebranding/footer/socials/discord.svg'
import { ReactComponent as Medium } from 'img/rebranding/footer/socials/medium.svg'
import { ReactComponent as Twitter } from 'img/rebranding/footer/socials/twitter.svg'
import { ReactComponent as Reddit } from 'img/rebranding/footer/socials/reddit.svg'
import bgImg from 'img/rebranding/footer/bg.png'
import bgImg2x from 'img/rebranding/footer/bg-2x.png'
import bgImgWebp from 'img/rebranding/footer/bg@1x.webp'
import bgImgWebp2x from 'img/rebranding/footer/bg@2x.webp'
import bgImgSm from 'img/rebranding/footer/bg-sm.png'
import bgImgSm2x from 'img/rebranding/footer/bg-sm-2x.png'
import bgImgSmWebp from 'img/rebranding/footer/bg-sm@1x.webp'
import bgImgSmWebp2x from 'img/rebranding/footer/bg-sm@2x.webp'
import useWindowSize from 'helpers/utils/useWindowSize'
import cloudsImg from 'img/rebranding/footer/clouds.png'
import cloudsImgWebp from 'img/rebranding/footer/clouds@1x.webp'
import { Link } from 'react-router-dom'
import {
  APPLY_IDO_LINK,
  CAREERS_LINK,
  DISCORD_LINK,
  FAQ_LINK,
  FORUM_LINK,
  ISPO_LINK,
  MEDIUM_LINK,
  REDDIT_LINK,
  SUPPORT_LINK,
  TELEGRAM_LINK,
  TWITTER_LINK,
} from 'configs/app.config'

const Footer: React.FunctionComponent = () => {
  const { width } = useWindowSize()

  return (
    <Root>
      {width <= 790 && <Clouds />}
      {width <= 790 && <BgMob />}
      {width > 790 && (
        <Bg>
          <source type="image/webp" srcSet={`${bgImgWebp}, ${bgImgWebp2x}`} />
          <source type="image/png" srcSet={`${bgImg}, ${bgImg2x}`} />
          <img src={bgImg} alt=" content" loading="lazy" />
        </Bg>
      )}
      <Cont>
        <Row>
          <LogoWrap>
            <Logo />
            <OccamText>OCCAM</OccamText>
          </LogoWrap>
          <MainNav>
            <MainLink href={'https://razer.occam.fi/'} target={'_blank'}>
              Razer Launchpad
            </MainLink>
            <MainLink href={'https://app.occamx.fi/'} target={'_blank'}>
              {width <= 790 ? 'OccamX' : 'OccamX DEX'}
            </MainLink>
            <MainLink href={ISPO_LINK} target={'_blank'}>
              ISPO
            </MainLink>
            <MainLink href={'https://occam.fi/incubator'} target={'_blank'}>
              Incubator
            </MainLink>
          </MainNav>
          <Line />
        </Row>
        <NavRow>
          <NavColumn>
            <NavItem>
              <Link to={'/vcdao'}>VC DAO</Link>
            </NavItem>
            <NavItem>
              <Link to={{ pathname: FAQ_LINK }} target={'_blank'}>
                FAQ
              </Link>
            </NavItem>
            <NavItem>
              <Link to={{ pathname: FORUM_LINK }} target={'_blank'}>
                Forum
              </Link>
            </NavItem>
          </NavColumn>
          <NavColumn>
            <NavItem>
              <Link to={{ pathname: '/docs/occam_deck.pdf' }} target={'_blank'}>
                Deck
              </Link>
            </NavItem>
            <NavItem>
              <Link to={{ pathname: APPLY_IDO_LINK }} target={'_blank'}>
                Apply for IDO
              </Link>
            </NavItem>
          </NavColumn>
          <NavColumn>
            <NavItem>
              <Link
                to={{ pathname: '/docs/occamrazer_wp_v1.pdf' }}
                target={'_blank'}
              >
                Whitepaper
              </Link>
            </NavItem>
            <NavItem>
              <Link to={{ pathname: SUPPORT_LINK }} target={'_blank'}>
                Support
              </Link>
            </NavItem>
            <NavItem>
              <Link
                to={{
                  pathname:
                    'https://drive.google.com/drive/folders/1KJRfZHf966IkpoMvsIVUOBWCsLZ15ERV?usp=sharing',
                }}
                target={'_blank'}
              >
                Media Pack
              </Link>
            </NavItem>
            <NavItem>
              <Link to={{ pathname: CAREERS_LINK }} target={'_blank'}>
                Careers
              </Link>
            </NavItem>
          </NavColumn>
          <Socials>
            <SocialItem href={MEDIUM_LINK} target={'_blank'}>
              <Medium />
            </SocialItem>
            <SocialItem href={TELEGRAM_LINK} target={'_blank'}>
              <Telegram />
            </SocialItem>
            <SocialItem href={REDDIT_LINK} target={'_blank'}>
              <Reddit />
            </SocialItem>
            <SocialItem href={DISCORD_LINK} target={'_blank'}>
              <Discord />
            </SocialItem>
            <SocialItem href={TWITTER_LINK} target={'_blank'}>
              <Twitter />
            </SocialItem>
          </Socials>
        </NavRow>
        <BottomRow>
          <CopyRight>© 2020-2024 Occam DAO </CopyRight>
        </BottomRow>
      </Cont>
    </Root>
  )
}

export default Footer

const Root = styled.div`
  position: relative;
  width: 100%;
  padding-bottom: 15px;
  ${({ theme }) => theme.adaptive.newHomePage.lg} {
    padding-bottom: 11px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.slg} {
    padding-bottom: 10px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.md} {
    padding-bottom: 9px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.sm} {
    padding-bottom: 26px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.smm} {
    padding-bottom: 13px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.xs} {
    padding-bottom: 12px;
  }
`

const Cont = styled.div`
  width: 1400px;
  margin: 0 auto;
  ${({ theme }) => theme.adaptive.newHomePage.lg} {
    width: 1200px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.slg} {
    width: 1032px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.md} {
    width: 766px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.sm} {
    width: 520px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.smm} {
    width: 350px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.xs} {
    width: 260px;
  }
`

const Row = styled.div`
  position: relative;
  display: flex;
  margin-bottom: 79px;
  ${({ theme }) => theme.adaptive.newHomePage.lg} {
    margin-bottom: 68px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.slg} {
    margin-bottom: 58px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.md} {
    margin-bottom: 43px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.sm} {
    margin-bottom: 52px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.xs} {
    margin-bottom: 40px;
  }
`

const borderCss = css`
  content: '';
  position: absolute;
  height: 1px;
  background: #333333;
  left: 50%;
  transform: translateX(-50%);
`

const LogoWrap = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  ${({ theme }) => theme.adaptive.newHomePage.sm} {
    display: none;
  }
  &:before {
    ${borderCss};
    width: 281px;
    bottom: -21px;
    ${({ theme }) => theme.adaptive.newHomePage.lg} {
      width: 241px;
      bottom: -18px;
    }
    ${({ theme }) => theme.adaptive.newHomePage.slg} {
      width: 207px;
      bottom: -15px;
    }
    ${({ theme }) => theme.adaptive.newHomePage.md} {
      width: 154px;
      bottom: -11px;
    }
  }
`

const Logo = styled.div`
  width: 75px;
  height: 53px;
  background: url('${logoImg}') center no-repeat;
  background-size: cover;
  margin-right: 50px;
  ${({ theme }) => theme.adaptive.newHomePage.lg} {
    width: 64px;
    height: 44px;
    margin-right: 43px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.slg} {
    width: 55px;
    height: 38px;
    margin-right: 37px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.md} {
    width: 41px;
    height: 28px;
    margin-right: 28px;
  }
`

const OccamText = styled.div`
  font-family: 'RPBrawl';
  font-weight: 400;
  font-size: 30px;
  line-height: 140%;
  color: #333333;
  ${({ theme }) => theme.adaptive.newHomePage.lg} {
    font-size: 25px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.slg} {
    font-size: 22px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.md} {
    font-size: 16px;
  }
`

const MainNav = styled.div`
  display: flex;
  align-items: center;
  margin-left: 286px;
  width: 100%;
  justify-content: space-between;
  ${({ theme }) => theme.adaptive.newHomePage.lg} {
    margin-left: 245px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.slg} {
    margin-left: 210px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.md} {
    margin-left: 151px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.sm} {
    width: 100%;
    margin-left: 0;
  }
  ${({ theme }) => theme.adaptive.newHomePage.smm} {
    flex-wrap: wrap;
  }
`

const MainLink = styled.a<{ isDisable?: boolean }>`
  position: relative;
  display: block;
  font-family: 'NeueHaasDisplay';
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  letter-spacing: -0.01em;
  color: #333333;
  cursor: ${({ isDisable }) => (isDisable ? 'no-drop' : 'pointer')};
  ${({ theme }) => theme.adaptive.newHomePage.lg} {
    font-size: 17px;
    line-height: 20px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.slg} {
    font-size: 15px;
    line-height: 18px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.md} {
    font-size: 12px;
    line-height: 14px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.sm} {
    font-size: 19px;
    line-height: 114%;
    margin-left: 20px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.xs} {
    font-size: 15px;
    margin-left: 8px;
  }
  &:last-child,
  &:first-child {
    ${({ theme }) => theme.adaptive.newHomePage.sm} {
      margin-left: 0;
    }
  }
  &:last-child {
    ${({ theme }) => theme.adaptive.newHomePage.smm} {
      margin-top: 30px;
    }
    ${({ theme }) => theme.adaptive.newHomePage.xs} {
      margin-top: 23px;
    }
  }
  &:before {
    ${borderCss};
    width: 100%;
    bottom: -36px;
    ${({ theme }) => theme.adaptive.newHomePage.lg} {
      bottom: -30px;
    }
    ${({ theme }) => theme.adaptive.newHomePage.slg} {
      bottom: -25px;
    }
    ${({ theme }) => theme.adaptive.newHomePage.md} {
      bottom: -18px;
    }
    ${({ theme }) => theme.adaptive.newHomePage.sm} {
      bottom: -10px;
    }
    ${({ theme }) => theme.adaptive.newHomePage.xs} {
      bottom: -8px;
    }
  }
`

const Line = styled.div`
  position: absolute;
  bottom: -21px;
  left: 50%;
  transform: translateX(-50%);
  width: 1800px;
  height: 1px;
  background: #333333;
  opacity: 0.1;
  ${({ theme }) => theme.adaptive.newHomePage.lg} {
    bottom: -18px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.slg} {
    bottom: -15px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.md} {
    bottom: -11px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.sm} {
    display: none;
  }
`

const NavRow = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  ${({ theme }) => theme.adaptive.newHomePage.sm} {
    justify-content: space-between;
    flex-wrap: wrap;
  }
`

const NavColumn = styled.div`
  ${({ theme }) => theme.adaptive.newHomePage.sm} {
    margin-right: 0 !important;
    order: 2;
  }
  &:nth-child(1) {
    width: 60px;
    margin-right: 72px;
    ${({ theme }) => theme.adaptive.newHomePage.lg} {
      width: 50px;
      margin-right: 64px;
    }
    ${({ theme }) => theme.adaptive.newHomePage.slg} {
      width: 46px;
      margin-right: 46px;
    }
    ${({ theme }) => theme.adaptive.newHomePage.md} {
      width: 40px;
      margin-right: 30px;
    }
    ${({ theme }) => theme.adaptive.newHomePage.sm} {
      width: 188px;
    }
    ${({ theme }) => theme.adaptive.newHomePage.smm} {
      width: 103px;
    }
    ${({ theme }) => theme.adaptive.newHomePage.xs} {
      width: 73px;
    }
  }
  &:nth-child(2) {
    width: 118px;
    margin-right: 72px;
    ${({ theme }) => theme.adaptive.newHomePage.lg} {
      width: 97px;
      margin-right: 67px;
    }
    ${({ theme }) => theme.adaptive.newHomePage.slg} {
      width: 92px;
      margin-right: 46px;
    }
    ${({ theme }) => theme.adaptive.newHomePage.md} {
      width: 79px;
      margin-right: 30px;
    }
    ${({ theme }) => theme.adaptive.newHomePage.sm} {
      width: 244px;
    }
    ${({ theme }) => theme.adaptive.newHomePage.smm} {
      width: 150px;
    }
    ${({ theme }) => theme.adaptive.newHomePage.xs} {
      width: 115px;
    }
  }
  &:nth-child(3) {
    width: 83px;
    margin-right: 118px;
    ${({ theme }) => theme.adaptive.newHomePage.lg} {
      width: 69px;
      margin-right: 104px;
    }
    ${({ theme }) => theme.adaptive.newHomePage.slg} {
      width: 65px;
      margin-right: 96px;
    }
    ${({ theme }) => theme.adaptive.newHomePage.md} {
      width: 55px;
      margin-right: 56px;
    }
    ${({ theme }) => theme.adaptive.newHomePage.sm} {
      width: 88px;
    }
    ${({ theme }) => theme.adaptive.newHomePage.smm} {
      width: 88px;
    }
    ${({ theme }) => theme.adaptive.newHomePage.xs} {
      width: 70px;
    }
  }
`

const NavItem = styled.div`
  a {
    position: relative;
    font-family: 'NeueHaasDisplay';
    display: block;
    font-weight: 300;
    font-size: 18px;
    line-height: 200%;
    letter-spacing: -0.01em;
    color: #333333;
    opacity: 0.6;
    cursor: pointer;
    transition: 0.4s;
    outline: none;
    ${({ theme }) => theme.adaptive.newHomePage.lg} {
      font-size: 15px;
    }
    ${({ theme }) => theme.adaptive.newHomePage.slg} {
      font-size: 14px;
    }
    ${({ theme }) => theme.adaptive.newHomePage.md} {
      font-size: 12px;
    }
    ${({ theme }) => theme.adaptive.newHomePage.sm} {
      font-size: 19px;
    }
    ${({ theme }) => theme.adaptive.newHomePage.xs} {
      font-size: 15px;
    }
    &:hover {
      opacity: 1;
      color: #56a0f5;
    }
  }
  position: relative;
  margin-bottom: 14px;
  ${({ theme }) => theme.adaptive.newHomePage.lg} {
    margin-bottom: 10px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.slg} {
    margin-bottom: 11px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.md} {
    margin-bottom: 9px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.sm} {
    margin-bottom: 6px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.xs} {
    margin-bottom: 4px;
  }
  &:last-child {
    margin-bottom: 0;
  }
`

const Socials = styled.div`
  display: flex;
  align-items: center;
  ${({ theme }) => theme.adaptive.newHomePage.sm} {
    justify-content: space-between;
    width: 100%;
    order: 1;
    margin-bottom: 40px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.xs} {
    margin-bottom: 32px;
  }
`

const SocialItem = styled.a`
  position: relative;
  display: block;
  max-width: 23px;
  max-height: 20px;
  margin-right: 54px;
  cursor: pointer;
  ${({ theme }) => theme.adaptive.newHomePage.lg} {
    max-width: 20px;
    max-height: 17px;
    margin-right: 46px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.slg} {
    max-width: 17px;
    max-height: 15px;
    margin-right: 40px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.md} {
    max-width: 13px;
    max-height: 11px;
    margin-right: 30px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.sm} {
    max-width: 21px;
    max-height: 18px;
    margin-right: 0;
  }
  ${({ theme }) => theme.adaptive.newHomePage.xs} {
    max-width: 17px;
    max-height: 14px;
  }
  &:last-child {
    margin: 0;
  }
  &:before {
    content: '';
    position: absolute;
    width: 48px;
    height: 48px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: rgba(118, 170, 231, 0.1);
    border: 1px solid rgba(118, 170, 231, 0.1);
    opacity: 0;
    transition: 0.4s;
    border-radius: 50%;
    ${({ theme }) => theme.adaptive.newHomePage.lg} {
      width: 41px;
      height: 41px;
    }
    ${({ theme }) => theme.adaptive.newHomePage.slg} {
      width: 35px;
      height: 35px;
    }
    ${({ theme }) => theme.adaptive.newHomePage.md} {
      width: 26px;
      height: 26px;
    }
    ${({ theme }) => theme.adaptive.newHomePage.sm} {
      display: none;
    }
  }
  svg {
    max-width: 23px;
    max-height: 20px;
    transition: 0.4s;
    fill: #333333;
    opacity: 0.3;
    ${({ theme }) => theme.adaptive.newHomePage.lg} {
      max-width: 20px;
      max-height: 17px;
    }
    ${({ theme }) => theme.adaptive.newHomePage.slg} {
      max-width: 17px;
      max-height: 15px;
    }
    ${({ theme }) => theme.adaptive.newHomePage.md} {
      max-width: 13px;
      max-height: 11px;
    }
    ${({ theme }) => theme.adaptive.newHomePage.sm} {
      max-width: 21px;
      max-height: 18px;
    }
    ${({ theme }) => theme.adaptive.newHomePage.xs} {
      max-width: 17px;
      max-height: 14px;
    }
  }
  &:hover {
    svg {
      fill: #76aae7;
      opacity: 1;
    }
    &:before {
      opacity: 1;
    }
  }
`

const BottomRow = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 41px;
  ${({ theme }) => theme.adaptive.newHomePage.lg} {
    margin-top: 25px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.slg} {
    margin-top: 15px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.md} {
    margin-top: 10px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.sm} {
    margin-top: 40px;
    justify-content: flex-start;
  }
  ${({ theme }) => theme.adaptive.newHomePage.xs} {
    margin-top: 31px;
  }
`

const CopyRight = styled.div`
  font-weight: 300;
  font-size: 18px;
  line-height: 181%;
  letter-spacing: -0.01em;
  color: #333333;
  opacity: 0.6;
  margin-left: 327px;
  margin-right: 136px;
  ${({ theme }) => theme.adaptive.newHomePage.lg} {
    font-size: 15px;
    margin-left: 289px;
    margin-right: 124px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.slg} {
    font-size: 12px;
    margin-left: 258px;
    margin-right: 115px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.md} {
    font-size: 12px;
    margin-left: 159px;
    margin-right: 57px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.sm} {
    font-size: 19px;
    margin-left: 0;
    margin-right: 0;
  }
  ${({ theme }) => theme.adaptive.newHomePage.xs} {
    font-size: 15px;
  }
`

const Cardano = styled(CopyRight)`
  margin: 0;
  ${({ theme }) => theme.adaptive.newHomePage.sm} {
    display: none;
  }
`

const Bg = styled.picture`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  object-fit: cover;
  img {
    width: 100%;
  }
`

const BgMob = styled.div`
  ${({ theme }) => theme.adaptive.newHomePage.sm} {
    position: absolute;
    left: -40px;
    bottom: -106px;
    width: 430px;
    height: 249px;
    background-image: url('${bgImgSm}');
    background-image: image-set(
      url('${bgImgSmWebp}') 1x,
      url('${bgImgSmWebp2x}') 2x,
      url('${bgImgSm}') 1x,
      url('${bgImgSm2x}') 2x
    );
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
  ${({ theme }) => theme.adaptive.newHomePage.smm} {
    left: -50px;
    bottom: -127px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.xs} {
    width: 336px;
    height: 194px;
    left: -32px;
    bottom: -96px;
  }
`

const Clouds = styled.div`
  position: absolute;
  top: -300px;
  left: -531px;
  width: 1738px;
  height: 934px;
  background-image: url('${cloudsImg}');
  background-image: image-set(
    url('${cloudsImgWebp}') 1x,
    url('${cloudsImg}') 1x
  );
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  ${({ theme }) => theme.adaptive.newHomePage.xs} {
    top: -234px;
    left: -487px;
    width: 1356px;
    height: 729px;
  }
`
