import React, { useRef } from 'react'
import styled, { css } from 'styled-components'
import { CSSTransition } from 'react-transition-group'

interface HeaderBurgerProps {
  onBurgerClick: () => void
  active: boolean
  color: string
  activeColor: string
  animationStart: boolean
  indexPage: boolean
}

interface BurgerProps {
  active: boolean
  color: string
  activeColor: string
}

interface BurgerLineProps {
  indexPage: boolean
}

const HeaderBurger: React.FunctionComponent<HeaderBurgerProps> = (props) => {
  const burgerNode = useRef(null)

  const toggleBurger = () => {
    props.onBurgerClick()
  }

  return (
    <BurgerAnimayionStyles indexPage={props.indexPage}>
      <CSSTransition
        nodeRef={burgerNode}
        in={props.animationStart}
        classNames="burger-anim"
        timeout={300}
      >
        <Burger
          onClick={toggleBurger}
          ref={burgerNode}
          className={`burger`}
          active={props.active}
          color={props.color}
          activeColor={props.activeColor}
        >
          <BurgerLine indexPage={props.indexPage} />
          <BurgerLine indexPage={props.indexPage} />
          <BurgerLine indexPage={props.indexPage} />
        </Burger>
      </CSSTransition>
    </BurgerAnimayionStyles>
  )
}

export default HeaderBurger

const BurgerLine = styled.div<BurgerLineProps>`
  position: relative;
  width: 100%;
  height: 2px;
  cursor: pointer;
  transition: transform 0.3s ease;

  ${({ indexPage }) => {
    if (indexPage) {
      return css`
        margin-bottom: 13px;
        ${({ theme }) => theme.adaptive.newDesign.lg} {
          margin-bottom: 10px;
        }
        ${({ theme }) => theme.adaptive.newDesign.slg} {
          margin-bottom: 8px;
        }
        ${({ theme }) => theme.adaptive.newDesign.md} {
          margin-bottom: 5px;
        }
      `
    } else
      return css`
        margin-bottom: 15px;
        ${({ theme }) => theme.adaptive.index.xl} {
          margin-bottom: 13px;
        }

        ${({ theme }) => theme.adaptive.newFundraising.lg} {
          margin-bottom: 12px;
        }

        ${({ theme }) => theme.adaptive.newFundraising.md} {
          margin-bottom: 10px;
        }

        ${({ theme }) => theme.adaptive.sm} {
          margin-bottom: 8px;
        }
      `
  }}

  &:last-child {
    margin-bottom: 0;
  }
`

const Burger = styled.div<BurgerProps>`
  width: 50px;
  cursor: pointer;

  ${({ theme }) => theme.adaptive.index.xl} {
    width: 43px;
  }

  ${({ theme }) => theme.adaptive.newFundraising.lg} {
    width: 38px;
  }

  ${({ theme }) => theme.adaptive.newFundraising.md} {
    width: 32px;
  }

  ${({ theme }) => theme.adaptive.sm} {
    width: 24px;
  }

  ${BurgerLine} {
    background-color: ${({ active, color, activeColor }) =>
      active ? activeColor : color};
  }

  &:hover {
    ${BurgerLine} {
      background-color: ${({ active, activeColor }) =>
        active ? activeColor : '#465062'};
    }
  }
`

const ROTATE_LINE_DEG = '45deg'
const TRANSLATE_LINE_DEG = '17px'
const TRANSLATE_LINE_DEG_MOB = '12px'
const TRANSLATE_LINE_DEG_XL_LAST = '13px'
const TRANSLATE_LINE_DEG_LG_LAST = '11px'
const TRANSLATE_LINE_DEG_LAST = '7px'
const TRANSLATE_LINE_DEG_MOB_LAST = '8px'

const BurgerAnimayionStyles = styled.div<BurgerLineProps>`
  .burger {
    &.burger-anim-enter-active {
      div {
        &:nth-child(1) {
          ${({ indexPage }) => {
            if (indexPage) {
              return css`
                transform: translateY(14px);
              `
            } else
              return css`
                transform: translateY(${TRANSLATE_LINE_DEG});

                ${({ theme }) => theme.adaptive.sm} {
                  transform: translateY(${TRANSLATE_LINE_DEG_MOB});
                }
              `
          }}
        }

        &:nth-child(3) {
          ${({ indexPage }) => {
            if (indexPage) {
              return css`
                transform: translateY(-${TRANSLATE_LINE_DEG});
              `
            } else
              return css`
                transform: translateY(-${TRANSLATE_LINE_DEG});

                ${({ theme }) => theme.adaptive.sm} {
                  transform: translateY(-${TRANSLATE_LINE_DEG_MOB});
                }
              `
          }}
        }
      }
    }

    &.burger-anim-enter-done {
      div {
        &:nth-child(1) {
          ${({ indexPage }) => {
            if (indexPage) {
              return css`
                transform: translateY(14px) rotate(-${ROTATE_LINE_DEG});

                ${({ theme }) => theme.adaptive.newDesign.md} {
                  transform: translateY(7px) rotate(-${ROTATE_LINE_DEG});
                }
              `
            } else
              return css`
                transform: translateY(${TRANSLATE_LINE_DEG})
                  rotate(-${ROTATE_LINE_DEG});

                ${({ theme }) => theme.adaptive.sm} {
                  transform: translateY(${TRANSLATE_LINE_DEG_MOB})
                    rotate(-${ROTATE_LINE_DEG});
                }
              `
          }}
        }

        &:nth-child(2) {
          opacity: 0;
        }

        &:nth-child(3) {
          ${({ indexPage }) => {
            if (indexPage) {
              return css`
                transform: translateY(-${TRANSLATE_LINE_DEG})
                  rotate(${ROTATE_LINE_DEG});

                ${({ theme }) => theme.adaptive.newDesign.lg} {
                  transform: translateY(-11px) rotate(${ROTATE_LINE_DEG});
                }

                ${({ theme }) => theme.adaptive.newDesign.slg} {
                  transform: translateY(-7px) rotate(${ROTATE_LINE_DEG});
                }
              `
            } else
              return css`
                transform: translateY(-${TRANSLATE_LINE_DEG})
                  rotate(${ROTATE_LINE_DEG});

                ${({ theme }) => theme.adaptive.index.xl} {
                  transform: translateY(-${TRANSLATE_LINE_DEG_XL_LAST})
                    rotate(${ROTATE_LINE_DEG});
                }

                ${({ theme }) => theme.adaptive.index.lg} {
                  transform: translateY(-${TRANSLATE_LINE_DEG_LG_LAST})
                    rotate(${ROTATE_LINE_DEG});
                }

                ${({ theme }) => theme.adaptive.index.main.slg} {
                  transform: translateY(-${TRANSLATE_LINE_DEG_LAST})
                    rotate(${ROTATE_LINE_DEG});
                }

                ${({ theme }) => theme.adaptive.sm} {
                  transform: translateY(-${TRANSLATE_LINE_DEG_MOB_LAST})
                    rotate(${ROTATE_LINE_DEG});
                }
              `
          }}
        }
      }
    }

    &.burger-anim-exit-active {
      div {
        &:nth-child(1) {
          ${({ indexPage }) => {
            if (indexPage) {
              return css`
                transform: rotate(0) translateY(${TRANSLATE_LINE_DEG});
              `
            } else
              return css`
                transform: rotate(0) translateY(${TRANSLATE_LINE_DEG});

                ${({ theme }) => theme.adaptive.sm} {
                  transform: rotate(0) translateY(${TRANSLATE_LINE_DEG_MOB});
                }
              `
          }}
        }

        &:nth-child(2) {
          opacity: 0;
        }

        &:nth-child(3) {
          ${({ indexPage }) => {
            if (indexPage) {
              return css`
                transform: rotate(0) translateY(-${TRANSLATE_LINE_DEG});
              `
            } else
              return css`
                transform: rotate(0) translateY(-${TRANSLATE_LINE_DEG});

                ${({ theme }) => theme.adaptive.sm} {
                  transform: rotate(0) translateY(-${TRANSLATE_LINE_DEG_MOB});
                }
              `
          }}
        }
      }
    }

    div {
      transform-origin: center center;
    }
  }
`
