import axios from 'axios'
import apiAuth from './authData.ts'

export default (data) => {
  const { name, email, message = '' } = data
  return axios.post(
    `${process.env.REACT_APP_API_URL}/vacancy`,
    {
      name,
      email,
      message,
    },
    apiAuth
  )
}
