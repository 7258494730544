import React from 'react'
import Header from '../Header/Header'
import styled from 'styled-components'
import Footer from '../Footer/Footer'
import backgroundMainImage from '../../img/bg-main.jpg'

export default function PageWrapper({
  children,
  bgImage = backgroundMainImage,
  footerEnabled = true,
  absoluteHeader = false,
  lightHeaderLogo = false,
  headerBurgerColor = undefined,
  headerXPadding = undefined,
  backgroundProperties = undefined,
  headerTitle = null,
  headerTitleColor = null,
  headerBtn = true,
  indexPage = false,
  isFullHeight = true,
  olympusPage = false,
  headerWhite = false,
  isMainPage = false,
}) {
  return (
    <PageColumn
      bgImage={bgImage}
      isFullHeight={isFullHeight}
      indexPage={indexPage}
      backgroundProperties={backgroundProperties}
    >
      <Header
        positionAbsolute={absoluteHeader}
        lightLogo={lightHeaderLogo}
        burgerColor={headerBurgerColor}
        xPadding={headerXPadding}
        headerTitle={headerTitle}
        headerTitleColor={headerTitleColor}
        headerBtn={headerBtn}
        indexPage={indexPage}
        mainPage={isMainPage}
        olympusPage={olympusPage}
        headerWhite={headerWhite}
      />
      <PageContent>{children}</PageContent>
      {footerEnabled ? <Footer /> : <div></div>}
    </PageColumn>
  )
}

const PageColumn = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: ${({ isFullHeight }) => (isFullHeight ? '100vh' : '')};
  justify-content: space-between;
  background-image: url(${({ bgImage }) => bgImage});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  ${({ backgroundProperties }) => backgroundProperties};
  overflow: hidden;
`

const PageContent = styled.div`
  height: 100%;
`
