import React from 'react'
import styled from 'styled-components'
import { Container } from 'theme/theme'
import titleImg from 'img/rebranding/ispo/title.svg'
import occamIconImg from 'img/rebranding/ispo/occam-icon.svg'
import ellipseImg from 'img/rebranding/ispo/ellipse.svg'
import { ReactComponent as Border } from 'img/rebranding/ispo/text-border.svg'
import { ReactComponent as BorderSm } from 'img/rebranding/ispo/text-border-sm.svg'
import ispoImg from 'img/rebranding/ispo/ispo-img.jpg'
import ispoImg2x from 'img/rebranding/ispo/ispo-img-2x.jpg'
import ispoImgWebp from 'img/rebranding/ispo/ispo-img@1x.webp'
import ispoImgWebp2x from 'img/rebranding/ispo/ispo-img@2x.webp'
import ispoImageText from 'img/rebranding/ispo/ispo-text.svg'
import { ReactComponent as BtnBg } from 'img/rebranding/ispo/btn-bg.svg'
import { ReactComponent as BtnIcon } from 'img/rebranding/apps/btn-icon.svg'
import useWindowSize from 'helpers/utils/useWindowSize'
import { ISPO_LINK } from 'configs/app.config'

const Ispo: React.FunctionComponent = () => {
  const { width } = useWindowSize()

  return (
    <Root>
      <Container>
        <TextBlock>
          <Round />
          <TextBorder>
            {width <= 790 && <BorderSm />}
            {width > 790 && <Border />}
            <Image>
              <ImageText />
            </Image>
          </TextBorder>
          <Title />
          <Text>
            <span>ISPOs are continuous token swap contracts</span> whereby
            native yield for staking native chain coins are exchanged for
            project tokens. Projects get block-by-block financing, stakers are
            free to unstake at any moment.
          </Text>
          <Row>
            <OccamIcon />
            <IDOs>
              ISPOs are offering adjusted risk/return profile when compared to
              IDOs.
            </IDOs>
          </Row>
        </TextBlock>
        <Button href={ISPO_LINK} target={'_blank'}>
          <ButtonBg>
            <BtnBg />
          </ButtonBg>
          Explore ISPO
          <ButtonIcon>
            <BtnIcon />
          </ButtonIcon>
        </Button>
      </Container>
    </Root>
  )
}

export default Ispo

const Root = styled.div`
  position: relative;
  width: 100%;
  padding-bottom: 368px;
  ${({ theme }) => theme.adaptive.newHomePage.lg} {
    padding-bottom: 315px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.slg} {
    padding-bottom: 272px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.md} {
    padding-bottom: 201px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.sm} {
    padding-bottom: 128px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.smm} {
    padding-bottom: 123px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.xs} {
    padding-bottom: 78px;
  }
`

const TextBlock = styled.div`
  position: relative;
  z-index: 2;
`

const Title = styled.div`
  position: relative;
  width: 215px;
  height: 50px;
  margin-bottom: 46px;
  background: url('${titleImg}') center no-repeat;
  background-size: cover;
  ${({ theme }) => theme.adaptive.newHomePage.lg} {
    width: 184px;
    height: 43px;
    margin-bottom: 39px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.slg} {
    width: 159px;
    height: 37px;
    margin-bottom: 34px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.md} {
    width: 118px;
    height: 27px;
    margin-bottom: 25px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.sm} {
    width: 215px;
    height: 50px;
    margin-bottom: 46px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.xs} {
    width: 168px;
    height: 39px;
    margin-bottom: 37px;
  }
`

const TextBorder = styled.div`
  position: absolute;
  top: -100px;
  left: -105px;
  width: 830px;
  height: 747px;
  filter: drop-shadow(0px 8px 27px rgba(19, 79, 120, 0.05));
  ${({ theme }) => theme.adaptive.newHomePage.lg} {
    top: -86px;
    left: -90px;
    width: 711px;
    height: 640px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.slg} {
    top: -74px;
    left: -78px;
    width: 612px;
    height: 551px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.md} {
    top: -55px;
    left: -58px;
    width: 454px;
    height: 409px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.sm} {
    top: -75px;
    left: -39px;
    width: 450px;
    height: 740px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.xs} {
    top: -58px;
    left: -19px;
    width: 351px;
    height: 578px;
  }
  svg {
    width: 500%;
    height: 500%;
    transform-origin: top left;
    transform: scale(0.2);
  }
`

const Image = styled.div`
  position: absolute;
  width: 695px;
  height: 850px;
  top: 87px;
  left: 706px;
  background-image: url('${ispoImg}');
  background-image: image-set(
    url('${ispoImgWebp}') 1x,
    url('${ispoImgWebp2x}') 2x,
    url('${ispoImg}') 1x,
    url('${ispoImg2x}') 2x
  );
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  z-index: -1;
  box-shadow: 0px 4px 33px rgba(0, 0, 0, 0.09);
  border-radius: 11px;
  ${({ theme }) => theme.adaptive.newHomePage.lg} {
    width: 596px;
    height: 729px;
    top: 75px;
    left: 605px;
    border-radius: 9px;
    box-shadow: 0px 3.42857px 28.2857px rgba(0, 0, 0, 0.09);
  }
  ${({ theme }) => theme.adaptive.newHomePage.slg} {
    width: 513px;
    height: 627px;
    top: 64px;
    left: 521px;
    border-radius: 8px;
    box-shadow: 0px 2.95238px 24.3571px rgba(0, 0, 0, 0.09);
  }
  ${({ theme }) => theme.adaptive.newHomePage.md} {
    width: 381px;
    height: 465px;
    top: 48px;
    left: 387px;
    border-radius: 6px;
    box-shadow: 0px 2.19048px 18.0714px rgba(0, 0, 0, 0.09);
  }
  ${({ theme }) => theme.adaptive.newHomePage.sm} {
    width: 632px;
    height: 773px;
    top: 67px;
    left: 324px;
    border-radius: 10px;
    box-shadow: 0px 3.63765px 30.0106px rgba(0, 0, 0, 0.09);
  }
  ${({ theme }) => theme.adaptive.newHomePage.smm} {
    display: none;
  }
`

const ImageText = styled.div`
  position: absolute;
  top: 766px;
  left: 62px;
  width: 140px;
  height: 33px;
  background: url('${ispoImageText}') center no-repeat;
  background-size: cover;
  opacity: 0.9;
  ${({ theme }) => theme.adaptive.newHomePage.lg} {
    top: 657px;
    left: 53px;
    width: 120px;
    height: 28px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.slg} {
    top: 565px;
    left: 46px;
    width: 103px;
    height: 24px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.md} {
    top: 419px;
    left: 34px;
    width: 77px;
    height: 18px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.sm} {
    display: none;
  }
`

const Text = styled.div`
  position: relative;
  font-family: 'NeueHaasDisplay';
  font-weight: 200;
  font-size: 40px;
  line-height: 120%;
  letter-spacing: -0.01em;
  color: #27303b;
  margin-bottom: 65px;
  width: 612px;
  ${({ theme }) => theme.adaptive.newHomePage.lg} {
    width: 525px;
    margin-bottom: 56px;
    font-size: 34px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.slg} {
    width: 452px;
    margin-bottom: 48px;
    font-size: 30px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.md} {
    width: 335px;
    margin-bottom: 26px;
    font-size: 22px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.sm} {
    width: 365px;
    margin-bottom: 46px;
    font-size: 32px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.smm} {
    width: 310px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.xs} {
    width: 260px;
    margin-bottom: 26px;
    font-size: 24px;
  }
  span {
    font-weight: 400;
  }
`

const Row = styled.div`
  display: flex;
  align-items: center;
`

const OccamIcon = styled.div`
  position: relative;
  width: 61px;
  height: 61px;
  background: rgba(232, 232, 232, 0.2);
  border: 1px solid rgba(98, 136, 180, 0.2);
  border-radius: 50%;
  ${({ theme }) => theme.adaptive.newHomePage.lg} {
    width: 52px;
    height: 52px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.slg} {
    width: 45px;
    height: 45px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.md} {
    width: 33px;
    height: 33px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.sm} {
    width: 61px;
    height: 61px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.xs} {
    width: 48px;
    height: 48px;
  }
  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 27px;
    height: 18px;
    background: url('${occamIconImg}') center no-repeat;
    background-size: contain;
    ${({ theme }) => theme.adaptive.newHomePage.lg} {
      width: 23px;
      height: 15px;
    }
    ${({ theme }) => theme.adaptive.newHomePage.slg} {
      width: 20px;
      height: 13px;
    }
    ${({ theme }) => theme.adaptive.newHomePage.md} {
      width: 15px;
      height: 10px;
    }
    ${({ theme }) => theme.adaptive.newHomePage.sm} {
      width: 27px;
      height: 18px;
    }
    ${({ theme }) => theme.adaptive.newHomePage.xs} {
      width: 21px;
      height: 14px;
    }
  }
`

const IDOs = styled.div`
  position: relative;
  font-weight: 300;
  font-size: 24px;
  line-height: 120%;
  letter-spacing: -0.01em;
  color: #27303b;
  opacity: 0.8;
  width: 472px;
  margin-left: 21px;
  ${({ theme }) => theme.adaptive.newHomePage.lg} {
    font-size: 20px;
    width: 405px;
    margin-left: 18px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.slg} {
    font-size: 18px;
    width: 348px;
    margin-left: 16px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.md} {
    font-size: 14px;
    width: 258px;
    margin-left: 12px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.sm} {
    font-size: 18px;
    width: 279px;
    margin-left: 21px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.smm} {
    width: 226px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.xs} {
    font-size: 15px;
    width: 196px;
    margin-left: 16px;
  }
`

const ButtonBg = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  svg {
    display: block;
    width: 100%;
    height: 100%;
    filter: drop-shadow(0px -1.17263px 0px #ffffff)
      drop-shadow(0px 35px 33px rgba(13, 52, 89, 0.12))
      drop-shadow(0px 5px 10px rgba(13, 52, 89, 0.04));
    fill: #f4f4f4;
    transition: 0.4s;
    transform: translate3d(0, 0, 0);
  }
`

const ButtonIcon = styled.div`
  position: relative;
  width: 23px;
  height: 15px;
  ${({ theme }) => theme.adaptive.newHomePage.lg} {
    width: 20px;
    height: 13px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.slg} {
    width: 17px;
    height: 11px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.md} {
    width: 13px;
    height: 8px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.sm} {
    width: 21px;
    height: 14px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.xs} {
    width: 17px;
    height: 11px;
  }
  svg {
    display: block;
    width: 100%;
    height: 100%;
    fill: #939ba9;
    transition: 0.4s;
  }
`

const Button = styled.a`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 243px;
  height: 70px;
  font-family: 'NeueHaasDisplay';
  font-weight: 400;
  font-size: 24px;
  line-height: 100%;
  letter-spacing: 0.01em;
  color: #6288b4;
  padding: 21px 24px;
  cursor: pointer;
  z-index: 2;
  margin-top: 195px;
  transition: 0.4s;
  ${({ theme }) => theme.adaptive.newHomePage.lg} {
    width: 208px;
    height: 60px;
    font-size: 20px;
    padding: 19px 23px;
    margin-top: 167px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.slg} {
    width: 179px;
    height: 52px;
    font-size: 18px;
    padding: 14px 18px;
    margin-top: 144px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.md} {
    width: 133px;
    height: 39px;
    font-size: 14px;
    padding: 11px 13px;
    margin-top: 107px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.sm} {
    width: 214px;
    height: 62px;
    font-size: 19px;
    padding: 20px 26px;
    margin-top: 155px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.xs} {
    width: 167px;
    height: 48px;
    font-size: 15px;
    padding: 15px 20px;
    margin-top: 103px;
  }
  &:hover {
    color: #56a0f5;
    ${ButtonBg} {
      svg {
        filter: drop-shadow(0px -1.17263px 0px #ffffff)
          drop-shadow(0px 35px 33px rgba(13, 52, 89, 0.15))
          drop-shadow(0px 5px 10px rgba(13, 52, 89, 0.17));
      }
    }
    ${ButtonIcon} {
      svg {
        fill: #56a0f5;
      }
    }
  }
`

const Round = styled.div`
  position: absolute;
  top: -159px;
  left: -4px;
  width: 1044px;
  height: 1044px;
  background: url('${ellipseImg}') center no-repeat;
  background-size: contain;
  z-index: -1;
  ${({ theme }) => theme.adaptive.newHomePage.lg} {
    top: -136px;
    left: -3px;
    width: 895px;
    height: 895px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.slg} {
    top: -117px;
    left: -3px;
    width: 771px;
    height: 771px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.md} {
    top: -87px;
    left: -2px;
    width: 572px;
    height: 572px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.sm} {
    top: 33px;
    left: 0;
    width: 732px;
    height: 732px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.xs} {
    top: 27px;
    left: 8px;
    width: 571px;
    height: 571px;
  }
`
