import React from 'react'
import { BrowserRouter as RouterReact, Switch, Route } from 'react-router-dom'
import Index from 'components/Rebranding/Home'
import Careers from './Careers'
import KYC from './KYC'
import US_KYC from './US-KYC'
import ZeroTier from './ZeroTier'
import ScrollToTop from './ScrollToTop'
import Incubator from './Incubator'

export default function Router() {
  return (
    <RouterReact>
      <ScrollToTop>
        <Switch>
          <Route exact path="/">
            <Index />
          </Route>
          <Route path="/careers">
            <Careers />
          </Route>
          <Route path="/ido-kyc">
            <KYC />
          </Route>
          <Route path="/usinst-ido-kyc">
            <US_KYC />
          </Route>
          <Route path="/vcdao">
            <ZeroTier />
          </Route>
          <Route path="/incubator">
            <Incubator />
          </Route>
          {/* <Route path="*">
            <NotFound />
          </Route> */}
        </Switch>
      </ScrollToTop>
    </RouterReact>
  )
}
