import React from 'react'
import styled, { css } from 'styled-components'
import bg from '../../img/zeroTier/tiers/bg.jpg'
import bgSmd from '../../img/zeroTier/tiers/bg-smd.jpg'
import bgSm from '../../img/zeroTier/tiers/bg-sm.jpg'
import bgSmm from '../../img/zeroTier/tiers/bg-smm.jpg'
import firstCard from '../../img/zeroTier/tiers/first-card.svg'
import olympusBadge from '../../img/zeroTier/tiers/olympus-badge.svg'
import olympusBtn from '../../img/zeroTier/tiers/olympus-btn.svg'
import nemeaCard from '../../img/zeroTier/tiers/nemea-card.svg'
import skadiCard from '../../img/zeroTier/tiers/skadi-card.svg'

export default function Tiers() {
  return (
    <Root id={'tiers'}>
      <Gradient />
      <Bg />
      <Container>
        <Occam>Occam</Occam>
        <Title>DAO Board Tiers</Title>
        <CardList>
          <OlympusCard>
            <OlympusCardTitle>Olympus Tier</OlympusCardTitle>
            <OlympusRequirements>OCC Stake requirements</OlympusRequirements>
            <OlympusOccVal>130,000 OCC</OlympusOccVal>
            <CardColumns>
              <OlympusLeftColumn>
                <CardBigText>Access to VC&nbsp;DAO</CardBigText>
                <CardBigText>Seat on the incubator board </CardBigText>
              </OlympusLeftColumn>
              <OlympusRightColumn>
                <OlympusBadge>Olympus badge</OlympusBadge>
                <OlympusSmallText>All of the below</OlympusSmallText>
              </OlympusRightColumn>
            </CardColumns>
            <CardBtnWrap>
              <OlympusBtn
                href={'https://razer.occam.fi/personal/occ-panel/staking'}
                target={'_blank'}
              >
                Stake OCC
              </OlympusBtn>
            </CardBtnWrap>
          </OlympusCard>
          <NemeaCard>
            <NemeaTitle>Nemea tier</NemeaTitle>
            <NemeaRow>
              <NemeaRequirements>OCC Stake requirements</NemeaRequirements>
              <CardOccVal>66,000 OCC</CardOccVal>
            </NemeaRow>
            <CardBigText>Access to seed & private rounds</CardBigText>
            <NemeaSmallText>
              Incubated projects, larger allocations
            </NemeaSmallText>
            <CardColumns>
              <NemeaLeftColumn>
                <CardBigText>
                  Private group with the board members of Occam
                </CardBigText>
                <BadgeWrap>
                  <NemeaBadge>Nemea badge</NemeaBadge>
                  <NemeaBadgeText>on the forum</NemeaBadgeText>
                </BadgeWrap>
              </NemeaLeftColumn>
              <NemeaRightColumn>
                <CardBigText>Early access to incubated projects</CardBigText>
                <NemeaBtn
                  href={'https://razer.occam.fi/personal/occ-panel/staking'}
                  target={'_blank'}
                >
                  Stake OCC
                </NemeaBtn>
              </NemeaRightColumn>
            </CardColumns>
          </NemeaCard>
          <SkadiCard>
            <SkadiTitle>Skadi tier</SkadiTitle>
            <SkadiRow>
              <SkadiRequirements>OCC Stake requirements</SkadiRequirements>
              <CardOccVal>33,000 OCC</CardOccVal>
            </SkadiRow>
            <CardColumns>
              <SkadiLeftColumn>
                <div>
                  <CardBigText>Access to private rounds</CardBigText>
                  <SkadiSmallText>
                    Incubated projects, larger allocations
                  </SkadiSmallText>
                </div>
                <BadgeWrap>
                  <SkadiBadge>Skadi badge</SkadiBadge>
                  <SkadiBadgeText>on the forum</SkadiBadgeText>
                </BadgeWrap>
              </SkadiLeftColumn>
              <SkadiRightColumn>
                <CardBigText>Early access to incubated projects</CardBigText>
              </SkadiRightColumn>
            </CardColumns>
            <CardBtnWrap>
              <SkadiBtn
                href={'https://razer.occam.fi/personal/occ-panel/staking'}
                target={'_blank'}
              >
                Stake OCC
              </SkadiBtn>
            </CardBtnWrap>
          </SkadiCard>
        </CardList>
      </Container>
    </Root>
  )
}

const Root = styled.div`
  position: relative;
  padding: 281px 0 327px 0;
  ${({ theme }) => theme.adaptive.zeroTier.xl} {
    padding: 246px 0 270px 0;
  }
  ${({ theme }) => theme.adaptive.zeroTier.lg} {
    padding: 211px 0 241px 0;
  }
  ${({ theme }) => theme.adaptive.zeroTier.slg} {
    padding: 181px 0 207px 0;
  }
  ${({ theme }) => theme.adaptive.zeroTier.md} {
    padding: 135px 0 161px 0;
  }
  ${({ theme }) => theme.adaptive.zeroTier.smd} {
    padding: 117px 0 229px 0;
  }
  ${({ theme }) => theme.adaptive.zeroTier.sm} {
    padding: 205px 0 738px 0;
  }
  ${({ theme }) => theme.adaptive.zeroTier.ssm} {
    padding: 160px 0 604px 0;
  }
`

const Bg = styled.div`
  position: absolute;
  top: -117px;
  left: calc(50% - 138px);
  transform: translateX(-50%);
  width: 2878px;
  height: 2712px;
  background: url('${bg}') center no-repeat;
  z-index: -1;
  ${({ theme }) => theme.adaptive.zeroTier.xl} {
    width: 2518px;
    height: 2373px;
    top: -113px;
    left: calc(50% - 121px);
    background-size: cover;
  }
  ${({ theme }) => theme.adaptive.zeroTier.lg} {
    width: 2159px;
    height: 2034px;
    top: -97px;
    left: calc(50% - 104px);
  }
  ${({ theme }) => theme.adaptive.zeroTier.slg} {
    width: 1859px;
    height: 1752px;
    top: -84px;
    left: calc(50% - 89px);
  }
  ${({ theme }) => theme.adaptive.zeroTier.md} {
    width: 1379px;
    height: 1300px;
    top: -62px;
    left: calc(50% - 67px);
  }
  ${({ theme }) => theme.adaptive.zeroTier.smd} {
    background: url('${bgSmd}') center no-repeat;
    width: 1400px;
    height: 2000px;
    top: -295px;
    left: 50%;
    transform: translateX(-50%);
  }
  ${({ theme }) => theme.adaptive.zeroTier.sm} {
    top: 141px;
    background: url('${bgSm}') center no-repeat;
    width: 1400px;
    height: 2154px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.ssm} {
    background: url('${bgSmm}') center no-repeat;
    top: 110px;
    width: 1004px;
    height: 1681px;
  }
`

const Gradient = styled.div`
  position: absolute;
  width: 100%;
  height: 2331px;
  top: -400px;
  left: 0;
  z-index: -1;
  background: linear-gradient(
    179.99deg,
    rgba(196, 196, 196, 0) 0.01%,
    #c4c4c4 24.61%,
    #c4c4c4 76.66%,
    rgba(196, 196, 196, 0) 95.89%
  );
  ${({ theme }) => theme.adaptive.zeroTier.xl} {
    height: 2039px;
    top: -370px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.lg} {
    height: 1483px;
    top: -282px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.slg} {
    height: 1277px;
    top: -242px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.md} {
    height: 947px;
    top: -175px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.smd} {
    height: 233px;
    top: -326px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.sm} {
    height: 233px;
    top: 111px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.ssm} {
    height: 233px;
    top: 82px;
  }
`

const Container = styled.div`
  position: relative;
  width: 1337px;
  margin: 0 auto;
  ${({ theme }) => theme.adaptive.zeroTier.xl} {
    width: 1202px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.lg} {
    width: 1039px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.slg} {
    width: 895px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.md} {
    width: 663px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.smd} {
    width: 532px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.sm} {
    width: 350px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.ssm} {
    width: 274px;
  }
`

const Occam = styled.div`
  width: 154px;
  margin: 0 auto 16px;
  font-family: 'RPBrawl';
  position: relative;
  font-size: 29px;
  line-height: 25px;
  text-align: center;
  background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.2) 0%,
      rgba(255, 255, 255, 0) 100%
    ),
    linear-gradient(180deg, #34404f 0%, #202131 47.4%, #090b0f 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-transform: uppercase;
  ${({ theme }) => theme.adaptive.zeroTier.xl} {
    width: 135px;
    margin: 0 auto 14px;
    font-size: 25px;
    line-height: 20px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.lg} {
    width: 116px;
    margin: 0 auto 12px;
    font-size: 22px;
    line-height: 17px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.slg} {
    width: 99px;
    margin: 0 auto 10px;
    font-size: 19px;
    line-height: 15px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.md} {
    width: 74px;
    margin: 0 auto 8px;
    font-size: 14px;
    line-height: 11px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.smd} {
    width: 99px;
    margin: 0 auto 10px;
    font-size: 19px;
    line-height: 15px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.sm} {
    width: 95px;
    margin: 0 auto 10px;
    font-size: 18px;
    line-height: 14px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.ssm} {
    width: 74px;
    margin: 0 auto 8px;
    font-size: 14px;
    line-height: 11px;
  }
`

const Title = styled.div`
  font-weight: 200;
  font-size: 68px;
  line-height: 82px;
  text-align: center;
  color: #342f2b;
  ${({ theme }) => theme.adaptive.zeroTier.xl} {
    font-size: 60px;
    line-height: 72px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.lg} {
    font-size: 51px;
    line-height: 61px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.slg} {
    font-size: 44px;
    line-height: 53px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.md} {
    font-size: 33px;
    line-height: 40px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.smd} {
    font-size: 44px;
    line-height: 53px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.ssm} {
    font-size: 33px;
    line-height: 40px;
  }
`

const CardList = styled.div`
  position: relative;
`

const CardTitle = styled.div`
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  text-transform: uppercase;
  color: #ffffff;
  ${({ theme }) => theme.adaptive.zeroTier.xl} {
    font-size: 21px;
    line-height: 25px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.lg} {
    font-size: 18px;
    line-height: 22px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.slg} {
    font-size: 16px;
    line-height: 19px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.md} {
    font-size: 13px;
    line-height: 16px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.smd} {
    font-size: 16px;
    line-height: 19px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.ssm} {
    font-size: 13px;
    line-height: 16px;
  }
`

const CardRequirements = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  opacity: 0.6;
  color: #ffffff;
  ${({ theme }) => theme.adaptive.zeroTier.xl} {
    font-size: 13px;
    line-height: 16px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.lg} {
    font-size: 12px;
    line-height: 14px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.slg} {
    font-size: 11px;
    line-height: 13px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.md} {
    font-size: 8px;
    line-height: 10px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.smd} {
    font-size: 11px;
    line-height: 13px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.ssm} {
    font-size: 8px;
    line-height: 10px;
  }
`

const CardOccVal = styled.div`
  font-weight: 300;
  font-size: 46px;
  line-height: 38px;
  color: #ffffff;
  ${({ theme }) => theme.adaptive.zeroTier.xl} {
    font-size: 40px;
    line-height: 33px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.lg} {
    font-size: 35px;
    line-height: 29px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.slg} {
    font-size: 30px;
    line-height: 25px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.md} {
    font-size: 22px;
    line-height: 18px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.smd} {
    font-size: 30px;
    line-height: 25px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.ssm} {
    font-size: 22px;
    line-height: 18px;
  }
`

const CardBigText = styled.div`
  font-weight: 300;
  font-size: 21px;
  line-height: 25px;
  color: #ffffff;
  ${({ theme }) => theme.adaptive.zeroTier.xl} {
    font-size: 18px;
    line-height: 22px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.lg} {
    font-size: 16px;
    line-height: 19px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.slg} {
    font-size: 14px;
    line-height: 17px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.md} {
    font-size: 11px;
    line-height: 13px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.smd} {
    font-size: 14px;
    line-height: 17px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.ssm} {
    font-size: 11px;
    line-height: 13px;
  }
`

const CardSmallText = css`
  font-weight: 300;
  font-size: 18px;
  line-height: 22px;
  color: #ffffff;
  ${({ theme }) => theme.adaptive.zeroTier.xl} {
    font-size: 16px;
    line-height: 19px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.lg} {
    font-size: 14px;
    line-height: 16px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.slg} {
    font-size: 12px;
    line-height: 14px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.md} {
    font-size: 9px;
    line-height: 11px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.smd} {
    font-size: 12px;
    line-height: 14px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.ssm} {
    font-size: 9px;
    line-height: 11px;
  }
`

const CardBtnWrap = styled.div`
  display: flex;
  justify-content: flex-end;
`

const CardBtn = css`
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: #f7f7f7;
  ${({ theme }) => theme.adaptive.zeroTier.xl} {
    font-size: 14px;
    line-height: 19px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.lg} {
    font-size: 12px;
    line-height: 16px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.slg} {
    font-size: 12px;
    line-height: 14px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.md} {
    font-size: 9px;
    line-height: 10px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.smd} {
    font-size: 12px;
    line-height: 14px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.ssm} {
    font-size: 9px;
    line-height: 10px;
  }
`

const CardColumns = styled.div`
  display: flex;
  justify-content: space-between;
`

const CardColumn = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const OlympusCard = styled.div`
  position: relative;
  top: 115px;
  left: 831px;
  width: 427px;
  height: 421px;
  padding: 12px 51px 26px 87px;
  background: url('${firstCard}') center no-repeat;
  ${({ theme }) => theme.adaptive.zeroTier.xl} {
    background-size: cover;
    top: 101px;
    left: 727px;
    width: 374px;
    height: 368px;
    padding: 11px 45px 23px 74px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.lg} {
    top: 86px;
    left: 635px;
    width: 320px;
    height: 316px;
    padding: 9px 38px 20px 64px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.slg} {
    top: 75px;
    left: 553px;
    width: 276px;
    height: 272px;
    padding: 8px 33px 17px 54px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.md} {
    top: 56px;
    left: 405px;
    width: 205px;
    height: 202px;
    padding: 6px 24px 12px 41px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.smd} {
    top: 40px;
    left: 250px;
    width: 276px;
    height: 272px;
    padding: 8px 33px 17px 54px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.sm} {
    top: 208px;
    left: 74px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.ssm} {
    top: 168px;
    left: 73px;
    width: 205px;
    height: 202px;
    padding: 6px 24px 12px 41px;
  }
`

const OlympusCardTitle = styled(CardTitle)`
  margin-bottom: 40px;
  ${({ theme }) => theme.adaptive.zeroTier.xl} {
    margin-bottom: 35px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.lg} {
    margin-bottom: 30px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.slg} {
    margin-bottom: 26px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.md} {
    margin-bottom: 19px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.smd} {
    margin-bottom: 26px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.ssm} {
    margin-bottom: 19px;
  }
`

const OlympusRequirements = styled(CardRequirements)`
  margin-bottom: 12px;
  ${({ theme }) => theme.adaptive.zeroTier.xl} {
    margin-bottom: 10px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.lg} {
    margin-bottom: 9px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.slg} {
    margin-bottom: 8px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.md} {
    margin-bottom: 6px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.smd} {
    margin-bottom: 8px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.ssm} {
    margin-bottom: 6px;
  }
`

const OlympusOccVal = styled(CardOccVal)`
  margin-bottom: 38px;
  ${({ theme }) => theme.adaptive.zeroTier.xl} {
    margin-bottom: 32px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.lg} {
    margin-bottom: 27px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.slg} {
    margin-bottom: 22px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.md} {
    margin-bottom: 17px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.smd} {
    margin-bottom: 22px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.ssm} {
    margin-bottom: 17px;
  }
`

const OlympusLeftColumn = styled(CardColumn)`
  width: 134px;
  height: 119px;
  ${({ theme }) => theme.adaptive.zeroTier.xl} {
    width: 117px;
    height: 104px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.lg} {
    width: 116px;
    height: 89px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.slg} {
    width: 94px;
    height: 77px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.md} {
    width: 74px;
    height: 57px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.smd} {
    width: 94px;
    height: 77px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.ssm} {
    width: 74px;
    height: 57px;
  }
`

const OlympusRightColumn = styled(CardColumn)`
  width: 98px;
  height: 119px;
  ${({ theme }) => theme.adaptive.zeroTier.xl} {
    width: 76px;
    height: 104px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.lg} {
    width: 65px;
    height: 104px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.slg} {
    width: 56px;
    height: 77px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.md} {
    width: 42px;
    height: 57px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.smd} {
    width: 56px;
    height: 77px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.ssm} {
    width: 42px;
    height: 57px;
  }
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: -29px;
    width: 1px;
    height: 119px;
    background: rgba(255, 255, 255, 0.16);
    ${({ theme }) => theme.adaptive.zeroTier.xl} {
      left: -26px;
      height: 104px;
    }
    ${({ theme }) => theme.adaptive.zeroTier.lg} {
      left: -22px;
      height: 89px;
    }
    ${({ theme }) => theme.adaptive.zeroTier.slg} {
      left: -19px;
      height: 77px;
    }
    ${({ theme }) => theme.adaptive.zeroTier.md} {
      left: -15px;
      height: 57px;
    }
    ${({ theme }) => theme.adaptive.zeroTier.smd} {
      left: -19px;
      height: 77px;
    }
    ${({ theme }) => theme.adaptive.zeroTier.ssm} {
      left: -15px;
      height: 57px;
    }
  }
`

const OlympusBadge = styled.div`
  width: 87px;
  padding: 6px 10px;
  ${CardSmallText};
  background: url('${olympusBadge}') center no-repeat;
  ${({ theme }) => theme.adaptive.zeroTier.xl} {
    background-size: cover;
    width: 76px;
    padding: 5px 9px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.lg} {
    width: 65px;
    padding: 5px 8px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.slg} {
    width: 56px;
    padding: 4px 6px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.md} {
    width: 42px;
    padding: 3px 4px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.smd} {
    width: 56px;
    padding: 4px 6px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.ssm} {
    width: 42px;
    padding: 3px 4px;
  }
`

const OlympusSmallText = styled.div`
  ${CardSmallText};
  opacity: 0.25;
`

const OlympusBtn = styled.a`
  ${CardBtn};
  width: 168px;
  height: 37px;
  padding: 8px 30px 9px 40px;
  background: url('${olympusBtn}') center no-repeat;
  margin-top: 50px;
  outline: none;
  ${({ theme }) => theme.adaptive.zeroTier.xl} {
    background-size: cover;
    width: 144px;
    height: 32px;
    padding: 7px 25px 8px 35px;
    margin-top: 44px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.lg} {
    width: 126px;
    height: 28px;
    padding: 6px 22px 7px 30px;
    margin-top: 38px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.slg} {
    width: 108px;
    height: 24px;
    padding: 5px 19px 6px 26px;
    margin-top: 31px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.md} {
    width: 81px;
    height: 18px;
    padding: 5px 14px 3px 19px;
    margin-top: 22px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.smd} {
    width: 108px;
    height: 24px;
    padding: 5px 19px 6px 26px;
    margin-top: 31px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.ssm} {
    width: 81px;
    height: 18px;
    padding: 5px 14px 3px 19px;
    margin-top: 22px;
  }
`

const NemeaCard = styled.div`
  position: relative;
  top: 278px;
  left: 0;
  width: 518px;
  height: 371px;
  padding: 16px 45px 39px 58px;
  background: url('${nemeaCard}') center no-repeat;
  ${({ theme }) => theme.adaptive.zeroTier.xl} {
    background-size: cover;
    top: 263px;
    width: 453px;
    height: 325px;
    padding: 14px 39px 35px 50px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.lg} {
    top: 226px;
    width: 389px;
    height: 279px;
    padding: 12px 34px 30px 44px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.slg} {
    top: 194px;
    width: 335px;
    height: 240px;
    padding: 10px 24px 26px 30px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.md} {
    top: 140px;
    width: 248px;
    height: 178px;
    padding: 7px 10px 19px 27px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.smd} {
    top: 110px;
    width: 335px;
    height: 240px;
    padding: 10px 24px 26px 30px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.sm} {
    top: 400px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.ssm} {
    top: 325px;
    width: 248px;
    height: 178px;
    padding: 7px 10px 19px 27px;
  }
`

const NemeaTitle = styled(CardTitle)`
  margin-bottom: 32px;
  ${({ theme }) => theme.adaptive.zeroTier.xl} {
    margin-bottom: 27px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.lg} {
    margin-bottom: 23px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.slg} {
    margin-bottom: 20px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.md} {
    margin-bottom: 15px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.smd} {
    margin-bottom: 20px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.ssm} {
    margin-bottom: 15px;
  }
`

const NemeaRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 34px;
  ${({ theme }) => theme.adaptive.zeroTier.xl} {
    margin-bottom: 26px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.lg} {
    margin-bottom: 23px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.slg} {
    margin-bottom: 19px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.md} {
    margin-bottom: 14px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.smd} {
    margin-bottom: 19px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.ssm} {
    margin-bottom: 14px;
  }
`

const NemeaRequirements = styled(CardRequirements)`
  width: 86px;
  margin-right: 22px;
  ${({ theme }) => theme.adaptive.zeroTier.xl} {
    width: 75px;
    margin-right: 19px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.lg} {
    width: 72px;
    margin-right: 9px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.slg} {
    width: 63px;
    margin-right: 7px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.md} {
    width: 48px;
    margin-right: 4px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.smd} {
    width: 63px;
    margin-right: 7px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.ssm} {
    width: 48px;
    margin-right: 4px;
  }
`

const NemeaSmallText = styled(CardRequirements)`
  margin-top: 6px;
  margin-bottom: 26px;
  ${({ theme }) => theme.adaptive.zeroTier.xl} {
    margin-top: 5px;
    margin-bottom: 23px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.lg} {
    margin-top: 4px;
    margin-bottom: 19px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.slg} {
    margin-top: 4px;
    margin-bottom: 17px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.md} {
    margin-top: 1px;
    margin-bottom: 15px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.smd} {
    margin-top: 4px;
    margin-bottom: 17px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.ssm} {
    margin-top: 1px;
    margin-bottom: 15px;
  }
`

const NemeaLeftColumn = styled(CardColumn)`
  width: 222px;
  height: 102px;
  ${({ theme }) => theme.adaptive.zeroTier.xl} {
    width: 194px;
    height: 91px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.lg} {
    width: 179px;
    height: 77px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.slg} {
    width: 154px;
    height: 66px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.md} {
    width: 117px;
    height: 48px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.smd} {
    width: 154px;
    height: 66px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.ssm} {
    width: 117px;
    height: 48px;
  }
`

const NemeaRightColumn = styled(CardColumn)`
  width: 155px;
  height: 102px;
  ${({ theme }) => theme.adaptive.zeroTier.xl} {
    width: 136px;
    height: 91px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.lg} {
    width: 118px;
    height: 77px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.slg} {
    width: 105px;
    height: 66px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.md} {
    width: 86px;
    height: 48px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.smd} {
    width: 105px;
    height: 66px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.ssm} {
    width: 86px;
    height: 48px;
  }
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: -21px;
    width: 1px;
    height: 44px;
    background: rgba(0, 0, 0, 0.1);
    ${({ theme }) => theme.adaptive.zeroTier.xl} {
      left: -19px;
      height: 39px;
    }
    ${({ theme }) => theme.adaptive.zeroTier.lg} {
      left: -16px;
      height: 33px;
    }
    ${({ theme }) => theme.adaptive.zeroTier.slg} {
      left: -14px;
      height: 28px;
    }
    ${({ theme }) => theme.adaptive.zeroTier.md} {
      left: -6px;
      height: 21px;
    }
    ${({ theme }) => theme.adaptive.zeroTier.smd} {
      left: -14px;
      height: 28px;
    }
    ${({ theme }) => theme.adaptive.zeroTier.ssm} {
      left: -6px;
      height: 21px;
    }
  }
`

const NemeaBtn = styled.a`
  position: relative;
  ${CardBtn};
  width: 146px;
  height: 37px;
  padding: 8px 24px 9px;
  border: 1px solid rgba(255, 255, 255, 0.35);
  border-radius: 3px;
  top: 6px;
  outline: none;
  ${({ theme }) => theme.adaptive.zeroTier.xl} {
    width: 128px;
    height: 32px;
    padding: 7px 21px 8px;
    top: 5px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.lg} {
    width: 110px;
    height: 28px;
    padding: 6px 18px 7px;
    top: 4px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.slg} {
    width: 94px;
    height: 24px;
    padding: 5px 15px 6px;
    top: 4px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.md} {
    width: 70px;
    height: 18px;
    padding: 4px 11px 3px;
    top: 3px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.smd} {
    width: 94px;
    height: 24px;
    padding: 5px 15px 6px;
    top: 4px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.ssm} {
    width: 70px;
    height: 18px;
    padding: 4px 11px 3px;
    top: 3px;
  }
`

const BadgeWrap = styled.div`
  display: flex;
  align-items: center;
`

const NemeaBadge = styled.div`
  ${CardSmallText};
  padding: 5px 10px;
  border-radius: 3px;
  background: rgba(255, 255, 255, 0.1);
  margin-right: 9px;
  ${({ theme }) => theme.adaptive.zeroTier.xl} {
    padding: 4px 1px;
    margin-right: 8px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.lg} {
    padding: 4px 8px;
    margin-right: 6px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.slg} {
    padding: 4px 4px;
    margin-right: 6px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.md} {
    padding: 3px 3px 2px;
    margin-right: 4px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.smd} {
    padding: 4px 4px;
    margin-right: 6px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.ssm} {
    padding: 3px 3px 2px;
    margin-right: 4px;
  }
`

const NemeaBadgeText = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #ffffff;
  opacity: 0.6;
  ${({ theme }) => theme.adaptive.zeroTier.xl} {
    font-size: 13px;
    line-height: 16px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.lg} {
    font-size: 12px;
    line-height: 14px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.slg} {
    font-size: 11px;
    line-height: 13px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.md} {
    font-size: 8px;
    line-height: 10px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.smd} {
    font-size: 11px;
    line-height: 13px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.ssm} {
    font-size: 8px;
    line-height: 10px;
  }
`

const SkadiCard = styled.div`
  position: relative;
  top: 160px;
  left: 850px;
  width: 520px;
  height: 363px;
  padding: 16px 46px 52px 53px;
  background: url('${skadiCard}') center no-repeat;
  ${({ theme }) => theme.adaptive.zeroTier.xl} {
    background-size: cover;
    top: 160px;
    left: 747px;
    width: 455px;
    height: 318px;
    padding: 14px 46px 41px 46px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.lg} {
    top: 140px;
    left: 747px;
    width: 390px;
    height: 273px;
    padding: 12px 27px 35px 40px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.slg} {
    top: 125px;
    left: 559px;
    width: 336px;
    height: 235px;
    padding: 11px 23px 30px 34px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.md} {
    top: 82px;
    left: 414px;
    width: 249px;
    height: 174px;
    padding: 8px 17px 22px 25px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.smd} {
    top: 180px;
    left: 207px;
    width: 336px;
    height: 235px;
    padding: 11px 23px 30px 34px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.sm} {
    top: 685px;
    left: 15px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.ssm} {
    top: 555px;
    left: 22px;
    width: 249px;
    height: 174px;
    padding: 8px 17px 22px 25px;
  }
`

const SkadiTitle = styled(CardTitle)`
  margin-bottom: 32px;
  ${({ theme }) => theme.adaptive.zeroTier.xl} {
    margin-bottom: 27px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.lg} {
    margin-bottom: 23px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.slg} {
    margin-bottom: 20px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.md} {
    margin-bottom: 15px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.smd} {
    margin-bottom: 20px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.ssm} {
    margin-bottom: 15px;
  }
`

const SkadiRow = styled(NemeaRow)`
  margin-bottom: 30px;
  ${({ theme }) => theme.adaptive.zeroTier.xl} {
    margin-bottom: 26px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.lg} {
    margin-bottom: 23px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.slg} {
    margin-bottom: 19px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.md} {
    margin-bottom: 15px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.smd} {
    margin-bottom: 19px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.ssm} {
    margin-bottom: 15px;
  }
`

const SkadiRequirements = styled(NemeaRequirements)``

const SkadiLeftColumn = styled(CardColumn)`
  width: 212px;
  height: 103px;
  ${({ theme }) => theme.adaptive.zeroTier.xl} {
    width: 185px;
    height: 91px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.lg} {
    width: 171px;
    height: 80px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.slg} {
    width: 151px;
    height: 68px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.md} {
    width: 115px;
    height: 51px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.smd} {
    width: 151px;
    height: 68px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.ssm} {
    width: 115px;
    height: 51px;
  }
`

const SkadiRightColumn = styled(CardColumn)`
  width: 155px;
  height: 103px;
  ${({ theme }) => theme.adaptive.zeroTier.xl} {
    width: 136px;
    height: 91px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.lg} {
    width: 128px;
    height: 80px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.slg} {
    width: 111px;
    height: 68px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.md} {
    width: 82px;
    height: 51px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.smd} {
    width: 111px;
    height: 68px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.ssm} {
    width: 82px;
    height: 51px;
  }
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: -21px;
    width: 1px;
    height: 44px;
    background: rgba(0, 0, 0, 0.1);
    ${({ theme }) => theme.adaptive.zeroTier.xl} {
      left: -19px;
      height: 38px;
    }
    ${({ theme }) => theme.adaptive.zeroTier.lg} {
      left: -16px;
      height: 33px;
    }
    ${({ theme }) => theme.adaptive.zeroTier.slg} {
      left: -14px;
      height: 28px;
    }
    ${({ theme }) => theme.adaptive.zeroTier.md} {
      left: -6px;
      height: 21px;
    }
    ${({ theme }) => theme.adaptive.zeroTier.smd} {
      left: -14px;
      height: 28px;
    }
    ${({ theme }) => theme.adaptive.zeroTier.ssm} {
      left: -6px;
      height: 21px;
    }
  }
`

const SkadiSmallText = styled(NemeaSmallText)`
  margin-bottom: 0;
`

const SkadiBadge = styled(NemeaBadge)`
  background: rgba(255, 255, 255, 0.35);
`

const SkadiBadgeText = styled(NemeaBadgeText)``

const SkadiBtn = styled(NemeaBtn)`
  margin-top: 28px;
  margin-right: 9px;
  ${({ theme }) => theme.adaptive.zeroTier.xl} {
    margin-top: 25px;
    margin-right: 7px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.lg} {
    margin-top: 21px;
    margin-right: 19px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.slg} {
    margin-top: 18px;
    margin-right: 17px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.md} {
    margin-top: 13px;
    margin-right: 12px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.smd} {
    margin-top: 18px;
    margin-right: 17px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.ssm} {
    margin-top: 13px;
    margin-right: 12px;
  }
`
