import React from 'react'
import Modal from './Modal'
import styled from 'styled-components'
import { noop } from 'lodash'
import parse from 'html-react-parser'

export default function ModalSoon({
  open = false,
  onClose = noop,
  Icon,
  name = '',
}) {
  return (
    <Modal open={open} onClose={onClose}>
      <ModalContent iconExist={Icon ? true : false}>
        <ModalClose onClick={onClose} />
        {Icon && (
          <ModalIcon>
            <Icon />
          </ModalIcon>
        )}
        <ModalText>{parse(name)}</ModalText>
      </ModalContent>
    </Modal>
  )
}

const ModalClose = styled.button`
  position: absolute;
  right: -60px;
  top: -60px;
  width: 55px;
  height: 55px;
  cursor: pointer;
  border-radius: 50%;
  background: rgba(213, 216, 216, 0.2);
  border: 1px solid #c3c4c9;
  ${({ theme }) => theme.adaptive.sm} {
    right: -40px;
    top: -40px;
    width: 30px;
    height: 30px;
  }
  &:before,
  &:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 30px;
    height: 2px;
    background: #f6f7f7;
    transform-origin: 0;
    ${({ theme }) => theme.adaptive.sm} {
      width: 19px;
    }
  }
  &:before {
    transform: rotate(45deg) translateY(-50%) translateX(-50%);
  }
  &:after {
    transform: rotate(-45deg) translateY(-50%) translateX(-50%);
  }
`

const ModalContent = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: ${({ iconExist }) =>
    iconExist ? 'space-between' : 'center'};
  align-items: center;
  background: rgba(213, 216, 216, 0.2);
  border: 1px solid #c3c4c9;
  border-radius: 10px;
  backdrop-filter: blur(47px);
  min-width: 380px;
  min-height: 380px;
  padding: 50px 50px 40px;
  ${({ theme }) => theme.adaptive.sm} {
    width: 209px;
    min-width: auto;
    min-height: 213px;
    padding: 50px 25px 40px;
  }
`

const ModalIcon = styled.div`
  text-align: center;
  max-width: 117px;
  max-height: 117px;
  ${({ theme }) => theme.adaptive.sm} {
    max-width: 70px;
    max-height: 70px;
    margin-bottom: 20px;
  }
  img,
  svg {
    height: auto;
    max-height: 100%;
    max-width: 100%;
  }
`

const ModalText = styled.span`
  display: inline-block;
  width: 100%;
  font-size: 50px;
  line-height: 60px;
  color: #f6f6f6;
  text-align: center;
  ${({ theme }) => theme.adaptive.sm} {
    font-size: 27px;
    line-height: 33px;
  }
`
