import React, { useState } from 'react'
import Button from '../Button/Button.tsx'
import styled from 'styled-components'
import careerRequest from '../../api/careerRequest'
import ModalSuccess from '../Modal/ModalSuccess'

export default function CareersForm() {
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [message, setMessage] = useState('')
  const [isSuccess, setIsSuccess] = useState(false)
  const [btnDisabled, setBtnDisabled] = useState(false)

  const clearForm = () => {
    setName('')
    setEmail('')
    setMessage('')
  }

  const onFormSubmit = () => {
    setBtnDisabled(true)
    careerRequest({
      name,
      email,
      message,
    })
      .then(() => {
        clearForm()
        setIsSuccess(true)
      })
      .catch((error) => {
        error.response.data.message
          ? alert(error.response.data.message)
          : clearForm()
      })
      .finally(() => {
        setBtnDisabled(false)
      })
  }

  return (
    <>
      <ModalSuccess open={isSuccess} onClose={() => setIsSuccess(false)} />
      <form>
        <Row>
          <Column>
            <CareersInput
              type="text"
              placeholder="Name"
              onChange={(e) => setName(e.target.value)}
              value={name}
            />
          </Column>
          <Column>
            <CareersInput
              type="text"
              placeholder="E-mail address"
              onChange={(e) => setEmail(e.target.value)}
              value={email}
            />
          </Column>
        </Row>
        <CareersTextarea
          placeholder="Your motivation for joining the Occam team"
          onChange={(e) => setMessage(e.target.value)}
          value={message}
        />
        <CareersButtonWrapper>
          <Button
            name="APPLY"
            left={true}
            right={true}
            isDisabled={btnDisabled}
            handleClick={onFormSubmit}
          />
        </CareersButtonWrapper>
      </form>
    </>
  )
}

const Row = styled.div`
  display: flex;
  margin: 0 -7px 15px;
  ${({ theme }) => theme.adaptive.forms.md} {
    margin: 0 -5px 9px;
  }
  ${({ theme }) => theme.adaptive.forms.sm} {
    flex-wrap: wrap;
    margin: 0 -5px 0;
  }
`

const Column = styled.div`
  width: 50%;
  padding: 0 5px;
  ${({ theme }) => theme.adaptive.forms.sm} {
    width: 100%;
    margin-bottom: 9px;
  }
`

const CareersInput = styled.input`
  width: 100%;
  height: 44px;
  font-size: 19px;
  border-radius: 8px;
  border: 2px solid #202131;
  padding: 0 23px;
  ${({ theme }) => theme.adaptive.forms.lg} {
    font-size: 16px;
    padding: 9px 20px;
    height: 38px;
  }
  ${({ theme }) => theme.adaptive.forms.md} {
    font-size: 11px;
    padding: 7px 15px;
    height: 26px;
  }
  ${({ theme }) => theme.adaptive.forms.sm} {
    font-size: 15px;
    padding: 8px 18px;
    height: 34px;
  }
  &::placeholder {
    color: #202131;
  }
`

const CareersTextarea = styled.textarea`
  border-radius: 8px;
  border: 2px solid #202131;
  width: 100%;
  height: 270px;
  font-size: 19px;
  background: none;
  margin-bottom: 20px;
  padding: 10px 23px;
  resize: none;
  ${({ theme }) => theme.adaptive.forms.lg} {
    font-size: 16px;
    padding: 15px 20px;
    height: 237px;
    margin-bottom: 10px;
  }
  ${({ theme }) => theme.adaptive.forms.md} {
    font-size: 11px;
    padding: 10px 15px;
    height: 160px;
    margin-bottom: 8px;
  }
  ${({ theme }) => theme.adaptive.forms.sm} {
    font-size: 15px;
    padding: 14px 18px;
    height: 205px;
    margin-bottom: 10px;
  }
  &::placeholder {
    color: #202131;
  }
`

const CareersButtonWrapper = styled.div`
  // button {
  //   padding-left: 41px;
  //   padding-right: 41px;
  // }
  // ${({ theme }) => theme.adaptive.forms.lg} {
  //   button {
  //     padding-left: unset;
  //     padding-right: unset;
  //   }
  // }
`
