import React from 'react'
import CareersForm from './CareersForm'
import FormLayout from '../FormLayout/FormLayout'

export default function Careers() {
  return (
    <FormLayout
      title="Careers"
      link="https://occam-fi.gitbook.io/occam-faq/-MY6AK8LDbVHXnVAsex9/careers"
    >
      <CareersForm />
    </FormLayout>
  )
}
