import React, { useEffect, useRef, useState } from 'react'
import styled, { css } from 'styled-components'
import { ReactComponent as BgTopImg } from 'img/rebranding/button/bg-top.svg'
import { ReactComponent as BgBottomImg } from 'img/rebranding/button/bg-bottom.svg'
import { ReactComponent as BtnIconImg } from 'img/rebranding/button/btn-icon.svg'
import exchanges from 'store/exchanges'
import gsap, { Linear } from 'gsap'
import useOnClickOutside from '../../../helpers/hooks/useOnClickOutside'

const Button: React.FunctionComponent = () => {
  const listRef = useRef(null)
  const rootRef = useRef(null)
  const [isOpen, setIsOpen] = useState(false)

  useOnClickOutside({
    ref: rootRef,
    handler: () => outSideHandlerClick(),
  })

  useEffect(() => {
    if (!listRef.current) return
    if (isOpen) {
      let delay = 0.1
      // @ts-ignore
      listRef.current.childNodes.forEach((_, index) => {
        // @ts-ignore
        const node = listRef.current.children[index] as gsap.TweenTarget
        fadeIn(node, delay)
        delay += 0.05
      })
    } else {
      let delay = 0.1
      // @ts-ignore
      listRef.current.childNodes.forEach((_, index) => {
        // @ts-ignore
        const node = listRef.current.children[index] as gsap.TweenTarget
        fadeOut(node, delay)
        delay += 0.05
      })
    }
  }, [isOpen])

  const clickHandler = () => {
    setIsOpen(!isOpen)
  }

  const outSideHandlerClick = () => {
    setIsOpen(false)
  }

  const fadeIn = (el: gsap.TweenTarget, delay: number) => {
    gsap.fromTo(
      el,
      { opacity: 0 },
      {
        delay: delay,
        duration: 0.4,
        opacity: 1,
        ease: Linear.easeNone,
      }
    )
  }

  const fadeOut = (el: gsap.TweenTarget, delay: number) => {
    gsap.to(el, {
      delay: delay,
      duration: 0.4,
      opacity: 0,
      ease: Linear.easeNone,
    })
  }

  return (
    <Root ref={rootRef} isOpen={isOpen} onClick={clickHandler}>
      <ButtonBg>
        <BgTop>
          <BgTopImg />
        </BgTop>
        <BgMiddle />
        <BgBottom>
          <BgBottomImg />
        </BgBottom>
      </ButtonBg>
      <BtnTitleWrap>
        <BtnText>Buy OCC</BtnText>
        <ButtonIcon>
          <BtnIconImg />
        </ButtonIcon>
      </BtnTitleWrap>
      <ExchangesList ref={listRef}>
        {exchanges.map((item, idx) => {
          return (
            <Exchange key={idx} href={item.link} target={'_blank'}>
              <ExchangeTitleWrap>
                <ExchangeIcon>{item.getIcon()}</ExchangeIcon>
                <ExchangeName>{item.title}</ExchangeName>
              </ExchangeTitleWrap>
              {/*<LinkIcon>*/}
              {/*  <BtnLinkImg />*/}
              {/*</LinkIcon>*/}
            </Exchange>
          )
        })}
      </ExchangesList>
    </Root>
  )
}

export default Button

const ButtonBg = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1;
`

const BgTopBottomCss = css`
  display: flex;
  width: 100%;
  height: 35px;
  ${({ theme }) => theme.adaptive.newHomePage.lg} {
    height: 30px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.slg} {
    height: 26px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.md} {
    height: 19px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.sm} {
    height: 35px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.smm} {
    height: 30px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.xs} {
    height: 26px;
  }
  svg {
    display: block;
    width: 100%;
    height: 100%;
    fill: #f4f4f4;
  }
`

const BgTop = styled.div`
  ${BgTopBottomCss};
`

const BgBottom = styled.div`
  ${BgTopBottomCss};
`

const BgMiddle = styled.div`
  display: flex;
  width: 100%;
  flex: 1 1 0%;
  background: #f4f4f4;
`

const ButtonIcon = styled.div`
  width: 27px;
  height: 18px;
  ${({ theme }) => theme.adaptive.newHomePage.lg} {
    width: 23px;
    height: 15px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.slg} {
    width: 20px;
    height: 13px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.md} {
    width: 15px;
    height: 10px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.sm} {
    width: 27px;
    height: 18px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.smm} {
    width: 23px;
    height: 15px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.xs} {
    width: 20px;
    height: 13px;
  }
  svg {
    display: block;
    width: 100%;
    height: 100%;
    fill: #6288b4;
    opacity: 0.5;
    transition: 0.4s;
  }
`

const BtnText = styled.div`
  font-family: 'NeueHaasDisplay';
  font-weight: 500;
  font-size: 21px;
  line-height: 25px;
  letter-spacing: 0.01em;
  color: #6288b4;
  ${({ theme }) => theme.adaptive.newHomePage.lg} {
    font-size: 18px;
    line-height: 22px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.slg} {
    font-size: 16px;
    line-height: 19px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.md} {
    font-size: 12px;
    line-height: 14px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.sm} {
    font-size: 21px;
    line-height: 25px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.smm} {
    font-size: 18px;
    line-height: 22px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.xs} {
    font-size: 16px;
    line-height: 19px;
  }
`

const BtnTitleWrap = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  &:before {
    content: '';
    position: absolute;
    opacity: 0;
    width: 100%;
    height: 1px;
    left: 0;
    bottom: -24px;
    background: #4f5564;
    transition: 0.4s;
    ${({ theme }) => theme.adaptive.newHomePage.lg} {
      bottom: -20px;
    }
    ${({ theme }) => theme.adaptive.newHomePage.slg} {
      bottom: -17px;
    }
    ${({ theme }) => theme.adaptive.newHomePage.md} {
      bottom: -13px;
    }
    ${({ theme }) => theme.adaptive.newHomePage.sm} {
      bottom: -24px;
    }
    ${({ theme }) => theme.adaptive.newHomePage.smm} {
      bottom: -20px;
    }
    ${({ theme }) => theme.adaptive.newHomePage.xs} {
      bottom: -17px;
    }
  }
`

const ExchangesList = styled.div`
  position: relative;
  opacity: 0;
  margin-top: 46px;
  pointer-events: none;
  z-index: -2;
  ${({ theme }) => theme.adaptive.newHomePage.lg} {
    margin-top: 40px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.slg} {
    margin-top: 36px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.md} {
    margin-top: 26px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.sm} {
    margin-top: 46px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.smm} {
    margin-top: 40px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.xs} {
    margin-top: 36px;
  }
`

const LinkIcon = styled.div`
  position: relative;
  width: 16px;
  height: 16px;
  ${({ theme }) => theme.adaptive.newHomePage.lg} {
    width: 14px;
    height: 14px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.slg} {
    width: 12px;
    height: 12px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.md} {
    width: 9px;
    height: 9px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.sm} {
    width: 16px;
    height: 16px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.smm} {
    width: 14px;
    height: 14px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.xs} {
    width: 12px;
    height: 12px;
  }
  svg {
    display: block;
    width: 100%;
    height: 100%;
    fill: #4f5564;
    opacity: 0.1;
    transition: 0.4s;
  }
`

const ExchangeTitleWrap = styled.div`
  display: flex;
  align-items: center;
`

const ExchangeIcon = styled.div`
  width: 25px;
  height: 26px;
  margin-right: 10px;
  ${({ theme }) => theme.adaptive.newHomePage.lg} {
    width: 22px;
    height: 22px;
    margin-right: 9px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.slg} {
    width: 18px;
    height: 19px;
    margin-right: 7px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.md} {
    width: 14px;
    height: 14px;
    margin-right: 5px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.sm} {
    width: 25px;
    height: 26px;
    margin-right: 10px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.smm} {
    width: 22px;
    height: 22px;
    margin-right: 9px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.xs} {
    width: 18px;
    height: 19px;
    margin-right: 7px;
  }
  svg {
    display: block;
    width: 100%;
    height: 100%;
  }
`

const ExchangeName = styled.div`
  position: relative;
  font-family: 'NeueHaasDisplay';
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #4f5564;
  opacity: 0.75;
  transition: 0.4s;
  ${({ theme }) => theme.adaptive.newHomePage.lg} {
    font-size: 16px;
    line-height: 21px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.slg} {
    font-size: 14px;
    line-height: 18px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.md} {
    font-size: 12px;
    line-height: 14px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.sm} {
    font-size: 18px;
    line-height: 22px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.smm} {
    font-size: 16px;
    line-height: 21px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.xs} {
    font-size: 14px;
    line-height: 18px;
  }
  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 1px;
    opacity: 0;
    background: #c3c4c9;
    left: 0;
    bottom: -1px;
  }
`

const Exchange = styled.a`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  opacity: 0;
  ${({ theme }) => theme.adaptive.newHomePage.lg} {
    margin-bottom: 12px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.slg} {
    margin-bottom: 11px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.md} {
    margin-bottom: 8px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.sm} {
    margin-bottom: 15px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.smm} {
    margin-bottom: 12px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.xs} {
    margin-bottom: 11px;
  }
  &:last-child {
    margin-bottom: 0;
  }
  &:hover {
    ${LinkIcon} {
      svg {
        opacity: 0.35;
      }
    }
    ${ExchangeName} {
      opacity: 1;
      &:before {
        opacity: 1;
      }
    }
  }
`

const Root = styled.div<{ isOpen: boolean }>`
  position: relative;
  width: 224px;
  height: 70px;
  color: #6288b4;
  padding: 21px 30px;
  cursor: pointer;
  z-index: 2;
  transition: 0.4s;
  filter: drop-shadow(0px -1.17263px 0px #ffffff)
    drop-shadow(0px 35px 33px rgba(13, 52, 89, 0.12))
    drop-shadow(0px 5px 10px rgba(13, 52, 89, 0.04));
  transform: translate3d(0, 0, 0);
  ${({ theme }) => theme.adaptive.newHomePage.lg} {
    width: 192px;
    height: 62px;
    padding: 20px 26px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.slg} {
    width: 167px;
    height: 51px;
    padding: 16px 23px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.md} {
    width: 124px;
    height: 40px;
    padding: 13px 17px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.sm} {
    width: 224px;
    height: 70px;
    padding: 21px 30px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.smm} {
    width: 192px;
    height: 62px;
    padding: 20px 26px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.xs} {
    width: 167px;
    height: 51px;
    padding: 16px 23px;
  }
  ${({ isOpen }) => {
    if (isOpen)
      return css`
        height: 385px;
        ${({ theme }) => theme.adaptive.newHomePage.lg} {
          height: 330px;
        }
        ${({ theme }) => theme.adaptive.newHomePage.slg} {
          height: 285px;
        }
        ${({ theme }) => theme.adaptive.newHomePage.md} {
          height: 213px;
        }
        ${({ theme }) => theme.adaptive.newHomePage.sm} {
          height: 385px;
        }
        ${({ theme }) => theme.adaptive.newHomePage.smm} {
          height: 330px;
        }
        ${({ theme }) => theme.adaptive.newHomePage.xs} {
          height: 285px;
        }
        ${BtnTitleWrap} {
          &:before {
            opacity: 0.3;
          }
        }
        ${BtnText} {
          color: #4f5564;
          opacity: 0.5;
        }
        ${ExchangesList} {
          pointer-events: auto;
          opacity: 1;
          z-index: 1;
        }
        ${ButtonIcon} {
          svg {
            fill: #4f5564;
            opacity: 0.5;
          }
        }
      `
  }}
`
