import React, { useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import { Link } from 'react-router-dom'
import { ReactComponent as ArrowIcon } from 'img/rebranding/header/arrow.svg'
import { $isMenuOpen, setIsMenuOpen } from 'store/menu'
import { useStore } from 'effector-react'
import exchanges from 'store/exchanges'
import { ReactComponent as Twitter } from 'img/rebranding/header/socials/twitter.svg'
import { ReactComponent as Reddit } from 'img/rebranding/header/socials/reddit.svg'
import { ReactComponent as Inst } from 'img/rebranding/header/socials/inst.svg'
import { ReactComponent as Telegram } from 'img/rebranding/header/socials/telegram.svg'
import { ReactComponent as Medium } from 'img/rebranding/header/socials/medium.svg'
import { ReactComponent as Discord } from 'img/rebranding/header/socials/discord.svg'
import { ReactComponent as CloseIcon } from 'img/rebranding/header/close.svg'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import logoImg from 'img/rebranding/Logo.svg'
import useWindowSize from 'helpers/utils/useWindowSize'
import {
  APPLY_IDO_LINK,
  CAREERS_LINK,
  DISCORD_LINK,
  FORUM_LINK,
  ISPO_LINK,
  KYC_LINK,
  KYC_US_LINK,
  MEDIUM_LINK,
  OCCAMX_LINK,
  REDDIT_LINK,
  TELEGRAM_LINK,
  TWITTER_LINK,
} from 'configs/app.config'

const Menu: React.FunctionComponent = () => {
  const isMenuOpen = useStore($isMenuOpen)
  const [isBuyOccOpen, setIsBuyOccOpen] = useState(false)
  const body = document.body
  const { width } = useWindowSize()

  useEffect(() => {
    if (isMenuOpen) disableBodyScroll(body)
    else enableBodyScroll(body)
  }, [isMenuOpen])

  return (
    <Root isOpen={isMenuOpen}>
      <Bg onClick={() => setIsMenuOpen(false)} />
      <Drawer isOpen={isMenuOpen}>
        {width <= 790 && <Logo />}
        <Close onClick={() => setIsMenuOpen(false)}>
          <CloseIcon />
        </Close>
        <Nav>
          <NavItem>
            <Link to={{ pathname: ISPO_LINK }} target="_blank">
              ISPO Infrastructure
            </Link>
          </NavItem>
          <NavItem>
            <Link to={{ pathname: OCCAMX_LINK }} target="_blank">
              OccamX
            </Link>
          </NavItem>
          <NavItem>
            <Link to={{ pathname: APPLY_IDO_LINK }} target="_blank">
              Apply for IDO
            </Link>
          </NavItem>
          <NavItem>
            <Link to={{ pathname: CAREERS_LINK }} target="_blank">
              Careers
            </Link>
          </NavItem>
          <NavItem>
            <Link to={{ pathname: KYC_LINK }} target="_blank">
              IDO KYC
            </Link>
          </NavItem>
          {/*<NavItem>*/}
          {/*  <Link to={{ pathname: KYC_US_LINK }} target="_blank">*/}
          {/*    Accredited KYC*/}
          {/*  </Link>*/}
          {/*</NavItem>*/}
          <NavItem>
            <Link to={'/vcdao'}>VC DAO</Link>
          </NavItem>
          <NavItem>
            <Link to={{ pathname: FORUM_LINK }} target="_blank">
              Forum
            </Link>
          </NavItem>
          <BuyOcc
            isOpen={isBuyOccOpen}
            onClick={() => setIsBuyOccOpen(!isBuyOccOpen)}
          >
            Buy OCC
            <ArrowIcon />
          </BuyOcc>
          <BuyOccList isOpen={isBuyOccOpen}>
            {exchanges.map((item, idx) => {
              return (
                <BuyOccItem href={item.link} target={'_blank'}>
                  {item.title}
                </BuyOccItem>
              )
            })}
          </BuyOccList>
        </Nav>
        <Socials>
          <SocialList>
            <SocialItem href={TWITTER_LINK} target={'_blank'}>
              <Twitter />
            </SocialItem>
            <SocialItem href={REDDIT_LINK} target={'_blank'}>
              <Reddit />
            </SocialItem>
            <SocialItem href={TELEGRAM_LINK} target={'_blank'}>
              <Telegram />
            </SocialItem>
            <SocialItem href={MEDIUM_LINK} target={'_blank'}>
              <Medium />
            </SocialItem>
            <SocialItem href={DISCORD_LINK} target={'_blank'}>
              <Discord />
            </SocialItem>
          </SocialList>
          <SocialText>Be a part of our community</SocialText>
        </Socials>
      </Drawer>
    </Root>
  )
}

export default Menu

const Bg = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #ffffff;
  opacity: 0.3;
`

const Root = styled.div<{ isOpen: boolean }>`
  position: absolute;
  min-width: 100%;
  min-height: 100vh;
  top: 0;
  left: 0;
  pointer-events: ${({ isOpen }) => (isOpen ? 'auto' : 'none')};
  z-index: 3;
  ${Bg} {
    pointer-events: ${({ isOpen }) => (isOpen ? 'auto' : 'none')};
    opacity: ${({ isOpen }) => (isOpen ? '0.3' : '0')};
  }
  ${({ theme }) => theme.adaptive.newHomePage.sm} {
    overflow-y: auto;
    height: auto;
  }
`

const Drawer = styled.div<{ isOpen: boolean }>`
  position: absolute;
  top: 0;
  right: ${({ isOpen }) => (isOpen ? '0px' : '-682px')};
  display: block;
  width: 682px;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(8px);
  border-radius: 10px;
  padding: 119px 108px;
  transition: 0.4s;
  transform: translate3d(0, 0, 0);
  ${({ theme }) => theme.adaptive.newHomePage.lg} {
    right: ${({ isOpen }) => (isOpen ? '0px' : '-598px')};
    width: 598px;
    border-radius: 10px;
    padding: 79px 81px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.slg} {
    right: ${({ isOpen }) => (isOpen ? '0px' : '-399px')};
    width: 399px;
    border-radius: 10px;
    padding: 66px 67px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.md} {
    right: ${({ isOpen }) => (isOpen ? '0px' : '-296px')};
    width: 296px;
    border-radius: 7px;
    padding: 49px 49px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.sm} {
    right: ${({ isOpen }) => (isOpen ? '0px' : '-100%')};
    width: 100%;
    border-radius: 0;
    padding: 101px 38px 36px;
    height: auto;
    min-height: 100vh;
  }
  ${({ theme }) => theme.adaptive.newHomePage.smm} {
    padding: 94px 27px 33px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.xs} {
    padding: 79px 20px 13px;
  }
`

const Nav = styled.div``

const LinkCss = css`
  display: block;
  font-family: 'NeueHaasDisplay';
  font-weight: 300;
  font-size: 24px;
  line-height: 29px;
  color: #27303b;
  transition: 0.4s;
  ${({ theme }) => theme.adaptive.newHomePage.lg} {
    font-size: 20px;
    line-height: 24px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.slg} {
    font-size: 18px;
    line-height: 22px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.md} {
    font-size: 14px;
    line-height: 17px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.sm} {
    font-size: 19px;
    line-height: 23px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.xs} {
    font-size: 15px;
    line-height: 18px;
  }
  &:hover {
    color: #56a0f5;
    svg {
      stroke: #56a0f5;
    }
  }
`

const NavItem = styled.div`
  position: relative;
  width: fit-content;
  a {
    ${LinkCss};
    margin-bottom: 22px;
    width: 100%;
    ${({ theme }) => theme.adaptive.newHomePage.slg} {
      margin-bottom: 18px;
    }
    ${({ theme }) => theme.adaptive.newHomePage.md} {
      margin-bottom: 13px;
    }
    ${({ theme }) => theme.adaptive.newHomePage.sm} {
      margin-bottom: 18px;
    }
    ${({ theme }) => theme.adaptive.newHomePage.xs} {
      margin-bottom: 15px;
    }
  }
`

const BuyOcc = styled.div<{ isOpen: boolean }>`
  position: relative;
  ${LinkCss};
  display: flex;
  align-items: center;
  cursor: pointer;
  width: fit-content;
  svg {
    display: flex;
    width: 10px;
    height: 4px;
    margin-left: 10px;
    stroke: #252525;
    transform: ${({ isOpen }) => (isOpen ? '' : 'rotate(180deg)')};
    transition: 0.4s;
    ${({ theme }) => theme.adaptive.newHomePage.md} {
      width: 7px;
      height: 3px;
      margin-left: 3px;
    }
    ${({ theme }) => theme.adaptive.newHomePage.sm} {
      width: 10px;
      height: 4px;
      margin-left: 10px;
    }
    ${({ theme }) => theme.adaptive.newHomePage.xs} {
      width: 9px;
      height: 3px;
      margin-left: 9px;
    }
  }
`

const BuyOccList = styled.div<{ isOpen: boolean }>`
  position: relative;
  width: 261px;
  display: flex;
  flex-wrap: wrap;
  margin-top: 16px;
  opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
  transition: 0.4s;
  ${({ theme }) => theme.adaptive.newHomePage.lg} {
    width: 225px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.slg} {
    width: 212px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.md} {
    width: 178px;
    margin-top: 12px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.sm} {
    width: 212px;
    margin-top: 16px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.xs} {
    width: 195px;
    margin-top: 9px;
  }
`

const BuyOccItem = styled.a`
  position: relative;
  display: block;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: #646464;
  opacity: 0.7;
  margin-right: 14px;
  margin-bottom: 6px;
  transition: 0.4s;
  ${({ theme }) => theme.adaptive.newHomePage.lg} {
    font-size: 15px;
    line-height: 24px;
    margin-right: 14px;
    margin-bottom: 4px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.slg} {
    font-size: 14px;
    line-height: 24px;
    margin-right: 14px;
    margin-bottom: 3px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.md} {
    font-size: 12px;
    line-height: 18px;
    margin-right: 10px;
    margin-bottom: 0;
  }
  ${({ theme }) => theme.adaptive.newHomePage.sm} {
    font-size: 14px;
    line-height: 24px;
    margin-right: 14px;
    margin-bottom: 3px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.xs} {
    font-size: 13px;
    line-height: 20px;
    margin-right: 12px;
    margin-bottom: 0;
  }
  &:nth-child(4n) {
    margin-right: 0;
  }
  &:hover {
    color: #56a0f5;
    opacity: 1;
  }
`

const Socials = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  margin-top: 90px;
  ${({ theme }) => theme.adaptive.newHomePage.lg} {
    margin-top: 78px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.slg} {
    margin-top: 53px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.md} {
    margin-top: 39px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.sm} {
    display: block;
    margin-top: 45px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.xs} {
    margin-top: 52px;
  }
  &:before {
    content: '';
    position: absolute;
    top: -35px;
    left: 0;
    width: 259px;
    height: 1px;
    background: #000;
    opacity: 0.1;
    ${({ theme }) => theme.adaptive.newHomePage.lg} {
      top: -31px;
      width: 303px;
    }
    ${({ theme }) => theme.adaptive.newHomePage.slg} {
      top: -20px;
      width: 300px;
    }
    ${({ theme }) => theme.adaptive.newHomePage.md} {
      top: -15px;
      width: 181px;
    }
    ${({ theme }) => theme.adaptive.newHomePage.sm} {
      top: -19px;
      width: 271px;
    }
    ${({ theme }) => theme.adaptive.newHomePage.xs} {
      top: -15px;
      width: 232px;
    }
  }
`

const SocialText = styled.div`
  font-family: 'NeueHaasDisplay';
  width: 181px;
  font-weight: 200;
  font-size: 24px;
  line-height: 120%;
  letter-spacing: -0.02em;
  color: #27303b;
  opacity: 0.3;
  margin-left: 32px;
  ${({ theme }) => theme.adaptive.newHomePage.lg} {
    width: 167px;
    margin-left: 23px;
    font-size: 20px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.slg} {
    width: 161px;
    margin-left: 20px;
    font-size: 18px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.md} {
    width: 130px;
    margin-left: 15px;
    font-size: 14px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.sm} {
    font-size: 19px;
    width: 100%;
    margin: 20px 0 0 0;
  }
  ${({ theme }) => theme.adaptive.newHomePage.xs} {
    font-size: 15px;
    margin: 13px 0 0 0;
  }
`

const SocialList = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100px;
  ${({ theme }) => theme.adaptive.newHomePage.lg} {
    width: 95px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.slg} {
    width: 65px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.md} {
    width: 50px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.sm} {
    justify-content: flex-start;
    width: 100%;
  }
`

const SocialItem = styled.a`
  display: block;
  margin-bottom: 14px;
  margin-right: 20px;
  cursor: pointer;
  ${({ theme }) => theme.adaptive.newHomePage.lg} {
    margin-bottom: 13px;
    margin-right: 19px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.slg} {
    margin-bottom: 10px;
    margin-right: 13px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.md} {
    margin-bottom: 8px;
    margin-right: 10px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.sm} {
    margin-bottom: 0px;
    margin-right: 23px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.xs} {
    margin-right: 20px;
  }
  &:nth-child(3n) {
    margin-right: 0;
    ${({ theme }) => theme.adaptive.newHomePage.sm} {
      margin-right: 23px;
    }
    ${({ theme }) => theme.adaptive.newHomePage.xs} {
      margin-right: 20px;
    }
  }
  &:nth-child(n + 3) {
    margin-bottom: 0;
  }
  svg {
    display: block;
    width: 20px;
    height: 20px;
    transition: 0.4s;
    fill: #333333;
    opacity: 0.3;
    ${({ theme }) => theme.adaptive.newHomePage.lg} {
      width: 19px;
      height: 19px;
    }
    ${({ theme }) => theme.adaptive.newHomePage.slg} {
      width: 13px;
      height: 13px;
    }
    ${({ theme }) => theme.adaptive.newHomePage.md} {
      width: 10px;
      height: 10px;
    }
    ${({ theme }) => theme.adaptive.newHomePage.sm} {
      width: 23px;
      height: 23px;
    }
    ${({ theme }) => theme.adaptive.newHomePage.xs} {
      width: 20px;
      height: 20px;
    }
  }
  &:hover {
    svg {
      fill: #56a0f5;
      opacity: 1;
    }
  }
`

const Close = styled.div`
  position: absolute;
  top: 66px;
  left: 375px;
  width: 24px;
  height: 24px;
  cursor: pointer;
  z-index: 2;
  ${({ theme }) => theme.adaptive.newHomePage.lg} {
    top: 69px;
    left: 366px;
    width: 20px;
    height: 20px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.slg} {
    top: 33px;
    left: 306px;
    width: 20px;
    height: 20px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.md} {
    top: 24px;
    left: 227px;
    width: 15px;
    height: 15px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.sm} {
    top: 48px;
    left: unset;
    right: 42px;
    width: 18px;
    height: 18px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.smm} {
    top: 47px;
    right: 32px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.xs} {
    top: 35px;
    right: 30px;
    width: 15px;
    height: 15px;
  }
  svg {
    width: 100%;
    height: 100%;
    transition: 0.4s;
    stroke: #646464;
    opacity: 0.4;
  }
  &:hover {
    svg {
      stroke: #56a0f5;
      opacity: 1;
    }
  }
`

const Logo = styled.div`
  position: absolute;
  top: 44px;
  left: 38px;
  width: 179px;
  height: 24px;
  background: url('${logoImg}') center no-repeat;
  background-size: cover;
  ${({ theme }) => theme.adaptive.newHomePage.smm} {
    top: 43px;
    left: 27px;
    width: 179px;
    height: 24px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.xs} {
    top: 34px;
    left: 20px;
    width: 140px;
    height: 19px;
  }
`
