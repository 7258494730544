import React from 'react'
import styled, { css } from 'styled-components'
import boardBg from '../../img/zeroTier/board/board-bg.png'
import boardBgSmd from '../../img/zeroTier/board/board-bg-smd.png'
import boardBgSm from '../../img/zeroTier/board/board-bg-sm.png'
import boardBtn from '../../img/zeroTier/board/board-btn.svg'
import boardBtnSmd from '../../img/zeroTier/board/board-btn-smd.svg'
import boardBtnIcon from '../../img/zeroTier/board/board-btn-icon.svg'
import benefitsFirstColumn from '../../img/zeroTier/board/benefits-first-column.png'
import benefitsFirstColumnSmd from '../../img/zeroTier/board/benefits-first-column-smd.png'
import benefitsFirstColumnSm from '../../img/zeroTier/board/benefits-first-column-sm.png'
import benefitsSecondColumn from '../../img/zeroTier/board/benefits-second-column.png'
import benefitsSecondColumnSmd from '../../img/zeroTier/board/benefits-second-column-smd.png'
import benefitsSecondColumnSm from '../../img/zeroTier/board/benefits-second-column-sm.png'
import schemaBg from '../../img/zeroTier/board/schema-bg.svg'
import schemaBgSmd from '../../img/zeroTier/board/schema-bg-smd.svg'
import schemaBgSm from '../../img/zeroTier/board/schema-bg-sm.svg'
import firstPeculiarityBg from '../../img/zeroTier/first-peculiarity-bg.svg'
import secondPeculiarityBg from '../../img/zeroTier/second-peculiarity-bg.svg'
import thirdPeculiarityBg from '../../img/zeroTier/third-peculiarity-bg.svg'
import fourthPeculiarityBg from '../../img/zeroTier/fourth-peculiarity-bg.svg'
import fifthPeculiarityBg from '../../img/zeroTier/fifth-peculiarity-bg.svg'

export default function Board() {
  return (
    <Root>
      <BoardBg>
        <BoardBtn href={'#benefits'}>Explore Occam Incubator</BoardBtn>
      </BoardBg>
      <BoardTitle>Welcome</BoardTitle>
      {/* <BoardSubTitle>
          to the Occam DAO Board Page
        </BoardSubTitle> */}
      <Benefits id={'benefits'}>
        <BenefitsTitle>Benefits Overview</BenefitsTitle>
        <BenefitsSubTitle>
          <span>Occam Incubator</span> is an ascending early stage agency that
          helps promising projects to thrive and find a place on the rapidly
          evolving Interchain DApp frontier.
        </BenefitsSubTitle>
        <AboutBenefits>
          <BenefitsColumn>
            The access to the governance mechanisms shaping Occam’s Incubator
            requires dedication to and belief in Occam’s business model and{' '}
            <span>a large stake in Occam’s native OCC tokens</span>.
          </BenefitsColumn>
          <BenefitsColumn>
            The Occam DAO Board tiers are reflective of that and are designed to
            reflect large ecosystem commitments with access to earlier
            fundraising rounds and other perks.
          </BenefitsColumn>
          <SchemaTitle>In particular:</SchemaTitle>
          <SchemaImg />
        </AboutBenefits>
        <SchemaFirstRow>
          <FirstPeculiarity>Access to early investment rounds</FirstPeculiarity>
          <SecondPeculiarity>
            Participation in the Occam Incubator governance process
          </SecondPeculiarity>
          <ThirdPeculiarity>Access to Account Manager</ThirdPeculiarity>
        </SchemaFirstRow>
        <SchemaSecondRow>
          <FourthPeculiarity>
            Invites to hosted and sponsored Occam events
          </FourthPeculiarity>
          <FifthPeculiarity>Invite to Board calls</FifthPeculiarity>
        </SchemaSecondRow>
      </Benefits>
    </Root>
  )
}

const Root = styled.div`
  position: relative;
  width: 100%;
  padding: 167px 0 0 0;
  ${({ theme }) => theme.adaptive.zeroTier.xl} {
    padding: 146px 0 0 0;
  }
  ${({ theme }) => theme.adaptive.zeroTier.lg} {
    padding: 125px 0 0 0;
  }
  ${({ theme }) => theme.adaptive.zeroTier.slg} {
    padding: 108px 0 0 0;
  }
  ${({ theme }) => theme.adaptive.zeroTier.md} {
    padding: 80px 0 0 0;
  }
  ${({ theme }) => theme.adaptive.zeroTier.smd} {
    padding: 93px 0 0 0;
  }
  ${({ theme }) => theme.adaptive.zeroTier.sm} {
    padding: 83px 0 0 0;
  }
  ${({ theme }) => theme.adaptive.zeroTier.ssm} {
    padding: 65px 0 0 0;
  }
`

const BoardBg = styled.div`
  position: absolute;
  top: 0;
  left: calc(50% - 59px);
  transform: translateX(-50%);
  width: 1871px;
  height: 1730px;
  background: url('${boardBg}') center no-repeat;
  background-size: cover;
  ${({ theme }) => theme.adaptive.zeroTier.xl} {
    left: calc(50% - 52px);
    width: 1637px;
    height: 1514px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.lg} {
    left: calc(50% - 44px);
    width: 1404px;
    height: 1297px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.slg} {
    left: calc(50% - 38px);
    width: 1208px;
    height: 1117px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.md} {
    left: calc(50% - 28px);
    width: 897px;
    height: 828px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.smd} {
    background: url('${boardBgSmd}') center no-repeat;
    background-size: cover;
    left: calc(50% - 18px);
    width: 584px;
    height: 1090px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.sm} {
    background: url('${boardBgSm}') center no-repeat;
    background-size: cover;
    left: 50%;
    width: 425px;
    height: 1459px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.ssm} {
    left: 50%;
    width: 320px;
    height: 1138px;
  }
`

const BoardBtn = styled.a`
  position: absolute;
  display: block;
  top: 27px;
  left: 153px;
  width: 568px;
  height: 63px;
  padding: 14px 138px 13px 108px;
  background: url('${boardBtn}') center no-repeat;
  background-size: contain;
  font-weight: 600;
  font-size: 21px;
  line-height: 37px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #50515f;
  outline: none;
  ${({ theme }) => theme.adaptive.zeroTier.xl} {
    top: 24px;
    left: 130px;
    width: 497px;
    height: 55px;
    padding: 12px 95px;
    font-size: 18px;
    line-height: 31px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.lg} {
    top: 20px;
    left: 112px;
    width: 426px;
    height: 47px;
    padding: 10px 67px;
    font-size: 16px;
    line-height: 26px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.slg} {
    top: 17px;
    left: 96px;
    width: 367px;
    height: 41px;
    padding: 9px 60px;
    font-size: 14px;
    line-height: 23px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.md} {
    top: 13px;
    left: 71px;
    width: 272px;
    height: 30px;
    padding: 7px 38px 5px;
    font-size: 11px;
    line-height: 17px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.smd} {
    background: url('${boardBtnSmd}') center no-repeat;
    background-size: cover;
    top: 10px;
    left: 46px;
    width: 270px;
    height: 27px;
    padding: 10px 18px 7px;
    font-size: 14px;
    line-height: 11px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.sm} {
    background: url('${boardBtn}') center no-repeat;
    background-size: contain;
    top: 7px;
    left: 16px;
    width: 349px;
    height: 39px;
    padding: 10px 45px 5px;
    font-size: 14px;
    line-height: 22px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.ssm} {
    top: 5px;
    left: 7px;
    width: 272px;
    height: 30px;
    padding: 7px 30px 5px;
    font-size: 12px;
    line-height: 17px;
  }
  &:before {
    content: '';
    position: absolute;
    width: 22px;
    height: 11px;
    top: 26px;
    right: 106px;
    background: url('${boardBtnIcon}') center no-repeat;
    background-size: cover;
    ${({ theme }) => theme.adaptive.zeroTier.xl} {
      width: 19px;
      height: 10px;
      top: 22px;
      right: 93px;
    }
    ${({ theme }) => theme.adaptive.zeroTier.lg} {
      width: 17px;
      height: 8px;
      top: 19px;
      right: 79px;
    }
    ${({ theme }) => theme.adaptive.zeroTier.slg} {
      width: 14px;
      height: 7px;
      top: 16px;
      right: 64px;
    }
    ${({ theme }) => theme.adaptive.zeroTier.md} {
      width: 11px;
      height: 5px;
      top: 13px;
      right: 37px;
    }
    ${({ theme }) => theme.adaptive.zeroTier.smd} {
      width: 8px;
      height: 5px;
      top: 11px;
      right: 19px;
    }
    ${({ theme }) => theme.adaptive.zeroTier.sm} {
      width: 14px;
      height: 7px;
      top: 17px;
      right: 58px;
    }
    ${({ theme }) => theme.adaptive.zeroTier.ssm} {
      width: 11px;
      height: 5px;
      top: 12px;
      right: 35px;
    }
  }
`

const BoardTitle = styled.div`
  width: 1541px;
  margin: 0 auto;
  font-weight: 100;
  font-size: 206px;
  line-height: 140%;
  background: linear-gradient(
    91.03deg,
    #fcb785 4.9%,
    rgba(255, 255, 255, 0.15) 90.12%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
  letter-spacing: 50px;
  text-transform: uppercase;
  opacity: 0.5;
  ${({ theme }) => theme.adaptive.zeroTier.xl} {
    width: 1348px;
    font-size: 180px;
    letter-spacing: 44px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.lg} {
    width: 1156px;
    font-size: 155px;
    letter-spacing: 38px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.slg} {
    width: 995px;
    font-size: 133px;
    letter-spacing: 32px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.md} {
    width: 738px;
    font-size: 100px;
    letter-spacing: 24px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.smd} {
    width: 544px;
    font-size: 73px;
    letter-spacing: 18px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.sm} {
    width: 371px;
    font-size: 50px;
    letter-spacing: 12px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.ssm} {
    width: 290px;
    font-size: 39px;
    letter-spacing: 9px;
  }
`

const BoardSubTitle = styled.div`
  font-weight: 200;
  font-size: 56px;
  line-height: 140%;
  text-align: center;
  color: #ffffff;
  opacity: 0.75;
  margin-top: -40px;
  ${({ theme }) => theme.adaptive.zeroTier.xl} {
    font-size: 50px;
    margin-top: -35px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.lg} {
    font-size: 42px;
    margin-top: -35px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.slg} {
    font-size: 36px;
    margin-top: -30px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.md} {
    font-size: 27px;
    margin-top: -25px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.smd} {
    font-size: 36px;
    margin-top: -15px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.sm} {
    width: 311px;
    margin: -10px auto 0;
  }
  ${({ theme }) => theme.adaptive.zeroTier.ssm} {
    font-size: 27px;
    width: 240px;
    margin: -10px auto 0;
  }
`

const Benefits = styled.div`
  position: relative;
  width: 1600px;
  margin: 164px auto 0;
  ${({ theme }) => theme.adaptive.zeroTier.xl} {
    width: 1400px;
    margin: 144px auto 0;
  }
  ${({ theme }) => theme.adaptive.zeroTier.lg} {
    width: 1200px;
    margin: 123px auto 0;
  }
  ${({ theme }) => theme.adaptive.zeroTier.slg} {
    width: 1032px;
    margin: 106px auto 0;
  }
  ${({ theme }) => theme.adaptive.zeroTier.md} {
    width: 765px;
    margin: 79px auto 0;
  }
  ${({ theme }) => theme.adaptive.zeroTier.smd} {
    width: 500px;
    margin: 39px auto 0;
  }
  ${({ theme }) => theme.adaptive.zeroTier.sm} {
    width: 341px;
    margin: 41px auto 0;
  }
  ${({ theme }) => theme.adaptive.zeroTier.ssm} {
    width: 267px;
    margin: 40px auto 0;
  }
`

const BenefitsTitle = styled.div`
  font-weight: 200;
  font-size: 68px;
  line-height: 140%;
  text-align: center;
  color: #ffffff;
  margin-bottom: 20px;
  ${({ theme }) => theme.adaptive.zeroTier.xl} {
    font-size: 60px;
    margin-bottom: 17px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.lg} {
    font-size: 51px;
    margin-bottom: 15px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.slg} {
    font-size: 44px;
    margin-bottom: 13px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.md} {
    font-size: 33px;
    margin-bottom: 10px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.smd} {
    font-size: 44px;
    margin-bottom: 15px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.ssm} {
    font-size: 33px;
    margin-bottom: 15px;
  }
`

const BenefitsSubTitle = styled.div`
  width: 1200px;
  margin: 0 auto 18px;
  font-weight: 200;
  font-size: 36px;
  line-height: 140%;
  text-align: center;
  color: #fbfbfb;
  ${({ theme }) => theme.adaptive.zeroTier.xl} {
    width: 1050px;
    font-size: 31px;
    margin: 0 auto 16px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.lg} {
    width: 900px;
    font-size: 27px;
    margin: 0 auto 13px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.slg} {
    width: 775px;
    font-size: 23px;
    margin: 0 auto 12px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.md} {
    width: 575px;
    font-size: 18px;
    margin: 0 auto 9px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.smd} {
    width: 500px;
    font-size: 23px;
    margin: 0 auto 10px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.sm} {
    width: 100%;
    font-size: 23px;
    margin-bottom: 20px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.ssm} {
    font-size: 18px;
    margin-bottom: 15px;
  }
  span {
    font-weight: 600;
  }
`

const AboutBenefits = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
`

const BenefitsColumn = styled.div`
  width: 773px;
  height: 238px;
  font-weight: 300;
  font-size: 25px;
  line-height: 140%;
  color: #ffffff;
  opacity: 0.8;
  span {
    font-weight: 500;
  }
  ${({ theme }) => theme.adaptive.zeroTier.xl} {
    width: 676px;
    height: 208px;
    font-size: 22px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.lg} {
    width: 580px;
    height: 178px;
    font-size: 19px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.slg} {
    width: 499px;
    height: 154px;
    font-size: 16px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.md} {
    width: 370px;
    height: 114px;
    font-size: 13px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.smd} {
    width: 241px;
    height: 180px;
    font-size: 16px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.sm} {
    width: 165px;
    height: 265px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.ssm} {
    width: 129px;
    height: 207px;
    font-size: 13px;
  }
  &:first-child {
    padding: 77px 20px 56px 46px;
    background: url('${benefitsFirstColumn}') center no-repeat;
    background-size: cover;
    ${({ theme }) => theme.adaptive.zeroTier.xl} {
      padding: 67px 17px 49px 40px;
    }
    ${({ theme }) => theme.adaptive.zeroTier.lg} {
      padding: 58px 15px 42px 34px;
    }
    ${({ theme }) => theme.adaptive.zeroTier.slg} {
      padding: 50px 32px 36px 30px;
    }
    ${({ theme }) => theme.adaptive.zeroTier.md} {
      padding: 37px 10px 27px 22px;
    }
    ${({ theme }) => theme.adaptive.zeroTier.smd} {
      background: url('${benefitsFirstColumnSmd}') center no-repeat;
      background-size: cover;
      padding: 28px 14px 22px 17px;
    }
    ${({ theme }) => theme.adaptive.zeroTier.sm} {
      background: url('${benefitsFirstColumnSm}') center no-repeat;
      background-size: cover;
      padding: 21px 9px 10px 13px;
    }
    ${({ theme }) => theme.adaptive.zeroTier.ssm} {
      padding: 15px 8px 10px 9px;
    }
  }
  &:nth-child(2) {
    padding: 77px 47px 56px 100px;
    background: url('${benefitsSecondColumn}') center no-repeat;
    background-size: cover;
    ${({ theme }) => theme.adaptive.zeroTier.xl} {
      padding: 67px 42px 49px 87px;
    }
    ${({ theme }) => theme.adaptive.zeroTier.lg} {
      padding: 58px 36px 42px 75px;
    }
    ${({ theme }) => theme.adaptive.zeroTier.slg} {
      padding: 50px 31px 36px 65px;
    }
    ${({ theme }) => theme.adaptive.zeroTier.md} {
      padding: 37px 23px 27px 48px;
    }
    ${({ theme }) => theme.adaptive.zeroTier.smd} {
      background: url('${benefitsSecondColumnSmd}') center no-repeat;
      background-size: cover;
      padding: 28px 15px 22px 32px;
    }
    ${({ theme }) => theme.adaptive.zeroTier.sm} {
      background: url('${benefitsSecondColumnSm}') center no-repeat;
      background-size: cover;
      padding: 20px 10px 10px 22px;
    }
    ${({ theme }) => theme.adaptive.zeroTier.ssm} {
      padding: 15px 8px 10px 17px;
    }
  }
`

const SchemaTitle = styled.div`
  position: absolute;
  top: 197px;
  left: 50%;
  transform: translateX(-50%);
  font-weight: 200;
  font-size: 18px;
  line-height: 130%;
  text-align: center;
  letter-spacing: 1px;
  color: #ffffff;
  opacity: 0.3;
  ${({ theme }) => theme.adaptive.zeroTier.xl} {
    top: 172px;
    font-size: 16px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.lg} {
    top: 148px;
    font-size: 14px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.slg} {
    top: 127px;
    font-size: 12px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.md} {
    top: 94px;
    font-size: 9px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.smd} {
    top: 190px;
    font-size: 12px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.sm} {
    top: 275px;
    font-size: 12px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.ssm} {
    top: 213px;
    font-size: 9px;
  }
`

const SchemaImg = styled.div`
  position: absolute;
  top: 180px;
  left: 50%;
  transform: translateX(-50%);
  width: 1920px;
  height: 665px;
  background: url('${schemaBg}') center no-repeat;
  background-size: cover;
  ${({ theme }) => theme.adaptive.zeroTier.xl} {
    top: 157px;
    width: 1680px;
    height: 581px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.lg} {
    top: 135px;
    width: 1440px;
    height: 499px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.slg} {
    top: 117px;
    width: 1240px;
    height: 430px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.md} {
    top: 86px;
    width: 920px;
    height: 319px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.smd} {
    background: url('${schemaBgSmd}') center no-repeat;
    top: 210px;
    transform: none;
    left: 64px;
    width: 359px;
    height: 303px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.sm} {
    background: url('${schemaBgSm}') center no-repeat;
    top: 295px;
    transform: none;
    left: 40px;
    width: 234px;
    height: 504px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.ssm} {
    background-size: cover;
    top: 229px;
    transform: none;
    left: 31px;
    width: 183px;
    height: 394px;
  }
`

const SchemaFirstRow = styled.div`
  position: relative;
  display: flex;
  margin-top: 147px;
  ${({ theme }) => theme.adaptive.zeroTier.xl} {
    margin-top: 129px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.lg} {
    margin-top: 110px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.slg} {
    margin-top: 95px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.md} {
    margin-top: 70px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.smd} {
    margin-top: 56px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.sm} {
    margin-top: 57px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.ssm} {
    margin-top: 44px;
  }
`

const PeculiarityCss = css`
  font-weight: 300;
  font-size: 24px;
  line-height: 29px;
  color: #ffffff;
  opacity: 0.92;
  background-size: cover;
  ${({ theme }) => theme.adaptive.zeroTier.xl} {
    font-size: 21px;
    line-height: 25px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.lg} {
    font-size: 18px;
    line-height: 22px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.slg} {
    font-size: 16px;
    line-height: 19px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.md} {
    font-size: 12px;
    line-height: 14px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.smd} {
    font-size: 16px;
    line-height: 19px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.sm} {
    font-size: 15px;
    line-height: 18px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.ssm} {
    font-size: 12px;
    line-height: 14px;
  }
`

const FirstPeculiarity = styled.div`
  width: 344px;
  height: 116px;
  padding: 28px 41px 35px 21px;
  margin-left: 73px;
  background: url('${firstPeculiarityBg}') center no-repeat;
  background-size: cover;
  text-align: right;
  ${PeculiarityCss};
  ${({ theme }) => theme.adaptive.zeroTier.xl} {
    width: 300px;
    height: 101px;
    padding: 25px 36px 31px 18px;
    margin-left: 64px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.lg} {
    width: 257px;
    height: 87px;
    padding: 21px 31px 27px 16px;
    margin-left: 55px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.slg} {
    width: 222px;
    height: 75px;
    padding: 18px 27px 23px 14px;
    margin-left: 57px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.md} {
    width: 164px;
    height: 55px;
    padding: 13px 20px 17px 10px;
    margin-left: 35px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.smd} {
    width: 222px;
    height: 75px;
    padding: 18px 27px 23px 14px;
    margin-left: -10px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.sm} {
    width: 211px;
    height: 71px;
    padding: 17px 25px 22px 13px;
    margin-left: -5px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.ssm} {
    width: 164px;
    height: 55px;
    padding: 13px 20px 17px 10px;
    margin-left: -4px;
  }
`

const SecondPeculiarity = styled.div`
  width: 369px;
  height: 125px;
  padding: 16px 22px 51px 24px;
  margin-left: 210px;
  margin-top: 12px;
  background: url('${secondPeculiarityBg}') center no-repeat;
  ${PeculiarityCss};
  ${({ theme }) => theme.adaptive.zeroTier.xl} {
    width: 322px;
    height: 109px;
    padding: 14px 20px 44px 21px;
    margin-left: 184px;
    margin-top: 10px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.lg} {
    width: 276px;
    height: 94px;
    padding: 12px 17px 38px 18px;
    margin-left: 157px;
    margin-top: 9px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.slg} {
    width: 238px;
    height: 81px;
    padding: 10px 14px 33px 16px;
    margin-left: 135px;
    margin-top: 8px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.md} {
    width: 176px;
    height: 60px;
    padding: 8px 11px 24px 12px;
    margin-left: 100px;
    margin-top: 6px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.smd} {
    width: 238px;
    height: 81px;
    padding: 10px 14px 33px 16px;
    margin-left: 61px;
    margin-top: 8px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.sm} {
    position: absolute;
    top: 124px;
    right: -5px;
    width: 226px;
    height: 77px;
    padding: 10px 14px 31px 15px;
    margin-left: 0;
    margin-top: 0;
  }
  ${({ theme }) => theme.adaptive.zeroTier.ssm} {
    top: 96px;
    right: -4px;
    width: 176px;
    height: 60px;
    padding: 8px 11px 24px 12px;
  }
`

const ThirdPeculiarity = styled.div`
  width: 324px;
  height: 116px;
  padding: 28px 38px 31px 30px;
  margin-left: 243px;
  background: url('${thirdPeculiarityBg}') center no-repeat;
  ${PeculiarityCss};
  text-align: right;
  ${({ theme }) => theme.adaptive.zeroTier.xl} {
    width: 283px;
    height: 101px;
    padding: 24px 34px 26px 27px;
    margin-left: 212px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.lg} {
    width: 242px;
    height: 87px;
    padding: 21px 29px 22px 23px;
    margin-left: 182px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.slg} {
    width: 209px;
    height: 74px;
    padding: 18px 25px 19px 20px;
    margin-left: 157px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.md} {
    width: 155px;
    height: 55px;
    padding: 13px 18px 14px 15px;
    margin-left: 116px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.smd} {
    position: absolute;
    top: 130px;
    left: 50%;
    transform: translateX(-50%);
    width: 209px;
    height: 74px;
    padding: 18px 25px 19px 20px;
    margin-left: 0;
  }
  ${({ theme }) => theme.adaptive.zeroTier.sm} {
    position: absolute;
    top: 241px;
    left: -5px;
    transform: none;
    width: 198px;
    height: 71px;
    padding: 17px 24px 18px 19px;
    margin-left: 0;
  }
  ${({ theme }) => theme.adaptive.zeroTier.ssm} {
    top: 188px;
    left: -4px;
    width: 155px;
    height: 55px;
    padding: 13px 18px 14px 15px;
  }
`

const SchemaSecondRow = styled.div`
  position: relative;
  display: flex;
  margin-top: 76px;
  ${({ theme }) => theme.adaptive.zeroTier.xl} {
    margin-top: 58px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.lg} {
    margin-top: 49px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.slg} {
    margin-top: 43px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.md} {
    margin-top: 32px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.smd} {
    margin-top: 182px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.sm} {
    margin-top: 299px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.ssm} {
    margin-top: 233px;
  }
`

const FourthPeculiarity = styled.div`
  width: 353px;
  height: 120px;
  padding: 14px 39px 48px 25px;
  margin-left: 372px;
  margin-top: 10px;
  background: url('${fourthPeculiarityBg}') center no-repeat;
  ${PeculiarityCss};
  ${({ theme }) => theme.adaptive.zeroTier.xl} {
    width: 308px;
    height: 105px;
    padding: 12px 34px 42px 22px;
    margin-left: 325px;
    margin-top: 9px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.lg} {
    width: 264px;
    height: 90px;
    padding: 11px 30px 36px 19px;
    margin-left: 279px;
    margin-top: 8px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.slg} {
    width: 228px;
    height: 76px;
    padding: 9px 25px 31px 16px;
    margin-left: 240px;
    margin-top: 6px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.md} {
    width: 169px;
    height: 58px;
    padding: 7px 19px 23px 12px;
    margin-left: 178px;
    margin-top: 4px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.smd} {
    width: 228px;
    height: 76px;
    padding: 9px 25px 31px 16px;
    margin-left: -10px;
    margin-top: 6px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.sm} {
    width: 216px;
    height: 74px;
    padding: 9px 24px 29px 15px;
    margin-left: 126px;
    margin-top: 0;
  }
  ${({ theme }) => theme.adaptive.zeroTier.ssm} {
    width: 169px;
    height: 58px;
    padding: 7px 19px 23px 12px;
    margin-left: 105px;
  }
`

const FifthPeculiarity = styled.div`
  width: 253px;
  height: 116px;
  padding: 25px 30px 33px 42px;
  margin-left: 268px;
  background: url('${fifthPeculiarityBg}') center no-repeat;
  ${PeculiarityCss};
  ${({ theme }) => theme.adaptive.zeroTier.xl} {
    width: 222px;
    height: 102px;
    padding: 22px 26px 29px 37px;
    margin-left: 234px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.lg} {
    width: 190px;
    height: 87px;
    padding: 19px 23px 25px 32px;
    margin-left: 201px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.slg} {
    width: 164px;
    height: 75px;
    padding: 16px 20px 22px 27px;
    margin-left: 173px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.md} {
    width: 121px;
    height: 56px;
    padding: 12px 15px 16px 20px;
    margin-left: 128px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.smd} {
    width: 164px;
    height: 75px;
    padding: 16px 20px 22px 27px;
    margin-left: 118px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.sm} {
    position: absolute;
    top: 107px;
    left: -5px;
    width: 156px;
    height: 71px;
    padding: 15px 19px 20px 26px;
    margin-left: 0;
  }
  ${({ theme }) => theme.adaptive.zeroTier.ssm} {
    top: 84px;
    left: -4px;
    width: 121px;
    height: 56px;
    padding: 12px 15px 16px 20px;
  }
`
