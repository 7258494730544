import axios from 'axios'
import apiAuth from './authData.ts'

export default (data) => {
  const { email, address, signal } = data
  return axios.post(
    `${process.env.REACT_APP_API_URL}/olympus`,
    {
      email,
      address,
      signal,
    },
    apiAuth
  )
}
