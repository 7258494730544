import React from 'react'
import Socials from '../Socials/Socials'
import styled from 'styled-components'
import LinkTitle from '../../img/forms/titleLink.svg'

export default function FormHeader(props) {
  return (
    <FormHeaderRow>
      {props.link ? (
        <TitleLink target="_blank" href={props.link}>
          {props.title}
        </TitleLink>
      ) : (
        <Title>{props.title}</Title>
      )}
      <div>
        <SocialsTitle>Join our community</SocialsTitle>
        <SocialsWrap>
          <Socials x="end" fill="#1B1D2A" />
        </SocialsWrap>
      </div>
    </FormHeaderRow>
  )
}

const FormHeaderRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 25px;
  ${({ theme }) => theme.adaptive.forms.lg} {
    margin-bottom: 20px;
  }
  ${({ theme }) => theme.adaptive.forms.md} {
    margin-bottom: 15px;
  }
  ${({ theme }) => theme.adaptive.forms.sm} {
    margin-bottom: 22px;
    align-items: flex-start;
  }
`

const Title = styled.span`
  color: #1c1f2b;
  font-size: 40px;
  ${({ theme }) => theme.adaptive.forms.lg} {
    font-size: 35px;
  }
  ${({ theme }) => theme.adaptive.forms.md} {
    font-size: 21px;
  }
`

const TitleLink = styled.a`
  color: #1c1f2b;
  position: relative;
  text-decoration: none;
  font-size: 40px;
  overflow: hidden;
  padding-right: 42px;
  ${({ theme }) => theme.adaptive.forms.lg} {
    font-size: 35px;
    padding-right: 35px;
  }
  ${({ theme }) => theme.adaptive.forms.md} {
    font-size: 24px;
    padding-right: 25px;
  }
  &:hover {
    &:after {
      left: 0;
    }
  }
  &:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 1px;
    bottom: 0;
    left: -100%;
    background: #1c1f2b;
    transition: left 0.4s ease;
  }
  &:before {
    content: '';
    top: 11px;
    right: 0;
    position: absolute;
    width: 30px;
    height: 30px;
    background: url(${LinkTitle}) no-repeat;
    ${({ theme }) => theme.adaptive.forms.lg} {
      width: 25px;
      height: 25px;
      top: 11px;
    }
    ${({ theme }) => theme.adaptive.forms.md} {
      width: 18px;
      height: 18px;
      top: 6px;
    }
  }
`

const SocialsTitle = styled.span`
  display: inline-block;
  color: #1d1f2d;
  font-size: 18px;
  text-transform: capitalize;
  margin-bottom: 8px;
  ${({ theme }) => theme.adaptive.forms.lg} {
    font-size: 16px;
  }
  ${({ theme }) => theme.adaptive.forms.md} {
    font-size: 11px;
    margin-bottom: 5px;
  }
  ${({ theme }) => theme.adaptive.forms.sm} {
    font-size: 17px;
    margin-bottom: 8px;
  }
`
const SocialsWrap = styled.div`
  a {
    ${({ theme }) => theme.adaptive.forms.lg} {
      width: 18px;
    }
    ${({ theme }) => theme.adaptive.forms.md} {
      width: 13px;
    }
    ${({ theme }) => theme.adaptive.forms.sm} {
      width: 19px;
    }
  }
`
