import React from 'react'

export default function ThirdClipPath() {
  return (
    <svg width={0} height={0}>
      <clipPath id={'third'}>
        <path d="M220.043 93.8271L190.768 94L14.9864 93.8408C6.70746 93.8333 2.92745e-07 87.1197 6.45004e-07 78.8408L3.36135e-06 15C3.71383e-06 6.71569 6.7157 -3.43619e-05 15 -3.39904e-05L706.839 2.75508e-05C710.773 2.77272e-05 714.549 1.54549 717.354 4.30343L768.516 54.6002C771.384 57.4202 773 61.2742 773 65.2968L773 78.8272C773 87.1114 766.284 93.8271 758 93.8271L220.043 93.8271Z" />
      </clipPath>
      <clipPath id={'thirdXl'}>
        <path d="M192.431 81.8492L166.83 82.0001L13.9874 81.862C6.26031 81.855 2.7278e-07 75.589 6.01556e-07 67.862L2.89331e-06 14C3.2223e-06 6.26804 6.26801 3.10029e-05 14 3.13496e-05L617.79 8.13131e-05C621.456 8.14775e-05 624.975 1.4378 627.592 4.00438L671.802 47.3592C674.487 49.9921 676 53.5944 676 57.3549L676 67.8493C676 75.5813 669.732 81.8493 662 81.8493L192.431 81.8492Z" />
      </clipPath>
      <clipPath id={'thirdLg'}>
        <path d="M165.103 69.8712L143.138 70L11.9892 69.8821C5.36597 69.8761 2.3408e-07 64.5053 5.16237e-07 57.8821L2.47088e-06 12C2.75321e-06 5.37256 5.37256 -2.57357e-05 12 -2.54389e-05L530.079 2.06524e-05C533.211 2.07926e-05 536.218 1.22414 538.459 3.41106L576.38 40.4108C578.695 42.6691 580 45.766 580 48.9998L580 57.8713C580 64.4987 574.627 69.8713 568 69.8713L165.103 69.8712Z" />
      </clipPath>
      <clipPath id={'thirdSlg'}>
        <path d="M149.734 63.8684L129.813 63.9843L9.99304 63.8774C4.47369 63.8724 0.00195332 59.3967 0.00195356 53.8774L0.00195538 10.9842C0.00195562 5.46138 4.47913 0.984233 10.002 0.984234L481.11 0.984274C483.707 0.984274 486.201 1.9941 488.067 3.80021L522.958 37.5853C524.904 39.4691 526.002 42.0614 526.002 44.7694L526.002 53.8684C526.002 59.3913 521.525 63.8684 516.002 63.8684L149.734 63.8684Z" />
      </clipPath>
    </svg>
  )
}
