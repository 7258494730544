import React from 'react'

export default function FourthClipPath() {
  return (
    <svg width={0} height={0}>
      <clipPath id={'fourth'}>
        <path d="M215.556 99.3207L195.261 122.802C192.408 126.103 188.26 127.998 183.898 127.994L14.9864 127.84C6.70746 127.833 2.92768e-07 121.119 6.45027e-07 112.84L4.808e-06 15C5.16049e-06 6.71569 6.7157 -3.43619e-05 15 -3.39904e-05L758 2.98449e-05C766.284 3.02164e-05 773 6.71575 773 15L773 59.1987L773 79.1295C773 87.4137 766.284 94.1295 758 94.1295L226.905 94.1294C222.547 94.1294 218.405 96.0241 215.556 99.3207Z" />
      </clipPath>
      <clipPath id={'fourthXl'}>
        <path d="M188.243 87.2114L171.023 107.146C168.36 110.228 164.488 111.998 160.415 111.994L13.9872 111.861C6.26025 111.854 2.7282e-07 105.588 6.01594e-07 97.8611L4.16977e-06 14C4.49876e-06 6.26805 6.26801 3.86323e-05 14 3.8979e-05L662 9.85543e-05C669.732 9.89011e-05 676 6.26811 676 14.0001L676 51.7989L676 68.3633C676 76.0953 669.732 82.3633 662 82.3633L198.837 82.3633C194.769 82.3633 190.902 84.1329 188.243 87.2114Z" />
      </clipPath>
      <clipPath id={'fourthLg'}>
        <path d="M161.514 74.7482L146.732 91.8439C144.45 94.4829 141.132 95.9982 137.644 95.995L11.9891 95.8808C5.36591 95.8748 2.34136e-07 90.504 5.16291e-07 83.8808L3.57851e-06 12C3.86085e-06 5.37256 5.37256 -2.57357e-05 12 -2.54389e-05L568 2.23507e-05C574.627 2.26476e-05 580 5.3726 580 12L580 44.399L580 58.5971C580 65.2245 574.627 70.5971 568 70.5971L170.591 70.5971C167.106 70.5971 163.793 72.1121 161.514 74.7482Z" />
      </clipPath>
      <clipPath id={'fourthSlg'}>
        <path d="M146.743 68.4194L132.808 84.5235C130.906 86.7211 128.143 87.9827 125.237 87.9801L9.99292 87.8755C4.47357 87.8705 0.00195332 83.3948 0.00195356 77.8755L0.00195641 10.9842C0.00195664 5.46138 4.47913 0.98423 10.002 0.98423L516.002 0.984275C521.525 0.984276 526.002 5.46143 526.002 10.9843L526.002 41.2209L526.002 54.9629C526.002 60.4857 521.525 64.9629 516.002 64.9629L154.305 64.9628C151.402 64.9628 148.642 66.2243 146.743 68.4194Z" />
      </clipPath>
    </svg>
  )
}
