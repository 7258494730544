import React, { useState } from 'react'

export default function Logo({
  light = false,
  enableHover = true,
  headerWhite = false,
}) {
  const [isHover, setIsHover] = useState(false)
  return (
    <svg
      onMouseEnter={() => {
        if (enableHover) setIsHover(true)
      }}
      onMouseLeave={() => {
        if (enableHover) setIsHover(false)
      }}
      width="77"
      height="51"
      viewBox="0 0 77 51"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M59.2611 0H20.1523H20.1404H20.1395H18.6997C14.0502 0 11.4375 3.78251 10.1995 8.52422L0.176026 41.8146C0.0329654 42.2909 -0.0312286 42.7903 0.0146242 43.286C0.423632 47.6368 4.09553 51 8.47723 51H16.6225H16.6235H68.5188C72.9867 51 76.6091 47.6093 76.9869 43.2327C77.0264 42.7747 76.975 42.3121 76.854 41.8679L67.7613 8.52422C66.4105 4.10531 63.9831 0 59.2611 0ZM9.30992 46.0983C9.14026 46.2675 8.90275 45.5382 8.72392 44.8549C8.54968 44.1872 8.56068 43.4846 8.74685 42.8207L18.6997 7.2128C19.4746 4.10439 20.5283 2.38557 22.9805 1.8209H53.8936L9.30992 46.0983ZM68.5188 43.7872C68.5188 46.845 66.0464 49.3244 62.9972 49.3244H20.3027C19.5846 49.3244 19.2306 48.4498 19.7442 47.9477L49.0983 19.2344C52.4401 15.9559 53.8936 10.7829 53.8936 6.63066C53.8936 4.07128 54.7584 2.56766 55.8038 2.07932C57.8305 2.89873 58.6953 4.74998 59.2611 7.2128L68.5188 43.7872Z"
        fill={(() => {
          if (light) {
            return 'url(#linear_light)'
          } else if (headerWhite) {
            return '#ffffff'
          }
          return 'url(#linear_dark)'
        })()}
      />
      <defs>
        <linearGradient
          id="linear_dark"
          x1="38.5"
          y1="0"
          x2="38.5"
          y2="51"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#505D6D" />
          <stop offset="0.473958" stopColor="#31334A" />
          <stop offset="1" stopColor="#151821" />
        </linearGradient>
        <linearGradient
          id="linear_light"
          x1="38.5"
          y1="0"
          x2="38.5"
          y2="51"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DADCE0" />
          <stop offset="0.473958" stopColor="#DADCE0" />
          <stop offset="1" stopColor="#DADCE0" />
        </linearGradient>
      </defs>
    </svg>
  )
}
