import React from 'react'
import styled from 'styled-components'
import border from 'img/incubator/deck/border.svg'
import bgMd from 'img/incubator/deck/bg-md.png'
import bgSm from 'img/incubator/deck/bg-sm.png'
import bgImg from 'img/incubator/deck/bg-img.jpg'
import useWindowSize from 'helpers/utils/useWindowSize'
import { INCUBATOR_DECK } from 'configs/app.config'

const Deck: React.FunctionComponent = () => {
  const { width } = useWindowSize()
  return (
    <Root>
      {width <= 830 && <MobileBg />}
      <BgImage />
      {/*<Dark />*/}
      <Title>Download the incubation services deck</Title>
      <Btn href={INCUBATOR_DECK} target={'_blank'}>
        Download
      </Btn>
    </Root>
  )
}

export default Deck

const Root = styled.div`
  position: relative;
  width: 100%;
  padding-top: 235px;
  padding-bottom: 570px;
  z-index: 2;
  ${({ theme }) => theme.adaptive.zeroTier.xl} {
    padding-top: 233px;
    padding-bottom: 409px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.lg} {
    padding-top: 165px;
    padding-bottom: 396px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.slg} {
    padding-top: 175px;
    padding-bottom: 329px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.md} {
    padding-top: 110px;
    padding-bottom: 425px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.smd} {
    padding-top: 120px;
    padding-bottom: 375px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.sm} {
    padding-top: 80px;
    padding-bottom: 127px;
  }
`

// const Dark = styled.div`
//   position: absolute;
//   bottom: 0;
//   left: 50%;
//   width: 100vw;
//   height: 421px;
//   background: linear-gradient(
//     180deg,
//     #000000 39.06%,
//     rgba(0, 0, 0, 0.9) 57.24%,
//     rgba(0, 0, 0, 0.5) 77.29%,
//     rgba(0, 0, 0, 0) 97.8%
//   );
//   transform: translateX(-50%) rotate(-180deg);
// `

const BgImage = styled.div`
  position: absolute;
  top: 429px;
  left: -499px;
  width: 2591px;
  height: 950px;
  background: url('${bgImg}') center no-repeat;
  background-size: cover;
  ${({ theme }) => theme.adaptive.zeroTier.xl} {
    top: 379px;
    left: -438px;
    width: 2267px;
    height: 831px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.lg} {
    top: 329px;
    left: -372px;
    width: 1943px;
    height: 712px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.slg} {
    top: 300px;
    left: -327px;
    width: 1744px;
    height: 639px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.md} {
    top: 249px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.smd} {
    top: 236px;
    left: -434px;
    width: 1478px;
    height: 541px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.sm} {
    top: 165px;
    left: -334px;
    width: 1002px;
    height: 367px;
  }
`

const Title = styled.div`
  position: relative;
  font-weight: 200;
  font-size: 68px;
  line-height: 82px;
  text-align: center;
  color: #ffffff;
  ${({ theme }) => theme.adaptive.zeroTier.lg} {
    font-size: 56px;
    line-height: 67px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.md} {
    font-size: 54px;
    line-height: 65px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.smd} {
    font-size: 34px;
    line-height: 41px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.sm} {
    width: 250px;
    margin: 0 auto;
    font-size: 26px;
    line-height: 28px;
  }
`

const Btn = styled.a`
  position: relative;
  display: block;
  width: 287px;
  height: 55px;
  padding: 10px;
  border: 1px solid #ffffff;
  border-radius: 5px;
  outline: none;
  margin: 0 auto;
  margin-top: 40px;

  font-weight: 500;
  font-size: 25px;
  line-height: 140%;
  text-align: center;
  color: #ffffff;
  ${({ theme }) => theme.adaptive.zeroTier.lg} {
    width: 250px;
    height: 48px;
    padding: 7px;
    border-radius: 4px;
    margin-top: 58px;

    font-size: 24px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.md} {
    margin-top: 42px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.smd} {
    width: 126px;
    height: 24px;
    padding: 2px;
    border-radius: 2px;
    margin-top: 25px;
    font-size: 14px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.sm} {
    margin-top: 12px;
  }
`

const MobileBg = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 773px;
  width: 100vw;
  height: 600px;
  background: #16181d;
  ${({ theme }) => theme.adaptive.zeroTier.sm} {
    top: 532px;
  }
`
