import React from 'react'
import styled from 'styled-components'

export default function Button({
  left = false,
  right = false,
  name = '',
  type = 'button',
  isDisabled = false,
  handleClick,
}) {
  return (
    <ButtonWrapper left={left} right={right}>
      <ButtonElem
        left={left}
        right={right}
        type={type}
        disabled={isDisabled}
        onClick={handleClick}
      >
        <ButtonName>{name}</ButtonName>
      </ButtonElem>
    </ButtonWrapper>
  )
}

const ButtonWrapper = styled.div`
  padding-left: ${({ left }) => (left ? '33px' : '0')};
  padding-right: ${({ right }) => (right ? '33px' : '0')};
  ${({ theme }) => theme.adaptive.forms.sm} {
    padding-left: 10px;
  }
`

const ButtonElem = styled.button`
  width: 188px;
  position: relative;
  padding: 18px 33px;
  background: #292c3e;
  border-radius: ${({ left }) => (left ? '0 8px 8px 0' : '8px 0 0 8px')};
  padding-left: ${({ right }) => (right ? '33px' : '')};
  padding-right: ${({ left }) => (left ? '33px' : '')};
  transition: background 0.4s ease;
  ${({ theme }) => theme.adaptive.forms.lg} {
    padding: 16px;
    width: 155px;
  }
  ${({ theme }) => theme.adaptive.forms.md} {
    padding: 8px;
    width: 90px;
  }
  ${({ theme }) => theme.adaptive.forms.sm} {
    padding: 10px 0 10px 8px;
    width: 100px;
  }
  &:hover {
    background: #171a24;
    &:before,
    &:after {
      background: #171a24;
    }
  }
  &:before {
    content: '';
    position: absolute;
    display: ${({ left }) => (left ? 'block' : 'none')};
    left: -22px;
    width: 50px;
    top: 0;
    height: 100%;
    transform: skewX(29deg);
    transform-origin: 100%;
    background: #292c3e;
    border-radius: 8px;
    transition: background 0.4s ease;
    ${({ theme }) => theme.adaptive.forms.sm} {
      transform: skewX(0deg);
      left: -10px;
    }
  }
  &:after {
    content: '';
    position: absolute;
    display: ${({ right }) => (right ? 'block' : 'none')};
    right: -22px;
    width: 50px;
    top: 0;
    height: 100%;
    transform: skewX(-29deg);
    transform-origin: 100%;
    background: #292c3e;
    border-radius: 8px;
    transition: background 0.4s ease;
    ${({ theme }) => theme.adaptive.forms.sm} {
      transform: skewX(342deg);
    }
  }
`

const ButtonName = styled.span`
  position: relative;
  z-index: 1;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  color: #80858a;
  ${({ theme }) => theme.adaptive.forms.lg} {
    font-size: 12px;
  }
  ${({ theme }) => theme.adaptive.forms.md} {
    font-size: 8px;
  }
  ${({ theme }) => theme.adaptive.forms.sm} {
    font-size: 12px;
  }
`
