import React from 'react'
import styled from 'styled-components'
import Socials from '../Socials/Socials'
import footerBg from '../../img/index/footer/footer-bg.svg'
// import footerLightBg from "../../img/index/footer/footer-bg-light.svg";
// import footerDarkBg from "../../img/index/footer/footer-bg-dark.svg";
import bottomBg from '../../img/index/footer/bottom-bg.png'
import footerSmBg from '../../img/index/footer/footer-mobile-bg.svg'
//import footerLogo from '../../img/index/footer/footer-logo.svg'
import { Link } from 'react-router-dom'
import {
  APPLY_IDO_LINK,
  FAQ_LINK,
  CAREERS_LINK,
  FORUM_LINK,
  OCCAMX_LINK,
  OCCAMX_DASHBOARD_LINK,
  SUPPORT_LINK,
} from '../../configs/app.config'

export default function IndexFooter({
  backGround,
  mobileBackGround,
  dark = false,
  color,
  bgColor = '#1F2227',
  bgImage = bottomBg,
  copyright = 'Occam DAO',
}) {
  return (
    <FooterCont bgColor={bgColor} bgImage={bgImage}>
      <FooterWrap mobileBackGround={mobileBackGround} bg={backGround}>
        <FooterRow>
          <FooterLogoColumn>
            <FooterLogo>
              <Link to={'/'}>Occam.fi</Link>
            </FooterLogo>
            <FooterLogoText bg={backGround || dark}>
              Interchain Smart DeFi Layer
            </FooterLogoText>
          </FooterLogoColumn>
          <FooterNav>
            <FooterColumn color={color}>
              {/* <Link to="/team">About Us</Link> */}
              {/* <Link to="/ecosystem">Ecosystem</Link> */}
              <Link to="/vcdao">VC DAO</Link>
            </FooterColumn>
            <FooterColumn color={color}>
              <FooterLink href="/docs/occam_deck.pdf" target="_blank">
                Deck
              </FooterLink>
              <FooterLink href="/docs/occamrazer_wp_v1.pdf" target="_blank">
                Whitepaper
              </FooterLink>
              <FooterLink
                href="https://drive.google.com/drive/folders/1KJRfZHf966IkpoMvsIVUOBWCsLZ15ERV?usp=sharing"
                target="_blank"
              >
                Media Pack
              </FooterLink>
            </FooterColumn>
            <FooterColumn color={color}>
              <FooterLink href={APPLY_IDO_LINK} target="_blank">
                Apply for IDO
              </FooterLink>
              <FooterLink href={FAQ_LINK} target="_blank">
                FAQ
              </FooterLink>
              <Link to={{ pathname: SUPPORT_LINK }} target={'_blank'}>
                Support
              </Link>
              <FooterLink href={CAREERS_LINK} target="_blank">
                Careers
              </FooterLink>
            </FooterColumn>
            <FooterColumn color={color}>
              <FooterLink href={OCCAMX_LINK} target="_blank">
                OccamX
              </FooterLink>
              <FooterLink href={OCCAMX_DASHBOARD_LINK} target="_blank">
                OCX Dashboard
              </FooterLink>
              <FooterLink href={FORUM_LINK} target="_blank">
                Forum
              </FooterLink>
            </FooterColumn>
          </FooterNav>
          <FooterSocialBlock>
            <SocialsWrap color={color}>
              <Socials x="end" />
            </SocialsWrap>
          </FooterSocialBlock>
        </FooterRow>
        {/*<Copyright>{copyright}</Copyright>*/}
      </FooterWrap>
    </FooterCont>
  )
}

const FooterCont = styled.div`
  position: relative;
  padding: 71px 0 0 0;
  width: 100%;
  background: ${({ bgColor }) => (bgColor ? bgColor : '')};
  ${({ theme }) => theme.adaptive.newIndex.xl} {
    padding: 61px 0 0 0;
  }
  ${({ theme }) => theme.adaptive.newIndex.lg} {
    padding: 57px 0 0 0;
  }
  ${({ theme }) => theme.adaptive.newIndex.md} {
    padding: 45px 0 0 0;
  }
  ${({ theme }) => theme.adaptive.newIndex.smd} {
    padding: 31px 0 0 0;
  }
  ${({ theme }) => theme.adaptive.newIndex.sm} {
    padding: 48px 0 0 0;
  }
  &:before {
    content: ${({ bg }) => (bg ? 'unset' : '""')};
    position: absolute;
    width: 100%;
    height: 864px;
    bottom: 0;
    z-index: 0;
    background: ${({ bgImage }) =>
      bgImage ? `url(${bottomBg}) center no-repeat` : ''};
    background-size: cover;
    ${({ theme }) => theme.adaptive.newIndex.xl} {
      height: 756px;
    }
    ${({ theme }) => theme.adaptive.newIndex.lg} {
      height: 648px;
    }
    ${({ theme }) => theme.adaptive.newIndex.md} {
      height: 558px;
    }
    ${({ theme }) => theme.adaptive.newIndex.smd} {
      height: 414px;
    }
    ${({ theme }) => theme.adaptive.newIndex.sm} {
      height: 558px;
      bottom: 200px;
    }
    ${({ theme }) => theme.adaptive.newIndex.smm} {
      height: 558px;
      bottom: 138px;
    }
  }
`

const FooterWrap = styled.div`
  position: relative;
  width: 100%;
  padding: 116px 0 32px;
  z-index: 2;
  background: url('${({ bg }) => (bg ? bg : footerBg)}') center no-repeat;
  background-size: cover;
  ${({ theme }) => theme.adaptive.newIndex.xl} {
    padding: 102px 0 28px;
  }
  ${({ theme }) => theme.adaptive.newIndex.lg} {
    padding: 87px 0 24px;
  }
  ${({ theme }) => theme.adaptive.newIndex.md} {
    padding: 75px 0 21px;
  }
  ${({ theme }) => theme.adaptive.newIndex.smd} {
    padding: 56px 0 16px;
  }
  ${({ theme }) => theme.adaptive.newIndex.sm} {
    padding: 108px 15px 16px;
  }
  ${({ theme }) => theme.adaptive.newIndex.smm} {
    background: url('${({ mobileBackGround }) =>
        mobileBackGround ? mobileBackGround : footerSmBg}')
      center no-repeat;
    padding: 68px 15px 16px;
    background-size: cover;
  }
  ${({ theme }) => theme.adaptive.newIndex.smm} {
    padding: 75px 15px 20px;
  }
`

const FooterRow = styled.div`
  display: flex;
  margin: 0 auto;
  width: 1466px;
  ${({ theme }) => theme.adaptive.newIndex.xl} {
    width: 1280px;
  }
  ${({ theme }) => theme.adaptive.newIndex.lg} {
    width: 1101px;
  }
  ${({ theme }) => theme.adaptive.newIndex.md} {
    width: 935px;
  }
  ${({ theme }) => theme.adaptive.newIndex.smd} {
    width: 699px;
  }
  ${({ theme }) => theme.adaptive.newIndex.sm} {
    width: 313px;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
  }
  ${({ theme }) => theme.adaptive.newIndex.smm} {
    width: 258px;
  }
`

const FooterLogoColumn = styled.div`
  display: inline-block;
  vertical-align: top;
  width: 358px;
  margin-right: 128px;
  ${({ theme }) => theme.adaptive.newIndex.xl} {
    width: 313px;
    margin-right: 112px;
  }
  ${({ theme }) => theme.adaptive.newIndex.lg} {
    width: 267px;
    margin-right: 96px;
  }
  ${({ theme }) => theme.adaptive.newIndex.md} {
    width: 231px;
    margin-right: 83px;
  }
  ${({ theme }) => theme.adaptive.newIndex.smd} {
    width: 172px;
    margin-right: 62px;
  }
  ${({ theme }) => theme.adaptive.newIndex.sm} {
    width: 100%;
    margin-right: 0;
  }
`

const FooterLogo = styled.div`
  a {
    display: block;
    font-family: 'RPBrawl';
    font-size: 54px;
    line-height: 54px;
    background: ${({ color }) => (color ? color : '#939BA9')};
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-transform: uppercase;
    transition: 0.4s;
    ${({ theme }) => theme.adaptive.newIndex.xl} {
      font-size: 47px;
      line-height: 47px;
    }
    ${({ theme }) => theme.adaptive.newIndex.lg} {
      font-size: 41px;
      line-height: 41px;
    }
    ${({ theme }) => theme.adaptive.newIndex.md} {
      font-size: 35px;
      line-height: 35px;
    }
    ${({ theme }) => theme.adaptive.newIndex.smd} {
      font-size: 26px;
      line-height: 26px;
    }
    ${({ theme }) => theme.adaptive.newIndex.sm} {
      font-size: 47px;
      line-height: 47px;
    }
    ${({ theme }) => theme.adaptive.newIndex.smm} {
      font-size: 39px;
      line-height: 39px;
    }
  }
`

const FooterLogoText = styled.p`
  margin: 0;
  display: block;
  font-size: 20px;
  line-height: 24px;
  color: ${({ color }) => (color ? color : '#939BA9')};
  opacity: 0.6;
  text-transform: capitalize;
  ${({ theme }) => theme.adaptive.newIndex.xl} {
    font-size: 18px;
    line-height: 22px;
  }
  ${({ theme }) => theme.adaptive.newIndex.lg} {
    font-size: 15px;
    line-height: 18px;
  }
  ${({ theme }) => theme.adaptive.newIndex.md} {
    font-size: 13px;
    line-height: 16px;
  }
  ${({ theme }) => theme.adaptive.newIndex.smd} {
    font-size: 10px;
    line-height: 12px;
  }
  ${({ theme }) => theme.adaptive.newIndex.sm} {
    font-size: 20px;
    line-height: 24px;
  }
  ${({ theme }) => theme.adaptive.newIndex.smm} {
    font-size: 17px;
    line-height: 20px;
  }
`

const FooterNav = styled.div`
  display: flex;
  margin-right: 130px;
  ${({ theme }) => theme.adaptive.newIndex.xl} {
    margin-right: 103px;
  }
  ${({ theme }) => theme.adaptive.newIndex.lg} {
    margin-right: 103px;
  }
  ${({ theme }) => theme.adaptive.newIndex.md} {
    margin-right: 90px;
  }
  ${({ theme }) => theme.adaptive.newIndex.smd} {
    margin-right: 48px;
  }
  ${({ theme }) => theme.adaptive.newIndex.sm} {
    width: 250px;
    margin-right: 43px;
    margin-top: 10px;
    flex-wrap: wrap;
    flex-direction: column;
    max-height: 400px;
  }
  ${({ theme }) => theme.adaptive.newIndex.smm} {
    margin-top: 22px;
    margin-right: 0;
    width: 100%;
    max-height: 316px;
  }
`

const FooterColumn = styled.div`
  display: inline-block;
  vertical-align: top;
  max-width: 142px;
  margin-right: 80px;
  position: relative;
  ${({ theme }) => theme.adaptive.newIndex.xl} {
    margin-right: 70px;
    max-width: 130px;
  }
  ${({ theme }) => theme.adaptive.newIndex.lg} {
    margin-right: 60px;
    max-width: 110px;
  }
  ${({ theme }) => theme.adaptive.newIndex.md} {
    margin-right: 45px;
    max-width: 95px;
  }
  ${({ theme }) => theme.adaptive.newIndex.smd} {
    margin-right: 38px;
    max-width: 67px;
  }
  ${({ theme }) => theme.adaptive.newIndex.sm} {
    width: 50%;
    max-width: unset;
    margin-right: 0;
    margin-top: 26px;
    &:nth-child(1),
    &:nth-child(4) {
      order: 1;
    }
    &:nth-child(3),
    &:nth-child(4) {
      margin-right: 0;
    }
  }
  ${({ theme }) => theme.adaptive.newIndex.smm} {
    margin-top: 20px;
  }
  &:last-child {
    margin-right: 0;
  }
  &:nth-child(2n) {
    ${({ theme }) => theme.adaptive.newIndex.sm} {
      margin-right: 0;
    }
  }
  a {
    font-weight: 300;
    font-size: 20px;
    line-height: 24px;
    color: ${({ color }) => (color ? color : '#939BA9')};
    opacity: 0.7;
    width: 100%;
    display: block;
    text-decoration: none;
    transition: color 0.4s;
    margin-top: 25px;
    ${({ theme }) => theme.adaptive.newIndex.xl} {
      font-size: 18px;
      line-height: 22px;
      margin-top: 25px;
    }
    ${({ theme }) => theme.adaptive.newIndex.lg} {
      font-size: 15px;
      line-height: 18px;
      margin-top: 23px;
    }
    ${({ theme }) => theme.adaptive.newIndex.md} {
      font-size: 13px;
      line-height: 16px;
      margin-top: 19px;
    }
    ${({ theme }) => theme.adaptive.newIndex.smd} {
      font-size: 10px;
      line-height: 12px;
      margin-top: 14px;
    }
    ${({ theme }) => theme.adaptive.newIndex.sm} {
      font-size: 18px;
      line-height: 25px;
      margin-top: 26px;
    }
    ${({ theme }) => theme.adaptive.newIndex.smm} {
      margin-top: 20px;
    }
    &:hover {
      color: #dadce0;
    }
    &:first-child {
      margin-top: 0;
    }
  }
`

const FooterLink = styled.a``

const FooterSocialBlock = styled.div`
  display: inline-block;
  vertical-align: top;
  width: 162px;
  ${({ theme }) => theme.adaptive.newIndex.xl} {
    width: 141px;
  }
  ${({ theme }) => theme.adaptive.newIndex.lg} {
    width: 121px;
  }
  ${({ theme }) => theme.adaptive.newIndex.md} {
    width: 104px;
  }
  ${({ theme }) => theme.adaptive.newIndex.smd} {
    width: 78px;
  }
  ${({ theme }) => theme.adaptive.newIndex.sm} {
    width: 20px;
    margin-top: 36px;
  }
  ${({ theme }) => theme.adaptive.newIndex.smm} {
    width: 100%;
    margin-top: 30px;
  }
`

const SocialsWrap = styled.div`
  div {
    flex-wrap: wrap;
    ${({ theme }) => theme.adaptive.newIndex.smm} {
      justify-content: space-between;
    }
  }
  a {
    display: flex;
    width: 26px;
    height: 26px;
    margin-left: 40px;
    margin-top: 21px;
    ${({ theme }) => theme.adaptive.newIndex.xl} {
      margin-left: 35px;
      width: 23px;
      margin-top: 17px;
    }
    ${({ theme }) => theme.adaptive.newIndex.lg} {
      width: 20px;
      height: 20px;
      margin-left: 30px;
      margin-top: 19px;
    }
    ${({ theme }) => theme.adaptive.newIndex.md} {
      width: 17px;
      height: 17px;
      margin-left: 26px;
      margin-top: 17px;
    }
    ${({ theme }) => theme.adaptive.newIndex.smd} {
      width: 13px;
      height: 13px;
      margin-left: 19px;
      margin-top: 12px;
    }
    ${({ theme }) => theme.adaptive.newIndex.sm} {
      width: 20px;
      height: 20px;
      margin-left: 0;
      margin-top: 13px;
    }
    &:nth-of-type(-n + 3) {
      margin-top: 0;
      ${({ theme }) => theme.adaptive.newIndex.sm} {
        margin-top: 13px;
      }
      ${({ theme }) => theme.adaptive.newIndex.smm} {
        margin-top: 0;
      }
    }
    ${({ theme }) => theme.adaptive.newIndex.smm} {
      margin-top: 0;
    }
    &:first-child {
      ${({ theme }) => theme.adaptive.newIndex.sm} {
        margin-top: 0;
      }
    }
    &:nth-of-type(4) {
      margin-left: 0;
    }
    &:hover {
      & path {
        fill: ${({ bg }) => (bg ? '#ffffff' : '#000000')};
      }
    }
  }
  & path {
    fill: ${({ color }) => (color ? color : '#80858A')};
    transition: 0.4s;
  }
  svg {
    display: block;
  }
`
const Copyright = styled.div`
  margin: 42px auto 0;
  width: 200px;
  text-align: center;
  font-size: 13px;
  color: #5e5e5e;
  opacity: 0.7;
  ${({ theme }) => theme.adaptive.newIndex.xl} {
    margin: 38px auto 0;
    font-size: 12px;
  }
  ${({ theme }) => theme.adaptive.newIndex.lg} {
    margin: 32px auto 0;
    font-size: 11px;
  }
  ${({ theme }) => theme.adaptive.newIndex.md} {
    margin: 27px auto 0;
    font-size: 9px;
  }
  ${({ theme }) => theme.adaptive.newIndex.smd} {
    margin: 20px auto 0;
    font-size: 8px;
  }
  ${({ theme }) => theme.adaptive.newIndex.sm} {
    font-size: 12px;
    line-height: 14px;
  }
  ${({ theme }) => theme.adaptive.newIndex.smm} {
    margin: 27px auto 0;
  }
`
