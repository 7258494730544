import React from 'react'
import styled, { css } from 'styled-components'
import { ReactComponent as BtnIcon } from 'img/rebranding/apps/btn-icon.svg'
import { ReactComponent as BtnIconSm } from 'img/rebranding/apps/btn-sm-icon.svg'
import { ReactComponent as BtnBg } from 'img/rebranding/apps/btn-bg.svg'
import appsList from 'store/apps'
import useWindowSize from 'helpers/utils/useWindowSize'

const Incubator: React.FunctionComponent = () => {
  const { width } = useWindowSize()

  return (
    <Root>
      <Cont>
        <Row>
          {appsList.map((item, idx) => {
            return (
              <Item
                href={item.link ? item.link : ''}
                target={'_blank'}
                key={idx}
                srcImg={item.background}
                srcImg2x={item.background2x}
                srcImgWebp={item.backgroundWebp}
                srcImgWebp2x={item.backgroundWebp2x}
              >
                <ItemBgHover />
                <ItemIconWrap>
                  <BigRound />
                  <ItemRound />
                  {item.icon && <ItemIcon>{item.icon}</ItemIcon>}
                  {item.getIcon && <ItemIcon>{item.getIcon()}</ItemIcon>}
                </ItemIconWrap>
                <ItemTitle>{item.title}</ItemTitle>
                <Button>
                  <ButtonBg>
                    <BtnBg />
                  </ButtonBg>
                  {item.btnText}
                  <ButtonIcon>
                    {width <= 790 && <BtnIconSm />}
                    {width > 790 && <BtnIcon />}
                  </ButtonIcon>
                </Button>
                <ItemBgClip>
                  {width <= 790 && item.getClipSm()}
                  {width > 790 && item.getClip()}
                </ItemBgClip>
              </Item>
            )
          })}
        </Row>
      </Cont>
    </Root>
  )
}

export default Incubator

const Root = styled.div`
  position: relative;
  width: 100%;
`

const Cont = styled.div`
  position: relative;
  width: 1400px;
  margin: 0 auto;
  left: 50%;
  transform: translateX(-50%);
  ${({ theme }) => theme.adaptive.newHomePage.lg} {
    width: 1200px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.slg} {
    width: 1032px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.md} {
    width: 766px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.sm} {
    width: 520px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.smm} {
    width: 350px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.xs} {
    width: 273px;
  }
`

const Row = styled.div`
  display: flex;
  ${({ theme }) => theme.adaptive.newHomePage.sm} {
    flex-wrap: wrap;
  }
`

const ItemTitle = styled.div`
  position: relative;
  font-family: 'NeueHaasDisplay';
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
  color: #27303b;
  margin: 0 auto;
  margin-bottom: 42px;
  pointer-events: none;
  z-index: 2;
  ${({ theme }) => theme.adaptive.newHomePage.lg} {
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 39px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.slg} {
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 32px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.md} {
    font-size: 14px;
    line-height: 17px;
    margin-bottom: 23px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.sm} {
    font-size: 28px;
    line-height: 34px;
    margin-bottom: 18px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.smm} {
    font-size: 19px;
    line-height: 23px;
    margin-bottom: 12px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.xs} {
    font-size: 15px;
    line-height: 18px;
    margin-bottom: 9px;
  }
`

const ItemIconWrap = styled.div`
  position: relative;
  margin: 0 auto;
  max-width: 76px;
  ${({ theme }) => theme.adaptive.newHomePage.lg} {
    max-width: 65px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.slg} {
    max-width: 56px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.md} {
    max-width: 42px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.sm} {
    max-width: 71px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.smm} {
    max-width: 48px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.xs} {
    max-width: 37px;
  }
`

const ItemIcon = styled.div`
  position: relative;
  font-family: 'RPBrawl';
  font-weight: 400;
  font-size: 72px;
  line-height: 86px;
  text-align: center;
  background: linear-gradient(180deg, #505d6d 0%, #3a4350 100%), #ffffff;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-transform: uppercase;
  ${({ theme }) => theme.adaptive.newHomePage.lg} {
    font-size: 62px;
    line-height: 74px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.slg} {
    font-size: 53px;
    line-height: 64px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.md} {
    font-size: 39px;
    line-height: 47px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.sm} {
    font-size: 58px;
    line-height: 43px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.smm} {
    font-size: 39px;
    line-height: 29px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.xs} {
    font-size: 31px;
    line-height: 23px;
  }
`

const ItemRound = styled.div`
  position: absolute;
  top: -49px;
  left: -58px;
  background: rgba(255, 255, 255, 0.377);
  border: 1px solid rgba(255, 255, 255, 0.1);
  width: 188px;
  height: 188px;
  border-radius: 50%;
  opacity: 0;
  transition: 0.4s;
  z-index: -1;
  backdrop-filter: blur(1.5px);
  transform: translate3d(0, 0, 0);
  ${({ theme }) => theme.adaptive.newHomePage.lg} {
    top: -42px;
    left: -49px;
    width: 161px;
    height: 161px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.slg} {
    top: -36px;
    left: -42px;
    width: 139px;
    height: 139px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.md} {
    top: -27px;
    left: -30px;
    width: 103px;
    height: 103px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.sm} {
    display: none;
  }
`

const BigRound = styled.div`
  position: absolute;
  top: -265px;
  left: -278px;
  //transform: translate(-50%, -50%);
  background: rgba(255, 255, 255, 0.55);
  opacity: 0;
  transition: 0.4s;
  border: 1px solid rgba(255, 255, 255, 0.92);
  width: 620px;
  height: 620px;
  border-radius: 50%;
  z-index: -1;
  ${({ theme }) => theme.adaptive.newHomePage.lg} {
    top: -265px;
    left: -278px;
    width: 531px;
    height: 531px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.slg} {
    top: -227px;
    left: -237px;
    width: 458px;
    height: 458px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.md} {
    top: -196px;
    left: -204px;
    width: 340px;
    height: 340px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.sm} {
    display: none;
  }
`

const ButtonBg = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  svg {
    display: block;
    width: 100%;
    height: 100%;
    filter: drop-shadow(0px -1.17263px 0px #ffffff)
      drop-shadow(0px 35px 33px rgba(13, 52, 89, 0.12))
      drop-shadow(0px 5px 10px rgba(13, 52, 89, 0.04));
    fill: #f4f4f4;
    transition: 0.4s;
    transform: translate3d(0, 0, 0);
  }
  ${({ theme }) => theme.adaptive.newHomePage.sm} {
    display: none;
  }
`

const ButtonIcon = styled.div`
  width: 20px;
  height: 13px;
  ${({ theme }) => theme.adaptive.newHomePage.lg} {
    width: 17px;
    height: 11px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.slg} {
    width: 15px;
    height: 10px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.md} {
    width: 11px;
    height: 7px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.sm} {
    width: 10px;
    height: 9px;
    margin-left: 7px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.smm} {
    width: 7px;
    height: 6px;
    margin-left: 5px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.xs} {
    width: 5px;
    height: 5px;
    margin-left: 4px;
  }
  svg {
    display: block;
    width: 100%;
    height: 100%;
    fill: #b9c4da;
    transition: 0.4s;
  }
`

const Button = styled.a`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 187px;
  height: 60px;
  font-family: 'NeueHaasDisplay';
  font-weight: 500;
  font-size: 18px;
  line-height: 100%;
  letter-spacing: 0.01em;
  color: #6288b4;
  padding: 19px 20px;
  cursor: pointer;
  margin: 0 auto;
  pointer-events: none;
  z-index: 2;
  transition: 0.4s;
  ${({ theme }) => theme.adaptive.newHomePage.lg} {
    width: 160px;
    height: 51px;
    font-size: 15px;
    padding: 16px 27px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.slg} {
    width: 138px;
    height: 44px;
    font-size: 14px;
    padding: 14px 23px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.md} {
    width: 102px;
    height: 33px;
    font-size: 12px;
    padding: 8px 15px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.sm} {
    justify-content: center;
    width: auto;
    color: #73787e;
    height: auto;
    font-size: 20px;
    padding: 0;
    font-weight: 400;
  }
  ${({ theme }) => theme.adaptive.newHomePage.smm} {
    font-size: 14px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.xs} {
    font-size: 12px;
  }
`

const ItemBgHover = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 0.75),
    rgba(255, 255, 255, 0.75)
  );
  opacity: 1;
  transition: 0.4s;
  pointer-events: none;
`

const ItemBg = styled.div`
  position: absolute;
  display: block;
  cursor: pointer;
`

const ItemBgClip = styled.div`
  position: absolute;
  top: 0;
  left: 0;
`

const Item = styled.a<{
  isDisable?: boolean
  srcImg: string
  srcImg2x: string
  srcImgWebp: string
  srcImgWebp2x: string
}>`
  display: block;
  position: relative;
  ${({ srcImg, srcImg2x, srcImgWebp, srcImgWebp2x }) => {
    return css`
      background-image: url('${srcImg}');
      background-image: image-set(
        url('${srcImgWebp}') 1x,
        url('${srcImgWebp2x}') 2x,
        url('${srcImg}') 1x,
        url('${srcImg2x}') 2x
      );
    `
  }}
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  transform: translate3d(0, 0, 0);
  &:nth-child(1) {
    width: 340px;
    height: 571px;
    clip-path: url('#razerBorder');
    margin-top: 51px;
    overflow: hidden;
    ${({ theme }) => theme.adaptive.newHomePage.lg} {
      margin-top: 47px;
      width: 291px;
      height: 489px;
    }
    ${({ theme }) => theme.adaptive.newHomePage.slg} {
      width: 251px;
      height: 421px;
      margin-top: 38px;
    }
    ${({ theme }) => theme.adaptive.newHomePage.md} {
      width: 186px;
      height: 313px;
      margin-top: 28px;
    }
    ${({ theme }) => theme.adaptive.newHomePage.sm} {
      width: 255px;
      height: 341px;
      margin-top: 143px;
      clip-path: url('#razerSmBorder');
    }
    ${({ theme }) => theme.adaptive.newHomePage.smm} {
      width: 172px;
      height: 230px;
      margin-top: 133px;
    }
    ${({ theme }) => theme.adaptive.newHomePage.xs} {
      width: 134px;
      height: 179px;
      margin-top: 110px;
    }
    ${ItemTitle} {
      margin-top: 100px;
      ${({ theme }) => theme.adaptive.newHomePage.lg} {
        margin-top: 85px;
      }
      ${({ theme }) => theme.adaptive.newHomePage.slg} {
        margin-top: 73px;
      }
      ${({ theme }) => theme.adaptive.newHomePage.md} {
        margin-top: 55px;
      }
      ${({ theme }) => theme.adaptive.newHomePage.sm} {
        margin-top: 18px;
        width: 160px;
      }
      ${({ theme }) => theme.adaptive.newHomePage.smm} {
        margin-top: 12px;
        width: 108px;
      }
      ${({ theme }) => theme.adaptive.newHomePage.xs} {
        margin-top: 9px;
        width: 84px;
      }
    }
    ${ItemIconWrap} {
      margin-top: 153px;
      ${({ theme }) => theme.adaptive.newHomePage.lg} {
        margin-top: 131px;
      }
      ${({ theme }) => theme.adaptive.newHomePage.slg} {
        margin-top: 113px;
      }
      ${({ theme }) => theme.adaptive.newHomePage.md} {
        margin-top: 84px;
      }
      ${({ theme }) => theme.adaptive.newHomePage.sm} {
        margin-top: 107px;
      }
      ${({ theme }) => theme.adaptive.newHomePage.smm} {
        margin-top: 72px;
      }
      ${({ theme }) => theme.adaptive.newHomePage.xs} {
        margin-top: 56px;
      }
    }
  }
  &:nth-child(2) {
    margin-left: -17px;
    margin-top: 61px;
    width: 370px;
    height: 591px;
    clip-path: url('#occamxBorder');
    overflow: hidden;
    ${({ theme }) => theme.adaptive.newHomePage.lg} {
      width: 317px;
      height: 507px;
      margin-top: 55px;
      margin-left: -14px;
    }
    ${({ theme }) => theme.adaptive.newHomePage.slg} {
      width: 273px;
      height: 436px;
      margin-top: 45px;
      margin-left: -12px;
    }
    ${({ theme }) => theme.adaptive.newHomePage.md} {
      width: 203px;
      height: 324px;
      margin-top: 34px;
      margin-left: -10px;
    }
    ${({ theme }) => theme.adaptive.newHomePage.sm} {
      width: 270px;
      height: 418px;
      clip-path: url('#occamxSmBorder');
      margin-top: 88px;
      margin-left: -6px;
    }
    ${({ theme }) => theme.adaptive.newHomePage.smm} {
      width: 182px;
      height: 282px;
      margin-top: 96px;
      margin-left: -4px;
    }
    ${({ theme }) => theme.adaptive.newHomePage.xs} {
      width: 142px;
      height: 220px;
      margin-top: 81px;
      margin-left: -3px;
    }
    ${ItemTitle} {
      margin-top: 120px;
      ${({ theme }) => theme.adaptive.newHomePage.lg} {
        margin-top: 103px;
      }
      ${({ theme }) => theme.adaptive.newHomePage.slg} {
        margin-top: 88px;
      }
      ${({ theme }) => theme.adaptive.newHomePage.md} {
        margin-top: 66px;
      }
      ${({ theme }) => theme.adaptive.newHomePage.sm} {
        margin-top: 18px;
        width: 122px;
      }
      ${({ theme }) => theme.adaptive.newHomePage.smm} {
        margin-top: 12px;
        width: 82px;
      }
      ${({ theme }) => theme.adaptive.newHomePage.xs} {
        margin-top: 9px;
        width: 64px;
      }
    }
    ${ItemIconWrap} {
      margin-top: 153px;
      ${({ theme }) => theme.adaptive.newHomePage.lg} {
        margin-top: 131px;
      }
      ${({ theme }) => theme.adaptive.newHomePage.slg} {
        margin-top: 113px;
      }
      ${({ theme }) => theme.adaptive.newHomePage.md} {
        margin-top: 84px;
      }
      ${({ theme }) => theme.adaptive.newHomePage.sm} {
        margin-top: 161px;
      }
      ${({ theme }) => theme.adaptive.newHomePage.smm} {
        margin-top: 109px;
      }
      ${({ theme }) => theme.adaptive.newHomePage.xs} {
        margin-top: 85px;
      }
    }
  }
  &:nth-child(3) {
    margin-left: -17px;
    margin-top: 21px;
    width: 370px;
    height: 631px;
    clip-path: url('#governanceBorder');
    overflow: hidden;
    ${({ theme }) => theme.adaptive.newHomePage.lg} {
      width: 317px;
      height: 541px;
      margin-top: 21px;
      margin-left: -14px;
    }
    ${({ theme }) => theme.adaptive.newHomePage.slg} {
      width: 273px;
      height: 466px;
      margin-top: 16px;
      margin-left: -12px;
    }
    ${({ theme }) => theme.adaptive.newHomePage.md} {
      width: 203px;
      height: 346px;
      margin-top: 12px;
      margin-left: -10px;
    }
    ${({ theme }) => theme.adaptive.newHomePage.sm} {
      width: 271px;
      height: 419px;
      clip-path: url('#governanceSmBorder');
      margin-top: -36px;
      margin-left: 0;
    }
    ${({ theme }) => theme.adaptive.newHomePage.smm} {
      width: 183px;
      height: 282px;
      margin-top: -25px;
    }
    ${({ theme }) => theme.adaptive.newHomePage.xs} {
      width: 142px;
      height: 220px;
      margin-top: -20px;
    }
    ${ItemRound} {
      top: -66px;
      left: -60px;
      ${({ theme }) => theme.adaptive.newHomePage.lg} {
        top: -57px;
        left: -52px;
      }
      ${({ theme }) => theme.adaptive.newHomePage.slg} {
        top: -48px;
        left: -44px;
      }
      ${({ theme }) => theme.adaptive.newHomePage.md} {
        top: -36px;
        left: -33px;
      }
    }
    ${ItemTitle} {
      margin-top: 122px;
      ${({ theme }) => theme.adaptive.newHomePage.lg} {
        margin-top: 104px;
      }
      ${({ theme }) => theme.adaptive.newHomePage.slg} {
        margin-top: 90px;
      }
      ${({ theme }) => theme.adaptive.newHomePage.md} {
        margin-top: 67px;
      }
      ${({ theme }) => theme.adaptive.newHomePage.sm} {
        margin-top: 18px;
        width: 151px;
      }
      ${({ theme }) => theme.adaptive.newHomePage.smm} {
        margin-top: 12px;
        width: 102px;
      }
      ${({ theme }) => theme.adaptive.newHomePage.xs} {
        margin-top: 9px;
        width: 81px;
      }
    }
    ${ItemIconWrap} {
      margin-top: 233px;
      ${({ theme }) => theme.adaptive.newHomePage.lg} {
        margin-top: 183px;
      }
      ${({ theme }) => theme.adaptive.newHomePage.slg} {
        margin-top: 153px;
      }
      ${({ theme }) => theme.adaptive.newHomePage.md} {
        margin-top: 116px;
      }
      ${({ theme }) => theme.adaptive.newHomePage.sm} {
        margin-top: 149px;
      }
      ${({ theme }) => theme.adaptive.newHomePage.smm} {
        margin-top: 101px;
      }
      ${({ theme }) => theme.adaptive.newHomePage.xs} {
        margin-top: 78px;
      }
    }
    ${ItemIcon} {
      svg {
        display: block;
        width: 68px;
        height: 56px;
        ${({ theme }) => theme.adaptive.newHomePage.lg} {
          width: 58px;
          height: 48px;
        }
        ${({ theme }) => theme.adaptive.newHomePage.slg} {
          width: 50px;
          height: 41px;
        }
        ${({ theme }) => theme.adaptive.newHomePage.md} {
          width: 36px;
          height: 30px;
        }
        ${({ theme }) => theme.adaptive.newHomePage.sm} {
          width: 60px;
          height: 50px;
        }
        ${({ theme }) => theme.adaptive.newHomePage.smm} {
          width: 40px;
          height: 33px;
        }
        ${({ theme }) => theme.adaptive.newHomePage.xs} {
          width: 31px;
          height: 25px;
        }
      }
    }
  }
  &:nth-child(4) {
    margin-left: -17px;
    margin-top: 51px;
    width: 370px;
    height: 571px;
    clip-path: url('#incubatorBorder');
    overflow: hidden;
    ${({ theme }) => theme.adaptive.newHomePage.lg} {
      margin-left: -14px;
      width: 317px;
      height: 489px;
      margin-top: 47px;
    }
    ${({ theme }) => theme.adaptive.newHomePage.slg} {
      width: 273px;
      height: 421px;
      margin-top: 38px;
      margin-left: -12px;
    }
    ${({ theme }) => theme.adaptive.newHomePage.md} {
      width: 203px;
      height: 313px;
      margin-top: 28px;
      margin-left: -10px;
    }
    ${({ theme }) => theme.adaptive.newHomePage.sm} {
      margin-top: -13px;
      width: 255px;
      height: 345px;
      clip-path: url('#incubatorSmBorder');
      margin-left: -6px;
    }
    ${({ theme }) => theme.adaptive.newHomePage.smm} {
      width: 172px;
      height: 233px;
      margin-left: -5px;
      margin-top: -10px;
    }
    ${({ theme }) => theme.adaptive.newHomePage.xs} {
      width: 134px;
      height: 182px;
      margin-left: -3px;
      margin-top: -8px;
    }
    ${ItemTitle} {
      margin-top: 134px;
      ${({ theme }) => theme.adaptive.newHomePage.lg} {
        margin-top: 115px;
      }
      ${({ theme }) => theme.adaptive.newHomePage.slg} {
        margin-top: 99px;
      }
      ${({ theme }) => theme.adaptive.newHomePage.md} {
        margin-top: 73px;
      }
      ${({ theme }) => theme.adaptive.newHomePage.sm} {
        margin-top: 18px;
        width: 118px;
      }
      ${({ theme }) => theme.adaptive.newHomePage.smm} {
        margin-top: 12px;
        width: 80px;
      }
      ${({ theme }) => theme.adaptive.newHomePage.xs} {
        margin-top: 9px;
        width: 64px;
      }
    }
    ${ItemIconWrap} {
      margin-top: 142px;
      ${({ theme }) => theme.adaptive.newHomePage.lg} {
        margin-top: 122px;
      }
      ${({ theme }) => theme.adaptive.newHomePage.slg} {
        margin-top: 105px;
      }
      ${({ theme }) => theme.adaptive.newHomePage.md} {
        margin-top: 78px;
      }
      ${({ theme }) => theme.adaptive.newHomePage.sm} {
        margin-top: 109px;
      }
      ${({ theme }) => theme.adaptive.newHomePage.smm} {
        margin-top: 73px;
      }
      ${({ theme }) => theme.adaptive.newHomePage.xs} {
        margin-top: 57px;
      }
    }
    ${ItemIcon} {
      svg {
        display: block;
        width: 76px;
        height: 63px;
        margin: 0 auto;
        ${({ theme }) => theme.adaptive.newHomePage.lg} {
          width: 65px;
          height: 54px;
        }
        ${({ theme }) => theme.adaptive.newHomePage.slg} {
          width: 56px;
          height: 47px;
        }
        ${({ theme }) => theme.adaptive.newHomePage.md} {
          width: 42px;
          height: 35px;
        }
        ${({ theme }) => theme.adaptive.newHomePage.sm} {
          width: 71px;
          height: 59px;
        }
        ${({ theme }) => theme.adaptive.newHomePage.smm} {
          width: 48px;
          height: 40px;
        }
        ${({ theme }) => theme.adaptive.newHomePage.xs} {
          width: 37px;
          height: 31px;
        }
      }
    }
  }
  ${({ isDisable }) => {
    if (!isDisable) {
      return css`
        &:hover {
          ${ItemBgHover} {
            opacity: 0;
          }
          ${ItemRound} {
            opacity: 1;
          }
          ${BigRound} {
            opacity: 0.53;
          }
          ~ ${Button} {
            color: #56a0f5;
            ${ButtonIcon} {
              svg {
                fill: #56a0f5;
              }
            }
          }
        }
      `
    } else
      return css`
        cursor: no-drop;
      `
  }}
`
