import React from 'react'
import styled from 'styled-components'
import bg from '../../img/forms/formBg.svg'
import bgSm from '../../img/forms/formBg_sm.svg'
import FormHeader from './FormHeader'

export default function FormLayout(props) {
  return (
    <FormContent>
      <FormBlock>
        <FormHeader link={props.link} title={props.title} />
        {props.children}
      </FormBlock>
    </FormContent>
  )
}

const FormContent = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

const FormBlock = styled.div`
  width: 807px;
  height: 538px;
  background: url(${bg}) no-repeat center;
  padding: 37px 24px 0 24px;
  ${({ theme }) => theme.adaptive.forms.lg} {
    width: 708px;
    height: fit-content;
    background-size: contain;
    padding: 35px 21px 13px 21px;
  }
  ${({ theme }) => theme.adaptive.forms.md} {
    width: 495px;
    height: fit-content;
    background-size: contain;
    padding: 20px 15px 13px 15px;
  }
  ${({ theme }) => theme.adaptive.forms.sm} {
    width: 322px;
    height: fit-content;
    background: url(${bgSm}) no-repeat center;
    padding: 26px 21px 13px 21px;
  }
`
