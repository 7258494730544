import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { TitleCss, Container } from 'theme/theme'
import arrow from 'img/rebranding/news/arrow.svg'
import getLatestMediumPosts from 'api/medium/medium'
import { format, parseISO } from 'date-fns'
import { LazyLoadComponent } from 'react-lazy-load-image-component'
import { ReactComponent as BtnBg } from 'img/rebranding/news/btn-bg.svg'
import { ReactComponent as BtnIcon } from 'img/rebranding/apps/btn-icon.svg'
import useWindowSize from 'helpers/utils/useWindowSize'
import Slider from 'react-slick'
import { MEDIUM_LINK } from 'configs/app.config'

const News: React.FunctionComponent = () => {
  const [newsList, setNewsList] = useState([])
  const [postsFetched, setPostFetched] = useState(false)
  const { width } = useWindowSize()

  const settings = {
    centerMode: false,
    infinite: false,
    speed: 500,
    arrows: false,
    swipeToSlide: true,
    variableWidth: true,
  }

  // @ts-ignore
  useEffect(async () => {
    const posts = await getLatestMediumPosts()
    setNewsList(posts.data)
    setPostFetched(true)
    document.addEventListener('contextmenu', (e) => e.preventDefault())
  }, [])

  const truncateByChars = (str: string, max: number) => {
    return str.length > max ? str.substring(0, max) + '...' : str
  }

  const parseArticleDate = (date: string) => {
    try {
      return format(parseISO(date), 'MMM dd, yyyy')
    } catch (error) {
      console.log(error)
      return ''
    }
  }

  const itemsList = newsList.map((item, idx) => {
    if (postsFetched) {
      return (
        <Article draggable={false} target={'_blank'} href={item.link} key={idx}>
          <ArticleBigImg src={item.thumbnail} />
          <ImgWrapper>
            <LazyLoadComponent>
              <ArticleImg src={item.thumbnail} />
            </LazyLoadComponent>
          </ImgWrapper>
          <ArticleText>
            <ArticleDate>{parseArticleDate(item.isoDate)}</ArticleDate>
            <ArticleTitle>{item.title}</ArticleTitle>
            <ArticleDesc>{truncateByChars(item.description, 150)}</ArticleDesc>
          </ArticleText>
          <More>More</More>
        </Article>
      )
    }
  })

  return (
    <Root>
      <Container>
        <Row>
          <Title>Latest news</Title>
          {width > 790 && (
            <Button href={MEDIUM_LINK} target={'_blank'}>
              <ButtonBg>
                <BtnBg />
              </ButtonBg>
              See more
              <ButtonIcon>
                <BtnIcon />
              </ButtonIcon>
            </Button>
          )}
        </Row>
      </Container>
      <List>
        <SliderWrap>
          <Slider {...settings} className="slider">
            {itemsList}
          </Slider>
        </SliderWrap>
      </List>
      {width <= 790 && (
        <Container>
          <Button>
            <ButtonBg>
              <BtnBg />
            </ButtonBg>
            See more
            <ButtonIcon>
              <BtnIcon />
            </ButtonIcon>
          </Button>
        </Container>
      )}
    </Root>
  )
}

export default News

const Root = styled.div`
  position: relative;
  width: 100%;
  padding-bottom: 130px;
  ${({ theme }) => theme.adaptive.newHomePage.lg} {
    padding-bottom: 111px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.slg} {
    padding-bottom: 96px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.md} {
    padding-bottom: 71px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.sm} {
    padding-bottom: 101px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.smm} {
    padding-bottom: 78px;
  }
`

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 82px;
  ${({ theme }) => theme.adaptive.newHomePage.lg} {
    margin-bottom: 70px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.slg} {
    margin-bottom: 61px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.md} {
    margin-bottom: 45px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.sm} {
    margin-bottom: 30px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.xs} {
    margin-bottom: 24px;
  }
`

const Title = styled.div`
  ${TitleCss};
  text-align: center;
  letter-spacing: -0.03em;
`

const List = styled.div`
  position: relative;
  width: 100vw;
  margin: 0 auto;
`

const Article = styled.a`
  position: relative;
  display: flex !important;
  justify-content: space-between;
  flex-direction: column;
  width: 433px;
  height: 590px;
  background: #ffffff;
  box-shadow: 0px 100px 80px rgba(21, 116, 181, 0.02),
    0px 41.7776px 33.4221px rgba(21, 116, 181, 0.0143771),
    0px 22.3363px 17.869px rgba(21, 116, 181, 0.0119221),
    0px 12.5216px 10.0172px rgba(21, 116, 181, 0.01),
    0px 6.6501px 5.32008px rgba(21, 116, 181, 0.00807786),
    0px 2.76726px 2.21381px rgba(21, 116, 181, 0.00562291);
  border-radius: 8px;
  padding: 0 47px 47px;
  outline: none;
  ${({ theme }) => theme.adaptive.newHomePage.lg} {
    width: 371px;
    height: 506px;
    border-radius: 7px;
    padding: 0 40px 40px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.slg} {
    width: 320px;
    height: 435px;
    border-radius: 6px;
    padding: 0 35px 35px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.md} {
    width: 237px;
    height: 323px;
    border-radius: 4px;
    padding: 0 25px 25px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.sm} {
    width: 300px;
    height: 426px;
    border-radius: 6px;
    padding: 0 20px 20px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.xs} {
    width: 234px;
    height: 332px;
    border-radius: 5px;
    padding: 0 16px 20px;
  }
`

const ImgWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 255px;
  border-radius: 8px 8px 0px 0px;
  overflow: hidden;
  ${({ theme }) => theme.adaptive.newHomePage.lg} {
    height: 219px;
    border-radius: 7px 7px 0px 0px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.slg} {
    height: 188px;
    border-radius: 6px 6px 0px 0px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.md} {
    height: 140px;
    border-radius: 4px 4px 0px 0px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.sm} {
    height: 177px;
    border-radius: 6px 6px 0px 0px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.xs} {
    height: 138px;
    border-radius: 5px 5px 0px 0px;
  }
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(#ffffff 0%, rgba(255, 255, 255, 0) 86.67%);
    transform: rotate(180deg);
  }
`

const ArticleBigImg = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0.05;
  top: 0;
  left: 0;
  object-fit: cover;
`

const ArticleImg = styled.img`
  display: block;
  border-radius: 8px 8px 0px 0px;
  width: 100%;
  height: 100%;
  ${({ theme }) => theme.adaptive.newHomePage.lg} {
    border-radius: 7px 7px 0px 0px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.slg} {
    border-radius: 6px 6px 0px 0px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.md} {
    border-radius: 4px 4px 0px 0px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.sm} {
    border-radius: 6px 6px 0px 0px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.xs} {
    border-radius: 5px 5px 0px 0px;
  }
`

const ArticleText = styled.div`
  position: relative;
`

const ArticleDate = styled.div`
  position: relative;
  font-family: 'NeueHaasDisplay';
  font-weight: 500;
  font-size: 14px;
  line-height: 130%;
  text-transform: uppercase;
  color: #000000;
  opacity: 0.3;
  margin-top: 209px;
  ${({ theme }) => theme.adaptive.newHomePage.lg} {
    margin-top: 179px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.slg} {
    font-size: 10px;
    margin-top: 154px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.md} {
    margin-top: 114px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.sm} {
    margin-top: 171px;
    font-size: 12px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.xs} {
    margin-top: 133px;
    font-size: 9px;
  }
`

const ArticleTitle = styled.div`
  position: relative;
  font-family: 'NeueHaasDisplay';
  font-weight: 500;
  font-size: 24px;
  line-height: 130%;
  color: #333333;
  margin-top: 15px;
  ${({ theme }) => theme.adaptive.newHomePage.lg} {
    font-size: 20px;
    margin-top: 12px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.slg} {
    font-size: 18px;
    margin-top: 11px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.md} {
    font-size: 14px;
    margin-top: 5px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.sm} {
    font-size: 19px;
    margin-top: 12px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.xs} {
    font-size: 15px;
    margin-top: 10px;
  }
`

const ArticleDesc = styled.div`
  position: relative;
  font-family: 'NeueHaasDisplay';
  font-weight: 400;
  font-size: 18px;
  line-height: 140%;
  color: #333333;
  opacity: 0.77;
  margin-top: 12px;
  ${({ theme }) => theme.adaptive.newHomePage.lg} {
    font-size: 15px;
    margin-top: 6px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.slg} {
    font-size: 14px;
    margin-top: 7px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.md} {
    font-size: 12px;
    margin-top: 7px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.sm} {
    font-size: 14px;
    margin-top: 13px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.xs} {
    font-size: 11px;
    margin-top: 11px;
  }
`

const More = styled.div`
  position: relative;
  width: fit-content;
  font-weight: 300;
  font-size: 18px;
  line-height: 140%;
  text-align: right;
  color: #6288b4;
  opacity: 0.77;
  ${({ theme }) => theme.adaptive.newHomePage.lg} {
    font-size: 18px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.slg} {
    font-size: 16px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.md} {
    font-size: 12px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.sm} {
    font-size: 14px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.xs} {
    font-size: 11px;
  }
  &:before {
    content: '';
    position: absolute;
    width: 27px;
    height: 9px;
    top: 50%;
    right: -43px;
    transform: translateY(-50%);
    background: url('${arrow}') center no-repeat;
    background-size: cover;
    ${({ theme }) => theme.adaptive.newHomePage.lg} {
      width: 23px;
      height: 8px;
      right: -37px;
    }
    ${({ theme }) => theme.adaptive.newHomePage.slg} {
      width: 20px;
      height: 7px;
      right: -32px;
    }
    ${({ theme }) => theme.adaptive.newHomePage.md} {
      width: 15px;
      height: 5px;
      right: -24px;
    }
    ${({ theme }) => theme.adaptive.newHomePage.sm} {
      width: 23px;
      height: 8px;
      right: -37px;
    }
    ${({ theme }) => theme.adaptive.newHomePage.xs} {
      width: 18px;
      height: 6px;
      right: -29px;
    }
  }
`

const ButtonBg = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  svg {
    display: block;
    width: 100%;
    height: 100%;
    filter: drop-shadow(0px -1.17263px 0px #ffffff)
      drop-shadow(0px 35px 33px rgba(13, 52, 89, 0.12))
      drop-shadow(0px 5px 10px rgba(13, 52, 89, 0.04));
    transition: 0.4s;
    fill: #f4f4f4;
    transform: translate3d(0, 0, 0);
  }
`

const ButtonIcon = styled.div`
  position: relative;
  width: 23px;
  height: 15px;
  ${({ theme }) => theme.adaptive.newHomePage.lg} {
    width: 20px;
    height: 13px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.slg} {
    width: 17px;
    height: 11px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.md} {
    width: 13px;
    height: 8px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.sm} {
    width: 20px;
    height: 13px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.xs} {
    width: 16px;
    height: 10px;
  }
  svg {
    display: block;
    width: 100%;
    height: 100%;
    fill: #939ba9;
    transition: 0.4s;
  }
`

const Button = styled.a`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 213px;
  height: 70px;
  font-family: 'NeueHaasDisplay';
  font-weight: 400;
  font-size: 24px;
  line-height: 100%;
  letter-spacing: 0.01em;
  color: #6288b4;
  padding: 19px 32px;
  cursor: pointer;
  z-index: 2;
  transition: 0.4s;
  ${({ theme }) => theme.adaptive.newHomePage.lg} {
    width: 182px;
    height: 60px;
    font-size: 20px;
    padding: 18px 27px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.slg} {
    width: 157px;
    height: 52px;
    font-size: 18px;
    padding: 13px 21px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.md} {
    width: 117px;
    height: 39px;
    font-size: 14px;
    padding: 10px 15px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.sm} {
    width: 181px;
    height: 60px;
    font-size: 19px;
    padding: 19px 19px;
    margin-top: 40px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.xs} {
    width: 141px;
    height: 47px;
    font-size: 15px;
    padding: 13px 21px;
    margin-top: 31px;
  }
  &:hover {
    color: #56a0f5;
    ${ButtonBg} {
      svg {
        filter: drop-shadow(0px -1.17263px 0px #ffffff)
          drop-shadow(0px 35px 33px rgba(13, 52, 89, 0.15))
          drop-shadow(0px 5px 10px rgba(13, 52, 89, 0.17));
      }
    }
    ${ButtonIcon} {
      svg {
        fill: #56a0f5;
      }
    }
  }
`

const SliderWrap = styled.div`
  position: relative;
  width: 100vw;
  .slick-slider {
    div,
    a {
      outline: none;
    }
  }
  .slick-slide {
    width: 433px !important;
    margin-right: 49px;
    ${({ theme }) => theme.adaptive.newHomePage.lg} {
      width: 371px !important;
      margin-right: 42px;
    }
    ${({ theme }) => theme.adaptive.newHomePage.slg} {
      width: 320px !important;
      margin-right: 36px;
    }
    ${({ theme }) => theme.adaptive.newHomePage.md} {
      width: 237px !important;
      margin-right: 27px;
    }
    ${({ theme }) => theme.adaptive.newHomePage.sm} {
      width: 300px !important;
      margin-right: 31px;
    }
    ${({ theme }) => theme.adaptive.newHomePage.xs} {
      width: 234px !important;
      margin-right: 25px;
    }
  }
  .slick-list {
    width: 1400px;
    overflow: unset;
    margin: 0 auto;
    ${({ theme }) => theme.adaptive.newHomePage.lg} {
      width: 1200px;
    }
    ${({ theme }) => theme.adaptive.newHomePage.slg} {
      width: 1032px;
    }
    ${({ theme }) => theme.adaptive.newHomePage.md} {
      width: 766px;
    }
    ${({ theme }) => theme.adaptive.newHomePage.sm} {
      width: 520px;
    }
    ${({ theme }) => theme.adaptive.newHomePage.smm} {
      width: 350px;
    }
    ${({ theme }) => theme.adaptive.newHomePage.xs} {
      width: 260px;
    }
  }
`
