import React, { useEffect } from 'react'
import snsWebSdk from '@sumsub/websdk'
import getAccessToken from '../api/kyc/getAccessToken'
import { useCookies } from 'react-cookie'
import { addMinutes, toDate } from 'date-fns'

export default function KYC() {
  const [cookies, setCookie] = useCookies(['kyc-cookie'])

  const apiUrl = process.env.REACT_APP_SUMSUB_URL
  let applicantEmail = 'test@example.org'

  const fetchToken = async (generateNew = false) => {
    if (generateNew || !cookies['kyc-cookie']) {
      const token = (await getAccessToken()).data.token
      setCookie('kyc-cookie', token, {
        expires: toDate(addMinutes(new Date(), 120)),
      })
      return await token
    } else {
      return cookies['kyc-cookie']
    }
  }

  const launchSDK = (token) => {
    let snsWebSdkInstance = snsWebSdk
      .Builder(apiUrl, 'Occam')
      .withAccessToken(token, async (newAccessTokenCallback) => {
        // access token expired
        // get a new one and pass it to the callback to re-initiate the WebSDK
        let newAccessToken = await fetchToken(true) // get a new token from your backend
        newAccessTokenCallback(newAccessToken)
      })
      .withConf({
        lang: 'en',
        email: applicantEmail,
        onMessage: async (type, payload) => {
          // const accessToken = await fetchToken(true)
          // launchSDK(accessToken)
          console.log(type, payload)
        },
        onError: (error) => {
          console.log('WebSDK onError', error)
        },
      })
      .build()

    snsWebSdkInstance.launch('#sumsub-websdk-container')
  }

  useEffect(async () => {
    const accessToken = await fetchToken()
    launchSDK(accessToken)
  }, [])

  return <div id="sumsub-websdk-container"></div>
}
