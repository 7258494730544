import React from 'react'
import styled from 'styled-components'
import partnersList from 'store/partners'
import Marquee from 'react-fast-marquee'

const Partners: React.FunctionComponent = () => {
  const DeskTop = (
    <List>
      <Marquee play={true} pauseOnHover={true} gradient={false}>
        {partnersList.map((article, idx) => {
          return (
            <Partner href={article.link} target={'_blank'} key={idx}>
              {article.getLogo('#3A4144')}
            </Partner>
          )
        })}
      </Marquee>
    </List>
  )
  return <Root>{DeskTop}</Root>
}

export default Partners

const Root = styled.div`
  position: relative;
  width: 100%;
  padding-bottom: 220px;
  ${({ theme }) => theme.adaptive.newHomePage.lg} {
    padding-bottom: 182px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.slg} {
    padding-bottom: 156px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.md} {
    padding-bottom: 116px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.sm} {
    padding-bottom: 101px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.smm} {
    padding-bottom: 124px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.xs} {
    padding-bottom: 79px;
  }
`

const List = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
`

const Partner = styled.a`
  position: relative;
  display: block;
  margin: 0 72px;
  max-height: 60px;
  max-width: 273px;
  ${({ theme }) => theme.adaptive.newHomePage.lg} {
    margin: 0 69px;
    max-height: 50px;
    max-width: 234px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.slg} {
    margin: 0 59px;
    max-height: 44px;
    max-width: 202px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.md} {
    margin: 0 44px;
    max-height: 33px;
    max-width: 150px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.sm} {
    margin: 0 19px;
    max-height: 36px;
    max-width: 163px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.smm} {
    margin: 0 18px;
    max-height: 33px;
    max-width: 148px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.xs} {
    margin: 0 14px;
    max-height: 25px;
    max-width: 116px;
  }
  svg {
    display: block;
    max-height: 50px;
    max-width: 273px;
    ${({ theme }) => theme.adaptive.newHomePage.lg} {
      max-height: 50px;
      max-width: 234px;
    }
    ${({ theme }) => theme.adaptive.newHomePage.slg} {
      max-height: 44px;
      max-width: 202px;
    }
    ${({ theme }) => theme.adaptive.newHomePage.md} {
      max-height: 33px;
      max-width: 150px;
    }
    ${({ theme }) => theme.adaptive.newHomePage.sm} {
      max-height: 36px;
      max-width: 163px;
    }
    ${({ theme }) => theme.adaptive.newHomePage.smm} {
      max-height: 33px;
      max-width: 148px;
    }
    ${({ theme }) => theme.adaptive.newHomePage.xs} {
      max-height: 25px;
      max-width: 116px;
    }
  }
`
