import React from 'react'
import styled from 'styled-components'
import aboutBg from '../../img/zeroTier/about/about-bg.svg'
import aboutBgSmd from '../../img/zeroTier/about/about-bg-smd.svg'
import spaceMens from '../../img/zeroTier/spacemens.png'
import spaceMensSmd from '../../img/zeroTier/about/spacemens-smd.png'

export default function About() {
  return (
    <AboutBoard>
      <Content>
        <AboutImg />
        <AboutContent>
          <AboutTitle>Occam DAO Board</AboutTitle>
          <AboutText>
            Committed to the ideals of decentralized governance.
          </AboutText>
          <AboutDisclaimer>
            With the partner tier initiative, we are tokenizing the entirety of
            our business model, enabling stakers access to the Occam Incubator
            pipeline.
          </AboutDisclaimer>
        </AboutContent>
      </Content>
    </AboutBoard>
  )
}

const AboutBoard = styled.div`
  position: relative;
  width: 100%;
  margin-top: 424px;
  padding: 169px 64px 7px;
  ${({ theme }) => theme.adaptive.zeroTier.xl} {
    margin: 371px auto 0;
    padding: 148px 56px 6px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.lg} {
    margin-top: 318px;
    padding: 127px 48px 5px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.slg} {
    margin-top: 274px;
    padding: 109px 41px 5px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.md} {
    margin-top: 203px;
    padding: 81px 31px 3px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.smd} {
    margin-top: 108px;
    padding: 33px 20px 20px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.sm} {
    width: 333px;
    margin: 92px auto 0;
    display: block;
    padding: 195px 0 31px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.ssm} {
    width: 260px;
    margin: 72px auto 0;
    display: block;
    padding: 152px 0 30px;
  }
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 836px;
    background: url('${aboutBg}') center no-repeat;
    background-size: cover;
    ${({ theme }) => theme.adaptive.zeroTier.xl} {
      height: 731px;
    }
    ${({ theme }) => theme.adaptive.zeroTier.lg} {
      height: 627px;
    }
    ${({ theme }) => theme.adaptive.zeroTier.slg} {
      height: 540px;
    }
    ${({ theme }) => theme.adaptive.zeroTier.md} {
      height: 400px;
    }
    ${({ theme }) => theme.adaptive.zeroTier.smd} {
      background: url('${aboutBgSmd}') center no-repeat;
      background-size: cover;
      width: 100%;
      height: 491px;
    }
    ${({ theme }) => theme.adaptive.zeroTier.sm} {
      width: 425px;
      height: 336px;
      left: -50px;
      transform: none;
    }
    ${({ theme }) => theme.adaptive.zeroTier.ssm} {
      width: 332px;
      height: 262px;
      left: -10px;
    }
  }
`

const Content = styled.div`
  width: 1920px;
  margin: 0 auto;
  display: flex;
  ${({ theme }) => theme.adaptive.zeroTier.xl} {
    width: 1680px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.lg} {
    width: 1440px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.slg} {
    width: 1240px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.md} {
    width: 920px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.smd} {
    width: 600px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.sm} {
    width: 100%;
    display: block;
  }
`

const AboutImg = styled.div`
  position: relative;
  width: 837px;
  ${({ theme }) => theme.adaptive.zeroTier.xl} {
    width: 720px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.lg} {
    width: 604px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.slg} {
    width: 531px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.md} {
    width: 394px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.smd} {
    width: 261px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.sm} {
    width: 279px;
    margin: 0 auto;
  }
  ${({ theme }) => theme.adaptive.zeroTier.ssm} {
    width: 218px;
  }
  &:before {
    content: '';
    position: absolute;
    top: -240px;
    left: 0;
    width: 100%;
    height: 964px;
    background: url('${spaceMens}') center no-repeat;
    background-size: cover;
    ${({ theme }) => theme.adaptive.zeroTier.xl} {
      top: -210px;
      width: 100%;
      height: 843px;
    }
    ${({ theme }) => theme.adaptive.zeroTier.lg} {
      top: -180px;
      width: 100%;
      height: 722px;
    }
    ${({ theme }) => theme.adaptive.zeroTier.slg} {
      top: -155px;
      width: 100%;
      height: 622px;
    }
    ${({ theme }) => theme.adaptive.zeroTier.md} {
      top: -115px;
      width: 100%;
      height: 461px;
    }
    ${({ theme }) => theme.adaptive.zeroTier.smd} {
      background: url('${spaceMensSmd}') center no-repeat;
      background-size: cover;
      top: -17px;
      width: 100%;
      height: 395px;
    }
    ${({ theme }) => theme.adaptive.zeroTier.sm} {
      background: url('${spaceMens}') center no-repeat;
      background-size: cover;
      top: -227px;
      width: 100%;
      height: 332px;
    }
    ${({ theme }) => theme.adaptive.zeroTier.ssm} {
      top: -177px;
      height: 259px;
    }
  }
`

const AboutContent = styled.div`
  position: relative;
  width: 755px;
  ${({ theme }) => theme.adaptive.zeroTier.xl} {
    width: 671px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.lg} {
    width: 572px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.slg} {
    width: 500px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.md} {
    width: 362px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.smd} {
    width: 287px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.sm} {
    width: 100%;
  }
`

const AboutTitle = styled.div`
  font-weight: 200;
  font-size: 68px;
  line-height: 140%;
  color: #3a3d4a;
  margin-bottom: 31px;
  ${({ theme }) => theme.adaptive.zeroTier.xl} {
    font-size: 60px;
    margin-bottom: 27px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.lg} {
    font-size: 51px;
    margin-bottom: 23px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.slg} {
    font-size: 44px;
    margin-bottom: 20px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.md} {
    font-size: 32px;
    margin-bottom: 15px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.smd} {
    text-align: left;
    font-size: 44px;
    margin-bottom: 20px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.sm} {
    text-align: center;
  }
  ${({ theme }) => theme.adaptive.zeroTier.ssm} {
    width: 249px;
    margin: 0 auto 18px;
    font-size: 32px;
  }
`

const AboutText = styled.div`
  font-weight: 300;
  font-size: 39px;
  line-height: 130%;
  color: #3a3d4a;
  margin-bottom: 24px;
  ${({ theme }) => theme.adaptive.zeroTier.xl} {
    font-size: 34px;
    margin-bottom: 21px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.lg} {
    font-size: 30px;
    margin-bottom: 18px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.slg} {
    font-size: 26px;
    margin-bottom: 16px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.md} {
    font-size: 19px;
    margin-bottom: 12px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.smd} {
    font-size: 26px;
    margin-bottom: 18px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.sm} {
    font-size: 25px;
    margin-bottom: 12px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.ssm} {
    font-size: 19px;
    margin-bottom: 9px;
  }
`

const AboutDisclaimer = styled.div`
  position: relative;
  font-weight: 300;
  font-size: 25px;
  line-height: 140%;
  color: #3a3d4a;
  opacity: 0.8;
  margin-left: 31px;
  ${({ theme }) => theme.adaptive.zeroTier.xl} {
    font-size: 22px;
    margin-left: 27px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.lg} {
    font-size: 19px;
    margin-left: 23px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.slg} {
    font-size: 16px;
    margin-left: 20px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.md} {
    font-size: 12px;
    margin-left: 15px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.smd} {
    font-size: 16px;
    margin-left: 10px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.sm} {
    font-size: 15px;
    margin-left: 8px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.ssm} {
    font-size: 12px;
    margin-left: 9px;
  }
  &:before {
    content: '';
    position: absolute;
    top: 6px;
    left: -31px;
    width: 2px;
    height: 55px;
    background: #fcb785;
    ${({ theme }) => theme.adaptive.zeroTier.xl} {
      top: 6px;
      left: -27px;
      height: 48px;
    }
    ${({ theme }) => theme.adaptive.zeroTier.lg} {
      top: 3px;
      left: -23px;
      height: 45px;
    }
    ${({ theme }) => theme.adaptive.zeroTier.slg} {
      left: -20px;
      width: 2px;
      height: 36px;
    }
    ${({ theme }) => theme.adaptive.zeroTier.md} {
      left: -15px;
      width: 1px;
      height: 26px;
    }
    ${({ theme }) => theme.adaptive.zeroTier.smd} {
      left: -10px;
      height: 79px;
    }
    ${({ theme }) => theme.adaptive.zeroTier.sm} {
      left: -8px;
      height: 56px;
    }
    ${({ theme }) => theme.adaptive.zeroTier.ssm} {
      left: -9px;
      height: 42px;
    }
  }
`
