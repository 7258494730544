import React, { useEffect, useRef, useState } from 'react'
import styled, { css } from 'styled-components'
import occeleratorBg from '../../img/olympus/occelerator-bg.svg'
import projectBgSmd from '../../img/olympus/project-bg-smd.svg'
import projectBgSm from '../../img/olympus/project-bg-sm.svg'
import projectBgSsm from '../../img/olympus/project-bg-ssm.svg'
import razerImg from '../../img/olympus/razer.svg'
import launchImg from '../../img/olympus/launch.svg'
import clubBg from '../../img/olympus/club.svg'
import clubBgSm from '../../img/olympus/club-bg-sm.svg'
import clubBgSmd from '../../img/olympus/club-bg-smd.svg'
import rootBg from '../../img/olympus/root-background.jpg'
import signBg from '../../img/olympus/sign-bg.png'
import animBg from '../../img/olympus/anim-background/anim-background.png'
import animBgSlg from '../../img/olympus/anim-background/anim-background_slg.png'
// import animBgMd from "../../img/olympus/anim-background/anim-background_md.png";
// import animBgSmd from "../../img/olympus/anim-background/anim-background_smd.png";
// import animBgSm from "../../img/olympus/anim-background/anim-background_sm.png";
// import animBgSsm from "../../img/olympus/anim-background/anim-background_ssm.png";
import footerLightBg from '../../img/index/footer/footer-bg-light.svg'
import Logo from '../Icons/Logo'
import IndexFooter from '../Index/IndexFooter'
import ModalOlympus from '../Modal/ModalOlympus'

export default function Olympus() {
  const divRef = useRef(null)
  const [opacityValue, setOpacityValue] = useState(0)
  const [modalOpen, setModalOpen] = useState(false)

  useEffect(() => {
    window.addEventListener('scroll', listenToScroll)
    return () => window.removeEventListener('scroll', listenToScroll)
  }, [])

  const listenToScroll = () => {
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop
    setOpacityValue(winScroll / 100)
  }

  return (
    <Root ref={divRef}>
      <ModalOlympus open={modalOpen} onClose={() => setModalOpen(false)} />
      <RootBg />
      <Container>
        <TitleWrap>
          <Title>Occam.fi</Title>
          <SubTitle>Olympus Mons</SubTitle>
        </TitleWrap>
        <Main style={{ opacity: `${opacityValue}` }}>
          <DescTitle>Tier 0</DescTitle>
          <DescWrap>
            <WelcomeText>
              You’ve climbed Everest, broke through the stratosphere, and now
              you’re going galactic — welcome to Olympus Mons.
            </WelcomeText>
            <Description>
              One of the largest summits in a solar system, Olympus Mons is the
              pinnacle of Mars, and the highest attainable Occam.fi tier — so
              significant that it’s unscalable for the average retail investor.
              At tier zero, you’re one of our largest individual holders of the
              OCC token, with over 25,000 OCC held or staked. Congratulations!
              But what does this mean for you as a token holder?
            </Description>
          </DescWrap>
          <About>
            <AboutText>
              In the Olympus Mons tier, you get exclusive access to certain IDOs
              before they launch on OccamRazer:
            </AboutText>
            <AboutProjects>
              <OcceleratorProject>
                <ProjectBg />
                <ProjectTitle>Occelerator projects</ProjectTitle>
                <ProjectText>
                  You can invest early in all those projects which come through
                  Occam.fi’s Occelerator incubator, and launch through an IDO on
                  OccamRazer.
                </ProjectText>
                <ProjectText>
                  You will be given the opportunity to participate in a
                  project’s cap table, getting in on the ground floor of the
                  hottest IDOs for the Cardano ecosystem.
                </ProjectText>
              </OcceleratorProject>
              <OtherProject>
                <ProjectBg reverse={true} />
                <ProjectTitle>Other IDO projects</ProjectTitle>
                <ProjectText>
                  We will work diligently with our other independent IDO
                  partners to include you on the cap table of upcoming IDO
                  launches, even on those projects that do not launch through
                  the Occam.fi Occelerator.
                </ProjectText>
                <ProjectText>
                  This will be at the discretion of the pool originator and
                  project team.
                </ProjectText>
              </OtherProject>
            </AboutProjects>
            <LogoWrapper>
              <Logo light={false} enableHover={false} />
            </LogoWrapper>
            <ClubWrapper>
              <Club>
                <ClubSubTitle>Become a member of the</ClubSubTitle>
                <ClubTitle>Occam.fi Club</ClubTitle>
                <ClubText>
                  You also get admission into the Occam.fi Club, where you will
                  be included in our latest news, upcoming milestones and
                  events, and earn exclusive rewards such as merchandise and
                  access to Occam.fi events.
                </ClubText>
              </Club>
            </ClubWrapper>
          </About>
          <InfoPanel>
            <LeftInfo>
              The only requirement to enter tier zero is to stake{' '}
              <span>25,000 OCC</span> through the OccamRazer panel.
            </LeftInfo>
            <ContactInfo>
              <p>
                In doing so you will also get to our staking reward mechanism,
                as well as becoming instantly eligible for participation in any
                IDO which launches on OccamRazer.
              </p>
              {/* <p>
                Reach out to <a href={"mailto:mark@occam.fi"}>mark@occam.fi</a>{" "}
                for more information on pre-IDO token sales.
              </p> */}
            </ContactInfo>
          </InfoPanel>
          <SignPanel>
            <SignTitle>
              Even though you’ve reached the summit, it’s still only up from
              here.
            </SignTitle>
            <SignButton type="button" onClick={() => setModalOpen(true)}>
              Are you a staker with over 25,000 OCC? Give us a sign now!
            </SignButton>
          </SignPanel>
        </Main>
      </Container>
      <IndexFooter backGround={footerLightBg} color={'#3B414A'} />
      <AnimBg style={{ opacity: 1 - `${opacityValue}` }} />
    </Root>
  )
}

export const olympusHeaderStyles = css`
  width: 1600px;
  margin: 0 auto;
  padding: 50px 0 0 0;
  ${({ theme }) => theme.adaptive.olympus.xl} {
    width: 1400px;
  }
  ${({ theme }) => theme.adaptive.olympus.lg} {
    width: 1200px;
    padding: 45px 0 0 0;
  }
  ${({ theme }) => theme.adaptive.olympus.slg} {
    width: 1028px;
    padding: 32px 0 0 0;
  }
  ${({ theme }) => theme.adaptive.olympus.md} {
    width: 762px;
    padding: 29px 0 0 0;
  }
  ${({ theme }) => theme.adaptive.olympus.smd} {
    width: 540px;
  }
  ${({ theme }) => theme.adaptive.olympus.sm} {
    width: 100%;
    padding: 20px 45px 0 45px;
  }
  ${({ theme }) => theme.adaptive.olympus.ssm} {
    padding: 15px 35px 0 35px;
  }
`

const Root = styled.div`
  width: 100%;
  overflow: hidden;
`

const RootBg = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100vh;
  z-index: -1;
  background: url('${rootBg}') top right no-repeat;
  background-size: cover;
  &:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 5%;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0.0001), #fff);
  }
  ${({ theme }) => theme.adaptive.olympus.smd} {
    background: url('${rootBg}') top left -1000px no-repeat;
  }
`

const AnimBg = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 110vh;
  z-index: -1;
  background-image: url('${animBg}');
  background-position: top right;
  background-repeat: no-repeat;
  background-size: cover;
  transition: opacity 0.1s linear;
  &:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 5%;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0.0001), #fff);
  }
  ${({ theme }) => theme.adaptive.olympus.slg} {
    background-image: url('${animBgSlg}');
  }
  ${({ theme }) => theme.adaptive.olympus.sm} {
    background-position: left -200px bottom;
  }
`

const Container = styled.div`
  padding: 133px 0 0 0;
  width: 1600px;
  margin: 0 auto;
  ${({ theme }) => theme.adaptive.olympus.xl} {
    width: 1400px;
    padding: 107px 0 0 0;
  }
  ${({ theme }) => theme.adaptive.olympus.lg} {
    width: 1200px;
    padding: 89px 0 0 0;
  }
  ${({ theme }) => theme.adaptive.olympus.slg} {
    width: 1028px;
    padding: 70px 0 0 0;
  }
  ${({ theme }) => theme.adaptive.olympus.md} {
    width: 762px;
    padding: 64px 0 0 0;
  }
  ${({ theme }) => theme.adaptive.olympus.smd} {
    width: 540px;
    padding: 38px 0 0 0;
  }
  ${({ theme }) => theme.adaptive.olympus.sm} {
    width: 100%;
    padding: 58px 30px 0 30px;
  }
  ${({ theme }) => theme.adaptive.olympus.ssm} {
    padding: 54px 30px 0 30px;
  }
`

const TitleWrap = styled.div`
  width: 623px;
  margin: 0 auto;
  ${({ theme }) => theme.adaptive.olympus.xl} {
    width: 532px;
  }
  ${({ theme }) => theme.adaptive.olympus.lg} {
    width: 454px;
  }
  ${({ theme }) => theme.adaptive.olympus.slg} {
    width: 400px;
  }
  ${({ theme }) => theme.adaptive.olympus.md} {
    width: 292px;
  }
  ${({ theme }) => theme.adaptive.olympus.smd} {
    width: 241px;
  }
  ${({ theme }) => theme.adaptive.olympus.sm} {
    width: 315px;
  }
  ${({ theme }) => theme.adaptive.olympus.ssm} {
    width: 258px;
  }
`

const Title = styled.div`
  font-family: 'RPBrawl';
  position: relative;
  font-size: 94px;
  line-height: 75px;
  text-align: center;
  background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.2) 0%,
      rgba(255, 255, 255, 0) 100%
    ),
    linear-gradient(180deg, #34404f 0%, #202131 47.4%, #090b0f 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-transform: uppercase;
  ${({ theme }) => theme.adaptive.olympus.xl} {
    font-size: 81px;
    line-height: 66px;
  }
  ${({ theme }) => theme.adaptive.olympus.lg} {
    font-size: 68px;
    line-height: 57px;
  }
  ${({ theme }) => theme.adaptive.olympus.slg} {
    font-size: 60px;
    line-height: 55px;
  }
  ${({ theme }) => theme.adaptive.olympus.md} {
    font-size: 44px;
    line-height: 38px;
  }
  ${({ theme }) => theme.adaptive.olympus.smd} {
    font-size: 36px;
    line-height: 31px;
  }
  ${({ theme }) => theme.adaptive.olympus.sm} {
    font-size: 48px;
    line-height: 41px;
  }
  ${({ theme }) => theme.adaptive.olympus.ssm} {
    font-size: 39px;
    line-height: 34px;
  }
`

const SubTitle = styled.div`
  width: 100%;
  margin-top: 24px;
  font-weight: 300;
  font-size: 43px;
  line-height: 52px;
  text-align: right;
  text-transform: capitalize;
  color: #2e2f3b;
  ${({ theme }) => theme.adaptive.olympus.xl} {
    font-size: 37px;
    line-height: 46px;
    margin-top: 20px;
  }
  ${({ theme }) => theme.adaptive.olympus.lg} {
    font-size: 33px;
    line-height: 40px;
    margin-top: 16px;
  }
  ${({ theme }) => theme.adaptive.olympus.slg} {
    font-size: 28px;
    line-height: 34px;
    margin-top: 10px;
  }
  ${({ theme }) => theme.adaptive.olympus.md} {
    font-size: 21px;
    line-height: 25px;
  }
  ${({ theme }) => theme.adaptive.olympus.smd} {
    font-size: 17px;
    line-height: 21px;
    margin-top: 9px;
  }
  ${({ theme }) => theme.adaptive.olympus.sm} {
    font-size: 22px;
    line-height: 26px;
  }
`

const Main = styled.div`
  font-family: 'NeueHaasDisplay';
  transition: opacity 0.1s linear;
`

const DescTitle = styled.div`
  font-style: normal;
  font-weight: 100;
  font-size: 82px;
  line-height: 91px;
  color: #2e2f3b;
  ${({ theme }) => theme.adaptive.olympus.xl} {
    font-size: 72px;
    line-height: 81px;
  }
  ${({ theme }) => theme.adaptive.olympus.lg} {
    font-size: 62px;
    line-height: 69px;
  }
  ${({ theme }) => theme.adaptive.olympus.slg} {
    font-size: 52px;
    line-height: 58px;
  }
  ${({ theme }) => theme.adaptive.olympus.md} {
    font-size: 40px;
    line-height: 44px;
  }
  ${({ theme }) => theme.adaptive.olympus.smd} {
    margin-top: 16px;
    font-size: 53px;
    line-height: 59px;
  }
  ${({ theme }) => theme.adaptive.olympus.sm} {
    margin-top: 45px;
  }
  ${({ theme }) => theme.adaptive.olympus.ssm} {
    font-size: 40px;
  }
`

const DescWrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 23px;
  ${({ theme }) => theme.adaptive.olympus.xl} {
    margin-top: 18px;
  }
  ${({ theme }) => theme.adaptive.olympus.slg} {
    align-items: center;
  }
  ${({ theme }) => theme.adaptive.olympus.md} {
    margin-top: 10px;
  }
  ${({ theme }) => theme.adaptive.olympus.smd} {
    display: block;
  }
`

const WelcomeText = styled.div`
  width: 542px;
  font-weight: 200;
  font-size: 36px;
  line-height: 130%;
  color: #465062;
  ${({ theme }) => theme.adaptive.olympus.xl} {
    width: 482px;
    font-size: 32px;
  }
  ${({ theme }) => theme.adaptive.olympus.lg} {
    width: 439px;
    font-size: 28px;
  }
  ${({ theme }) => theme.adaptive.olympus.slg} {
    width: 381px;
    font-size: 24px;
  }
  ${({ theme }) => theme.adaptive.olympus.md} {
    width: 272px;
    font-size: 18px;
  }
  ${({ theme }) => theme.adaptive.olympus.smd} {
    width: 100%;
    font-size: 24px;
    margin-bottom: 7px;
  }
  ${({ theme }) => theme.adaptive.olympus.ssm} {
    font-size: 18px;
    margin-bottom: 5px;
  }
`

const Description = styled.div`
  width: 950px;
  font-style: normal;
  font-weight: 300;
  font-size: 25px;
  line-height: 140%;
  color: #465062;
  padding-top: 5px;
  ${({ theme }) => theme.adaptive.olympus.xl} {
    width: 831px;
    font-size: 22px;
    padding-top: 1px;
  }
  ${({ theme }) => theme.adaptive.olympus.lg} {
    width: 712px;
    font-size: 19px;
  }
  ${({ theme }) => theme.adaptive.olympus.slg} {
    width: 611px;
    font-size: 16px;
  }
  ${({ theme }) => theme.adaptive.olympus.md} {
    width: 453px;
    font-size: 12px;
  }
  ${({ theme }) => theme.adaptive.olympus.smd} {
    width: 100%;
    font-size: 16px;
    padding-top: 0;
  }
  ${({ theme }) => theme.adaptive.olympus.ssm} {
    font-size: 12px;
  }
`

const About = styled.div`
  width: 100%;
  margin-top: 81px;
  ${({ theme }) => theme.adaptive.olympus.xl} {
    margin-top: 76px;
  }
  ${({ theme }) => theme.adaptive.olympus.lg} {
    margin-top: 60px;
  }
  ${({ theme }) => theme.adaptive.olympus.md} {
    margin-top: 42px;
  }
  ${({ theme }) => theme.adaptive.olympus.smd} {
    margin-top: 34px;
  }
  ${({ theme }) => theme.adaptive.olympus.sm} {
    margin-top: 23px;
  }
  ${({ theme }) => theme.adaptive.olympus.ssm} {
    margin-top: 18px;
  }
`

const AboutText = styled.div`
  width: 100%;
  text-align: center;
  font-weight: 600;
  font-size: 24px;
  line-height: 140%;
  color: #465062;
  ${({ theme }) => theme.adaptive.olympus.xl} {
    font-size: 21px;
  }
  ${({ theme }) => theme.adaptive.olympus.lg} {
    font-size: 18px;
  }
  ${({ theme }) => theme.adaptive.olympus.slg} {
    font-size: 16px;
  }
  ${({ theme }) => theme.adaptive.olympus.md} {
    font-size: 12px;
  }
  ${({ theme }) => theme.adaptive.olympus.smd} {
    font-size: 16px;
    max-width: 438px;
    margin-left: auto;
    text-align: right;
    margin-bottom: 3px;
  }
  ${({ theme }) => theme.adaptive.olympus.sm} {
    max-width: 315px;
  }
  ${({ theme }) => theme.adaptive.olympus.ssm} {
    max-width: 258px;
    font-size: 12px;
  }
`

const AboutProjects = styled.div`
  position: relative;
  display: flex;
  margin: -3px 0 0;
  width: 100%;
  gap: 54px;
  ${({ theme }) => theme.adaptive.olympus.lg} {
    gap: 42px;
  }
  ${({ theme }) => theme.adaptive.olympus.slg} {
    margin: -8px 0 0;
  }
  ${({ theme }) => theme.adaptive.olympus.smd} {
    display: block;
    margin: 0;
  }
  ${({ theme }) => theme.adaptive.olympus.sm} {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0;
  }
`

const LogoWrapper = styled.div`
  width: 99px;
  margin: -34px auto 0;
  ${({ theme }) => theme.adaptive.olympus.lg} {
    width: 75px;
    margin: -26px auto 0;
  }
  ${({ theme }) => theme.adaptive.olympus.slg} {
    width: 65px;
    margin: -21px auto 0;
  }
  ${({ theme }) => theme.adaptive.olympus.md} {
    width: 48px;
    margin: -15px auto 0;
  }
  ${({ theme }) => theme.adaptive.olympus.smd} {
    width: 65px;
    margin: 33px auto -4px;
  }
  ${({ theme }) => theme.adaptive.olympus.sm} {
    width: 48px;
    margin: 22px auto 0;
  }
  ${({ theme }) => theme.adaptive.olympus.ssm} {
    width: 39px;
    margin: 18px auto 0;
  }
  svg {
    width: 100%;
    height: auto;
  }
`

const Project = css`
  position: relative;
  height: 410px;
  width: 50%;
  ${({ theme }) => theme.adaptive.olympus.xl} {
    height: 372px;
  }
  ${({ theme }) => theme.adaptive.olympus.lg} {
    height: 307px;
  }
  ${({ theme }) => theme.adaptive.olympus.slg} {
    height: 264px;
  }
  ${({ theme }) => theme.adaptive.olympus.md} {
    height: 195px;
  }
  ${({ theme }) => theme.adaptive.olympus.smd} {
    height: 264px;
    width: 100%;
    background: url('${projectBgSmd}') center no-repeat;
    padding: 53px 60px 0 131px !important;
    margin-bottom: 40px;
    &:last-child {
      margin-bottom: 0;
    }
    &:before {
      left: 40px !important;
      top: 59px !important;
    }
  }
  ${({ theme }) => theme.adaptive.olympus.sm} {
    height: 234px;
    width: 352px;
    background: url('${projectBgSm}') center no-repeat !important;
    background-size: contain;
    padding: 34px 18px 0 70px !important;
    margin-bottom: 27px;
    &:before {
      left: 22px !important;
      top: 38px !important;
    }
  }
  ${({ theme }) => theme.adaptive.olympus.ssm} {
    height: 212px;
    width: 289px;
    background: url('${projectBgSsm}') center no-repeat !important;
    background-size: contain;
    padding: 28px 15px 0 57px !important;
    margin-bottom: 22px;
    &:before {
      left: 18px !important;
      top: 31px !important;
    }
  }
`

const ProjectBg = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1;
  background: url('${occeleratorBg}')
    ${({ reverse }) => (reverse ? 'right' : 'left')} center no-repeat;
  background-size: contain;
  transform: ${({ reverse }) => (reverse ? 'scaleX(-1) scaleY(1)' : '')};
  ${({ theme }) => theme.adaptive.olympus.smd} {
    display: none;
  }
`

const OcceleratorProject = styled.div`
  ${Project};
  padding: 83px 70px 80px 217px;
  ${({ theme }) => theme.adaptive.olympus.xl} {
    padding: 76px 43px 0 190px;
  }
  ${({ theme }) => theme.adaptive.olympus.lg} {
    padding: 61px 53px 0 163px;
  }
  ${({ theme }) => theme.adaptive.olympus.slg} {
    padding: 53px 46px 0 141px;
  }
  ${({ theme }) => theme.adaptive.olympus.md} {
    padding: 37px 24px 0 104px;
  }
  &:before {
    content: '';
    position: absolute;
    top: 92px;
    left: 92px;
    width: 45px;
    height: 256px;
    opacity: 0.3;
    background: url('${razerImg}') center no-repeat;
    background-size: contain;
    ${({ theme }) => theme.adaptive.olympus.xl} {
      top: 82px;
      left: 78px;
      width: 45px;
      height: 225px;
    }
    ${({ theme }) => theme.adaptive.olympus.lg} {
      height: 192px;
      width: 33px;
      left: 69px;
      top: 68px;
    }
    ${({ theme }) => theme.adaptive.olympus.slg} {
      height: 165px;
      width: 28px;
      left: 60px;
      top: 59px;
    }
    ${({ theme }) => theme.adaptive.olympus.md} {
      height: 122px;
      width: 21px;
      left: 44px;
      top: 42px;
    }
    ${({ theme }) => theme.adaptive.olympus.smd} {
      height: 165px;
      width: 28px;
    }
    ${({ theme }) => theme.adaptive.olympus.ssm} {
      height: 131px;
      width: 21px;
    }
  }
`

const OtherProject = styled.div`
  ${Project};
  padding: 80px 217px 80px 70px;
  ${({ theme }) => theme.adaptive.olympus.xl} {
    padding: 76px 190px 0 43px;
  }
  ${({ theme }) => theme.adaptive.olympus.lg} {
    padding: 61px 163px 0 53px;
  }
  ${({ theme }) => theme.adaptive.olympus.slg} {
    padding: 53px 141px 0 46px;
  }
  ${({ theme }) => theme.adaptive.olympus.md} {
    padding: 37px 69px 0 30px;
  }
  &:before {
    content: '';
    position: absolute;
    top: 95px;
    right: 87px;
    width: 31px;
    height: 245px;
    background: url('${launchImg}') center no-repeat;
    background-size: contain;
    ${({ theme }) => theme.adaptive.olympus.lg} {
      height: 183px;
      width: 23px;
      right: 65px;
      top: 71px;
    }
    ${({ theme }) => theme.adaptive.olympus.slg} {
      height: 158px;
      width: 20px;
      right: 56px;
      top: 61px;
    }
    ${({ theme }) => theme.adaptive.olympus.md} {
      height: 117px;
      width: 15px;
      right: 40px;
      top: 43px;
    }
    ${({ theme }) => theme.adaptive.olympus.smd} {
      height: 158px;
      width: 20px;
    }
    ${({ theme }) => theme.adaptive.olympus.ssm} {
      height: 144px;
      width: 18px;
    }
  }
`

const ProjectTitle = styled.div`
  font-weight: 300;
  font-size: 35px;
  line-height: 47px;
  color: #465062;
  margin-bottom: 21px;
  ${({ theme }) => theme.adaptive.olympus.xl} {
    font-size: 31px;
    line-height: 130%;
    margin-bottom: 19px;
  }
  ${({ theme }) => theme.adaptive.olympus.lg} {
    font-size: 27px;
    margin-bottom: 14px;
  }
  ${({ theme }) => theme.adaptive.olympus.slg} {
    font-size: 24px;
    margin-bottom: 12px;
  }
  ${({ theme }) => theme.adaptive.olympus.md} {
    font-size: 18px;
    margin-bottom: 9px;
  }
  ${({ theme }) => theme.adaptive.olympus.smd} {
    font-size: 24px;
    margin-bottom: 12px;
  }
  ${({ theme }) => theme.adaptive.olympus.ssm} {
    font-size: 18px;
  }
`

const ProjectText = styled.div`
  width: 485px;
  font-weight: 300;
  font-size: 22px;
  line-height: 31px;
  color: #465062;
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0;
  }
  ${({ theme }) => theme.adaptive.olympus.xl} {
    width: auto;
    font-size: 19px;
    line-height: 140%;
    margin-bottom: 19px;
  }
  ${({ theme }) => theme.adaptive.olympus.lg} {
    font-size: 16px;
    margin-bottom: 15px;
  }
  ${({ theme }) => theme.adaptive.olympus.slg} {
    font-size: 14px;
    margin-bottom: 11px;
  }
  ${({ theme }) => theme.adaptive.olympus.md} {
    font-size: 12px;
    margin-bottom: 8px;
  }
  ${({ theme }) => theme.adaptive.olympus.smd} {
    font-size: 14px;
    margin-bottom: 15px;
  }
  ${({ theme }) => theme.adaptive.olympus.ssm} {
    font-size: 12px;
    margin-bottom: 8px;
  }
`

const ClubWrapper = styled.div`
  ${({ theme }) => theme.adaptive.olympus.sm} {
    display: flex;
    justify-content: center;
  }
`

const Club = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  height: 148px;
  padding: 28px 61px 23px;
  background: url('${clubBg}') center no-repeat;
  background-size: cover;
  margin-top: -12px;
  ${({ theme }) => theme.adaptive.olympus.xl} {
    height: 130px;
    padding: 22px 61px 23px;
  }
  ${({ theme }) => theme.adaptive.olympus.lg} {
    height: 111px;
    padding: 18px 45px 23px;
  }
  ${({ theme }) => theme.adaptive.olympus.slg} {
    height: 95px;
    padding: 14px 36px 11px;
  }
  ${({ theme }) => theme.adaptive.olympus.md} {
    height: 71px;
    padding: 10px 26px 11px;
  }
  ${({ theme }) => theme.adaptive.olympus.smd} {
    display: block;
    height: 185px;
    background: url(${clubBgSmd}) no-repeat center;
    background-size: contain;
    padding: 29px 29px 11px;
  }
  ${({ theme }) => theme.adaptive.olympus.sm} {
    width: 352px;
    height: 213px;
    background: url(${clubBgSm}) no-repeat center;
    background-size: contain;
    padding: 34px 19px 0;
    flex-shrink: 0;
  }
  ${({ theme }) => theme.adaptive.olympus.ssm} {
    width: 289px;
    height: 175px;
    margin-top: -5px;
  }
`

const ClubSubTitle = styled.div`
  width: 100%;
  font-weight: 300;
  font-size: 19px;
  line-height: 140%;
  color: #939ba9;
  ${({ theme }) => theme.adaptive.olympus.xl} {
    font-size: 17px;
  }
  ${({ theme }) => theme.adaptive.olympus.lg} {
    font-size: 15px;
  }
  ${({ theme }) => theme.adaptive.olympus.slg} {
    font-size: 14px;
  }
  ${({ theme }) => theme.adaptive.olympus.md} {
    font-size: 12px;
  }
  ${({ theme }) => theme.adaptive.olympus.smd} {
    font-size: 14px;
    margin-bottom: 10px;
  }
  ${({ theme }) => theme.adaptive.olympus.sm} {
    margin-bottom: 0;
  }
  ${({ theme }) => theme.adaptive.olympus.ssm} {
    font-size: 12px;
  }
`

const ClubTitle = styled.div`
  width: 379px;
  font-weight: 100;
  font-size: 67px;
  line-height: 130%;
  color: #465062;
  ${({ theme }) => theme.adaptive.olympus.xl} {
    font-size: 58px;
  }
  ${({ theme }) => theme.adaptive.olympus.lg} {
    font-size: 50px;
    width: auto;
    line-height: normal;
  }
  ${({ theme }) => theme.adaptive.olympus.slg} {
    font-size: 43px;
  }
  ${({ theme }) => theme.adaptive.olympus.md} {
    font-size: 32px;
  }
  ${({ theme }) => theme.adaptive.olympus.smd} {
    font-size: 36px;
  }
  ${({ theme }) => theme.adaptive.olympus.ssm} {
    font-size: 32px;
    margin-bottom: 7px;
  }
`

const ClubText = styled.div`
  width: 1000px;
  margin-left: 68px;
  font-weight: 300;
  font-size: 22px;
  line-height: 140%;
  color: #465062;
  ${({ theme }) => theme.adaptive.olympus.xl} {
    width: 873px;
    font-size: 19px;
    margin-left: 10px;
  }
  ${({ theme }) => theme.adaptive.olympus.lg} {
    width: 739px;
    font-size: 16px;
    margin-left: 60px;
  }
  ${({ theme }) => theme.adaptive.olympus.slg} {
    width: 648px;
    font-size: 14px;
    margin-left: 53px;
  }
  ${({ theme }) => theme.adaptive.olympus.md} {
    width: 492px;
    font-size: 12px;
    margin-left: 39px;
  }
  ${({ theme }) => theme.adaptive.olympus.smd} {
    width: 100%;
    font-size: 14px;
    margin-left: 0;
  }
  ${({ theme }) => theme.adaptive.olympus.ssm} {
    font-size: 12px;
  }
`

const InfoPanel = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 75px;
  ${({ theme }) => theme.adaptive.olympus.xl} {
    margin-top: 65px;
  }
  ${({ theme }) => theme.adaptive.olympus.lg} {
    margin-top: 55px;
  }
  ${({ theme }) => theme.adaptive.olympus.md} {
    margin-top: 35px;
  }
  ${({ theme }) => theme.adaptive.olympus.smd} {
    display: block;
    margin-top: 43px;
  }
  ${({ theme }) => theme.adaptive.olympus.ssm} {
    margin-top: 31px;
  }
  &:before {
    content: '';
    position: absolute;
    width: 1675px;
    height: 874px;
    top: -40px;
    left: 50%;
    transform: translateX(-50%);
    background: url('${signBg}') center no-repeat;
    background-size: cover;
    z-index: -1;
    ${({ theme }) => theme.adaptive.olympus.xl} {
      top: -55px;
      width: 1466px;
      height: 765px;
    }
    ${({ theme }) => theme.adaptive.olympus.lg} {
      top: -50px;
      width: 1256px;
      height: 656px;
    }
    ${({ theme }) => theme.adaptive.olympus.slg} {
      top: -35px;
      width: 1082px;
      height: 564px;
    }
    ${({ theme }) => theme.adaptive.olympus.md} {
      top: -25px;
      width: 803px;
      height: 419px;
    }
    ${({ theme }) => theme.adaptive.olympus.smd} {
      top: 155px;
      width: 524px;
      height: 273px;
    }
    ${({ theme }) => theme.adaptive.olympus.sm} {
      bottom: -450px;
      top: unset;
      width: 1082px;
      height: 564px;
    }
    ${({ theme }) => theme.adaptive.olympus.ssm} {
      bottom: -340px;
      width: 803px;
      height: 419px;
    }
  }
`

const LeftInfo = styled.div`
  width: 542px;
  font-weight: 200;
  font-size: 36px;
  line-height: 130%;
  color: #465062;
  ${({ theme }) => theme.adaptive.olympus.xl} {
    font-size: 32px;
    width: 482px;
  }
  ${({ theme }) => theme.adaptive.olympus.lg} {
    font-size: 27px;
    width: 428px;
  }
  ${({ theme }) => theme.adaptive.olympus.slg} {
    font-size: 23px;
    width: 375px;
  }
  ${({ theme }) => theme.adaptive.olympus.md} {
    width: 266px;
    font-size: 18px;
  }
  ${({ theme }) => theme.adaptive.olympus.smd} {
    width: 100%;
    font-size: 23px;
    margin-bottom: 14px;
  }
  ${({ theme }) => theme.adaptive.olympus.ssm} {
    font-size: 18px;
    margin-bottom: 6px;
  }
  span {
    font-weight: 600;
  }
`

const ContactInfo = styled.div`
  position: relative;
  width: 950px;
  font-weight: 300;
  font-size: 25px;
  line-height: 140%;
  color: #465062;
  ${({ theme }) => theme.adaptive.olympus.xl} {
    width: 830px;
    font-size: 22px;
  }
  ${({ theme }) => theme.adaptive.olympus.lg} {
    width: 707px;
    font-size: 19px;
  }
  ${({ theme }) => theme.adaptive.olympus.slg} {
    width: 611px;
    font-size: 16px;
  }
  ${({ theme }) => theme.adaptive.olympus.md} {
    width: 453px;
    font-size: 12px;
  }
  ${({ theme }) => theme.adaptive.olympus.smd} {
    width: 100%;
    font-size: 16px;
  }
  ${({ theme }) => theme.adaptive.olympus.ssm} {
    font-size: 12px;
  }
  p {
    margin: 0;
  }
  p:last-child {
    margin-top: 29px;
    ${({ theme }) => theme.adaptive.olympus.lg} {
      margin-top: 24px;
    }
    ${({ theme }) => theme.adaptive.olympus.slg} {
      margin-top: 20px;
    }
    ${({ theme }) => theme.adaptive.olympus.md} {
      margin-top: 17px;
    }
    ${({ theme }) => theme.adaptive.olympus.sm} {
      line-height: 180%;
    }
  }
  a {
    padding: 14px 22px;
    background: rgba(196, 196, 196, 0.25);
    border: 1px solid rgba(46, 47, 59, 0.2);
    box-sizing: border-box;
    border-radius: 8px;
    text-align: center;
    font-size: 25px;
    line-height: 140%;
    color: #2e2f3b;
    margin: 0 13px;
    ${({ theme }) => theme.adaptive.olympus.xl} {
      padding: 12px 19px;
      font-size: 22px;
      border-radius: 7px;
    }
    ${({ theme }) => theme.adaptive.olympus.lg} {
      padding: 10px 16px;
      font-size: 19px;
      border-radius: 6px;
    }
    ${({ theme }) => theme.adaptive.olympus.slg} {
      padding: 8px 15px;
      font-size: 16px;
      border-radius: 5px;
    }
    ${({ theme }) => theme.adaptive.olympus.md} {
      padding: 6px 11px;
      font-size: 12px;
      margin: 0 8px;
      border-radius: 4px;
    }
    ${({ theme }) => theme.adaptive.olympus.smd} {
      font-size: 16px;
      border-radius: 5px;
      padding: 9px 15px;
    }
    ${({ theme }) => theme.adaptive.olympus.sm} {
      padding: 5px 9px;
    }
    ${({ theme }) => theme.adaptive.olympus.ssm} {
      font-size: 12px;
      padding: 4px 8px;
      margin: 0 5px;
    }
  }
`

const SignPanel = styled.div`
  position: relative;
  margin-top: 178px;
  ${({ theme }) => theme.adaptive.olympus.xl} {
    margin-top: 142px;
  }
  ${({ theme }) => theme.adaptive.olympus.lg} {
    margin-top: 127px;
  }
  ${({ theme }) => theme.adaptive.olympus.slg} {
    margin-top: 105px;
  }
  ${({ theme }) => theme.adaptive.olympus.md} {
    margin-top: 83px;
  }
  ${({ theme }) => theme.adaptive.olympus.smd} {
    margin-top: 105px;
  }
  ${({ theme }) => theme.adaptive.olympus.sm} {
    margin-top: 147px;
  }
  ${({ theme }) => theme.adaptive.olympus.ssm} {
    margin-top: 105px;
  }
`

const SignTitle = styled.div`
  font-weight: 200;
  font-size: 53px;
  line-height: 110%;
  text-align: center;
  color: #2e2f3b;
  ${({ theme }) => theme.adaptive.olympus.xl} {
    font-size: 46px;
  }
  ${({ theme }) => theme.adaptive.olympus.lg} {
    font-size: 40px;
  }
  ${({ theme }) => theme.adaptive.olympus.slg} {
    font-size: 34px;
  }
  ${({ theme }) => theme.adaptive.olympus.md} {
    font-size: 25px;
  }
  ${({ theme }) => theme.adaptive.olympus.smd} {
    font-size: 34px;
  }
  ${({ theme }) => theme.adaptive.olympus.sm} {
    font-size: 25px;
    max-width: 315px;
    margin: 0 auto;
  }
  ${({ theme }) => theme.adaptive.olympus.ssm} {
    max-width: 258px;
  }
`

const SignButton = styled.button`
  display: block;
  margin: 0 auto;
  margin-top: 22px;
  width: 673px;
  padding: 10px;
  background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.2) 0%,
      rgba(255, 255, 255, 0) 100%
    ),
    linear-gradient(180deg, #34404f 0%, #202131 47.4%, #090b0f 100%);
  border-radius: 8px;
  font-weight: 300;
  font-size: 25px;
  line-height: 140%;
  text-align: center;
  color: #ffffff;
  ${({ theme }) => theme.adaptive.olympus.xl} {
    font-size: 22px;
    width: 589px;
    margin-top: 20px;
    padding: 9px;
  }
  ${({ theme }) => theme.adaptive.olympus.lg} {
    font-size: 19px;
    width: 505px;
    margin-top: 17px;
    padding: 8px;
  }
  ${({ theme }) => theme.adaptive.olympus.slg} {
    font-size: 16px;
    width: 435px;
    padding: 7px;
    margin-top: 14px;
  }
  ${({ theme }) => theme.adaptive.olympus.md} {
    width: 323px;
    font-size: 12px;
    margin-top: 7px;
    padding: 5px;
  }
  ${({ theme }) => theme.adaptive.olympus.smd} {
    font-size: 16px;
    width: 435px;
    padding: 7px;
    margin-top: 14px;
  }
  ${({ theme }) => theme.adaptive.olympus.sm} {
    font-size: 14px;
    padding: 6px 40px;
    max-width: 315px;
    margin: 0 auto;
    margin-top: 20px;
  }
  ${({ theme }) => theme.adaptive.olympus.ssm} {
    font-size: 12px;
    max-width: 259px;
  }
`
