import React from 'react'
import styled, { css } from 'styled-components'
import bg from 'img/incubator/board/bg.svg'
import bgImg from 'img/incubator/board/bg-img.png'
import bgImgMd from 'img/incubator/board/bg-img-md.png'
import borderImg from 'img/incubator/board/border.svg'
import firstItemBg from 'img/incubator/board/first-item-bg.svg'
import secondItemBg from 'img/incubator/board/second-item-bg.svg'
import thirdItemBg from 'img/incubator/board/third-item-bg.svg'
import fourthItemBg from 'img/incubator/board/fourth-item-bg.svg'
import logoImg from 'img/incubator/board/logo.svg'
import logoImgMd from 'img/incubator/board/logo-md.svg'
import logoImgSmd from 'img/incubator/board/logo-smd.svg'
import logoImgSm from 'img/incubator/board/logo-sm.svg'
import btnIcon from 'img/incubator/btn-icon.svg'
import { ReactComponent as BtnImg } from 'img/incubator/board/btn.svg'
import FirstClipPath from '../clips/FirstClip'
import ThirdClipPath from '../clips/ThirdClip'
import FourthClipPath from '../clips/FourthClip'
import SecondClipPath from '../clips/SecondClip'
import { MAIL_LINK } from 'configs/app.config'

const Board: React.FunctionComponent = () => {
  return (
    <Root>
      <Bg />
      <BgImg />
      <Border />
      <Title>value</Title>
      <List>
        <Row>
          <Item>
            Technology consulting
            <ItemBg />
            <ItemNumber>01</ItemNumber>
          </Item>
          <Item>
            Marketing and PR roadmap and execution
            <ItemBg isTransform={true} />
            <ItemNumber isRight={true}>02</ItemNumber>
          </Item>
        </Row>
        <Row>
          <Item>
            Community building, engagement and management
            <ItemBg />
            <ItemNumber>03</ItemNumber>
          </Item>
          <Item>
            Consulting on token structuring
            <ItemBg isTransform={true} />
            <ItemNumber isRight={true}>04</ItemNumber>
          </Item>
        </Row>
        <Row>
          <Item>
            Distributional and Utility tokenomics
            <ItemBg />
            <ItemNumber>05</ItemNumber>
          </Item>
          <Item>
            Powerful APIs to accelerate prototyping and GTM
            <ItemBg isTransform={true} />
            <ItemNumber isRight={true}>06</ItemNumber>
          </Item>
        </Row>
        <Row>
          <Item>
            Secondary market listing strategy and execution
            <ItemBg />
            <ItemNumber>07</ItemNumber>
          </Item>
          <Item>
            Captable management and investor relations
            <ItemBg isTransform={true} />
            <ItemNumber isRight={true}>08</ItemNumber>
          </Item>
        </Row>
        <Row>
          <Item>
            Network sharing and introductions
            <ItemBg />
            <ItemNumber>09</ItemNumber>
          </Item>
        </Row>
        <Logo />
      </List>
      <Button>
        <BtnImg />
        <BtnText href={MAIL_LINK} target={'_blank'}>
          contact our account managers
        </BtnText>
        <BtnIcon />
      </Button>
      <FirstClipPath />
      <SecondClipPath />
      <ThirdClipPath />
      <FourthClipPath />
    </Root>
  )
}

export default Board

const Root = styled.div`
  position: relative;
  width: 100%;
  height: 1744px;
  padding-top: 78px;
  z-index: 2;
  ${({ theme }) => theme.adaptive.zeroTier.xl} {
    height: 1518px;
    padding-top: 81px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.lg} {
    height: 1321px;
    padding-top: 53px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.slg} {
    height: 1294px;
    padding-top: 89px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.md} {
    height: 1394px;
    padding-top: 36px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.smd} {
    height: 1023px;
    padding-top: 55px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.sm} {
    height: 1020px;
    padding-top: 63px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.ssm} {
    height: 912px;
    padding-top: 29px;
  }
`

const Bg = styled.div`
  position: absolute;
  top: 0;
  left: -171px;
  width: 1871px;
  height: 1744px;
  background: url('${bg}') center no-repeat;
  background-size: cover;
  ${({ theme }) => theme.adaptive.zeroTier.xl} {
    left: -141px;
    width: 1673px;
    height: 1518px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.lg} {
    height: 1321px;
    left: -136px;
    width: 1418px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.slg} {
    height: 1294px;
    width: 1390px;
    left: -225px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.md} {
    height: 1394px;
    width: 1496px;
    left: -581px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.smd} {
    height: 1024px;
    width: 1100px;
    left: -480px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.sm} {
    height: 1020px;
    width: 1097px;
    left: -672px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.ssm} {
    height: 913px;
    width: 981px;
    left: -610px;
  }
`

const BgImg = styled.div`
  position: absolute;
  top: 0;
  left: -171px;
  width: 1871px;
  height: 1744px;
  background: url('${bgImg}') center no-repeat;
  background-size: cover;
  ${({ theme }) => theme.adaptive.zeroTier.xl} {
    left: -141px;
    width: 1673px;
    height: 1518px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.lg} {
    height: 1321px;
    left: -136px;
    width: 1418px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.slg} {
    height: 1294px;
    width: 1390px;
    left: -225px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.md} {
    height: 1394px;
    width: 1496px;
    left: -580px;
    background: url('${bgImgMd}') center no-repeat;
    background-size: cover;
  }
  ${({ theme }) => theme.adaptive.zeroTier.smd} {
    height: 1024px;
    width: 1100px;
    left: -480px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.sm} {
    height: 1020px;
    width: 1097px;
    left: -672px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.ssm} {
    height: 913px;
    width: 981px;
    left: -610px;
  }
`

const Border = styled.div`
  position: absolute;
  top: 29px;
  left: -214px;
  width: 1887px;
  height: 1572px;
  background: url('${borderImg}') center no-repeat;
  background-size: cover;
  ${({ theme }) => theme.adaptive.zeroTier.xl} {
    top: 25px;
    left: -178px;
    width: 1643px;
    height: 1369px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.lg} {
    top: 29px;
    left: -166px;
    width: 1422px;
    height: 1184px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.slg} {
    top: 28px;
    left: -253px;
    width: 1393px;
    height: 1160px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.md} {
    top: 22px;
    left: -614px;
    width: 1507px;
    height: 1257px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.smd} {
    top: 15px;
    left: -505px;
    width: 1108px;
    height: 923px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.sm} {
    top: 16px;
    left: -697px;
    width: 1104px;
    height: 920px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.ssm} {
    top: 16px;
    left: -618px;
    width: 974px;
    height: 812px;
  }
`

const Title = styled.div`
  position: relative;
  font-weight: 100;
  font-size: 206px;
  line-height: 140%;
  text-align: center;
  letter-spacing: 50px;
  text-transform: uppercase;
  background: linear-gradient(
    91.03deg,
    #dcffcc 4.9%,
    rgba(218, 255, 201, 0.2) 90.12%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  ${({ theme }) => theme.adaptive.zeroTier.xl} {
    font-size: 180px;
    letter-spacing: 43px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.lg} {
    font-size: 156px;
    letter-spacing: 38px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.smd} {
    font-size: 80px;
    letter-spacing: 22px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.ssm} {
    font-size: 70px;
  }
`

const List = styled.div`
  position: relative;
  margin-top: 450px;
  ${({ theme }) => theme.adaptive.zeroTier.xl} {
    margin-top: 389px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.lg} {
    margin-top: 333px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.slg} {
    margin-top: 303px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.md} {
    margin-top: 193px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.smd} {
    margin-top: 154px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.sm} {
    margin-top: 161px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.ssm} {
    margin-top: 120px;
  }
`

const Logo = styled.div`
  position: absolute;
  top: -451px;
  left: -157px;
  width: 1830px;
  height: 992px;
  background: url('${logoImg}') center no-repeat;
  background-size: cover;
  ${({ theme }) => theme.adaptive.zeroTier.xl} {
    top: -395px;
    left: -140px;
    width: 1609px;
    height: 870px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.lg} {
    top: -343px;
    left: -120px;
    width: 1374px;
    height: 750px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.slg} {
    left: -96px;
    top: -312px;
    width: 1219px;
    height: 682px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.md} {
    left: -56px;
    top: -215px;
    width: 931px;
    height: 215px;
    background: url('${logoImgMd}') center no-repeat;
    background-size: cover;
  }

  ${({ theme }) => theme.adaptive.zeroTier.smd} {
    left: -61px;
    top: -137px;
    width: 638px;
    height: 137px;
    background: url('${logoImgSmd}') center no-repeat;
    background-size: cover;
  }
  ${({ theme }) => theme.adaptive.zeroTier.sm} {
    left: -181px;
    top: -133px;
    width: 691px;
    height: 133px;
    background: url('${logoImgSm}') center no-repeat;
    background-size: cover;
  }
  ${({ theme }) => theme.adaptive.zeroTier.ssm} {
    left: -208px;
  }
`

const Item = styled.div`
  position: relative;
  width: 773px;
  height: 128px;
  padding-top: 65px;

  font-weight: 300;
  font-size: 28px;
  line-height: 34px;

  text-align: center;

  color: #ffffff;
  z-index: 1;
  ${({ theme }) => theme.adaptive.zeroTier.xl} {
    width: 676px;
    height: 112px;
    padding-top: 56px;

    font-size: 24px;
    line-height: 29px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.lg} {
    width: 580px;
    height: 96px;
    padding-top: 48px;

    font-size: 21px;
    line-height: 25px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.slg} {
    width: 526px;
    height: 87px;
    padding-top: 44px;

    font-size: 19px;
    line-height: 23px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.md} {
    width: 816px;
    height: 62px !important;
    padding-top: 20px !important;

    font-size: 24px;
    line-height: 29px;
    margin-bottom: 32px;
    z-index: 1;
  }
  ${({ theme }) => theme.adaptive.zeroTier.smd} {
    width: 100%;
    height: 40px !important;
    padding-top: 12px !important;

    font-size: 13px;
    line-height: 16px;
    margin-bottom: 20px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.sm} {
    font-size: 12px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.ssm} {
    height: auto !important;
    margin-bottom: 20px;
    padding: 12px 40px !important;
  }
`

const ItemNumber = styled.div<{ isRight?: boolean }>`
  position: absolute;
  ${({ isRight }) =>
    isRight
      ? css`
          right: 26px;
        `
      : css`
          left: 26px;
        `}
  top: 40px;

  font-weight: 300;
  font-size: 44px;
  line-height: 53px;
  letter-spacing: 4px;

  color: #ffffff;
  ${({ theme }) => theme.adaptive.zeroTier.lg} {
    ${({ isRight }) =>
      isRight
        ? css`
            right: 20px;
          `
        : css`
            left: 20px;
          `}
    top: 34px;

    font-size: 38px;
    line-height: 46px;
    letter-spacing: 3px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.slg} {
    ${({ isRight }) =>
      isRight
        ? css`
            right: 18px;
          `
        : css`
            left: 18px;
          `}
    top: 31px;

    font-size: 34px;
    line-height: 41px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.md} {
    ${({ isRight }) =>
      isRight
        ? css`
            right: 31px;
          `
        : css`
            left: 31px;
          `}
    top: 14px !important;
  }
  ${({ theme }) => theme.adaptive.zeroTier.smd} {
    ${({ isRight }) =>
      isRight
        ? css`
            right: 19px;
          `
        : css`
            left: 19px;
          `}
    top: 9px !important;

    font-size: 18px;
    line-height: 22px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.sm} {
    ${({ isRight }) =>
      isRight
        ? css`
            right: 12px;
          `
        : css`
            left: 12px;
          `}
  }
  ${({ theme }) => theme.adaptive.zeroTier.ssm} {
    ${({ isRight }) =>
      isRight
        ? css`
            right: 8px;
          `
        : css`
            left: 8px;
          `}
    top: 7px !important;
  }
`

const ItemBg = styled.div<{ isTransform?: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  clip-path: url('#first');
  backdrop-filter: blur(6px);
  background: url('${firstItemBg}') center no-repeat;
  background-size: cover;
  transform: ${({ isTransform }) =>
    isTransform ? 'matrix(0, 1, 1, 0, 0, 0) rotate(270deg)' : ''};
  z-index: -1;
  ${({ theme }) => theme.adaptive.zeroTier.xl} {
    clip-path: url('#firstXl');
  }
  ${({ theme }) => theme.adaptive.zeroTier.lg} {
    clip-path: url('#firstLg');
  }
  ${({ theme }) => theme.adaptive.zeroTier.slg} {
    clip-path: url('#firstSlg');
  }
  ${({ theme }) => theme.adaptive.zeroTier.md} {
    background: rgba(160, 255, 150, 0.02);
    border: 1px solid rgba(160, 255, 150, 0.5) !important;
    border-radius: 10px !important;
    backdrop-filter: blur(8px) !important;
    clip-path: unset !important;
  }
  ${({ theme }) => theme.adaptive.zeroTier.smd} {
    border-radius: 6px !important;
  }
`

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
  ${({ theme }) => theme.adaptive.zeroTier.xl} {
    margin-bottom: 25px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.lg} {
    margin-bottom: 22px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.slg} {
    margin-bottom: 20px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.md} {
    display: block;
    margin-bottom: 0;
    ${Item} {
      &:nth-child(2) {
        ${ItemBg} {
          background: rgba(160, 255, 150, 0.04);
        }
      }
    }
  }
  &:nth-child(2) {
    margin-bottom: 38px;
    ${({ theme }) => theme.adaptive.zeroTier.xl} {
      margin-bottom: 33px;
    }
    ${({ theme }) => theme.adaptive.zeroTier.lg} {
      margin-bottom: 29px;
    }
    ${({ theme }) => theme.adaptive.zeroTier.slg} {
      margin-bottom: 27px;
    }
    ${Item} {
      height: 94px;
      padding-top: 32px;
      ${({ theme }) => theme.adaptive.zeroTier.xl} {
        height: 82px;
        padding-top: 25px;
      }
      ${({ theme }) => theme.adaptive.zeroTier.lg} {
        height: 70px;
        padding-top: 21px;
      }
      ${({ theme }) => theme.adaptive.zeroTier.slg} {
        height: 63px;
        padding-top: 19px;
      }
      ${({ theme }) => theme.adaptive.zeroTier.md} {
        ${ItemBg} {
          background: rgba(160, 255, 150, 0.06);
        }
        &:nth-child(2) {
          ${ItemBg} {
            background: rgba(160, 255, 150, 0.08);
          }
        }
      }
    }
    ${ItemNumber} {
      top: 15px;
      ${({ theme }) => theme.adaptive.zeroTier.xl} {
        top: 15px;
      }
      ${({ theme }) => theme.adaptive.zeroTier.lg} {
        top: 12px;
      }
      ${({ theme }) => theme.adaptive.zeroTier.slg} {
        top: 11px;
      }
    }
    ${ItemBg} {
      clip-path: url('#second');
      background: url('${secondItemBg}') center no-repeat;
      background-size: cover;
      ${({ theme }) => theme.adaptive.zeroTier.xl} {
        clip-path: url('#secondXl');
      }
      ${({ theme }) => theme.adaptive.zeroTier.lg} {
        clip-path: url('#secondLg');
      }
      ${({ theme }) => theme.adaptive.zeroTier.slg} {
        clip-path: url('#secondSlg');
      }
    }
  }
  &:nth-child(3) {
    ${Item} {
      height: 94px;
      padding-top: 34px;
      ${({ theme }) => theme.adaptive.zeroTier.xl} {
        height: 82px;
        padding-top: 29px;
      }
      ${({ theme }) => theme.adaptive.zeroTier.lg} {
        height: 70px;
        padding-top: 24px;
      }
      ${({ theme }) => theme.adaptive.zeroTier.slg} {
        height: 63px;
        padding-top: 21px;
      }
      ${({ theme }) => theme.adaptive.zeroTier.md} {
        ${ItemBg} {
          background: rgba(160, 255, 150, 0.1);
        }
        &:nth-child(2) {
          ${ItemBg} {
            background: rgba(160, 255, 150, 0.12);
          }
        }
      }
    }
    ${ItemNumber} {
      top: 17px;
      ${({ theme }) => theme.adaptive.zeroTier.xl} {
        top: 17px;
      }
      ${({ theme }) => theme.adaptive.zeroTier.lg} {
        top: 14px;
      }
      ${({ theme }) => theme.adaptive.zeroTier.slg} {
        top: 12px;
      }
    }
    ${ItemBg} {
      background: url('${thirdItemBg}') center no-repeat;
      background-size: cover;
      clip-path: url('#third');
      ${({ theme }) => theme.adaptive.zeroTier.xl} {
        clip-path: url('#thirdXl');
      }
      ${({ theme }) => theme.adaptive.zeroTier.lg} {
        clip-path: url('#thirdLg');
      }
      ${({ theme }) => theme.adaptive.zeroTier.slg} {
        clip-path: url('#thirdSlg');
      }
    }
  }
  &:nth-child(4) {
    margin-bottom: 0;
    ${Item} {
      padding-top: 32px;
      ${({ theme }) => theme.adaptive.zeroTier.xl} {
        padding-top: 25px;
      }
      ${({ theme }) => theme.adaptive.zeroTier.lg} {
        padding-top: 22px;
      }
      ${({ theme }) => theme.adaptive.zeroTier.slg} {
        padding-top: 20px;
      }
      ${({ theme }) => theme.adaptive.zeroTier.md} {
        ${ItemBg} {
          background: rgba(160, 255, 150, 0.14);
        }
        &:nth-child(2) {
          ${ItemBg} {
            background: rgba(160, 255, 150, 0.16);
          }
        }
      }
    }
    ${ItemNumber} {
      top: 15px;
      ${({ theme }) => theme.adaptive.zeroTier.xl} {
        top: 15px;
      }
      ${({ theme }) => theme.adaptive.zeroTier.lg} {
        top: 13px;
      }
      ${({ theme }) => theme.adaptive.zeroTier.slg} {
        top: 12px;
      }
    }
    ${ItemBg} {
      background: url('${fourthItemBg}') center no-repeat;
      background-size: cover;
      clip-path: url('#fourth');
      ${({ theme }) => theme.adaptive.zeroTier.xl} {
        clip-path: url('#fourthXl');
      }
      ${({ theme }) => theme.adaptive.zeroTier.lg} {
        clip-path: url('#fourthLg');
      }
      ${({ theme }) => theme.adaptive.zeroTier.slg} {
        clip-path: url('#fourthSlg');
      }
    }
  }
  &:nth-child(5) {
    justify-content: center;
    margin-bottom: 0;
    ${Item} {
      height: 94px;
      padding-top: 34px;
      ${({ theme }) => theme.adaptive.zeroTier.xl} {
        height: 82px;
        padding-top: 28px;
      }
      ${({ theme }) => theme.adaptive.zeroTier.lg} {
        height: 70px;
        padding-top: 23px;
      }
      ${({ theme }) => theme.adaptive.zeroTier.slg} {
        height: 63px;
        padding-top: 20px;
      }
      ${({ theme }) => theme.adaptive.zeroTier.md} {
        ${ItemBg} {
          background: rgba(160, 255, 150, 0.18);
        }
      }
    }
    ${ItemNumber} {
      top: 17px;
      ${({ theme }) => theme.adaptive.zeroTier.xl} {
        top: 17px;
      }
      ${({ theme }) => theme.adaptive.zeroTier.lg} {
        top: 13px;
      }
      ${({ theme }) => theme.adaptive.zeroTier.slg} {
        top: 11px;
      }
    }
    ${ItemBg} {
      background: rgba(160, 255, 150, 0.18);
      border: 1px solid rgba(160, 255, 150, 0.5);
      border-radius: 15px;
      clip-path: unset;
      ${({ theme }) => theme.adaptive.zeroTier.xl} {
        border-radius: 14px;
      }
      ${({ theme }) => theme.adaptive.zeroTier.lg} {
        border-radius: 11px;
      }
      ${({ theme }) => theme.adaptive.zeroTier.slg} {
        border-radius: 10px;
      }
    }
  }
`

const Button = styled.button`
  position: absolute;
  bottom: 40px;
  left: 974px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 568px;
  height: 63px;
  border: 0;
  ${({ theme }) => theme.adaptive.zeroTier.xl} {
    bottom: 31px;
    left: 854px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.lg} {
    bottom: 25px;
    left: 717px;
    width: 459px;
    height: 51px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.slg} {
    bottom: 23px;
    left: 608px;
    height: 47px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.md} {
    left: 333px;
    height: 52px;
    bottom: 26px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.smd} {
    left: 132px;
    height: 40px;
    bottom: 23px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.sm} {
    left: 50%;
    transform: translateX(-50%);
    bottom: 20px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.ssm} {
    width: 300px;
    height: 35px;
    bottom: 17px;
  }
  svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    filter: drop-shadow(0px 2.24631px 4.49262px rgba(0, 0, 0, 0.03));
  }
`

const BtnText = styled.a`
  position: relative;
  display: block;
  font-weight: 600;
  font-size: 21px;
  line-height: 36px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #50515f;
  ${({ theme }) => theme.adaptive.zeroTier.lg} {
    font-size: 18px;
    line-height: 26px;
    letter-spacing: 0.7px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.slg} {
    font-size: 16px;
    line-height: 24px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.md} {
    font-size: 18px;
    line-height: 26px;
    letter-spacing: 0.7px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.smd} {
    font-size: 13px;
    line-height: 17px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.ssm} {
    font-size: 11px;
    line-height: 15px;
  }
`

const BtnIcon = styled.div`
  position: relative;
  width: 23px;
  height: 22px;
  background: url('${btnIcon}') center no-repeat;
  background-size: cover;
  margin-left: 8px;
  ${({ theme }) => theme.adaptive.zeroTier.lg} {
    width: 17px;
    height: 16px;
    margin-left: 7px;
  }
`
