import { ReactComponent as OccamX } from 'img/incubator/partners/occamx.svg'
import { ReactComponent as ChoiseLogo } from 'img/incubator/partners/choise.svg'
import { ReactComponent as CornucopiasLogo } from 'img/incubator/partners/cornucopias.svg'
import { ReactComponent as GeniusLogo } from 'img/incubator/partners/genius.svg'
import { ReactComponent as StarlyLogo } from 'img/incubator/partners/starly.svg'
import { ReactComponent as IagonLogo } from 'img/incubator/partners/iagon.svg'
import { ReactComponent as DKLogo } from 'img/incubator/partners/dk.svg'
import { ReactComponent as NunetLogo } from 'img/incubator/partners/nunet.svg'
import { ReactComponent as BlueshiftLogo } from 'img/incubator/partners/blueshift.svg'

const partnersList = {
  firstRow: [
    {
      link: 'https://www.iagon.com/',
      getLogo: () => <IagonLogo />,
    },
    {
      link: 'https://occam-x.fi/',
      getLogo: () => <OccamX />,
    },
    {
      link: 'https://www.cornucopias.io/',
      getLogo: () => <CornucopiasLogo />,
    },
    {
      link: 'https://www.geniusyield.co/',
      getLogo: () => <GeniusLogo />,
    },
  ],
  secondRow: [
    {
      link: 'https://choise.com/ ',
      getLogo: () => <ChoiseLogo />,
    },
    {
      link: 'https://starly.io/',
      getLogo: () => <StarlyLogo />,
    },
    {
      link: 'https://duelistking.com/',
      getLogo: () => <DKLogo />,
    },
    {
      link: 'https://nunet.io/',
      getLogo: () => <NunetLogo />,
    },

    {
      link: 'https://blueshift.fi/',
      getLogo: () => <BlueshiftLogo />,
    },
  ],
  thirdRow: [],
}

export default partnersList
