import React, { useState } from 'react'
import styled from 'styled-components'
import partnersList from 'store/partnersIncubator'
import rowImg from 'img/incubator/partners/img/row.svg'
import rowImgMd from 'img/incubator/partners/img/row-md.svg'
import { ReactComponent as Cfund } from 'img/incubator/partners/cfund.svg'
import { ReactComponent as Emurgo } from 'img/incubator/partners/emurgo.svg'
import { ReactComponent as Spartan } from 'img/incubator/partners/spartan.svg'
import bg from 'img/incubator/partners/bg.jpg'
import bgMd from 'img/incubator/partners/bg-md.jpg'
import useWindowSize from 'helpers/utils/useWindowSize'
import Ticker from 'react-ticker'
import PageVisibility from 'react-page-visibility'
import border from '../../../img/incubator/deck/border.svg'

const Partners: React.FunctionComponent = () => {
  const [isPlay, setIsPlay] = useState(true)
  const [pageIsVisible, setPageIsVisible] = useState(true)

  const handleVisibilityChange = (
    isVisible: boolean | ((prevState: boolean) => boolean)
  ) => {
    setPageIsVisible(isVisible)
  }
  const mobileList = [...partnersList.firstRow, ...partnersList.secondRow]
  const { width } = useWindowSize()

  const ticker = (
    <TickerWrap>
      <PageVisibility onChange={handleVisibilityChange}>
        {pageIsVisible && (
          <Ticker move={isPlay}>
            {({ index }) => (
              <Row onMouseEnter={onEnter} onMouseLeave={onLeave}>
                {mobileList.map((partner, idx) => {
                  return (
                    <Item key={idx} href={partner.link} target={'_blank'}>
                      {partner.getLogo()}
                    </Item>
                  )
                })}
              </Row>
            )}
          </Ticker>
        )}
      </PageVisibility>
    </TickerWrap>
  )

  const mobile = mobileList.map((partner, idx) => {
    if (idx < 16) {
      return (
        <Item key={idx} href={partner.link} target={'_blank'}>
          {partner.getLogo()}
        </Item>
      )
    }
  })

  const desktop = (
    <>
      <Row>
        {partnersList.firstRow.map((partner, idx) => {
          return (
            <Item key={idx} href={partner.link} target={'_blank'}>
              {partner.getLogo()}
            </Item>
          )
        })}
      </Row>
      <Row>
        {partnersList.secondRow.map((partner, idx) => {
          return (
            <Item key={idx + 6} href={partner.link} target={'_blank'}>
              {partner.getLogo()}
            </Item>
          )
        })}
      </Row>
    </>
  )

  const onEnter = () => {
    setIsPlay(false)
  }

  const onLeave = () => {
    setIsPlay(true)
  }

  return (
    <Root>
      <RowBg />
      <Border />
      <Title>
        We work with the <span>best</span>
      </Title>
      <List>
        {width <= 830 && ticker}
        {width > 1100 ? desktop : width <= 1100 && width > 830 ? mobile : ''}
        <InvestBlock>
          <InvestTitle>VCs investing into our projects</InvestTitle>
          <InvestRow>
            <Item href={'https://cfund.vc/'} target={'_blank'}>
              <Cfund />
            </Item>
            <Line />
            <Item href={'https://emurgo.io/'} target={'_blank'}>
              <Emurgo />
            </Item>
            <Line />
            <Item
              href={'https://www.spartangroup.io/index.html'}
              target={'_blank'}
            >
              <Spartan />
            </Item>
          </InvestRow>
        </InvestBlock>
      </List>
    </Root>
  )
}

export default Partners

const Root = styled.div`
  position: relative;
  width: 100%;
  padding-top: 116px;
  z-index: 1;
  ${({ theme }) => theme.adaptive.zeroTier.xl} {
    padding-top: 102px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.lg} {
    padding-top: 86px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.slg} {
    padding-top: 71px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.md} {
    padding-top: 118px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.smd} {
    padding-top: 61px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.sm} {
    padding-top: 53px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.ssm} {
    padding-top: 36px;
  }
  &:before {
    content: '';
    position: absolute;
    width: 2592px;
    height: 1963px;
    top: -555px;
    left: 50%;
    transform: translateX(-50%);
    background: url('${bg}') center no-repeat;
    background-size: cover;
    ${({ theme }) => theme.adaptive.zeroTier.xl} {
      width: 2268px;
      height: 1718px;
      top: -470px;
    }
    ${({ theme }) => theme.adaptive.zeroTier.lg} {
      width: 1944px;
      height: 1472px;
      top: -399px;
    }
    ${({ theme }) => theme.adaptive.zeroTier.slg} {
      width: 1745px;
      height: 1321px;
      top: -364px;
    }
    ${({ theme }) => theme.adaptive.zeroTier.md} {
      width: 1161px;
      height: 879px;
      top: -90px;
      background: url('${bgMd}') center no-repeat;
      background-size: cover;
    }
    ${({ theme }) => theme.adaptive.zeroTier.smd} {
      top: -320px;
    }
    ${({ theme }) => theme.adaptive.zeroTier.ssm} {
      top: -350px;
    }
  }
`

const Title = styled.div`
  position: relative;
  font-weight: 300;
  font-size: 68px;
  line-height: 82px;
  text-align: center;
  color: #3a3d4a;
  ${({ theme }) => theme.adaptive.zeroTier.lg} {
    font-size: 56px;
    line-height: 67px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.md} {
    font-size: 54px;
    line-height: 65px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.smd} {
    font-size: 34px;
    line-height: 41px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.sm} {
    font-size: 26px;
    line-height: 31px;
  }
  span {
    color: #62a85d;
  }
`

const List = styled.div`
  position: relative;
  margin-top: 80px;
  ${({ theme }) => theme.adaptive.zeroTier.lg} {
    margin-top: 65px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.slg} {
    margin-top: 80px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.md} {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 69px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.smd} {
    margin-top: 0;
  }
`

const Row = styled.div`
  position: revert;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 80%;
  margin: 0 auto 80px;
  ${({ theme }) => theme.adaptive.zeroTier.xl} {
    margin: 0 auto 70px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.lg} {
    margin: 0 auto 61px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.slg} {
    margin: 0 auto 56px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.smd} {
    width: 100%;
    margin-top: 25px;
    margin-bottom: 0;
    justify-content: flex-start;
  }
  ${({ theme }) => theme.adaptive.zeroTier.sm} {
    margin-top: 13px;
  }
  &:nth-child(2) {
    width: 80%;
    margin: 0 auto 80px;
    ${({ theme }) => theme.adaptive.zeroTier.xl} {
      margin: 0 auto 70px;
    }
    ${({ theme }) => theme.adaptive.zeroTier.lg} {
      margin: 0 auto 61px;
    }
    ${({ theme }) => theme.adaptive.zeroTier.slg} {
      margin: 0 auto 56px;
    }
  }
  &:last-child {
    margin-bottom: 0;
  }
`

const Item = styled.a`
  display: flex;
  align-items: center;
  max-width: 163px;
  max-height: 49px;
  ${({ theme }) => theme.adaptive.zeroTier.xl} {
    max-width: 143px;
    max-height: 43px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.lg} {
    max-width: 130px;
    max-height: 38px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.slg} {
    max-width: 114px;
    max-height: 33px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.md} {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 33.33%;
    max-width: unset;
    max-height: 49px;
    margin-bottom: 65px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.smd} {
    max-width: 144px;
    width: auto;
    margin-bottom: 0;
    margin-right: 47px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.sm} {
    max-width: 101px;
    margin-right: 32px;
  }
  svg {
    display: block;
    height: auto;
    ${({ theme }) => theme.adaptive.zeroTier.xl} {
      max-width: 176px;
    }
    ${({ theme }) => theme.adaptive.zeroTier.lg} {
      max-width: 151px;
    }
    ${({ theme }) => theme.adaptive.zeroTier.slg} {
      max-width: 136px;
    }
    ${({ theme }) => theme.adaptive.zeroTier.md} {
      display: block;
      max-width: 165px;
    }
    ${({ theme }) => theme.adaptive.zeroTier.smd} {
      max-width: 144px;
    }
    ${({ theme }) => theme.adaptive.zeroTier.sm} {
      max-width: 101px;
    }
  }
`

const InvestBlock = styled.div`
  position: relative;
  margin: 0 auto;
  margin-top: 150px;
  width: 1594px;
  height: 156px;
  padding: 44px 96px;
  background: url('${rowImg}') center no-repeat;
  background-size: cover;
  ${({ theme }) => theme.adaptive.zeroTier.xl} {
    margin-top: 106px;
    width: 1394px;
    height: 136px;
    padding: 38px 84px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.lg} {
    margin-top: 104px;
    width: 1197px;
    height: 117px;
    padding: 33px 69px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.slg} {
    margin-top: 89px;
    width: 1082px;
    height: 105px;
    padding: 29px 62px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.md} {
    position: relative;
    margin-top: 89px;
    width: 100%;
    height: 303px;
    padding: 60px 65px;
    background: unset;
  }
  ${({ theme }) => theme.adaptive.zeroTier.smd} {
    margin-top: 142px;
    width: 100%;
    height: auto;
    padding: 43px 42px 0;
  }
  ${({ theme }) => theme.adaptive.zeroTier.sm} {
    margin-top: 110px;
    padding: 38px 22px 0;
  }
`

const RowBg = styled.div`
  display: none;
  ${({ theme }) => theme.adaptive.zeroTier.md} {
    display: block;
    left: 50%;
    transform: translateX(-50%);
    bottom: -228px;
    position: absolute;
    width: 1100px;
    height: 532px;
    background: url('${rowImgMd}') center no-repeat;
    background-size: cover;
  }
  ${({ theme }) => theme.adaptive.zeroTier.smd} {
    bottom: -240px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.sm} {
    width: 920px;
    height: 444px;
    bottom: -183px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.ssm} {
    width: 622px;
    height: 300px;
    bottom: -49px;
  }
`

const InvestTitle = styled.div`
  position: relative;
  font-weight: 600;
  font-size: 24px;
  line-height: 130%;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #62a85d;
  ${({ theme }) => theme.adaptive.zeroTier.xl} {
    font-size: 21px;
    letter-spacing: 1.75px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.lg} {
    font-size: 20px;
    letter-spacing: 1px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.slg} {
    font-size: 18px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.md} {
    font-size: 28px;
    margin-bottom: 60px;
    padding-left: 27px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.smd} {
    font-size: 18px;
    margin-bottom: 50px;
    padding-left: 19px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.sm} {
    width: 241px;
    margin-bottom: 47px;
  }
  &:before {
    content: '';
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: -40px;
    width: 2px;
    height: 50px;
    background: #7cb36c;
    ${({ theme }) => theme.adaptive.zeroTier.xl} {
      left: -35px;
      height: 44px;
    }
    ${({ theme }) => theme.adaptive.zeroTier.lg} {
      left: -27px;
      height: 37px;
    }
    ${({ theme }) => theme.adaptive.zeroTier.slg} {
      left: -24px;
      height: 33px;
    }
    ${({ theme }) => theme.adaptive.zeroTier.md} {
      left: 0;
      height: 41px;
    }
    ${({ theme }) => theme.adaptive.zeroTier.smd} {
      left: 0;
      height: 41px;
    }
    ${({ theme }) => theme.adaptive.zeroTier.sm} {
      height: 38px;
    }
  }
`

const Line = styled.div`
  width: 1px;
  height: 50px;
  background: #24282a;
  opacity: 0.4;
  ${({ theme }) => theme.adaptive.zeroTier.xl} {
    height: 43px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.lg} {
    height: 37px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.slg} {
    height: 34px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.md} {
    height: 43px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.smd} {
    display: none;
  }
`

const InvestRow = styled.div`
  position: absolute;
  top: 73px;
  right: 80px;
  width: 773px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${({ theme }) => theme.adaptive.zeroTier.xl} {
    top: 62px;
    right: 70px;
    width: 675px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.lg} {
    top: 55px;
    right: 44px;
    width: 610px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.slg} {
    top: 48px;
    right: 55px;
    width: 523px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.md} {
    position: relative;
    top: unset;
    right: unset;
    width: 751px;
    ${Item} {
      width: auto;
      margin: 0;
    }
  }
  ${({ theme }) => theme.adaptive.zeroTier.smd} {
    flex-wrap: wrap;
    justify-content: center;
    width: 453px;
    margin: 0 auto;
    ${Item} {
      &:nth-child(1) {
        margin-right: 77px;
      }
      margin-bottom: 43px;
    }
    ${Line} {
      &:nth-child(2) {
        display: block;
        margin-right: 65px;
        height: 35px;
        margin-bottom: 43px;
      }
    }
  }
  ${({ theme }) => theme.adaptive.zeroTier.sm} {
    width: 301px;
    ${Item} {
      &:nth-child(1) {
        margin-right: 41px;
      }
      margin-bottom: 33px;
    }
    ${Line} {
      &:nth-child(2) {
        display: block;
        margin-right: 31px;
        height: 30px;
        margin-bottom: 33px;
      }
    }
  }
  ${({ theme }) => theme.adaptive.zeroTier.ssm} {
    width: 261px;
    ${Item} {
      &:nth-child(1) {
        margin-right: 30px;
      }
      margin-bottom: 29px;
    }
    ${Line} {
      &:nth-child(2) {
        display: block;
        margin-right: 20px;
        height: 26px;
        margin-bottom: 29px;
      }
    }
  }
  svg {
    display: block;
    max-width: 150px;
    max-height: 41px;
    ${({ theme }) => theme.adaptive.zeroTier.xl} {
      max-width: 131px;
      max-height: 36px;
    }
    ${({ theme }) => theme.adaptive.zeroTier.lg} {
      max-width: 108px;
      max-height: 30px;
    }
    ${({ theme }) => theme.adaptive.zeroTier.slg} {
      max-width: 98px;
      max-height: 27px;
    }
    ${({ theme }) => theme.adaptive.zeroTier.md} {
      max-width: 131px;
      max-height: 36px;
    }
    ${({ theme }) => theme.adaptive.zeroTier.smd} {
      max-width: 167px;
      max-height: 49px;
    }
    ${({ theme }) => theme.adaptive.zeroTier.sm} {
      max-width: 124px;
      max-height: 36px;
    }
    ${({ theme }) => theme.adaptive.zeroTier.ssm} {
      max-width: 108px;
      max-height: 31px;
    }
  }
`

const TickerWrap = styled.div`
  position: absolute;
  width: 100vw;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
`

const Border = styled.div`
  position: absolute;
  bottom: -430px;
  left: -499px;
  width: 2591px;
  height: 794px;
  background: url('${border}') center no-repeat;
  background-size: cover;
  ${({ theme }) => theme.adaptive.zeroTier.xl} {
    bottom: -380px;
    left: -438px;
    width: 2268px;
    height: 694px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.lg} {
    bottom: -330px;
    left: -372px;
    width: 1944px;
    height: 595px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.slg} {
    bottom: -301px;
    left: -327px;
    width: 1745px;
    height: 534px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.md} {
    bottom: -250px;
    left: -380px;
    width: 1745px;
    height: 534px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.smd} {
    bottom: -237px;
    left: -434px;
    width: 1477px;
    height: 452px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.sm} {
    bottom: -166px;
    left: -334px;
    width: 1002px;
    height: 306px;
  }
`
