import React from 'react'
import ReactTooltip from 'react-tooltip'

export default function OlympusTooltip({ text }) {
  return (
    <ReactTooltip
      html={true}
      id="olympus"
      backgroundColor="#37393D"
      border={false}
      className="olympus-tooltip"
      place="right"
      offset={{
        right: 0,
      }}
    />
  )
}
