import React from 'react'
import styled from 'styled-components'

export default function Footer() {
  return (
    <FooterContent>
      <FooterText>Interchain Smart DeFi Layer</FooterText>
    </FooterContent>
  )
}

const FooterContent = styled.footer`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 30px 55px;
  ${({ theme }) => theme.adaptive.sm} {
    padding: 30px 0 15px;
  }
`

const FooterText = styled.span`
  color: #1c1f2b;
  font-size: 22px;
  ${({ theme }) => theme.adaptive.sm} {
    display: none;
  }
`
