import React from 'react'
import styled, { css } from 'styled-components'

export default function GiveUs({ modalOpen }) {
  return (
    <Root>
      <Row>
        <Text>Are you a staker with over 33,000 OCC?</Text>
        <Button onClick={modalOpen}>Join us now!</Button>
      </Row>
    </Root>
  )
}

const Root = styled.div`
  position: relative;
  padding: 81px 0 0 0;
  z-index: 1;
  ${({ theme }) => theme.adaptive.zeroTier.xl} {
    padding: 70px 0 0 0;
  }
  ${({ theme }) => theme.adaptive.zeroTier.lg} {
    padding: 60px 0 0 0;
  }
  ${({ theme }) => theme.adaptive.zeroTier.slg} {
    padding: 52px 0 0 0;
  }
  ${({ theme }) => theme.adaptive.zeroTier.md} {
    padding: 38px 0 0 0;
  }
  ${({ theme }) => theme.adaptive.zeroTier.smd} {
    padding: 47px 0 0 0;
  }
  ${({ theme }) => theme.adaptive.zeroTier.sm} {
    padding: 41px 0 0 0;
  }
  ${({ theme }) => theme.adaptive.zeroTier.ssm} {
    padding: 32px 0 0 0;
  }
`

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ theme }) => theme.adaptive.zeroTier.smd} {
    display: block;
    width: 370px;
    margin: 0 auto;
  }
  ${({ theme }) => theme.adaptive.zeroTier.sm} {
    width: 195px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.ssm} {
    width: 142px;
  }
`

const Text = styled.div`
  font-weight: 300;
  font-size: 35px;
  line-height: 140%;
  color: #ffffff;
  opacity: 0.8;
  ${({ theme }) => theme.adaptive.zeroTier.xl} {
    font-size: 31px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.lg} {
    font-size: 27px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.slg} {
    font-size: 23px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.md} {
    font-size: 17px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.smd} {
    font-size: 23px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.ssm} {
    font-size: 17px;
  }
`

const Button = styled.div`
  padding: 10px 50px;
  font-weight: 300;
  font-size: 25px;
  line-height: 140%;
  text-align: center;
  color: #ffffff;
  margin-left: 54px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 5px;
  cursor: pointer;
  ${({ theme }) => theme.adaptive.zeroTier.xl} {
    padding: 8px 40px;
    font-size: 22px;
    margin-left: 47px;
    border-radius: 4px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.lg} {
    padding: 8px 35px;
    font-size: 19px;
    margin-left: 25px;
    border-radius: 3px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.slg} {
    padding: 6px 32px;
    font-size: 19px;
    margin-left: 17px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.md} {
    padding: 5px 12px;
    font-size: 12px;
    margin-left: 26px;
    border-radius: 2px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.smd} {
    width: 185px;
    margin: 20px auto 0;
    padding: 6px 31px;
    font-size: 16px;
    border-radius: 3px;
  }
  ${({ theme }) => theme.adaptive.zeroTier.sm} {
    margin: 10px auto 0;
  }
  ${({ theme }) => theme.adaptive.zeroTier.ssm} {
    width: 138px;
    margin: 15px auto 0;
    padding: 5px 12px;
    font-size: 12px;
    border-radius: 2px;
  }
`
