import React, { useEffect } from 'react'
import { createPortal } from 'react-dom'
import { noop } from 'lodash'
import styled, { css } from 'styled-components'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'

const portal = document.getElementById('root')

export interface ModalProps {
  onClose?(): void
  children?: React.ReactNode
  open: boolean
  background?: string
  noOutside?: boolean
  isFullSize?: boolean
  center?: boolean
  isHidden?: boolean
}

function Modal(props: ModalProps) {
  const {
    open = false,
    onClose = noop,
    children = null,
    background = '',
    noOutside = false,
    isFullSize = false,
    center = true,
    isHidden = false,
  } = props
  const body = document.body

  useEffect(() => {
    open ? disableBodyScroll(body) : enableBodyScroll(body)
  }, [open])

  if (!portal || !open) return null

  const onCloseModal = () => {
    if (open) onClose()
  }

  const handleModalClick = (e: {
    target: { tagName: string; className: string | string[] }
  }) => {
    if (noOutside) return
    if (e.target.tagName != 'svg') {
      if (e.target.className.includes('modal-wrapper')) return onClose()
    }
  }

  return createPortal(
    <ModalWrapper
      center={center}
      background={background}
      className="modal-wrapper"
      onClick={() => handleModalClick}
      hidden={isHidden}
    >
      <ModalContent isFullSize={isFullSize}>{children}</ModalContent>,
    </ModalWrapper>,
    portal
  )
}

export default Modal

const ModalContent = styled.div<{ isFullSize: boolean }>`
  position: relative;
  ${({ isFullSize }) => {
    if (isFullSize) {
      return css`
        width: 100%;
        height: 100%;
      `
    }
  }};
`

const ModalWrapper = styled.div<{
  hidden?: boolean
  center: boolean
  background: string
}>`
  position: fixed;
  width: 100%;
  height: 100%;
  min-height: 100%;
  top: 0;
  left: 0;
  display: flex;
  ${({ center }) => (center ? 'align-items: center' : 'padding-top:16px')};
  justify-content: center;
  z-index: 6;
  overflow: ${({ hidden }) => (hidden ? 'hidden' : 'auto')};
  background: ${({ background }) =>
    background
      ? background
      : 'linear-gradient(180deg, rgba(80,93,109, .9) 0%, rgba(49,51,74, .9) 47.4%, rgba(21,24,33, .9) 100%)'};
`
