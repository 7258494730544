import React from 'react'
import styled from 'styled-components'
import { TitleCss, Container } from 'theme/theme'
import { ReactComponent as BtnBg } from 'img/rebranding/governance/btn-bg.svg'
import { ReactComponent as BtnIcon } from 'img/rebranding/apps/btn-icon.svg'
import bgImg from 'img/rebranding/governance/bg.svg'
import bgImgSm from 'img/rebranding/governance/bg-sm.svg'
import monkImg from 'img/rebranding/governance/monk.png'
import monkImg2x from 'img/rebranding/governance/monk-2x.png'
import monkImgWebp from 'img/rebranding/governance/monk@1x.webp'
import monkImgWebp2x from 'img/rebranding/governance/monk@2x.webp'
import sphereImg from 'img/rebranding/governance/sphere.png'
import sphereImg2x from 'img/rebranding/governance/sphere-2x.png'
import sphereImgWebp from 'img/rebranding/governance/sphere@1x.webp'
import sphereImgWebp2x from 'img/rebranding/governance/sphere@2x.webp'
import firstImgLeft from 'img/rebranding/governance/first-left-img.png'
import firstImgLeft2x from 'img/rebranding/governance/first-left-img-2x.png'
import firstImgLeftWebp from 'img/rebranding/governance/first-left-img@1x.webp'
import firstImgLeftWebp2x from 'img/rebranding/governance/first-left-img@2x.webp'
import secondImgLeft2x from 'img/rebranding/governance/seconf-left-img-2x.png'
import secondImgLeftWebp from 'img/rebranding/governance/second-left-img@1x.webp'
import secondImgLeftWebp2x from 'img/rebranding/governance/second-left-img@2x.webp'
import secondImgLeft from 'img/rebranding/governance/seconf-left-img.png'
import thirdImgLeft2x from 'img/rebranding/governance/third-left-img-2x.png'
import thirdImgLeftWebp from 'img/rebranding/governance/third-left-img@1x.webp'
import thirdImgLeftWebp2x from 'img/rebranding/governance/third-left-img@2x.webp'
import thirdImgLeft from 'img/rebranding/governance/third-left-img.png'
import firstImgRight2x from 'img/rebranding/governance/first-right-img-2x.png'
import firstImgRightWebp from 'img/rebranding/governance/first-right-img@1x.webp'
import firstImgRightWebp2x from 'img/rebranding/governance/first-right-img@2x.webp'
import firstImgRight from 'img/rebranding/governance/first-right-img.png'
import secondImgRight2x from 'img/rebranding/governance/second-right-img-2x.png'
import secondImgRightWebp from 'img/rebranding/governance/second-right-img@1x.webp'
import secondImgRightWebp2x from 'img/rebranding/governance/second-right-img@2x.webp'
import secondImgRight from 'img/rebranding/governance/second-right-img.png'
import thirdImgRight2x from 'img/rebranding/governance/third-right-img-2x.png'
import thirdImgRightWebp from 'img/rebranding/governance/third-right-img@1x.webp'
import thirdImgRightWebp2x from 'img/rebranding/governance/third-right-img@2x.webp'
import thirdImgRight from 'img/rebranding/governance/third-right-img.png'
import { ReactComponent as ItemBorder } from 'img/rebranding/governance/item-bg.svg'
import cloudsImg from 'img/rebranding/governance/clouds.png'
import cloudsImgWebp from 'img/rebranding/governance/clouds@1x.webp'
import cloudsImgSm from 'img/rebranding/governance/clouds-sm.png'
import cloudsImgSmWebp from 'img/rebranding/governance/clouds-sm@1x.webp'
import useWindowSize from 'helpers/utils/useWindowSize'

const Governance: React.FunctionComponent = () => {
  const { width } = useWindowSize()

  return (
    <Root>
      <Container>
        <TextBlock>
          <Clouds />
          {width > 790 && <Round />}
          <Title>Governance NFTs</Title>
          <SubTitle>
            May the Mutable/upgradeable/Soult-bound NFTs be with you on the
            Occam Monk Path
          </SubTitle>
          {width <= 790 && (
            <Round>
              <RightSide>
                <Item>
                  <ItemBorder />
                  <ItemImg>
                    <source
                      type="image/webp"
                      srcSet={`${firstImgRightWebp}, ${firstImgRightWebp2x}`}
                    />
                    <source
                      type="image/png"
                      srcSet={`${firstImgRight}, ${firstImgRight2x}`}
                    />
                    <img src={firstImgRight} alt="content" loading="lazy" />
                  </ItemImg>
                  <ItemName>NFT</ItemName>
                  <ItemNumber>69HDYL3</ItemNumber>
                </Item>
                <Item>
                  <ItemBorder />
                  <ItemImg>
                    <source
                      type="image/webp"
                      srcSet={`${secondImgRightWebp}, ${secondImgRightWebp2x}`}
                    />
                    <source
                      type="image/png"
                      srcSet={`${secondImgRight}, ${secondImgRight2x}`}
                    />
                    <img src={secondImgRight} alt="content" loading="lazy" />
                  </ItemImg>
                  <ItemNumber>739HFR1</ItemNumber>
                </Item>
                <Item>
                  <ItemBorder />
                  <ItemImg>
                    <source
                      type="image/webp"
                      srcSet={`${thirdImgRightWebp}, ${thirdImgRightWebp2x}`}
                    />
                    <source
                      type="image/png"
                      srcSet={`${thirdImgRight}, ${thirdImgRight2x}`}
                    />
                    <img src={thirdImgRight} alt="content" loading="lazy" />
                  </ItemImg>
                  <ItemNumber>03^fd43</ItemNumber>
                </Item>
              </RightSide>
            </Round>
          )}
          {width > 790 && (
            <Monk>
              <LeftSide>
                <Item>
                  <ItemBorder />
                  <ItemImg>
                    <source
                      type="image/webp"
                      srcSet={`${firstImgLeftWebp}, ${firstImgLeftWebp2x}`}
                    />
                    <source
                      type="image/png"
                      srcSet={`${firstImgLeft}, ${firstImgLeft2x}`}
                    />
                    <img src={firstImgLeft} alt="content" loading="lazy" />
                  </ItemImg>
                  <ItemName>NFT</ItemName>
                  <ItemNumber>65^UD35</ItemNumber>
                </Item>
                <Item>
                  <ItemBorder />
                  <ItemImg>
                    <source
                      type="image/webp"
                      srcSet={`${secondImgLeftWebp}, ${secondImgLeftWebp2x}`}
                    />
                    <source
                      type="image/png"
                      srcSet={`${secondImgLeft}, ${secondImgLeft2x}`}
                    />
                    <img src={secondImgLeft} alt="content" loading="lazy" />
                  </ItemImg>
                  <ItemNumber>62^fd48</ItemNumber>
                </Item>
                <Item>
                  <ItemBorder />
                  <ItemImg>
                    <source
                      type="image/webp"
                      srcSet={`${thirdImgLeftWebp}, ${thirdImgLeftWebp2x}`}
                    />
                    <source
                      type="image/png"
                      srcSet={`${thirdImgLeft}, ${thirdImgLeft2x}`}
                    />
                    <img src={thirdImgLeft} alt="content" loading="lazy" />
                  </ItemImg>
                  <ItemNumber>26^NM83</ItemNumber>
                </Item>
              </LeftSide>
              <RightSide>
                <Item>
                  <ItemBorder />
                  <ItemImg>
                    <source
                      type="image/webp"
                      srcSet={`${firstImgRightWebp}, ${firstImgRightWebp2x}`}
                    />
                    <source
                      type="image/png"
                      srcSet={`${firstImgRight}, ${firstImgRight2x}`}
                    />
                    <img src={firstImgRight} alt="content" loading="lazy" />
                  </ItemImg>
                  <ItemName>NFT</ItemName>
                  <ItemNumber>69HDYL3</ItemNumber>
                </Item>
                <Item>
                  <ItemBorder />
                  <ItemImg>
                    <source
                      type="image/webp"
                      srcSet={`${secondImgRightWebp}, ${secondImgRightWebp2x}`}
                    />
                    <source
                      type="image/png"
                      srcSet={`${secondImgRight}, ${secondImgRight2x}`}
                    />
                    <img src={secondImgRight} alt="content" loading="lazy" />
                  </ItemImg>
                  <ItemNumber>739HFR1</ItemNumber>
                </Item>
                <Item>
                  <ItemBorder />
                  <ItemImg>
                    <source
                      type="image/webp"
                      srcSet={`${thirdImgRightWebp}, ${thirdImgRightWebp2x}`}
                    />
                    <source
                      type="image/png"
                      srcSet={`${thirdImgRight}, ${thirdImgRight2x}`}
                    />
                    <img src={thirdImgRight} alt="content" loading="lazy" />
                  </ItemImg>
                  <ItemNumber>03^fd43</ItemNumber>
                </Item>
              </RightSide>
              <Sphere />
            </Monk>
          )}
          <Desc>
            <span>OccamDAO employs an innovative governance model</span> built
            around mutable SBT and integrated directly into our community
            discord.
          </Desc>
          <Row>
            <Button
              href={'https://hackmd.io/@OccamDAO/Governance'}
              target={'_blank'}
            >
              <ButtonBg>
                <BtnBg />
              </ButtonBg>
              Learn More
              <ButtonIcon>
                <BtnIcon />
              </ButtonIcon>
            </Button>
            <Text>Create a guild and drive the interchain innovation.</Text>
          </Row>
        </TextBlock>
      </Container>
    </Root>
  )
}

export default Governance

const Root = styled.div`
  position: relative;
  width: 100%;
  padding-bottom: 271px;
  ${({ theme }) => theme.adaptive.newHomePage.lg} {
    padding-bottom: 232px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.slg} {
    padding-bottom: 200px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.md} {
    padding-bottom: 148px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.sm} {
    padding-bottom: 105px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.smm} {
    padding-bottom: 100px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.xs} {
    padding-bottom: 42px;
  }
`

const TextBlock = styled.div`
  position: relative;
  margin: 0 auto;
  width: 782px;
  ${({ theme }) => theme.adaptive.newHomePage.lg} {
    width: 670px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.slg} {
    width: 577px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.md} {
    width: 428px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.sm} {
    width: 520px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.smm} {
    width: 350px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.xs} {
    width: 260px;
  }
`

const Title = styled.div`
  ${TitleCss};
  text-align: center;
  letter-spacing: -0.03em;
  margin-bottom: 53px;
  ${({ theme }) => theme.adaptive.newHomePage.lg} {
    margin-bottom: 45px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.slg} {
    margin-bottom: 39px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.md} {
    margin-bottom: 29px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.sm} {
    margin-bottom: 11px;
    text-align: left;
  }
  ${({ theme }) => theme.adaptive.newHomePage.xs} {
    margin-bottom: 9px;
  }
`

const SubTitle = styled.div`
  font-family: 'NeueHaasDisplay';
  font-weight: 200;
  font-size: 40px;
  line-height: 120%;
  text-align: center;
  letter-spacing: -0.01em;
  color: #27303b;
  width: 100%;
  margin: 0 auto;
  ${({ theme }) => theme.adaptive.newHomePage.lg} {
    font-size: 34px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.slg} {
    font-size: 30px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.md} {
    font-size: 22px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.sm} {
    font-size: 31px;
    text-align: left;
  }
  ${({ theme }) => theme.adaptive.newHomePage.xs} {
    font-size: 24px;
  }
`

const Desc = styled.div`
  position: relative;
  font-weight: 300;
  font-size: 24px;
  line-height: 120%;
  text-align: center;
  letter-spacing: -0.01em;
  color: #27303b;
  width: 648px;
  margin: 0 auto;
  margin-bottom: 49px;
  ${({ theme }) => theme.adaptive.newHomePage.lg} {
    font-size: 20px;
    width: 572px;
    margin-bottom: 44px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.slg} {
    font-size: 18px;
    width: 493px;
    margin-bottom: 35px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.md} {
    font-size: 14px;
    width: 380px;
    margin-bottom: 23px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.sm} {
    font-size: 18px;
    width: 100%;
    margin-bottom: 23px;
    text-align: left;
  }
  ${({ theme }) => theme.adaptive.newHomePage.xs} {
    font-size: 15px;
    margin-bottom: 18px;
  }
  span {
    font-weight: 500;
  }
`

const Row = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ theme }) => theme.adaptive.newHomePage.sm} {
    flex-wrap: wrap;
    justify-content: flex-start;
  }
`

const Text = styled.div`
  position: relative;
  font-weight: 300;
  font-size: 24px;
  line-height: 120%;
  text-align: center;
  letter-spacing: -0.01em;
  color: #646464;
  width: 232px;
  margin-left: 31px;
  ${({ theme }) => theme.adaptive.newHomePage.lg} {
    font-size: 20px;
    width: 197px;
    margin-left: 27px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.slg} {
    font-size: 18px;
    width: 171px;
    margin-left: 23px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.md} {
    font-size: 14px;
    width: 131px;
    margin-left: 17px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.sm} {
    width: 100%;
    font-size: 18px;
    margin-left: 0;
    order: 1;
    text-align: left;
  }
  ${({ theme }) => theme.adaptive.newHomePage.xs} {
    font-size: 15px;
  }
`

const ButtonBg = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  svg {
    display: block;
    width: 100%;
    height: 100%;
    filter: drop-shadow(0px -1.17263px 0px #ffffff)
      drop-shadow(0px 117.263px 93.8102px rgba(13, 52, 89, 0.07))
      drop-shadow(0px 48.9896px 39.1917px rgba(13, 52, 89, 0.0503198))
      drop-shadow(0px 22.3363px 17.869px rgba(13, 52, 89, 0.0417275))
      drop-shadow(0px 12.5216px 10.0172px rgba(13, 52, 89, 0.035))
      drop-shadow(0px 6.6501px 5.32008px rgba(13, 52, 89, 0.0282725));
    stroke: rgba(147, 155, 169, 0.5);
    transition: 0.4s;
  }
`

const ButtonIcon = styled.div`
  position: relative;
  width: 23px;
  height: 15px;
  ${({ theme }) => theme.adaptive.newHomePage.slg} {
    width: 17px;
    height: 11px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.md} {
    width: 13px;
    height: 8px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.sm} {
    width: 19px;
    height: 12px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.xs} {
    width: 15px;
    height: 10px;
  }
  svg {
    display: block;
    width: 100%;
    height: 100%;
    fill: #939ba9;
    transition: 0.4s;
  }
`

const Button = styled.a`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 263px;
  height: 70px;
  font-family: 'NeueHaasDisplay';
  font-weight: 400;
  font-size: 24px;
  line-height: 100%;
  letter-spacing: 0.01em;
  color: #6288b4;
  padding: 22px 23px;
  z-index: 2;
  transition: 0.4s;
  ${({ theme }) => theme.adaptive.newHomePage.lg} {
    width: 225px;
    height: 60px;
    padding: 19px 21px;
    font-size: 20px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.slg} {
    width: 194px;
    height: 52px;
    padding: 14px 18px;
    font-size: 18px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.md} {
    width: 144px;
    height: 39px;
    padding: 11px 13px;
    font-size: 14px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.sm} {
    width: 214px;
    height: 62px;
    padding: 19px 19px;
    font-size: 19px;
    margin-top: 23px;
    order: 2;
  }
  ${({ theme }) => theme.adaptive.newHomePage.xs} {
    width: 167px;
    height: 48px;
    padding: 15px 16px;
    font-size: 15px;
    margin-top: 18px;
  }
  // &:hover {
  //   color: #56a0f5;
  //   ${ButtonBg} {
  //     svg {
  //       stroke: rgba(86, 160, 245, 0.5);
  //     }
  //   }
  //   ${ButtonIcon} {
  //     svg {
  //       fill: #56a0f5;
  //     }
  //   }
  // }
`

const Round = styled.div`
  position: absolute;
  top: -100px;
  left: -935px;
  width: 2647px;
  height: 1150px;
  background: url('${bgImg}') center no-repeat;
  background-size: cover;
  ${({ theme }) => theme.adaptive.newHomePage.lg} {
    top: -86px;
    left: -801px;
    width: 2269px;
    height: 986px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.slg} {
    top: -74px;
    left: -690px;
    width: 1954px;
    height: 849px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.md} {
    top: -55px;
    left: -512px;
    width: 1450px;
    height: 630px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.sm} {
    position: relative;
    top: unset;
    left: unset;
    margin: 59px auto 53px;
    width: 532px;
    height: 354px;
    background: url('${bgImgSm}') center no-repeat;
    background-size: cover;
  }
  ${({ theme }) => theme.adaptive.newHomePage.smm} {
    margin: 43px auto 68px;
    left: 50%;
    transform: translateX(-50%);
    width: 452px;
    height: 301px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.xs} {
    margin: 42px auto 53px;
    width: 353px;
    height: 235px;
  }
`

const Monk = styled.div`
  position: relative;
  width: 379px;
  height: 418px;
  background-image: url('${monkImg}');
  background-image: image-set(
    url('${monkImgWebp}') 1x,
    url('${monkImgWebp2x}') 2x,
    url('${monkImg}') 1x,
    url('${monkImg2x}') 2x
  );
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  margin: 35px 0 34px 160px;
  ${({ theme }) => theme.adaptive.newHomePage.lg} {
    width: 325px;
    height: 358px;
    margin: 30px 0 29px 138px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.slg} {
    width: 280px;
    height: 308px;
    margin: 25px 0 25px 118px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.md} {
    width: 208px;
    height: 229px;
    margin: 20px 0 20px 88px;
  }
`

const Sphere = styled.div`
  position: absolute;
  width: 206px;
  height: 203px;
  top: 110px;
  left: 125px;
  background-image: url('${sphereImg}');
  background-image: image-set(
    url('${sphereImgWebp}') 1x,
    url('${sphereImgWebp2x}') 2x,
    url('${sphereImg}') 1x,
    url('${sphereImg2x}') 2x
  );
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  ${({ theme }) => theme.adaptive.newHomePage.lg} {
    width: 176px;
    height: 174px;
    top: 94px;
    left: 107px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.slg} {
    width: 152px;
    height: 150px;
    top: 81px;
    left: 92px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.md} {
    width: 113px;
    height: 111px;
    top: 60px;
    left: 69px;
  }
`

const Item = styled.div`
  position: absolute;
  width: 268px;
  height: 417px;
  ${({ theme }) => theme.adaptive.newHomePage.lg} {
    width: 230px;
    height: 357px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.slg} {
    width: 198px;
    height: 307px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.md} {
    width: 147px;
    height: 228px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.sm} {
    width: 203px;
    height: 315px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.smm} {
    width: 172px;
    height: 268px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.xs} {
    width: 135px;
    height: 209px;
  }
  svg {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    filter: drop-shadow(0px 3.4675px 57.2138px rgba(0, 0, 0, 0.07));
    transform: translate3d(0, 0, 0);
  }
`

const ItemImg = styled.picture`
  position: relative;
  display: block;
  margin: 10px auto 0;
  width: 248px;
  height: 300px;
  img {
    width: 100%;
  }
  ${({ theme }) => theme.adaptive.newHomePage.lg} {
    margin: 12px auto 0;
    width: 213px;
    height: 257px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.slg} {
    margin: 10px auto 0;
    width: 184px;
    height: 222px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.md} {
    margin: 8px auto 0;
    width: 136px;
    height: 164px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.sm} {
    margin: 11px auto 0;
    width: 188px;
    height: 227px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.smm} {
    margin: 9px auto 0;
    width: 160px;
    height: 193px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.xs} {
    margin: 7px auto 0;
    width: 125px;
    height: 151px;
  }
`

const ItemName = styled.div`
  position: absolute;
  right: 31px;
  bottom: 20px;
  font-family: 'RPBrawl';
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  background: linear-gradient(180deg, #505d6d 0%, #3a4350 100%), #ffffff;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-transform: uppercase;
  ${({ theme }) => theme.adaptive.newHomePage.lg} {
    right: 26px;
    bottom: 18px;
    font-size: 17px;
    line-height: 20px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.slg} {
    right: 24px;
    bottom: 16px;
    font-size: 15px;
    line-height: 17px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.md} {
    right: 17px;
    bottom: 11px;
    font-size: 11px;
    line-height: 13px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.sm} {
    right: 21px;
    bottom: 16px;
    font-size: 13px;
    line-height: 15px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.smm} {
    right: 18px;
    bottom: 13px;
    font-size: 13px;
    line-height: 15px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.xs} {
    right: 14px;
    bottom: 10px;
    font-size: 10px;
    line-height: 12px;
  }
`

const ItemNumber = styled.div`
  position: absolute;
  left: 32px;
  bottom: 40px;
  font-weight: 400;
  font-family: 'RPBrawl';
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  background: linear-gradient(180deg, #505d6d 0%, #3a4350 100%), #ffffff;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-transform: uppercase;
  transform: rotate(3.5deg);
  opacity: 0.3;
  ${({ theme }) => theme.adaptive.newHomePage.lg} {
    left: 27px;
    bottom: 35px;
    font-size: 10px;
    line-height: 12px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.slg} {
    left: 23px;
    bottom: 30px;
    font-size: 9px;
    line-height: 11px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.md} {
    left: 16px;
    bottom: 22px;
    font-size: 7px;
    line-height: 8px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.sm} {
    left: 25px;
    bottom: 31px;
    font-size: 8px;
    line-height: 9px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.smm} {
    left: 21px;
    bottom: 26px;
    font-size: 8px;
    line-height: 9px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.xs} {
    left: 17px;
    bottom: 20px;
    font-size: 6px;
    line-height: 7px;
  }
`

const LeftSide = styled.div`
  position: absolute;
  top: 0;
  left: -77px;
  ${({ theme }) => theme.adaptive.newHomePage.lg} {
    left: -66px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.slg} {
    left: -57px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.md} {
    left: -42px;
  }
  ${Item} {
    &:nth-child(1) {
      top: 0;
      right: 0;
      z-index: 3;
      svg {
        fill: #ffffff;
      }
    }
    &:nth-child(2) {
      top: -70px;
      right: 138px;
      svg {
        fill: #f7f7f7;
      }
      ${ItemImg} {
        opacity: 0.7;
      }
      z-index: 2;
      ${({ theme }) => theme.adaptive.newHomePage.lg} {
        top: -60px;
        right: 118px;
      }
      ${({ theme }) => theme.adaptive.newHomePage.slg} {
        top: -52px;
        right: 102px;
      }
      ${({ theme }) => theme.adaptive.newHomePage.md} {
        top: -38px;
        right: 76px;
      }
    }
    &:nth-child(3) {
      top: -140px;
      right: 276px;
      opacity: 0.5;
      z-index: 1;
      svg {
        fill: #f7f7f7;
      }
      ${ItemImg} {
        opacity: 0.7;
      }
      ${({ theme }) => theme.adaptive.newHomePage.lg} {
        top: -120px;
        right: 236px;
      }
      ${({ theme }) => theme.adaptive.newHomePage.slg} {
        top: -104px;
        right: 204px;
      }
      ${({ theme }) => theme.adaptive.newHomePage.md} {
        top: -76px;
        right: 152px;
      }
    }
  }
`

const RightSide = styled.div`
  position: absolute;
  top: 0;
  right: -148px;
  ${({ theme }) => theme.adaptive.newHomePage.lg} {
    right: -127px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.slg} {
    right: -110px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.md} {
    right: -81px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.sm} {
    right: unset;
    left: 60px;
    top: 51px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.smm} {
    left: 48px;
    top: 47px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.xs} {
    left: 38px;
    top: 37px;
  }
  ${Item} {
    svg {
      transform: scale(-1, 1);
    }
    ${ItemName} {
      right: unset;
      left: 31px;
      ${({ theme }) => theme.adaptive.newHomePage.lg} {
        left: 27px;
      }
      ${({ theme }) => theme.adaptive.newHomePage.slg} {
        left: 23px;
      }
      ${({ theme }) => theme.adaptive.newHomePage.md} {
        left: 17px;
      }
      ${({ theme }) => theme.adaptive.newHomePage.sm} {
        left: 21px;
      }
      ${({ theme }) => theme.adaptive.newHomePage.smm} {
        left: 18px;
      }
      ${({ theme }) => theme.adaptive.newHomePage.xs} {
        left: 14px;
      }
    }
    ${ItemNumber} {
      left: unset;
      right: 32px;
      transform: rotate(-3.68deg);
      ${({ theme }) => theme.adaptive.newHomePage.lg} {
        right: 27px;
      }
      ${({ theme }) => theme.adaptive.newHomePage.slg} {
        right: 23px;
      }
      ${({ theme }) => theme.adaptive.newHomePage.md} {
        right: 17px;
      }
      ${({ theme }) => theme.adaptive.newHomePage.sm} {
        right: 25px;
      }
      ${({ theme }) => theme.adaptive.newHomePage.smm} {
        right: 21px;
      }
      ${({ theme }) => theme.adaptive.newHomePage.xs} {
        right: 17px;
      }
    }
    &:nth-child(1) {
      top: 0;
      left: 0;
      z-index: 3;
      svg {
        fill: #ffffff;
      }
    }
    &:nth-child(2) {
      top: -70px;
      left: 138px;
      svg {
        fill: #f7f7f7;
      }
      ${ItemImg} {
        opacity: 0.7;
      }
      z-index: 2;
      ${({ theme }) => theme.adaptive.newHomePage.lg} {
        top: -60px;
        left: 118px;
      }
      ${({ theme }) => theme.adaptive.newHomePage.slg} {
        top: -46px;
        left: 81px;
      }
      ${({ theme }) => theme.adaptive.newHomePage.md} {
        top: -38px;
        left: 76px;
      }
      ${({ theme }) => theme.adaptive.newHomePage.sm} {
        top: -41px;
        left: 105px;
      }
      ${({ theme }) => theme.adaptive.newHomePage.smm} {
        top: -35px;
        left: 89px;
      }
      ${({ theme }) => theme.adaptive.newHomePage.xs} {
        top: -27px;
        left: 69px;
      }
    }
    &:nth-child(3) {
      top: -140px;
      left: 276px;
      opacity: 0.5;
      svg {
        fill: #f7f7f7;
      }
      ${ItemImg} {
        opacity: 0.7;
      }
      z-index: 1;
      ${({ theme }) => theme.adaptive.newHomePage.lg} {
        top: -120px;
        left: 236px;
      }
      ${({ theme }) => theme.adaptive.newHomePage.slg} {
        top: -92px;
        left: 162px;
      }
      ${({ theme }) => theme.adaptive.newHomePage.md} {
        top: -76px;
        left: 152px;
      }
      ${({ theme }) => theme.adaptive.newHomePage.sm} {
        top: -82px;
        left: 210px;
      }
      ${({ theme }) => theme.adaptive.newHomePage.smm} {
        top: -70px;
        left: 178px;
      }
      ${({ theme }) => theme.adaptive.newHomePage.xs} {
        top: -54px;
        left: 138px;
      }
    }
  }
`

const Clouds = styled.div`
  position: absolute;
  width: 1448px;
  height: 844px;
  top: 175px;
  left: -204px;
  background-image: url('${cloudsImg}');
  background-image: image-set(
    url('${cloudsImgWebp}') 1x,
    url('${cloudsImg}') 1x
  );
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  ${({ theme }) => theme.adaptive.newHomePage.lg} {
    width: 1241px;
    height: 723px;
    top: 150px;
    left: -174px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.slg} {
    width: 1069px;
    height: 491px;
    top: 129px;
    left: -150px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.md} {
    width: 793px;
    height: 364px;
    top: 96px;
    left: -112px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.sm} {
    width: 1115px;
    height: 512px;
    top: 298px;
    left: -243px;
    background-image: url('${cloudsImgSm}');
    background-image: image-set(
      url('${cloudsImgSmWebp}') 1x,
      url('${cloudsImgSm}') 1x
    );
    background-size: cover;
  }
  ${({ theme }) => theme.adaptive.newHomePage.smm} {
    width: 947px;
    height: 435px;
    top: 420px;
    left: -248px;
  }
  ${({ theme }) => theme.adaptive.newHomePage.xs} {
    width: 739px;
    height: 340px;
    top: 328px;
    left: -200px;
  }
`
